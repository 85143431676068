//==============================================================================
export const DivDiv             = `
<div class='{{div1Class}}'>
    <div class='{{div2Class}}'>
    </div>
</div>
`
//==============================================================================
export const ItemDiv            = `
<div class='{{div1Class}}'><div class='{{div2Class}}'>
<ItemControls
    [header]                    = 'header'
    [message]                   = 'message'
    [iDef]                      = 'iDef'
    [btns]                      = 'btns'
    [item]                      = 'item'
    [debug]                     = 'debug'
></ItemControls>
</div></div>
`
//==============================================================================
export const ListDiv            = `
<div class='{{div1Class}}'><div class='{{div2Class}}'>
<ListControls
    [header]                    = 'header'
    [message]                   = 'message'
    [lDef]                      = 'lDef'
    [btns]                      = 'btns'
    [criteria]                  = 'criteria'
    [labelsOnly]                = 'labelsOnly'
    [list]                      = 'list'
    [debug]                     = 'debug'
></ListControls>
</div></div>
`
//==============================================================================
export const MixedDiv           = `
<div class='{{div1Class}}'><div class='{{div2Class}} isMixed'>
<ItemControls
    [header]                    = 'header'
    [message]                   = 'message'
    [iDef]                      = 'iDef'
    [btns]                      = 'btns'
    [item]                      = 'item'
    [debug]                     = 'debug'
></ItemControls>
<ListControls
    [lDef]                      = 'lDef'
    [btns]                      = 'noBtns'
    [criteria]                  = 'criteria'
    [labelsOnly]                = 'labelsOnly'
    [list]                      = 'list'
    [debug]                     = 'debug'
    [isMixed]                   = true
></ListControls>
</div></div>
`
//==============================================================================
