//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { WrkService                                } from './service'
import  { WrkLst                , Wrk               } from './wrkLst'
//------------------------------------------------------------------------------
let c = [ WrkLst                , Wrk
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ WrkService ]
    }
)
export class WrkModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
