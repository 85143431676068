//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { CodService                                } from './service'
import  { CodLst                , Cod               } from './codLst'
import  { SelectCod                                 } from './selectCod'
//------------------------------------------------------------------------------
let c = [ CodLst                , Cod
        , SelectCod
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ CodService ]
    }
)
export class CodModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
