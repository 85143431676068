//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { ArtService                                } from './service'
import  { ArtLst                , Art               } from './artLst'
import  { ArtDim                                    } from './artDim'
import  { ArtDimLst             , ArtDimUpd         } from './artDimLst'
import  { ArtDtl                                    } from './artDtl'
import  { ArtKpi                                    } from './artKpi'
import  { ArtPriLst             , ArtPri            } from './artPriLst'
import  { ArtRsrLst             , ArtRsr            } from './artRsrLst'
import  { ArtTktLst             , ArtTkt            } from './artTktLst'
import  { ArtVrgLst                                 } from './artVrgLst'
//------------------------------------------------------------------------------
let c = [ ArtLst                , Art
        , ArtDim
        , ArtDimLst             , ArtDimUpd
        , ArtDtl
        , ArtKpi
        , ArtPriLst             , ArtPri
        , ArtRsrLst             , ArtRsr
        , ArtTktLst             , ArtTkt
        , ArtVrgLst
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ ArtService ]
    }
)
export class ArtModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
import  { SelectArt                                 } from './selectArt'
let d = [ SelectArt ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : d
    , providers                 : [ ArtService ]
    }
)
export class SelectArtModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(d) }
}
//------------------------------------------------------------------------------
