//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemModal, WndService            } from './service'
//------------------------------------------------------------------------------
@Component({selector:'Wnd', template:ItemDiv})
export class Wnd extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:WndService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date','ols_id']
        , name          : {lab:'window name'        , wide:12               , copyCheck:true}

        , icon_img      : {opt:'icon'               , wide:12, typ:'image'}

        , cat_id        : {lab:'top category'       , wide:12, cod:'cat_paths'}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.dft.ols_id         = this.ctx.ols_id
        this.defineItem         (this.itemDefn      )
        this.buttonCancel       (this.onCancelP     )
        if (this.ctx.id) {
            this.setHeader      ('edit'             )
            this.buttonDelete   (this.onDeleteP     )
        } else {
            this.setHeader      ('add'              )
        }
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        return this.readP({ id:this.ctx.id })
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
