//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, promiseLoop
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { PstListDiv            , PstList
        , PstModalDiv           , PstModal
        , RtnService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'RtnQtyLst', template:PstListDiv})
export class RtnQtyLst extends PstList {
    //--------------------------------------------------------------------------
    constructor( public service:RtnService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    selectorDefaults    :any    = { selector: '', qty: 1000 }
    parseTask           :string = 'select_psts'
    parseBase           :any    = {}
    recalcPst                   = true
    price_reqd                  = true
    allow_zero_qtys             = false

    mergeKey                    = 'art_id'
    xrefKeys                    = 'ean_number,number,code,matchcode'.split(/\W+/g)
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { selector      : {opt:'article number or barcode'
                                                    , wide:10}
        , qty           : {opt:'quantity'           , wide: 2, typ:'quantity'}
        }
    listDefn            :any    =
        { line_num      : {lab:'line'               , wide: 1, typ:'integer'}
        , number        : {lab:'number'             , wide: 4}
        , matchcode     : {lab:'matchcode'          , wide:10}
        , code          : {lab:'code'               , wide: 2}
        , ean_number    : {lab:'ean number'         , wide: 2}
        , lnk_qty       : {lab:'delivered'          , wide: 1, typ:'quantity'}
        , rtn_qty       : {lab:'returned'           , wide: 1, typ:'quantity'}
        , qty           : {lab:'qty'                , wide: 1, typ:'quantity'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'qty_load'
    onSave_task                 = 'qty_save'
    //--------------------------------------------------------------------------
    onInitP() {
        this.ctx.canEdit        = true
        this.ctx.vrg_id         = this.ctx.id
        this.dft.vrg_id         = this.ctx.id
//         this.dft                = { vrg_id:this.ctx.id }
        this.defineItem         (this.itemDefn      )
        this.defineList         (this.listDefn, true)
        this.setHeader          (this.ctx.header    )
        this.buttonBack         (this.onBackP       )
        this.buttonSelAll       (this.onSelectAllP  )
        this.buttonInvSel       (this.onInvertSelP  )
        this.makeButton         (this.onSetSelP     , 'set selected' )
        this.makeButton         (this.onSaveP       , 'create return')
        this.formSubmit         (this.onSubmitP     )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateListP )

        return this.readP()
        .then(() => this.loadP() )
        .then(() => this.setFocusP(this.messageClear()) )
    }
    //--------------------------------------------------------------------------
    readResult(res:any) {
        this.cache.vrg          = res
        return { selector:'', qty:1000 }
    }
    //--------------------------------------------------------------------------
    onSetSelP(key:string, values:any) {
        return promiseLoop(this.list, [], (item:any, idx:number) => {
            if (!item.SELECT)  {
                return RESOLVE()
            }
            return this.applyValuesP(item, { qty:item.lnk_qty - item.rtn_qty })
            .then(() => {
                this.setItemColour(item, idx)
                return RESOLVE()
            })
        })
        .then(() => this.setFocusP(this.messageClear()) )
    }
    //--------------------------------------------------------------------------
    onSaveP(key:string, values:any) {
        return this.busyServiceEmitP(this.onSave_task,
            { vrg_id            : this.ctx.vrg_id
            , psts              : this.list
            , newOpts           : this.ctx.newOpts || {}
            , allow_zero_qtys   : this.allow_zero_qtys
            })
        .then(() => this.onBackP(false) )
    }
    //--------------------------------------------------------------------------
    setItemColour(item:any, idx:number) {
// this.log('setColour:', idx)
        let max_qty             = item.lnk_qty - item.rtn_qty
        let colour              = (item.qty == 0)       ? 'none'
                                : (item.qty > max_qty)  ? 'red'
                                                        : 'green'
// this.log('colour:', colour)
        this.set_cell_bg(idx, 'qty', colour)
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'RtnQty', template:PstModalDiv})
export class RtnQty extends PstModal {
    //--------------------------------------------------------------------------
    constructor( public service:RtnService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['idx','art_id']
        , line_num      : {rdo:'line'               , wide:12, typ:'integer'}
        , number        : {rdo:'number'             , wide:12}
        , matchcode     : {rdo:'matchcode'          , wide:12}
        , lnk_qty       : {rdo:'delivered quantity' , wide:12, typ:'quantity'}
        , rtn_qty       : {rdo:'returned quantity'  , wide:12, typ:'quantity'}
        , qty           : {opt:'quantity'           , wide:12, typ:'quantity', chg:this.on_qty}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        this.setHeader          ('edit'             )
        this.buttonPrev         (this.onPrevP       )
        this.buttonNext         (this.onNextP       )
        this.buttonCancel       (this.onCancelP     )
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        this.setSource(this.cache[this.name + 'Lst'])
        return this.setItemFromSourceP(this.ctx.idx)
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
    on_qty(key:string, value:any) {
        this.item.qty           = value
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
