//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Base                                      } from './base'
import  { DivDiv                                    } from './templates/divs'
//------------------------------------------------------------------------------
export class DivComp extends Base.Comp {}
//------------------------------------------------------------------------------
export const Div                =
{ Comp                          : DivComp
, DivDiv                        : DivDiv
}
//------------------------------------------------------------------------------
