//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, is
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , CatService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'CatSelLst', template:ListDiv})
export class CatSelLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:CatService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { sup_name      : {lab:'supplier'           , wide:10}
        , vnd_name      : {lab:'vendor'             , wide:10}
        , dsg_name      : {lab:'designer'           , wide:10}
        , brn_name      : {lab:'brand'              , wide:10}
        , wrg_name      : {lab:'product group'      , wide:10}
        , prd_selector  : {lab:'product number'     , wide:10}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'sel_load'
    //--------------------------------------------------------------------------
//     onInitP() {
//         this.defineList         (this.listDefn      )
//         this.buttonSelAll       (this.onSelectAllP  )
//         this.buttonInvSel       (this.onInvertSelP  )
//         this.buttonNew          (this.onNewP        )
//         this.onCriteria         (this.onCriteriaP   )
//         this.listClick          (this.onSelectItmP  )
//         this.listDclick         (this.onUpdateP     )
//         return this.loadAndFocusP()
//     }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'CatSel', template:ItemDiv})
export class CatSel extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:CatService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date','cat_id']
        , sup_id        : {opt:'supplier'           , wide: 6, cod:'sup'    , copyCheck:true}
        , vnd_id        : {opt:'vendor'             , wide: 6, cod:'vnd'    , copyCheck:true}

        , dsg_id        : {opt:'designer'           , wide: 6, cod:'dsg'    , copyCheck:true}
        , brn_id        : {opt:'brand'              , wide: 6, cod:'brn'    , copyCheck:true}

        , wrg_id        : {opt:'product group'      , wide: 6, cod:'wrg'    , copyCheck:true}
//         , ent_id        : {opt:'entity group id'    , wide: 6, typ:'integer', copyCheck:true}

        , prd_selector  : {opt:'product number selector (does not add implicit wildcard characters)'
                                                    , wide:12               , copyCheck:true
                                                                            , newline:true
                                                                            }
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'sel_read'
    onSave_task                 = 'sel_save'
    onDelete_task               = 'sel_dele'
    //--------------------------------------------------------------------------
//     onInitP() {
// //         this.dft.id             = this.ctx.id
//         this.dft.cat_id         = this.ctx.cat_id
//         return super.onInitP()
//     }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
