//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { WrgService                                } from './service'
import  { WrgLst                , Wrg               } from './wrgLst'
//------------------------------------------------------------------------------
let c = [ WrgLst                , Wrg
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ WrgService ]
    }
)
export class WrgModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
