//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { RptService                                } from './service'
import  { Rpt                                       } from './rpt'
import  { RptModal                                  } from './rptModal'
//------------------------------------------------------------------------------
let c = [ Rpt
        , RptModal
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ RptService ]
    }
)
export class RptModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
