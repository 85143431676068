//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { SelectDiv, SelectModal, RolService        } from './service'
//------------------------------------------------------------------------------
@Component({selector:'SelectRol', template:SelectDiv})
export class SelectRol extends SelectModal {
    //--------------------------------------------------------------------------
    constructor( public service:RolService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { value         : {opt:'role'               , wide:12}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        if (this.ctx.multi)     { this.setHeader('select roles') }
        else                    { this.setHeader('select role' ) }
        this.onInitSelect()

        return this.onSearchP('search', this.item)
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
