//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { SelectDiv, SelectModal, PrdService        } from './service'
//------------------------------------------------------------------------------
@Component({selector:'SelectPrd', template:SelectDiv})
export class SelectPrd extends SelectModal {
    //--------------------------------------------------------------------------
    constructor( public service:PrdService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { number        : {lab:'number'             , wide: 2}
        , matchcode     : {lab:'matchcode'          , wide:10}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        if (this.ctx.multi)     { this.setHeader('select products') }
        else                    { this.setHeader('select product' ) }

        this.dft.typ            = this.ctx.typ || null

        this.onInitSelect()

        if (this.emptyCriteria) {
            return this.setFocusP(this.messageNoLoad())
        }

        return this.onSearchP('search', this.item)
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
