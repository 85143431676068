//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , VrgService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'VrgEvnLst', template:ListDiv})
export class VrgEvnLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:VrgService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { valid_from    : {lab:'valid from'         , wide: 6, typ:'datetime'}
        , run_date      : {lab:'run at'             , wide: 6, typ:'datetime'}
        , run_state     : {lab:'run state'          , wide: 6}
        , evn_name      : {lab:'event'              , wide: 6}
        , npc           : {lab:'state'              , wide: 2, cls:'center'}
        , emp_name      : {lab:'user'               , wide: 6}
        , txt           : {lab:'error'              , wide:20, cls:'lines'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'evn_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn, true)

        this.defineList         (this.listDefn      )
        this.makeButton         (this.onEventP      , 'hold'  )
        this.makeButton         (this.onEventP      , 'cancel')
        this.makeButton         (this.onEventP      , 'retry' )
        this.listClick          (this.onSelectItmP  )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    onEventP(key:string, values:any) {
        return this.getSelectedIdUpdsP()
        .then((items) => this.busyServiceEmitP('evn_' + key, { items:items }) )
        .then((     ) => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
