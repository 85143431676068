//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { PstListDiv            , PstList
        , PstModalDiv           , PstModal
        , IdlService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'IdlValLst', template:PstListDiv})
export class IdlValLst extends PstList {
    //--------------------------------------------------------------------------
    constructor( public service:IdlService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    selectorDefaults    :any    = { selector: '' }
    parseTask           :string = 'select_psts'     // '' -> no parse
    parseBase           :any    = {}
    recalcPst                   = true
    price_reqd                  = true
    allow_zero_qtys             = true

    mergeKey                    = 'art_id'
//     xrefKeys                    = 'ean_number,number,code,matchcode,ext_number'.split(/\W+/g)
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { selector      : {opt:`selector (use '?' and '*' as wildcards)`
                                                    , wide: 6}
        , stuff         : {rdo:''                   , wide: 2, typ:'skip'}
//         , qty           : {opt:'quantity'           , wide: 2, typ:'quantity'}
        , net_tot       : {rdo:'total netto'        , wide: 2, typ:'decimal'}
        , brt_tot       : {rdo:'total brutto'       , wide: 2, typ:'decimal'}
        }
    listDefn            :any    =
        { line_num      : {lab:'line'               , wide: 1, typ:'integer'}
        , number        : {lab:'number'             , wide: 5}
        , matchcode     : {lab:'matchcode'          , wide:10}
        , ean_number    : {lab:'ean number'         , wide: 3}
        , ext_number    : {lab:'external sku'       , wide: 3}
        , weight        : {lab:'weight'             , wide: 2, typ:'quantity'}
        , qty           : {lab:'qty'                , wide: 1, typ:'quantity'}
        , netto         : {lab:'netto'              , wide: 2, typ:'decimal'}
        , netto_tot     : {lab:'total'              , wide: 2, typ:'decimal'}
        , cur_typ       : {lab:'cur'                , wide: 1}

        , retail_net    : {lab:'retail'             , wide: 2, typ:'decimal'}
        , margin        : {lab:'margin'             , wide: 2, typ:'decimal'}
        , markup        : {lab:'markup (%)'         , wide: 2, typ:'decimal'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'val_load'
    onRead_task                 = 'val_read'
    onSave_task                 = 'val_save'
    //--------------------------------------------------------------------------
    onInitP() {
        this.ctx.canEdit        = true
        this.ctx.vrg_id         = this.ctx.id
        this.dft.vrg_id         = this.ctx.id
//         this.dft                = { vrg_id:this.ctx.vrg_id }
        this.defineItem         (this.itemDefn      )
        this.defineList         (this.listDefn, true)
        this.setHeader          (this.ctx.header    )
        this.buttonBack         (this.onBackP       )
        this.makeButton         (this.onLabelsP     , 'print labels')
        this.makeButton         (this.onSaveP       , 'save')
        this.formSubmit         (this.onSubmitP     )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateListP )

        return this.readP()
        .then(() => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
    readResult(res:any) {
        this.cache.vrg          = res
        return this.selectorDefaults
    }
    //--------------------------------------------------------------------------
    loadResult(list:any) {
        list.forEach((item:any) => {
            this.setExtraValues(item)
        })
        return list
    }
    //--------------------------------------------------------------------------
    setExtraValues(values:any) {
        [ values.margin, values.markup ]
                                = this.service.calc_profit(values.netto, values.retail_net)
    }
    //--------------------------------------------------------------------------
    onLabelsP(key:string, values:any) {
        return this.getSelectedItemsP()
        .then((items:any) => {
            items               = items.map((item) => (
                { number        : item.number
                , matchcode     : item.matchcode
                , code          : item.ean_number || item.code
                , qty           : item.qty / 1000
                }
            ))
            items               = items.filter((item) => item.qty > 0)
            if (items.length < 1) {
                return REJECT('nothing to print')
            }
            let ctx             =
                { name          : 'sku labels'
                , items         : items
                , qtyIsSet      : true
                }
            return this.callComponent('PrtLabModal', ctx)
            .then((res:any) => this.onLabelsDone() )
        })
    }
    onLabelsDone() {
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    onSubmitP(key:string, values:any) {
        let selector            =
        this.item.selector      = values.selector.trim()
        if (selector) {
            selector            = selector.replace(/([*?])/g, (x:string) => x == '*' ? '.*' : '.')
        }
        let regex               = new RegExp(selector, 'i')
        let found               = false
        this.list.forEach((item:any, idx:number) => {
            item.SELECT         = false
            if ( !selector
              || item.ean_number == selector
              || regex.test(item.ext_number)
              || regex.test(item.number    )
              || regex.test(item.matchcode )
               ) {
                found           = true
                item.HIDDEN     = false
            } else {
                item.HIDDEN     = true
            }
        })
        return this.setFocusP('selector')
        .then(() => {
            if (found) {
                return RESOLVE()
            } else {
                this.list.forEach((item:any) => item.HIDDEN = false )
                return REJECT('no matching items')
            }
        })
    }
    //--------------------------------------------------------------------------
    applyValuesToList_extra(idx:number, values:any) {
        this.setExtraValues(values)
    }
    applyValuesToListDoneP(idx:number, item:any) {
        return this.setTotalsP()
    }
    //--------------------------------------------------------------------------
    setTotalsP() {
        let net_tot             = 0
        let brt_tot             = 0
        this.list.forEach((item:any) => {
            net_tot            += item.netto    * item.qty / 1000
            brt_tot            += item.brutto   * item.qty / 1000
        })
        return this.setItemValuesP(
                                { net_tot           : net_tot
                                , brt_tot           : brt_tot
                                })
    }
    //--------------------------------------------------------------------------
    onSaveP(key:string, values:any) {
        let selector            = this.item.selector
        if (selector) {
            return this.onSubmitP('onSaveP', { selector:'' })
            .then(() => REJECT(`'selector' has been cleared - please check the list and then click 'save' again`) )
        }
        return this.busyServiceEmitP(this.onSave_task,
            { vrg               : this.cache.vrg
            , psts              : this.list
            , allow_zero_qtys   : this.allow_zero_qtys
            })
        .then(() => this.readP() )
        .then(() => this.loadP() )
        .then(() => RESOLVE(this.messageSaved()) )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'IdlVal', template:PstModalDiv})
export class IdlVal extends PstModal {
    //--------------------------------------------------------------------------
    constructor( public service:IdlService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['idx','art_id']
        , number        : {rdo:'number'             , wide:12}
        , matchcode     : {rdo:'matchcode'          , wide:12}
        , netto         : {opt:'price (nett)'       , wide: 6, typ:'decimal' , chg:this.on_netto}
        , qty           : {rdo:'quantity'           , wide: 6, typ:'quantity'}
        , netto_tot     : {rdo:'total'              , wide: 6, typ:'decimal'}
        , retail_net    : {rdo:'retail'             , wide: 6, typ:'decimal'}
        , margin        : {rdo:'margin'             , wide: 6, typ:'decimal'}
        , markup        : {rdo:'markup (%)'         , wide: 6, typ:'decimal'}

//         , matchcode     : {opt:'matchcode'          , wide: 9                , rtn:this.onKeyP}
//         , matchcode_btn : {lab:'update'             , wide: 3                , btn:this.onKeyP}
        , ean_number    : {opt:'ean number'         , wide: 9                , rtn:this.onKeyP}
        , ean_number_btn: {lab:'update'             , wide: 3                , btn:this.onKeyP}
        , ext_number    : {opt:'external sku'       , wide: 9                , rtn:this.onKeyP}
        , ext_number_btn: {lab:'update'             , wide: 3                , btn:this.onKeyP}
        , weight        : {opt:'weight'             , wide: 9, typ:'quantity', rtn:this.onKeyP}
        , weight_btn    : {lab:'update'             , wide: 3                , btn:this.onKeyP}
//         , review_sw     : {opt:'price review reqd'  , wide: 9, typ:'boolean' , chg:this.onKeyP}
//         , review_sw_btn : {lab:'update'             , wide: 3                , btn:this.onKeyP}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        this.setHeader          ('edit'             )
        this.buttonPrev         (this.onPrevP       )
        this.buttonNext         (this.onNextP       )
        this.buttonCancel       (this.onCancelP     )
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        this.setSource(this.cache[this.name + 'Lst'])
        return this.setItemFromSourceP(this.ctx.idx)
        .then(() => this.setFocusP('netto') )
    }
    //--------------------------------------------------------------------------
    setExtraValues(values:any) {
        [ values.margin, values.markup ]
                                = this.service.calc_profit(values.netto, values.retail_net)
    }
    //--------------------------------------------------------------------------
    on_netto(key:string, value:any) {
// this.log('on_netto:', [ value, this.clone(this.item) ] )
        let item                = this.item
        if (value == item.netto) {
            return RESOLVE()
        }
        let netto               = value
        let brutto              = addpercent(netto, item.taxpct)
        let netto_tot           = Math.round((netto  * item.qty) / 1000)
        let brutto_tot          = Math.round((brutto * item.qty) / 1000)
        let [ margin, markup ]  = this.service.calc_profit(netto, item.retail_net)
        return this.setItemValuesP(
            { netto             , brutto
            , netto_tot         , brutto_tot
            , margin            , markup
            })
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
function addpercent(value, pct) {
    if (pct)                    { return value + percentage(value, pct) }
    else                        { return value                          }
}
function percentage(value, pct) {
    if (pct == 0 || value == 0) { return     0 }
    if (pct == 10000)           { return value }
    return Math.round(value * pct / 10000.0)
}
//------------------------------------------------------------------------------
