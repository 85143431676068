//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, jQuery
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ChangeDetectorRef                         } from '@angular/core'
import  { ItemDiv               , ItemModal
        , ListDiv               , ListComp
        , PriPrdService                             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'PriPrdLst', template:ListDiv})
export class PriPrdLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:PriPrdService, private cdr:ChangeDetectorRef ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { number        : {lab:'product'            , wide: 4               }
        , matchcode     : {lab:'matchcode'          , wide:10               }
        , brn_name      : {lab:'brand'              , wide: 5               }
        , sup_name      : {lab:'supplier'           , wide: 8               }
        , pri_cls_ws    : {lab:'w/s class'          , wide: 2               }
        , abc_typ       : {lab:'rev ABC'            , wide: 1, cls:'center' }
        , target_retail : {lab:'target retail'      , wide: 2, typ:'decnone'}
        , retail_base   : {lab:'base retail'        , wide: 2, typ:'decimal'}
        , diff_pct      : {lab:'difference (%)'     , wide: 2, typ:'decnone'}
        , margin        : {lab:'margin (%)'         , wide: 2, typ:'decnone'}
        , retail_new    : {lab:'new retail'         , wide: 2, typ:'decnone'}
        , retail_due    : {lab:'apply date'         , wide: 2, typ:'date'   }
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    dontLoadAll         :boolean= true
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )

        this.onCriteria         (this.onCriteriaP   )
        this.listDclick         (this.onUpdateP     )

if (this.glob.userIsDeveloper && this.emptyCriteria) {
    this.criteria.brn_name  = 'burgschneider'
    this.criteria.cur_typ   = '*'
}

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    loadResult(list:any) {
        return this.preformat_list(list)
    }
    //--------------------------------------------------------------------------
    setItemColour(item:any, idx:number) {
        let key                 = 'diff_pct'
        let value               = item[key]
        if (!value)             { return }
        return value <= -3000   ? this.set_cell_bg(idx, key, 'red'   )
             : value <= -2000   ? this.set_cell_bg(idx, key, 'orange')
             : value <= -1000   ? this.set_cell_bg(idx, key, 'yellow')
             : null
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'PriPrd', template:ItemDiv})
export class PriPrd extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:PriPrdService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['prd_id','prd_upd','pur_id','pur_upd']
        , number        : {rdo:'number'             , wide: 3, newline:true}
        , vrn_suffix    : {rdo:'base variant'       , wide: 3}
        , last_pur_date : {rdo:'last purchase'      , wide: 3, typ:'date'}
        , pur_wsale     : {rdo:'purchase price'     , wide: 3, typ:'decimal'}

        , sup_name      : {rdo:'supplier'           , wide: 6, newline:true}
        , ddp_uplift    : {rdo:'cog uplift (%)'     , wide: 3, typ:'decimal'}
        , ddp_uplift_val: {rdo:'value'              , wide: 3, typ:'decimal'}

        , matchcode     : {rdo:'matchcode'          , wide: 6, newline:true}
        , prd_uplift    : {rdo:'cog adjustment (%)' , wide: 3, typ:'decimal'}
        , prd_uplift_val: {rdo:'value'              , wide: 3, typ:'decimal'}

        , dsg_name      : {rdo:'designer'           , wide: 6, newline:true}
        , dsg_uplift    : {rdo:'designer uplift (%)', wide: 3, typ:'decimal'}
        , dsg_uplift_val: {rdo:'value'              , wide: 3, typ:'decimal'}

        , brn_name      : {rdo:'brand'              , wide: 6, newline:true}
        , brn_uplift    : {rdo:'brand uplift (%)'         , wide: 3, typ:'decimal'}
        , brn_uplift_val: {rdo:'value'              , wide: 3, typ:'decimal'}

        , pri_cls_id_ws : {lab:'w/s class'          , wide: 6, cod:'pri_cls_ws', newline:true}
        , ws_factor     : {rdo:'w/s factor'         , wide: 3, typ:'quantity'}
        , ws_factor_val : {rdo:'value'              , wide: 3, typ:'decimal'}

        , SKIP_2        : {rdo:''                   , wide: 6, typ:'skip'   , newline:true}
        , cur_tax_pct   : {rdo:'tax (%)'            , wide: 3, typ:'decimal'}
        , cur_tax_val   : {rdo:'value'              , wide: 3, typ:'decimal'}

        , SKIP_3        : {rdo:''                   , wide: 6, typ:'skip'   , newline:true}
        , margin        : {rdo:'current margin'     , wide: 3, typ:'percent'}
        , target_retail : {rdo:'target retail'      , wide: 3, typ:'decimal'}

        , retail_base   : {lab:'base retail'        , wide: 4, typ:'decimal', newline:true}
        , retail_new    : {opt:'new base retail'    , wide: 4, typ:'decimal'}
        , retail_due    : {opt:'apply date'         , wide: 4, typ:'date'   }
        }
    //--------------------------------------------------------------------------
    onInitP() {
        return super.onInitP()
        .then(() => {
            this.setHeader('product details')
            return RESOLVE()
        })
    }
    //--------------------------------------------------------------------------
    onRead_task                 = 'prd_read'
    onSave_task                 = 'prd_save'
    onDelete_task               = null
    //--------------------------------------------------------------------------
    readP(criteria:any={}) {
        return super.readP(this.ctx)
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
