//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { ModalTag                                  } from './modal'
import  { ModalElem                                 } from './service'
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , exports                   : [ ModalTag ]
    , declarations              : [ ModalTag, ModalElem ]
    }
)
export class ModalModule extends BaseModule {
    onConstructor() { this.glob.registerComponents([ ModalTag ]) }
}
//------------------------------------------------------------------------------
