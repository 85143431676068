//------------------------------------------------------------------------------
import  { Injectable                                } from '@angular/core'
//------------------------------------------------------------------------------
import * as lib from '../library'
//------------------------------------------------------------------------------
export const Imports            = lib.Imports
export const BaseModule         = lib.Base.Module

export const ItemModal          = lib.Item.Modal
export const ItemDiv            = lib.Item.Div
export const ListComp           = lib.List.Comp
export const ListDiv            = lib.List.Div
//------------------------------------------------------------------------------
@Injectable()
export class DtyCodService extends lib.Base.Service {}
//------------------------------------------------------------------------------
