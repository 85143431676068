//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { BaseComp, MenuService                     } from './service'
//------------------------------------------------------------------------------
@Component({selector:'MenuTag', templateUrl:'menu.html'})
export class MenuTag extends BaseComp {
    //--------------------------------------------------------------------------
    constructor( public service:MenuService ) {
        super()
        this.glob.registerRoot(this)
    }
    comp_type                   = 'menu'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
