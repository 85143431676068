//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , OlsService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'OlsSalLst', template:ListDiv})
export class OlsSalLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:OlsService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { descr         : {lab:'description'        , wide:10               , isKey:true}
        , startdate     : {lab:'start'              , wide: 3, typ:'date'   , isKey:true}
        , enddate       : {lab:'end'                , wide: 3, typ:'date'   , isKey:true}
        , percent       : {opt:'percent'            , wide: 2, typ:'percent', isKey:true}
        , activated     : {opt:'active'             , wide: 1, typ:'flag'   , isKey:true}
        , suspended     : {opt:'susp.'              , wide: 1, typ:'flag'   , isKey:true}

        , selectors     : {lab:'selectors / filters', wide:20, typ:'textarea', cls:'left-border'}
        }
    groupingKey                 = 'id'
    //--------------------------------------------------------------------------
    onLoad_task                 = 'sal_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.buttonSelAll       (this.onSelectAllP  )
        this.buttonInvSel       (this.onInvertSelP  )
        this.makeButton         (this.onNewP        , 'new sale'    )
        this.makeButton         (this.onNewSelP     , 'new filter'  )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )
        this.cellDclick         (this.onUpdSelP     , 'selectors'   )
        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    defaultCriteria() {
        return Object.assign({ enddate:this.getDateSince('day') }, this.ctx)
    }
    //--------------------------------------------------------------------------
    onNewSelP() {
        return this.getSelectedItemP()
        .then((item) => this.callComponent('OlsSalSel', { id:0, dsc_id:item.id }) )
        .then((item) => this.onNewDoneP(item) )
    }
    //--------------------------------------------------------------------------
    onUpdSelP(idx:number) {
this.log(`onUpdSelP(${idx})`, this.list[idx])
        let item                = this.list[idx]
        return this.callComponent('OlsSalSel', { id:item.sel_id, dsc_id:item.id })
        .then((res) => this.onUpdateDoneP(idx, res) )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'OlsSal', template:ItemDiv})
export class OlsSal extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:OlsService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , descr         : {lab:'description'        , wide:12               , copyCheck:true}

        , startdate     : {lab:'start'              , wide: 6, typ:'date'   , copyCheck:true}
        , enddate       : {lab:'end'                , wide: 6, typ:'date'   , copyCheck:true}

        , percent       : {opt:'percent'            , wide: 6, typ:'percent', newline:true}
        , activated     : {opt:'activated'          , wide: 3, typ:'boolean'}
        , suspended     : {opt:'suspended'          , wide: 3, typ:'boolean'}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'sal_read'
    onSave_task                 = 'sal_save'
    onDelete_task               = 'sal_dele'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'OlsSalSel', template:ItemDiv})
export class OlsSalSel extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:OlsService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date','dsc_id']
        , descr         : {rdo:'description'        , wide:12}

        , sup_id        : {opt:'supplier'           , wide: 4, cod:'sup'    , copyCheck:true}
        , dsg_id        : {opt:'designer'           , wide: 4, cod:'dsg'    , copyCheck:true}
        , brn_id        : {opt:'brand'              , wide: 4, cod:'brn'    , copyCheck:true}

        , wrg_id        : {opt:'product group'      , wide: 4, cod:'wrg'    , copyCheck:true}
        , rbg_id        : {opt:'discount group'     , wide: 4, cod:'rbg'    , copyCheck:true}
        , pri_cls_id_ws : {opt:'wholesale class'    , wide: 4, cod:'pri_cls_ws'
                                                                            , copyCheck:true}

        , ent_id        : {opt:'discount group'     , wide: 4, cod:'ent_dsc', copyCheck:true}

        , prd_selector  : {opt:'product number selector (does not add implicit wildcard characters)'
                                                    , wide:12               , copyCheck:true
                                                                            }
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'salSel_read'
    onSave_task                 = 'salSel_save'
    onDelete_task               = 'salSel_dele'
    //--------------------------------------------------------------------------
    onInitP() {
        this.dft.id             = this.ctx.id
        this.dft.dsc_id         = this.ctx.dsc_id
        return super.onInitP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
