//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
//------------------------------------------------------------------------------
import  { Controls                                  } from './controls'
import  { AlertModule                               } from './alert/module'
import  { BusyModule                                } from './busy/module'
import  { FlexModule                                } from './flex/module'
import  { MainModule                                } from './main/module'
import  { MenuModule                                } from './menu/module'
import  { ModalModule                               } from './modal/module'
import  { LogoModule                                } from './logo/module'
import  { LoginModule                               } from './login/module'

let e = [ Controls.Module
        , AlertModule
        , BusyModule
        , FlexModule
        , MainModule
        , MenuModule
        , ModalModule
        , LogoModule
        , LoginModule
        ]
//------------------------------------------------------------------------------
@NgModule(
    { exports                   : e
    }
)
export class LibModule {}
//------------------------------------------------------------------------------
