//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { ScpService                                } from './service'
import  { ScpLst                , Scp               } from './scpLst'
//------------------------------------------------------------------------------
let c = [ ScpLst                , Scp
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ ScpService ]
    }
)
export class ScpModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
import  { SelectScp                                 } from './selectScp'
let d = [ SelectScp
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : d
    , providers                 : [ ScpService ]
    }
)
export class SelectScpModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(d) }
}
//------------------------------------------------------------------------------
