//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , PrdService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'PrdAttLst', template:ListDiv})
export class PrdAttLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:PrdService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { typ_name      : {lab:'type'               , wide: 5}
        , typ_value     : {lab:'value'              , wide:15}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'att_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn, true)
        this.buttonNew          (this.onNewP        )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'PrdAtt', template:ItemDiv})
export class PrdAtt extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:PrdService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , typ_id        : {lab:'type'               , wide: 6, cod:'prd_att', chg:this.on_typ
//                                                                             , rtn:this.on_typ
                                                                            , copyCheck:true}
        , val_id        : {lab:'value'              , wide: 6, cod:'prd_att:1'
                                                                            , copyCheck:true}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'att_read'
    onSave_task                 = 'att_save'
    onDelete_task               = 'att_dele'
    //--------------------------------------------------------------------------
    readResult(res:any) {
        if (res && res.typ_id) {
            let typ_id          = res.typ_id
            this.iDef.val_id.cod
                                = `prd_att:${typ_id}`
        }
        return res
    }
    //--------------------------------------------------------------------------
    on_typ(key:string, typ_id:number) {
        if (!typ_id)            { return RESOLVE() }
        let current_typ_id      = parseInt(this.iDef.val_id.cod.split(':')[1])
        if (typ_id == current_typ_id)
                                { return RESOLVE() }
        this.iDef.val_id.cod    = `prd_att:${typ_id}`
        return this.setItemValuesP({ val_id:this.item.val_id })
        .then(() => this.setFocusP('val_id') )
    }
    //--------------------------------------------------------------------------
//     receivedItem(item:any) {
//         Object.assign(this.item, item)
//         if (this.item.id) {
//             this.setCache(this.item)
//             this.ctx.prd_id     = this.dft.prd_id
//                                 = this.item.id
//             this.service.showValidTabs()
//             this.service.setHeader(this.item)
//             this.showButton('copy'  )
//             this.showButton('delete')
//         }
//     }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
