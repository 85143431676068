//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { FlexService                               } from './service'
import  { FlexItem  , FlexList  , FlexModal         } from './flex'
//------------------------------------------------------------------------------
let c = [ FlexItem  , FlexList  , FlexModal
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ FlexService ]
    }
)
export class FlexModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
