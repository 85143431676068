//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { PckService                                } from './service'
import  { PckLst                , Pck               } from './pckLst'
import  { PckDtl                                    } from './pckDtl'
import  { PckPstLst                                 } from './pckPstLst'
import  { DHLModal                                  } from './dhlModal'
import  { FEDEXModal                                } from './fedexModal'
//------------------------------------------------------------------------------
let c = [ PckLst                , Pck
        , PckDtl
        , PckPstLst
        , DHLModal
        , FEDEXModal
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ PckService ]
    }
)
export class PckModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
