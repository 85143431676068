//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, $
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv               , ItemComp
        , AiqService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'Aiq', template:ItemDiv})
export class Aiq extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:AiqService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    TIMEOUT             :any    = undefined

    onInitP() {
        return this.busyServiceEmitP('get_options',
            { ctx               : this.ctx
            , NAME              : this.ctx.name || 'NAME'
            , TITLE             : this.header   || 'TITLE'
            })
        .then((res:any) => {
            if (res.options && res.options.TIMEOUT) {
                this.TIMEOUT    = res.options.TIMEOUT
            }
            let itemDefn        = res.fields
            for (let key in itemDefn) {
                if (!itemDefn[key].wide) {
                    itemDefn[key].wide
                                = 12
                }
            }
            this.defineItem     (itemDefn           )
            $('div.pagebody')
                .append(`
                    <hr>
                    <div class='row'>
                        <div class='columns six'>
                            <textarea id='res_text'></textarea>
                        </div>
                        <div class='columns six'>
                            <img      id='res_image' max-height='500px' src='/no-image.png'>
                        </div>
                    </div>`)

            this.glob.onResize()

            this.setHeader      ('=menu='           )

            this.buttonOk       (this.onSubmitP     )
            this.formSubmit     (this.onSubmitP     )

            this.item           = res.item
            return this.setFocusP()
        })
    }
    //--------------------------------------------------------------------------
    onSubmitP(key:string, values:any) {
        if (!values)            { return RESOLVE() }
        values.ctx              = this.ctx
        values.NAME             = this.ctx.name
        values.TITLE            = this.header
        values.TIMEOUT          = this.TIMEOUT
        return this.busyServiceEmitP('execute_query', values)
        .then((res:any) => {
this.log('service RES:', res)
            if (!res.text && !res.image) {
                return REJECT('no result returned')
            }
            $('#res_text' )[0].value
                                = res.text || ''
            $('#res_image')[0].src
                                = (res.image?.src)
                                    ? res.image.src
                                    : '/no-image.png'
            return RESOLVE()
        })
        .catch((err:any) => {
            this.log('downloadP:', err)
            let msg             = err.message || err.toString()
            window.alert(`creation error: ${msg}`)
            return REJECT(err)
        })
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
