//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, is
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv               , ItemModal
        , ListDiv               , ListComp
        , ArtService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'ArtRsrLst', template:ListDiv})
export class ArtRsrLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:ArtService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { name          : {lab:'location'           , wide:10}
        , shelves       : {lab:'shelves'            , wide: 5, typ:'textarea'}
        , count_date    : {lab:'last counted'       , wide: 4, typ:'date'    }
        , count_qty     : {lab:'last count'         , wide: 2, typ:'quantity', tot:true}
//         , moved         : {lab:'moved'              , wide: 2, typ:'quantity', tot:true}
        , onhand        : {lab:'onhand'             , wide: 2, typ:'quantity', tot:true}
//         , reserved      : {lab:'reserved'           , wide: 2, typ:'quantity', tot:true}
        , available     : {lab:'available'          , wide: 2, typ:'quantity', tot:true}
        , on_order      : {lab:'on order'           , wide: 2, typ:'quantity', tot:true}
        , sku_available : {lab:'sku available'      , wide: 2, typ:'quantity', tot:true}
        , sku_reserved  : {lab:'sku reserved'       , wide: 2, typ:'quantity', tot:true}
        , sku_on_order  : {lab:'sku on order'       , wide: 2, typ:'quantity', tot:true}
        , sku_expected  : {lab:'sku expected'       , wide: 2, typ:'date'    }
//         , sku_sold      : {lab:'sku sold'           , wide: 2, typ:'quantity', tot:true}
//         , sku_curr_onh  : {lab:'curr. onhand'       , wide: 2, typ:'quantity', tot:true}
//         , sku_curr_ord  : {lab:'curr. order'        , wide: 2, typ:'quantity', tot:true}
//         , sku_curr_dlv  : {lab:'curr. delivery'     , wide: 2, typ:'quantity', tot:true}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'rsr_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn, true)
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'ArtRsr', template:ItemDiv})
export class ArtRsr extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:ArtService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','art_id','rsr_id']
        , onhand        : {rdo:'current onhand'     , wide:12, typ:'quantity'}
        , available     : {rdo:'current available'  , wide:12, typ:'quantity'}
        , stocktake     : {opt:'stock count'        , wide: 9, typ:'quantity', rtn:this.onKeyP}
        , stocktake_btn : {lab:'commit'             , wide: 3                , btn:this.onKeyP}
        , shelves       : {rdo:'shelves'            , wide:12}
        , shelf         : {opt:'shelf'              , wide: 9                , rtn:this.onKeyP}
        , shelf_btn     : {lab:'add/remove'         , wide: 3                , btn:this.onKeyP}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'rsr_read'
    onSave_task                 = 'rsr_save'
    onDelete_task               = 'rsr_dele'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        // special case to refresh list even on cancel...
        this.buttonCancel       (this.onCancelP     )
        this.buttonOk           (this.onCancelP     )

        return this.readP({ id:this.ctx.id })
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
    readResult(res:any) {
        res.shelf               = ''
        this.header             = res.name
        return res
    }
    //--------------------------------------------------------------------------
    onKeyP(key:string, value:any) {
// this.log('onKeyP:', [key, value])
        if (/_btn$/.test(key)) {
            key                 = key.replace(/_btn$/,'')
            value               = value[key]
        }
        if (is.string(value)) {
            value               = value.trim()
            if (!value)         { return RESOLVE() }
        }
        return this.busyServiceEmitP('onKey',
            { key               : key
            , value             : value
            , rsr_id            : this.item.id
            , item              : this.item
            })
        .then( (res:any) => {
            return this.setItemValuesP(res)
        })
        .then( (res:any) => {
            return this.onKeyDoneP(key, res)
        })
    }
    //--------------------------------------------------------------------------
    onCancelP() {
this.log('onCancelP')
        return this.onSubmitDoneP(this.item)
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
