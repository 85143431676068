//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { RgnService                                } from './service'
import  { RgnLst                , Rgn               } from './rgnLst'
import  { RgnShpLst             , RgnShp            } from './rgnShpLst'
//------------------------------------------------------------------------------
let c = [ RgnLst                , Rgn
        , RgnShpLst             , RgnShp
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ RgnService ]
    }
)
export class RgnModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
