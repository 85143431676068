//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemComp, KntService             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'KntCusPtn', template:ItemDiv})
export class KntCusPtn extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:KntService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , iban          : {opt:'iban'               , wide: 3}
        //----------------------------------------------------------------------
        , paypal_email  : {opt:'paypal email'       , wide: 3               , section:true}
        //----------------------------------------------------------------------
        , bank_name     : {opt:'bank name'          , wide: 3               , section:true}
        , bank_bic      : {opt:'bic / swift code'   , wide: 3}
        , bank_acc_name : {opt:'account name'       , wide: 3}
        , bank_acc_number
                        : {opt:'account number'     , wide: 3}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'ptn_read'
    onSave_task                 = 'ptn_save'
    onDelete_task               = 'ptn_dele'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        this.buttonDelete       (this.onDeleteP     )
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        return this.readP()
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
    receivedItem(item:any) {
        super.receivedItem(item)
        if (item.id) {
            this.showButton('delete')
        } else {
            this.hideButton('delete')
        }
    }
    //--------------------------------------------------------------------------
    onDeleteDoneP(res:any) {
        this.receivedItem(res)
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
