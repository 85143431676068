//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , RgnService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'RgnShpLst', template:ListDiv})
export class RgnShpLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:RgnService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { rgn_name      : {lab:'region'             , wide: 4}
        , shp_cls_name  : {lab:'shipping class'     , wide:10}
        , shp_fee_name  : {lab:'fee type'           , wide:10}
        , brutto        : {lab:'fee'                , wide: 2, typ:'decimal'}
        , threshold     : {lab:'threshold'          , wide: 2, typ:'decimal'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'shp_load'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'RgnShp', template:ItemDiv})
export class RgnShp extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:RgnService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , rgn_id        : {lab:'region'             , wide: 6, cod:'rgn'    , copyCheck:true}
        , shp_cls_id    : {lab:'shipping class'     , wide: 6, cod:'shp_cls', copyCheck:true}

        , shp_fee_id    : {lab:'fee type'           , wide: 6, cod:'shp_fee'}
        , brutto        : {opt:'fee (brutto)'       , wide: 6, typ:'decimal'}

        , threshold     : {opt:'threshold'          , wide: 6, typ:'decimal'}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'shp_read'
    onSave_task                 = 'shp_save'
    onDelete_task               = 'shp_dele'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
