//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemComp, ArtService             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'ArtDtl', template:ItemDiv})
export class ArtDtl extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:ArtService ) { super() }
    comp_type                   = 'maintab'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date','prd_id']
        , RDO           : true
        , number        : {lab:'number'             , wide: 2               , copyCheck:true}
        , matchcode     : {lab:'matchcode'          , wide: 4               , copyCheck:true}
        , typ_id        : {lab:'type'               , wide: 3, cod:'artXfam', copyCheck:true}
        , mne_id        : {lab:'units'              , wide: 3, cod:'mne'    }


        , txt           : {rdo:'text (english)'     , wide:12, typ:'textarea', newline:true}


        , brn_id        : {lab:'brand'              , wide: 3, cod:'brn'    , newline:true}
        , dsg_id        : {opt:'designer'           , wide: 3, cod:'knt_sup'}
        , vnd_id        : {opt:'vendor'             , wide: 3, cod:'vnd'    }
        , coo_id        : {opt:'country of origin'  , wide: 3, cod:'lnd'    }


        , sup_id        : {opt:'supplier'           , wide: 3, cod:'knt_sup', newline:true}
        , on_behalf     : {opt:'on-behalf'          , wide: 1, typ:'boolean'}
        , purch_pct     : {opt:'purchase %'         , wide: 2, typ:'decimal'}
        , brutto        : {opt:'brutto'             , wide: 3, typ:'decimal'}


        , wrg_id        : {lab:'group'              , wide: 3, cod:'wrg'    , newline:true}
        , ean_number    : {opt:'ean number'         , wide: 3               , copyCheck:true}
        , ext_number    : {opt:'external sku'       , wide: 3}


        , last_pur_date : {rdo:'last purchase date' , wide: 3, typ:'date'   , newline:true}
        , pur_netto     : {opt:'purchase netto'     , wide: 3, typ:'decimal'}
        , apr_average   : {rdo:'purchase average'   , wide: 3, typ:'decimal'}


        , lnd_id_tax    : {opt:'tax country'        , wide: 3, cod:'lnd'    , newline:true}
        , art_tax_id    : {lab:'tax (article)'      , wide: 3, cod:'art_tax'}
        , dty_id        : {lab:'hs code'            , wide: 3, cod:'dty'    }


        , status        : {rdo:'status'             , wide: 3               , newline:true}


//         , sort_no       : {opt:'sort number'        , wide: 3, typ:'integer'}
//         , netto         : {rdo:'nett price'         , wide: 3, typ:'decimal'}
//         , height        : {opt:'height (cm)'        , wide: 3, typ:'integer'}
//         , width         : {opt:'width (cm)'         , wide: 3, typ:'integer'}
//         , depth         : {opt:'depth (cm)'         , wide: 3, typ:'integer'}
//         , weight        : {opt:'weight (kg)'        , wide: 3, typ:'quantity'}
//         , min_order_qty : {opt:'min. order qty'     , wide: 3, typ:'quantity'}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        this.buttonPrev         (this.onPrevP       )
        this.buttonNext         (this.onNextP       )
//         if (this.ctx.art_id) {
//             this.buttonCopy     (this.onCopyP       )
//             this.buttonDelete   (this.onDeleteP     )
//         }
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        return this.readP()
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
    onPrevP(key:string, values:any) {
        return this.readAdjacent('desc')
    }
    //--------------------------------------------------------------------------
    onNextP(key:string, values:any) {
        return this.readAdjacent('asc')
    }
    //--------------------------------------------------------------------------
    readAdjacent(direction:string) {
        return this.busyServiceEmitP('read_adjacent',
            { art_id            : this.ctx.art_id
            , direction         : direction
            })
        .then((res:any) => {
            res                 = this.readResult(res)
            if (res) {
                this.receivedItem(res)
            }
            return RESOLVE()
        })
    }
    //--------------------------------------------------------------------------
    receivedItem(item:any) {
        Object.assign(this.item, item)
        this.ctx.art_id         =
        this.dft.art_id         = this.item.id
        this.setCache(this.item)
        this.service.setHeader(this.item)
        if (this.item.id) {
            this.service.showValidTabs()
            this.enableField ('number'    )
            this.enableField ('ean_number')
        }
        this.enableField ('pur_netto')
//         if (this.item.last_pur_date) {
//             this.disableField('pur_netto')
//         } else {
//             this.enableField ('pur_netto')
//         }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
