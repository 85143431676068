//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, list_div, jQuery
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , CurService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'CurRndLst', template:ListDiv})
export class CurRndLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:CurService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { cur_name      : {lab:'currency'           , wide: 2}
        , from_amt      : {lab:'from amount'        , wide: 2, typ:'decimal'}
        , rounding      : {lab:'round up to'        , wide: 2, typ:'decimal'}
        , adjust        : {lab:'adjustment'         , wide: 2, typ:'decimal'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'cur_rnd_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.buttonNew          (this.onNewP        )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'CurRnd', template:ItemDiv})
export class CurRnd extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:CurService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , cur_id        : {lab:'currency'           , wide:12, cod:'cur'    , copyCheck:true}
        , from_amt      : {opt:'from amount'        , wide:12, typ:'decimal', copyCheck:true}
        , rounding      : {lab:'round up to'        , wide:12, typ:'decimal'}
        , adjust        : {opt:'adjustment'         , wide:12, typ:'decimal'}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'cur_rnd_read'
    onSave_task                 = 'cur_rnd_save'
    onDelete_task               = 'cur_rnd_dele'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
