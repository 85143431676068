//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { EntService                                } from './service'
import  { EntLst                , Ent               } from './entLst'
import  { EntAttLst             , EntAtt            } from './entAttLst'
//------------------------------------------------------------------------------
let c = [ EntLst                , Ent
        , EntAttLst             , EntAtt
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ EntService ]
    }
)
export class EntModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
import  { SelectEnt                                 } from './selectEnt'
let d = [ SelectEnt ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : d
    , providers                 : [ EntService ]
    }
)
export class SelectEntModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(d) }
}
//------------------------------------------------------------------------------
