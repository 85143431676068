//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { EmlService                                } from './service'
import  { EmlAccLst             , EmlAcc            } from './emlAccLst'
import  { EmlTmpLst             , EmlTmp            } from './emlTmpLst'
//------------------------------------------------------------------------------
let c = [ EmlAccLst             , EmlAcc
        , EmlTmpLst             , EmlTmp
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ EmlService ]
    }
)
export class EmlModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
