//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, is
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv, ListComp, EmpService             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'EmpRolLst', template:ListDiv})
export class EmpRolLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:EmpService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { name          : {lab:'roles'              , wide:20}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'rol_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn, true)
        this.buttonSelAll       (this.onSelectAllP  )
        this.buttonInvSel       (this.onInvertSelP  )
        this.buttonRemSel       (this.onRemoveP     )
        this.buttonAdd          (this.onAddP        )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    onAddP() {
        let ctx                 =
            { selector          : ''
            , foundItems        : []
            , multi             : true
            }
        return this.callComponent('SelectRol', ctx)
        .then((res:any) => {
            if (!res)           { return RESOLVE() }
            if (is.object(res)) { res = [ res ] }
            return this.busyServiceEmitP('rol_add_links',
                { emp_id        : this.ctx.emp_id
                , rol_ids       : this.extractKeys(res)
                })
            .then(() => this.loadAndFocusP() )
        })
    }
    //--------------------------------------------------------------------------
    onRemoveP(key:string, values:any) {
        return this.getSelectedIdUpdsP({ confirm:'removal' })
        .then((items) => this.busyServiceEmitP('rol_remove_links', { items:items }) )
        .then((     ) => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
