//------------------------------------------------------------------------------
import  { Injectable                                } from '@angular/core'
//------------------------------------------------------------------------------
import * as lib from '../library'
//------------------------------------------------------------------------------
export const Imports            = lib.Imports
export const BaseModule         = lib.Base.Module

export const ItemComp           = lib.Item.Comp
export const ItemDiv            = lib.Item.Div
export const PstList            = lib.Pst.List
export const PstListDiv         = lib.Pst.ListDiv
export const PstModal           = lib.Pst.Modal
export const PstModalDiv        = lib.Pst.ModalDiv
//------------------------------------------------------------------------------
@Injectable()
export class StkService extends lib.Base.Service {
    //--------------------------------------------------------------------------
    allTabs             :any    = 'dtl pst evn'.split(/\W+/)
    validTabs           :any    = this.allTabs
    //--------------------------------------------------------------------------
    setHeader(values:any) {
        this.ctx.header         = (!values.id)      ? 'new...'
                                                    : values.number
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
