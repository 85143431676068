//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , CatTemplate           , TabbedComp
        , CatService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'CatLst', template:ListDiv})
export class CatLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:CatService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { name          : {lab:'name'               , wide:10}
        , banner_src    : {lab:'banner'             , wide:15, typ:'image'}
        , thumb_src     : {lab:'thumbnail'          , wide: 5, typ:'image'}
        , slider_src    : {lab:'slider'             , wide:10, typ:'image'}
        , video_url     : {lab:'video'              , wide:10}
        , sort_no       : {lab:'sort'               , wide: 1, typ:'integer'}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.dft.cat_id         = this.ctx.cat_id
        this.defineList         (this.listDefn      )
        this.setHeader          (this.ctx.header || '=menu=')
        this.buttonBack         (this.onBackP       )
        this.buttonNew          (this.onNewP        )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )
        this.listButton_cell    ('name', 'children', this.whenChildren, this.onChildrenP, 'floatr')

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    whenChildren(item:any) {
        return true
    }
    onChildrenP(idx:number) {
        let item                = this.list[idx]
        let header              = this.ctx.header || this.glob.menu.title
        let ctx                 = { cat_id          : item.id
                                  , header          : `${header} / ${item.name}`
                                  }
        return this.callComponent('CatLst', ctx)
        .then((res:any) => this.onChildrenDoneP(res) )
    }
    onChildrenDoneP(res:any) {
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    onNewCompName               = 'Cat'
//     onNewP() {
//         let ctx                 = this.onNewContext()
//          return this.callComponent('Cat', ctx)
//         .then((res:any) => this.onNewDoneP(res) )
//     }
    onNewContext() {
        return { cat_id:0 }
    }
    //--------------------------------------------------------------------------
    onUpdateCompName            = 'Cat'
//     onUpdateP(idx:number) {
//         let ctx                 = this.onUpdateContext(idx)
//         return this.callComponent('Cat', ctx)
// //         .then((res:any) => this.onUpdateDoneP(idx, res) )
//     }
    onUpdateContext(idx:number) {
        return { cat_id:this.list[idx].id }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Cat', template:CatTemplate})
export class Cat extends TabbedComp {
    //--------------------------------------------------------------------------
    constructor( public service:CatService ) { super() }
    //--------------------------------------------------------------------------
    onInitP() {
        this.service.initTabs(this.ctx.id, 'dtl')
//         this.ctx.cat_id         = this.ctx.id
        this.dft.cat_id         = this.ctx.cat_id
        this.cache              = {}
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
