<form ref-form='ngForm'
  (ngSubmit)='onSubmit(form)'
  (keydown.meta.arrowleft) ='glob.onPrev(form)' (keydown.meta.bracketleft) ='glob.onPrev(form)'
  (keydown.meta.arrowright)='glob.onNext(form)' (keydown.meta.bracketright)='glob.onNext(form)'
>
  <div class='pagehead'>
    <div class='hidden-submit'><input type='submit' tabindex='-1'/></div>
    <span                   class='actions top floatr {{header && "header"}}'>
      <button *ngIf ='iD.forceEdit'
                            class='btn forceEdit'
                            (click)='iD.forceEdit()'>force edit</button>
      <button *ngIf ='iD.onDump'
                            class='btn'
                            (click)='iD.onDump()'>dump</button>
      <button *ngFor='let btn of btns.tl'
                            class='{{btn.cls}} {{btn.offClass}} {{btn.hide}}'
                            (click)='btn.CLICK(form, $event)'>{{btn.lab}}</button>
      <button *ngFor='let btn of btns.tr'
                            class='{{btn.cls}} {{btn.offClass}} {{btn.hide}}'
                            (click)='btn.CLICK(form, $event)'>{{btn.lab}}</button>
    </span>
    <h3       *ngIf ='btns.bk.length || header'>
      <button *ngFor='let btn of btns.bk'
                            class='btn back {{btn.offClass}} {{btn.hide}}'
                            (click)='btn.CLICK(form, $event)'>{{glob.backArrow}}</button>
      <span   *ngIf ='header'>{{header}}</span>
    </h3>

    <span                   class='message {{message.cls}}'>{{message.text}}&nbsp;</span>
    <span class='u-cf'></span>
  </div>
  <div class='rows container pagebody'>
    <div      *ngFor='let row of iD.ROWS'>
      <hr     *ngIf ='row=="<HR>"' class='item-hr'>
      <div    *ngIf ='row!="<HR>"' class='row'>
        <div  *ngFor='let key of row'
                        [ngSwitch]   ='iD[key].typ' class='{{iD[key].divCls}}'>
          <div          *ngSwitchCase='"btn"'       ><br/><button type='button' class='btn' (click)='iD[key].onBUTTON(key, form.value)'>{{iD[key].lab}}</button></div>
          <div          *ngSwitchCase='"skip"'      ><br/></div>
          <BooleanIC    *ngSwitchCase='"boolean"'   [def]=iD[key] ngModel [datum]='item[key]' [name]='key' [rdo]='iD[key].rdo'></BooleanIC  >
          <CodIC        *ngSwitchCase='"cod"'       [def]=iD[key] ngModel [datum]='item[key]' [name]='key' [rdo]='iD[key].rdo'
                                                                                                           [cod]='iD[key].cod'></CodIC      >
          <CodeIC       *ngSwitchCase='"code"'      [def]=iD[key] ngModel [datum]='item[key]' [name]='key' [rdo]='iD[key].rdo'></CodeIC     >
          <DateIC       *ngSwitchCase='"date"'      [def]=iD[key] ngModel [datum]='item[key]' [name]='key' [rdo]='iD[key].rdo'></DateIC     >
          <DatetimeIC   *ngSwitchCase='"datetime"'  [def]=iD[key] ngModel [datum]='item[key]' [name]='key' [rdo]='iD[key].rdo'></DatetimeIC >
          <DecimalIC    *ngSwitchCase='"decimal"'   [def]=iD[key] ngModel [datum]='item[key]' [name]='key' [rdo]='iD[key].rdo'></DecimalIC  >
<!--           <EditorIC     *ngSwitchCase='"editor"'    [def]=iD[key] ngModel [datum]='item[key]' [name]='key' [rdo]='iD[key].rdo'></EditorIC   > -->
          <ExchrateIC   *ngSwitchCase='"exchrate"'  [def]=iD[key] ngModel [datum]='item[key]' [name]='key' [rdo]='iD[key].rdo'></ExchrateIC >
          <ImageIC      *ngSwitchCase='"image"'     [def]=iD[key] ngModel [datum]='item[key]' [name]='key' [rdo]='iD[key].rdo'></ImageIC    >
          <IntegerIC    *ngSwitchCase='"integer"'   [def]=iD[key] ngModel [datum]='item[key]' [name]='key' [rdo]='iD[key].rdo'></IntegerIC  >
          <IntegerIC    *ngSwitchCase='"intnone"'   [def]=iD[key] ngModel [datum]='item[key]' [name]='key' [rdo]='iD[key].rdo'></IntegerIC  >
          <PasswordIC   *ngSwitchCase='"password"'  [def]=iD[key] ngModel [datum]='item[key]' [name]='key' [rdo]='iD[key].rdo'></PasswordIC >
          <DecimalIC    *ngSwitchCase='"percent"'   [def]=iD[key] ngModel [datum]='item[key]' [name]='key' [rdo]='iD[key].rdo'></DecimalIC  >
          <QuantityIC   *ngSwitchCase='"quantity"'  [def]=iD[key] ngModel [datum]='item[key]' [name]='key' [rdo]='iD[key].rdo'></QuantityIC >
          <TextIC       *ngSwitchDefault            [def]=iD[key] ngModel [datum]='item[key]' [name]='key' [rdo]='iD[key].rdo'></TextIC     >
          <TextareaIC   *ngSwitchCase='"textarea"'  [def]=iD[key] ngModel [datum]='item[key]' [name]='key' [rdo]='iD[key].rdo' [rows]='iD[key].rows'
                                                                                                                              ></TextareaIC >
          <TimeIC       *ngSwitchCase='"time"'      [def]=iD[key] ngModel [datum]='item[key]' [name]='key' [rdo]='iD[key].rdo'></TimeIC     >
          <TimestampIC  *ngSwitchCase='"timestamp"' [def]=iD[key] ngModel [datum]='item[key]' [name]='key' [rdo]='iD[key].rdo'></TimestampIC>
        </div>
      </div>
    </div>
  </div>
  <div class='pagefoot'>
    <span                   class='actions bot floatl'>
      <button *ngFor='let btn of btns.bl'
                            class='{{btn.cls}} {{btn.offClass}} {{btn.hide}}'
                            (click)='btn.CLICK(form, $event)'>{{btn.lab}}</button>
    </span>
    <span                   class='actions bot floatr'>
      <button *ngFor='let btn of btns.br'
                            class='{{btn.cls}} {{btn.offClass}} {{btn.hide}}'
                            (click)='btn.CLICK(form, $event)'>{{btn.lab}}</button>
      <input *ngFor='let btn of btns.ok'
                            class='{{btn.cls}} {{btn.offClass}} {{btn.hide}}'
                            type='submit' value='{{btn.lab}}'>
    </span>
    <input    *ngFor='let key of iD.HIDE' type='hidden' [(ngModel)]='item[key]' [name]='key'>
    <input    class='key_catcher u-cf'>
  </div>
</form>
<div *ngIf='debug'>value:{{form.value|json}}, item:{{item|json}}, valid:{{form.valid}}, off:{{isOff}}</div>
