//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemComp, UtlService             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'Utl', template:ItemDiv})
export class Utl extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:UtlService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    TIMEOUT             :any    = undefined

    onInitP() {
        return this.busyServiceEmitP('get_options',
            { ctx               : this.ctx
            , NAME              : this.ctx.name || 'NAME'
            })
        .then((res:any) => {
            if (res.options && res.options.TIMEOUT) {
                this.TIMEOUT    = res.options.TIMEOUT
            }
            for (let key in res.fields) {
                res.fields[key].wide
                                = 12
            }
            this.defineItem     (res.fields         )
            this.setHeader      ('=menu='           )

            this.buttonOk       (this.onSubmitP     )
            this.formSubmit     (this.onSubmitP     )

            this.item           = res.item
            return RESOLVE()
        })
    }
    //--------------------------------------------------------------------------
    onSubmitP(key:string, values:any) {
        // values = { <document options> }
        if (!values) {
            return RESOLVE()
        }
        values.ctx              = this.ctx
        values.NAME             = this.ctx.name
        values.TIMEOUT          = this.TIMEOUT

        let prefix              = `[${this.header}]`
        setTimeout(() => this.messageClear(), 5000)
        this.messageDone(`${prefix} request submitted`)
        return this.serviceEmitP('execute', values)
        .then((res:any) => {
            if (!res) {
                window.alert(`${prefix} result is empty`)
            } else {
                window.alert(`${prefix} result:\n${res}`)
            }
            return RESOLVE()
        })
        .catch((err:any) => {
            window.alert(`${prefix} error:\n${err}`)
            return REJECT(err)
        })
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
