//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { CpaService                                } from './service'
import  { CpaLst                , Cpa               } from './cpaLst'
import  { CpaDtl                                    } from './cpaDtl'
import  { CpaItmLst             , CpaItm            } from './cpaItmLst'
//------------------------------------------------------------------------------
let c = [ CpaLst                , Cpa
        , CpaDtl
        , CpaItmLst             , CpaItm
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ CpaService ]
    }
)
export class CpaModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
