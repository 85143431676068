//------------------------------------------------------------------------------
import  { Injectable                                } from '@angular/core'
//------------------------------------------------------------------------------
import * as lib from '../library'
//------------------------------------------------------------------------------
export const Imports            = lib.Imports
export const BaseModule         = lib.Base.Module

export const ItemComp           = lib.Item.Comp
export const ItemDiv            = lib.Item.Div
export const ItemModal          = lib.Item.Modal
export const ListComp           = lib.List.Comp
export const ListDiv            = lib.List.Div
export const SelectModal        = lib.Select.Modal
export const SelectDiv          = lib.Select.Div
export const TabbedComp         = lib.Base.Tabbed
//------------------------------------------------------------------------------
@Injectable()
export class EmpService extends lib.Base.Service {
    //--------------------------------------------------------------------------
    allTabs             :any    = 'dtl rol'.split(/\W+/)
    validTabs           :any    = this.allTabs
    //--------------------------------------------------------------------------
    setHeader(values:any) {
        this.ctx.header         = (!values.id)      ? 'new...'
                                                    : `${values.name}: ${values.email}`
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
export const EmpDiv             = `
<div class='tabs container'>
  <div class='tabshead'>
    <h3 class='header floatl'>
      <button               class='btn back'
                            (click)='popClick()'
      >{{glob.backArrow}}</button>{{ctx.header}}</h3>
    <div class='u-cf'></div>
  </div>
  <div class='tabstabs'>
    <div                    (click)='stabSel("dtl")' class='{{stabCls("dtl")}}'>details  </div>
    <div *ngIf='stabs.rol'  (click)='stabSel("rol")' class='{{stabCls("rol")}}'>roles    </div>
  </div>
  <div class='tabsbody' [ngSwitch]='stab'>
    <EmpDtl    *ngSwitchDefault     ></EmpDtl   >
    <EmpRolLst *ngSwitchCase='"rol"'></EmpRolLst>
  </div>
</div>
`
//------------------------------------------------------------------------------
