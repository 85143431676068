//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { PriDefService                             } from './service'
import  { PriDefLst             , PriDef            } from './priDefLst'
//------------------------------------------------------------------------------
let c = [ PriDefLst             , PriDef
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ PriDefService ]
    }
)
export class PriDefModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
