//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemComp, CatService             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'CatDtl', template:ItemDiv})
export class CatDtl extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:CatService ) { super() }
    comp_type                   = 'maintab'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , name          : {lab:'name'               , wide: 9               , copyCheck:true}
        , sort_no       : {opt:'sort'               , wide: 3, typ:'integer'}

        , id_to         : {opt:'member of'          , wide:12, cod:'cat_paths'}

        , banner_img    : {opt:'banner'             , wide: 6, typ:'image'}
        , thumb_img     : {opt:'thumbnail'          , wide: 2, typ:'image'}
        , slider_img    : {opt:'slider'             , wide: 4, typ:'image'}

        , video_url     : {opt:'video url'          , wide:12}

        , descr         : {opt:'txt'                , wide:12, typ:'textarea'}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        if (this.ctx.cat_id) {
            this.buttonCopy     (this.onCopyP       )
            this.buttonDelete   (this.onDeleteP     )
        }
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        return this.readP()
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
    receivedItem(item:any) {
        Object.assign(this.item, item)
        this.ctx.id             =
        this.ctx.cat_id         =
        this.dft.cat_id         = this.item.id
        this.setCache(this.item)
        this.service.setHeader(this.item)
        if (this.item.id) {
            this.service.showValidTabs()
            this.showButton('copy'  )
            this.showButton('delete')
        } else {
            this.hideButton('copy'  )
            this.hideButton('delete')
        }
    }
    //--------------------------------------------------------------------------
    onCopyP() {
        return this.setItemValuesP({ id:0 })
        .then(() => {
            this.ctx.id         =
            this.ctx.cat_id     =
            this.dft.cat_id     = this.item.id
            this.setCache(this.item)
            this.service.setHeader(this.item)
            this.service.initTabs(this.item.id, 'dtl')
            this.hideButton('copy'  )
            this.hideButton('delete')
            return this.setFocusP()
        })
    }
    //--------------------------------------------------------------------------
    onDeleteDoneP(res:any) {
        return this.onBackP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
