//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , EmpDiv                , TabbedComp
        , EmpService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'EmpLst', template:ListDiv})
export class EmpLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:EmpService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { knt_name      : {lab:'employee'           , wide:10}
        , email         : {lab:'email'              , wide:10}
        , name          : {lab:'username'           , wide: 5}
        , filters       : {lab:'filters'            , wide:10}
        , roles         : {lab:'roles'              , wide:10, typ:'textarea'}
        , using_2fa     : {lab:'2fa'                , wide: 1}
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Emp', template:EmpDiv})
export class Emp extends TabbedComp {
    //--------------------------------------------------------------------------
    constructor( public service:EmpService ) { super() }
    //--------------------------------------------------------------------------
    onInitP() {
        this.service.initTabs(this.ctx.id, 'dtl')
        this.ctx.emp_id         =
        this.dft.emp_id         = this.ctx.id
        this.cache              = {}
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
