//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemComp, KntService             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'KntEmpDtl', template:ItemDiv})
export class KntEmpDtl extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:KntService ) { super() }
    comp_type                   = 'maintab'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date','typ_id','number','knt_id_rep','knt_id_agt','adr_id','adr_update_date','emp_id']
        , anr_id        : {lab:'salutation'         , wide: 3, cod:'anr'    }
        , title         : {opt:'title'              , wide: 3}
        , forename      : {opt:'forename'           , wide: 3}
        , surname       : {lab:'surname'            , wide: 3}
        //----------------------------------------------------------------------
        , email         : {opt:'account email'      , wide: 3               , section:true}
        , emp_name      : {lab:'username'           , wide: 3}
        , password      : {opt:'password'           , wide: 3, typ:'password'}
        //----------------------------------------------------------------------
        , adr_name      : {lab:'address name'       , wide: 6               , section:true}
        , adr_descr     : {opt:'description'        , wide: 6}

        , adr_street    : {lab:'street'             , wide: 3               , newline:true}
        , adr_postcode  : {lab:'postcode'           , wide: 3}
        , adr_city      : {lab:'city'               , wide: 3}
        , adr_lnd_id    : {lab:'country'            , wide: 3, cod:'lnd'    }

        , adr_email     : {opt:'address email'      , wide: 3               , newline:true}
        , adr_tel_num   : {opt:'telephone'          , wide: 3}

        , adr_rsr_id    : {opt:'location'           , wide: 3, cod:'rsr'    , newline:true}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        if (this.ctx.id) {
            this.buttonDelete   (this.onDeleteP     )
        }
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        return this.readP()
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
    receivedItem(item:any) {
        Object.assign(this.item, item)
        if (this.item.id) {
            this.setCache(this.item)
            this.ctx.knt_id     =
            this.dft.knt_id     = this.item.id
            this.service.showValidTabs()
            this.service.setHeader(this.item)
        }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
