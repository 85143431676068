//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , ScpService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'ScpLst', template:ListDiv})
export class ScpLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:ScpService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { descr         : {lab:'name'               , wide: 5}
        , feature       : {lab:'module'             , wide: 2}
        , params        : {lab:'context (json)'     , wide:10}
        , sort_no       : {lab:'sort'               , wide: 1, typ:'integer'}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.dft.menu_id        = this.ctx.menu_id
        this.defineList         (this.listDefn      , true)
        this.setHeader          (this.ctx.header || '=menu=')
        this.buttonBack         (this.onBackP       )
        this.buttonNew          (this.onNewP        )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )
        this.listButton_cell    ('feature', 'children', this.whenChildren, this.onChildrenP)

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    whenChildren(item:any) {
        return item.feature == ''
    }
    onChildrenP(idx:number) {
        let item                = this.list[idx]
        let header              = this.ctx.header || this.glob.menu.title
        let ctx                 = { menu_id         : item.id
                                  , header          : `${header} / ${item.descr}`
                                  }
        return this.callComponent('ScpLst', ctx)
        .then((res:any) => this.onChildrenDoneP(idx, res) )
    }
    onChildrenDoneP(idx:number, res:any) {
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Scp', template:ItemDiv})
export class Scp extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:ScpService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , descr         : {lab:'name'               , wide: 6}
        , sort_no       : {opt:'sort'               , wide: 6, typ:'integer'}

        , id_to         : {opt:'group'              , wide: 6, cod:'scp_menus'
                                                                            , copyCheck:true}
        , feature       : {opt:'module'             , wide: 6               , copyCheck:true}

        , params        : {opt:'context (json)'     , wide:12}
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
