//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , AttService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'AttLst', template:ListDiv})
export class AttLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:AttService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { typ           : {lab:'type'               , wide: 3}
//         , subtyp        : {lab:'subtype'            , wide: 3}
        , name          : {lab:'name'               , wide: 6}
        , descr         : {lab:'description'        , wide: 6}
//         , txt           : {lab:'alpha'              , wide: 6}
//         , num           : {lab:'numeric'            , wide: 1, typ:'integer'}
        , sort_no       : {lab:'sort'               , wide: 1, typ:'integer'}
        }
//     dontLoadAll         :boolean= true
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.buttonNew          (this.onNewP        )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        let tab                 = this.ctx.attribute || 'prd_att'
        if (!/^prd_att(-\w+)?$/.test(tab)) {
            if (/^\w+$/.test(tab)) {
                tab             = 'prd_att-' + tab
            } else {
                return REJECT('invalid table (tab) value in context')
            }
        }
        this.dft.tab            = tab

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Att', template:ItemDiv})
export class Att extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:AttService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , typ           : {opt:'type'               , wide: 6               , copyCheck:true}
        , sort_no       : {opt:'sort'               , wide: 6, typ:'integer'}

        , name          : {opt:'name'               , wide:12}

        , descr         : {opt:'description'        , wide:12}
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
