//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, list_div, jQuery
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , EmlService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'EmlAccLst', template:ListDiv})
export class EmlAccLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:EmlService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { name          : {lab:'name'               , wide: 2}
        , lng_typ       : {lab:'language'           , wide: 1}
        , account_name  : {lab:'"account_name"      \n(email name)'
                                                    , wide: 3}
        , account_email : {lab:'"account_email"     \n(email address)'
                                                    , wide: 3}
        , account_address
                        : {lab:'"account_address"'  , wide: 9, typ:'textarea'}
        , domain        : {lab:'"domain"'           , wide: 3}
        , domain_name   : {lab:'"domain_name"'      , wide: 3}
        , icon_image    : {lab:'"icon_image"        \n(icon file path)'
                                                    , wide: 3}
        , icon_alt      : {lab:'"icon_alt"          \n(alt. text)'
                                                    , wide: 4}
        , sign_name     : {lab:'"sign_name"         \n(signature)'
                                                    , wide: 3}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'eml_acc_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.buttonNew          (this.onNewP        )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'EmlAcc', template:ItemDiv})
export class EmlAcc extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:EmlService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , name          : {lab:'name'               , wide: 6               , copyCheck:true}
        , lng_typ       : {opt:'language'           , wide: 2               , copyCheck:true}

        , account_name  : {lab:'"account_name" (email name)'
                                                    , wide: 6               , newline:true}
        , account_email : {lab:'"account_email"'    , wide: 6}
        , account_address
                        : {lab:'"account_address"'  , wide:12, typ:'textarea'}

        , domain        : {lab:'"domain"'           , wide: 6, newline:true}
        , domain_name   : {lab:'"domain_name"'      , wide: 6}

        , icon_image    : {lab:'"icon_image" (icon file path)'
                                                    , wide: 6, newline:true}
       , icon_alt      : {lab:'"icon_alt" (alt. text)'
                                                    , wide: 6}

        , sign_name     : {lab:'"sign_name" (signature)'
                                                    , wide: 6}


        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'eml_acc_read'
    onSave_task                 = 'eml_acc_save'
    onDelete_task               = 'eml_acc_dele'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
