//------------------------------------------------------------------------------
import  { Directive, ViewContainerRef               } from '@angular/core'
//------------------------------------------------------------------------------
import * as lib from '../../library'
// ------------------------------------------------------------------------------
export const Imports            = lib.Imports
export const BaseModule         = lib.Base.Module

export const BaseComp           = lib.Base.Comp
//------------------------------------------------------------------------------
@Directive({selector:'[MainElement]'})
export class MainElem {
    //--------------------------------------------------------------------------
    constructor( public viewContainerRef:ViewContainerRef ) { }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
export const MainDiv            = '<div class="div-main"><ng-template MainElement></ng-template></div>'
//------------------------------------------------------------------------------
