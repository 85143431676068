//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, window
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , PrtQueService                             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'PrtQueLst', template:ListDiv})
export class PrtQueLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:PrtQueService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { name          : {lab:'name'               , wide:10}
        , hostname      : {lab:'hostname'           , wide:10}
        , mac_addr      : {lab:'mac address'        , wide:10}
        , ext_addr      : {lab:'external address'   , wide:10}
        , vpn_addr      : {lab:'vpn address'        , wide:15}
        , int_addr      : {lab:'internal address'   , wide:15}
        , idvendor      : {lab:'vendor id'          , wide: 6}
        , idproduct     : {lab:'product id'         , wide: 6}
        , since_update  : {lab:'age (mins)'         , wide: 6, typ:'decimal'}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        return super.onInitP()
        .then((res) => {
            this.makeButton     (this.onTestP       , '"rename"')
            this.makeButton     (this.onPingP       , 'ping printers')
            this.listButton_cell('vpn_addr', 'ssh'  , () => true, this.onVpn, 'floatr')
            this.listButton_cell('int_addr', 'ssh'  , () => true, this.onInt, 'floatr')
            return RESOLVE(res)
        })
    }
    //--------------------------------------------------------------------------
    onPingP(key:string, values:any) {
        return this.busyServiceEmitP('ping', {})
        .then((res:any) => {
            this.messageDone('"ping" broadcast initiated')
            return RESOLVE()
        })
    }
    //--------------------------------------------------------------------------
    onTestP(key:string, values:any) {
        return this.busyServiceEmitP('test', {})
        .then((res:any) => {
            this.messageDone(this.stringify(res))
            return RESOLVE()
        })
    }
    //--------------------------------------------------------------------------
    onVpn(idx, values:any) { return this.SSH(this.list[idx].vpn_addr) }
    onInt(idx, values:any) { return this.SSH(this.list[idx].int_addr) }
    //--------------------------------------------------------------------------
    SSH(addr) {
        window.location         = `ssh://root@${addr}`
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    setItemColour(item:any, idx:number) {
        item.CLASS              = item.since_update < 1000  ? ''
                                : item.since_update < 2000  ? 'orange'
                                                            : 'red'
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'PrtQue', template:ItemDiv})
export class PrtQue extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:PrtQueService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , name          : {lab:'name'               , wide: 6, copyCheck:true}
        , hostname      : {lab:'hostname'           , wide: 6}

        , mac_addr      : {opt:'mac address'        , wide: 6}
        , ext_addr      : {opt:'external address'   , wide: 6}

        , vpn_addr      : {opt:'vpn address'        , wide: 6}
        , int_addr      : {opt:'internal address'   , wide: 6}

        , idvendor      : {opt:'vendor id'          , wide: 6}
        , idproduct     : {opt:'product id'         , wide: 6}
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
