//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { BrnService                                } from './service'
import  { BrnLst                , Brn               } from './brnLst'
//------------------------------------------------------------------------------
let c = [ BrnLst                , Brn
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ BrnService ]
    }
)
export class BrnModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
