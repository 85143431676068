//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, is
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , VndTemplate           , TabbedComp
        , KntService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'KntVndLst', template:ListDiv})
export class KntVndLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:KntService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { name          : {lab:'name'               , wide: 6}
        , email         : {lab:'email'              , wide: 6}
        , adr_city      : {lab:'city'               , wide: 6}
        , adr_postcode  : {lab:'postcode'           , wide: 2}
        , adr_lnd_name  : {lab:'country'            , wide: 4}
        , adr_email     : {lab:'addr. email'        , wide: 6}
        , adr_tel_num   : {lab:'phone'              , wide: 4}
        , grp_name      : {lab:'group'              , wide: 6}
        , cur_typ       : {lab:'cur'                , wide: 1}
        , wsale_yn      : {lab:'w/s'                , wide: 1, typ:'flag'}
        , monthly       : {lab:'mthly'              , wide: 1, typ:'flag'}
        , number        : {lab:'number'             , wide: 1}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.dft.typ            = 'cus'
        this.dft.subtyp         = ''
        this.dft.vendor         = true

        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.buttonNew          (this.onNewP        )
        this.buttonPrint        (this.onPrintP      )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    onNewCompName               = 'KntVnd'
//     onNewP() {
//         let ctx                 = this.onNewContext()
//          return this.callComponent('KntVnd', ctx)
//         .then((res:any) => this.onNewDoneP(res) )
//     }
    onNewContext() {
        return  { id            : 0
                , typ           : this.dft.typ
                , subtyp        : this.dft.subtyp
                , vendor        : this.dft.vendor
                }
    }
    //--------------------------------------------------------------------------
    onUpdateCompName            = 'KntVnd'
//     onUpdateP(idx:number) {
//         let ctx                 = this.onUpdateContext(idx)
//         return this.callComponent('KntVnd', ctx)
//         .then((res:any) => this.onUpdateDoneP(idx, res) )
//     }
    onUpdateContext(idx:number) {
        return { id:this.list[idx].id }
    }
    //--------------------------------------------------------------------------
    onPrintP(key:string, values:any) {
        let massage             = (s:any) => {
            if (is.string(s)) {
                return s.replace(/[\n\t]/g,', ')
            } else {
                return s
            }
        }
        if (this.list.length < 1) {
            return REJECT('nothing to print')
        }
        let keys        :any    = { name            : 'name'
                                  , email           : 'account email'
                                  , adr_street      : 'street'
                                  , adr_city        : 'city'
                                  , adr_postcode    : 'postcode'
                                  , adr_lnd_name    : 'country'
                                  , adr_email       : 'address email'
                                  , adr_tel_num     : 'phone'
                                  , grp_name        : 'group'
                                  , cur_typ         : 'currency'
                                  , wsale_yn        : 'wholesale'
                                  , monthly         : 'monthly'
                                  }

        let data                = Object.keys(keys).map((k:string) => keys[k])
        data                    = [ data.join('\t') ]
        this.list.forEach((item:any) => {
            let values          = []
            for (let key in keys) {
                values.push(massage(item[key]))
            }
            data.push(values.join('\t'))
        })
        this.saveFile(data.join('\n'), 'account details.csv', 'text/csv')
        this.messageDone('saved as "account details.csv"')
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'KntVnd', template:VndTemplate})
export class KntVnd extends TabbedComp {
    //--------------------------------------------------------------------------
    constructor( public service:KntService ) { super() }
    //--------------------------------------------------------------------------
    onInitP() {
        this.service.initTabs(this.ctx.id, 'dtl')
        this.ctx.knt_id         =
        this.dft.knt_id         = this.ctx.id
        this.dft.typ            = this.ctx.typ
        this.dft.subtyp         = this.ctx.subtyp
        this.dft.vendor         = this.ctx.vendor
        this.cache              = {}
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
