//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { TaxService                                } from './service'
import  { TaxLst                , Tax               } from './taxLst'

import  { TaxLnkService                             } from './service'
import  { TaxLnkLst             , TaxLnk            } from './taxLnkLst'
//------------------------------------------------------------------------------
let c = [ TaxLst                , Tax
        , TaxLnkLst             , TaxLnk
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ TaxService, TaxLnkService ]
    }
)
export class TaxModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
