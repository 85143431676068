//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemComp, RolService             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'RolDtl', template:ItemDiv})
export class RolDtl extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:RolService ) { super() }
    comp_type                   = 'maintab'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , name          : {lab:'name'               , wide:12}
        , descr         : {opt:'description'        , wide:12}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        this.buttonDelete       (this.onDeleteP     )
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        this.ctx.rol_id         =
        this.dft.rol_id         = this.ctx.rol_id || this.dft.rol_id
        return this.readP({ id:this.ctx.rol_id })
    }
    //--------------------------------------------------------------------------
    receivedItem(item:any) {
        Object.assign(this.item, item)
        this.setCache(this.item)
        this.service.setHeader(this.item)
        if (this.item.id) {
            this.ctx.rol_id     =
            this.dft.rol_id     = this.item.id
            this.service.showValidTabs()
        }
    }
    //--------------------------------------------------------------------------
    onDeleteDoneP(res:any) {
        return this.onBackP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
