//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemComp, VrgService             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'VrgPpp', template:ItemDiv})
export class VrgPpp extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:VrgService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { instr_type    : {rdo:'instruction type'   , wide:12}

        , bank_name     : {rdo:'bank name'          , wide: 6}
        , acct_name     : {rdo:'acct name'          , wide: 6}

        , iban          : {rdo:'iban'               , wide: 6}
        , bic           : {rdo:'bic'                , wide: 6}

        , ref_num       : {rdo:'reference number'   , wide: 6}
        , amount        : {rdo:'amount'             , wide: 6, typ:'decimal'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'ppp_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        return this.readP()
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
