//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { RtnService                                } from './service'
import  { RtnQtyLst             , RtnQty            } from './rtnQtyLst'
//------------------------------------------------------------------------------
let c = [ RtnQtyLst             , RtnQty
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ RtnService ]
    }
)
export class RtnModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
