//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { StkService                                } from './service'
import  { StkArt                                    } from './stkArt'
import  { StkShlLst             , StkShl            } from './stkShlLst'
import  { StkShlScn                                 } from './stkShlScn'
import  { StkStkLst             , StkStk            } from './stkStkLst'
//------------------------------------------------------------------------------
let c = [ StkArt
        , StkShlLst             , StkShl
        , StkShlScn
        , StkStkLst             , StkStk
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ StkService ]
    }
)
export class StkModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
