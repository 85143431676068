//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { ShpService                                } from './service'
import  { ShpLst                , Shp               } from './shpLst'
import  { DHLLabelModal                             } from './dhlLabelModal'
//------------------------------------------------------------------------------
let c = [ ShpLst                , Shp
        , DHLLabelModal
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ ShpService ]
    }
)
export class ShpModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
