//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, is
//------------------------------------------------------------------------------
import  { Socket                                    } from 'ngx-socket-io'
//------------------------------------------------------------------------------
export class MySocket extends Socket {
    //--------------------------------------------------------------------------
    constructor(args:any={}) {
        super({ url:window.location.origin, options:{} })
        this.heartrate          = args.socket.heartrate
        this.TIMEOUT            = args.socket.timeout
    }
    //--------------------------------------------------------------------------
    name                :string = this.constructor.name
    private heartrate           =   30000
    private TIMEOUT             =  120000

    private hbTimer     :any    = null
    private isOpen      :boolean= false
    private LISTENERS   :any    = {}
    //--------------------------------------------------------------------------
    open(socketInit) {
        this.log('***** open')
        this.on('connect'       , (err, res) => {
            this.log('***** connect:', err)
            this.isOpen         = !err
            this.emitBeat(0)
        })
        this.on('socketInit'    , (res) => { socketInit(res) })
        this.on('disconnect'    , (res) => {
            this.log('disconnect:', res)
            this.isOpen         = false
        })
        this.on('heartbeat'     , (res) => { this.onBeat(res) })
        this.on('connect_error' , (res) => { this.log('connect_error:'          , res) })
        this.on('reconnect_attempt'
                                , (res) => { this.log('reconnection_attempt:'   , res) })
        this.on('reconnect'     , (res) => { this.log('reconnect:'              , res) })
    }
    //--------------------------------------------------------------------------
    emitBeat(count=0) {
        clearTimeout(this.hbTimer)
        if (this.isOpen) {
//             this.log('emit heartbeat:', count)
            this.emit('heartbeat', count)
        } else {
            this.log('emitBeat: socket is not open')
        }
        this.hbTimer            = setTimeout(
            () => this.emitBeat(count + 1)
        , this.heartrate
        )
    }
    //--------------------------------------------------------------------------
    version             :any    = null
    vers_remind         :number = new Date().valueOf()
    onBeat(version:any) {
// this.log('heartbeat:', version)
        if (version == this.version) {
            return
        }
        if (!this.version || window.location.hostname == 'localhost') {
            // first heartbeat OR we're on my box and don't want to be bothered...
            this.version        = version
            return
        }
        if (this.vers_remind < new Date().valueOf()) {
            window.alert( styledUnicode('A newer version of this application is available.', 'bold')
                        + '\n\nPlease refresh this browser window at your earliest convenience.'
                        )
            this.vers_remind    = new Date().valueOf() + 600000
        }
    }
    //--------------------------------------------------------------------------
    close() {
        this.log('***** close *****')
        for (let event in this.LISTENERS) {
            this.off(event)
        }
        if (this.isOpen) {
//             super.close()
            this.isOpen             = false
        }
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    private ident_      :number = 0

    private get nextIdent() {
// this.log('nextIdent')
        return '#' + this.ident_++
    }
    //--------------------------------------------------------------------------
    emitPayload(action:string, args:any={}) {
        if (!this.isOpen) {
            return REJECT('server connection not available')
        }
        let ident               = this.nextIdent
        let payload             = { action          : action
                                  , ident           : ident
                                  , args            : args
                                  }
        let ctx                 = args.ctx || {}

        let seconds             = ctx.TIMEOUT || args.TIMEOUT || 0
        let TIMEOUT             = Math.max(seconds * 1000, this.TIMEOUT)
        if (TIMEOUT != this.TIMEOUT) {
            this.log('non-standard timeout:', TIMEOUT)
        }
        //----------------------------------------------------------------------
        return new Promise((resolve, reject) => {
            let timer           = setTimeout(() => {
                this.off(ident)
                return reject('socket timeout')
            }, TIMEOUT)
            //----------------------------------------------------------------------
            this.on(ident, (res) => {
                clearTimeout(timer)
                this.off(ident)
                return (res?.err) ? reject(res.err) : resolve(res)
            })
            this.emit('action', payload)
            .catch(reject)
        })
    }
    //--------------------------------------------------------------------------
    on(event, func) {
// this.log('on:', event)
        this.LISTENERS[event]   = true
        super.on(event, func)
    }
    //--------------------------------------------------------------------------
    off(event) {
// this.log('off:', event)
        this.removeAllListeners(event)
        delete this.LISTENERS[event]
    }
    //--------------------------------------------------------------------------
    emit(event, data:any=undefined) {
// this.log(`emit ${event}:`, data)
        super.emit(event, data)
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    log(...args) {
        if (is.string(args[0])) {
            args[0]             = `[${this.name}] ` + args[0]
        } else {
            args.unshift(`[${this.name}]`)
        }
        console.log.apply(this, args)
    }
    //--------------------------------------------------------------------------
}
//==============================================================================
function styledUnicode(str, style='m', flags='') {
    // default to monospace...
    style                       = suAlias [style] || style
    style                       = suOffset[style] ? style : 'm'
    let underline               = /\bunderline\b/i.test(flags)
    let strike                  = /\bstrike\b/i.test(flags)
    let result                  = ''

    for (let c of str) {
        try {
            c                   = suSxCP(suSpecial[style][c])
        } catch(e) {
            let cp              = c.codePointAt(0)
            if        (cp >= 48 && cp <= 57) {
                // [0-9]...
                c               = suSxCP(cp - 48 + suOffset[style][1])
            } else if (cp >= 65 && cp <= 90) {
                // [A-Z]...
                c               = suSxCP(cp - 65 + suOffset[style][0])
            } else if (cp >= 97 && cp <= 122) {
                // [a-z]...
                c               = suSxCP(cp - 71 + suOffset[style][0])
            }
        }
        result                 += c
        // add underline...
        if (underline)          { result += '\u0332' }
        // add strike-through...
        if (strike   )          { result += '\u0336' }
    }
    return result
}
//------------------------------------------------------------------------------
// special characters (absolute values)
const suSpecial                 =
    { g: { c: 0x212d, h: 0x210c, i: 0x2111, r: 0x211c, z: 0x2128 }
    , i: { h: 0x210e }
    , m: { ' ': 0x2000, '-': 0x2013 }
    , o: { 0: 0x24ea, 1: 0x2460, 2: 0x2461, 3: 0x2462, 4: 0x2463, 5: 0x24614, 6: 0x24615, 7: 0x24616, 8: 0x24617, 9: 0x24618 }
    , p: { a: 0x249c, b: 0x249d, c: 0x249e, d: 0x249f, e: 0x24a0, f: 0x24a1, g: 0x24a2, h: 0x24a3, i: 0x24a4, j: 0x24a5, k: 0x24a6, l: 0x24a7, m: 0x24a8, n: 0x24a9, o: 0x24aa, p: 0x24ab, q: 0x24ac, r: 0x24ad, s: 0x24ae, t: 0x24af, u: 0x24b0, v: 0x24b1, w: 0x24b2, x: 0x24b3, y: 0x24b4, z: 0x24b5 }
    , w: { a: 0xff41, b: 0xff42, c: 0xff43, d: 0xff44, e: 0xff45, f: 0xff46, g: 0xff47, h: 0xff48, i: 0xff49, j: 0xff4a, k: 0xff4b, l: 0xff4c, m: 0xff4d, n: 0xff4e, o: 0xff4f, p: 0xff50, q: 0xff51, r: 0xff52, s: 0xff53, t: 0xff54, u: 0xff55, v: 0xff56, w: 0xff57, x: 0xff58, y: 0xff59, z: 0xff5a }
    }
//                                [ alpha , numeric]
const suOffset                  =
    { b                         : [0x1d400, 0x1d7ce]
    , bi                        : [0x1d468, 0x00030]
    , bis                       : [0x1d63c, 0x00030]
    , bs                        : [0x1d5d4, 0x1d7ec]
    , bc                        : [0x1d4d0, 0x00030]
    , d                         : [0x1d538, 0x1d7d8]
    , w                         : [ 0xff21,  0xff10]
    , g                         : [0x1d504, 0x00030]
    , bg                        : [0x1d56c, 0x00030]
    , i                         : [0x1d434, 0x00030]
    , is                        : [0x1d608, 0x00030]
    , m                         : [0x1d670, 0x1d7f6]
    , o                         : [ 0x24b6,  0x2460]
    , p                         : [ 0x249c,  0x2474]
    , s                         : [0x1d5a0, 0x1d7e2]
    , c                         : [0x1d49c, 0x00030]
    , u                         : [ 0x2090,  0xff10]
    }
// human aliases
const suAlias                   =
    {'bold'                     : 'b'
    ,'bold italic sans'         : 'bis'
    ,'bold italic'              : 'bi'
    ,'bold sans'                : 'bs'
    ,'bold script'              : 'bc'
    ,'circled'                  : 'o'
    ,'doublestruck'             : 'd'
    ,'fullwidth'                : 'w'
    ,'gothic bold'              : 'bg'
    ,'gothic'                   : 'g'
    ,'italic sans'              : 'is'
    ,'italic'                   : 'i'
    ,'monospace'                : 'm'
    ,'parenthesis'              : 'p'
    ,'sans'                     : 's'
    ,'script'                   : 'c'
    }

const suSxCP                    = (c) => String.fromCodePoint(c)
//==============================================================================
