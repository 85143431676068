//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { NmbService                                } from './service'
import  { NmbLst                , Nmb               } from './nmbLst'
//------------------------------------------------------------------------------
let c = [ NmbLst                , Nmb
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ NmbService ]
    }
)
export class NmbModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
