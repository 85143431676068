//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { PrtQueService                             } from './service'
import  { PrtQueLst             , PrtQue            } from './prtQueLst'
//------------------------------------------------------------------------------
let c = [ PrtQueLst             , PrtQue
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ PrtQueService ]
    }
)
export class PrtQueModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
