//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , VraService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'VraLst', template:ListDiv})
export class VraLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:VraService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { typ           : {lab:'type'               , wide: 3}
        , name          : {lab:'name'               , wide:10}
//         , typ_grp       : {lab:'group'              , wide: 3}
        , faux_number   : {lab:'last num'           , wide: 5}
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Vra', template:ItemDiv})
export class Vra extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:VraService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , typ_id        : {lab:'type'               , wide: 12, cod:'vrg'}
//         , typ_id_grp    : {opt:'group'              , wide: 6, cod:'vrg'}

        , prefix_1      : {opt:'prefix 1'           , wide: 6}
        , prefix_2      : {opt:'prefix 2'           , wide: 6}

        , prefix_yy     : {lab:'prefix year'        , wide: 4, typ:'boolean'}
        , prefix_mm     : {lab:'prefix month'       , wide: 4, typ:'boolean'}
        , prefix_dd     : {lab:'prefix day'         , wide: 4, typ:'boolean'}

        , last_number   : {opt:'last number used'   , wide: 6, typ:'integer'}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        let def                 = this.itemDefn
        if (this.ctx.id) {
            def.typ_id.rdo      = true
//             def.typ_id_grp.rdo  = true
            this.defineItem     (def                )
            this.setHeader      ('edit'             )
            this.buttonCancel   (this.onCancelP     )
            this.buttonDelete   (this.onDeleteP     )
            this.buttonOk       (this.onSubmitP     )
            this.formSubmit     (this.onSubmitP     )
        } else {
            this.defineItem     (def                )
            this.setHeader      ('add'              )
            this.buttonCancel   (this.onCancelP     )
            this.buttonOk       (this.onSubmitP     )
            this.formSubmit     (this.onSubmitP     )
        }

        return this.readP({ id:this.ctx.id })
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
