//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { CslService                                } from './service'
import  { CslLst                , Csl               } from './cslLst'
import  { CslCatLst                                 } from './cslCatLst'
import  { CslDtl                                    } from './cslDtl'
import  { CslPrdLst                                 } from './cslPrdLst'
//------------------------------------------------------------------------------
let c = [ CslLst                , Csl
        , CslCatLst
        , CslDtl
        , CslPrdLst
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ CslService ]
    }
)
export class CslModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
import  { SelectCsl                                 } from './selectCsl'
let d = [ SelectCsl ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : d
    , providers                 : [ CslService ]
    }
)
export class SelectCslModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(d) }
}
//------------------------------------------------------------------------------
