//------------------------------------------------------------------------------
import  { Directive, ViewContainerRef               } from '@angular/core'
//------------------------------------------------------------------------------
import * as lib from '../../library'
//------------------------------------------------------------------------------
export const Imports            = lib.Imports
export const BaseModule         = lib.Base.Module

export const BaseComp           = lib.Base.Comp
//------------------------------------------------------------------------------
@Directive({selector:'[ModalElement]'})
export class ModalElem {
    //--------------------------------------------------------------------------
    constructor( public viewContainerRef:ViewContainerRef ) { }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
export const ModalDiv           = '<ng-template ModalElement></ng-template>'
//------------------------------------------------------------------------------
