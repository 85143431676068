//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { KntService                                } from './service'

import  { KntCusLst             , KntCus            } from './kntCusLst'
import  { KntCusAdrLst          , KntCusAdr         } from './kntCusAdrLst'
import  { KntCusCmtLst          , KntCusCmt         } from './kntCusCmtLst'
import  { KntCusDtl                                 } from './kntCusDtl'
import  { KntCusEntLst                              } from './kntCusEntLst'
import  { KntCusPtn                                 } from './kntCusPtn'
import  { KntCusRbgLst          , KntCusRbg         } from './kntCusRbgLst'
import  { KntCusVrgLst                              } from './kntCusVrgLst'

import  { KntEmpLst             , KntEmp            } from './kntEmpLst'
import  { KntEmpAdrLst          , KntEmpAdr         } from './kntEmpAdrLst'
import  { KntEmpCmtLst          , KntEmpCmt         } from './kntEmpCmtLst'
import  { KntEmpDtl                                 } from './kntEmpDtl'

import  { KntGrpLst             , KntGrp            } from './kntGrpLst'
import  { KntGrpRbgLst          , KntGrpRbg         } from './kntGrpRbgLst'
import  { KntGrpDtl                                 } from './kntGrpDtl'

import  { KntSupLst             , KntSup            } from './kntSupLst'
import  { KntSupAdrLst          , KntSupAdr         } from './kntSupAdrLst'
import  { KntSupCmtLst          , KntSupCmt         } from './kntSupCmtLst'
import  { KntSupDtl                                 } from './kntSupDtl'

import  { KntVndLst             , KntVnd            } from './kntVndLst'
import  { KntVndAdrLst          , KntVndAdr         } from './kntVndAdrLst'
import  { KntVndCmtLst          , KntVndCmt         } from './kntVndCmtLst'
import  { KntVndDtl                                 } from './kntVndDtl'
import  { KntVndRbgLst          , KntVndRbg         } from './kntVndRbgLst'
//------------------------------------------------------------------------------
let c = [ KntCusLst             , KntCus
        , KntCusAdrLst          , KntCusAdr
        , KntCusCmtLst          , KntCusCmt
        , KntCusEntLst
        , KntCusPtn
        , KntCusRbgLst          , KntCusRbg
        , KntCusDtl
        , KntCusVrgLst

        , KntEmpLst             , KntEmp
        , KntEmpAdrLst          , KntEmpAdr
        , KntEmpCmtLst          , KntEmpCmt
        , KntEmpDtl

        , KntGrpLst             , KntGrp
        , KntGrpRbgLst          , KntGrpRbg
        , KntGrpDtl

        , KntSupLst             , KntSup
        , KntSupAdrLst          , KntSupAdr
        , KntSupCmtLst          , KntSupCmt
        , KntSupDtl

        , KntVndLst             , KntVnd
        , KntVndAdrLst          , KntVndAdr
        , KntVndCmtLst          , KntVndCmt
        , KntVndDtl
        , KntVndRbgLst          , KntVndRbg
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ KntService ]
    }
)
export class KntModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
import  { SelectKnt                                 } from './selectKnt'
let d = [ SelectKnt ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : d
    , providers                 : [ KntService ]
    }
)
export class SelectKntModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(d) }
}
//------------------------------------------------------------------------------
import  { SelectKntAdr                              } from './selectKntAdr'
let e = [ SelectKntAdr ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : e
    , providers                 : [ KntService ]
    }
)
export class SelectKntAdrModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(e) }
}
//------------------------------------------------------------------------------
