//------------------------------------------------------------------------------
import  { Injectable                                } from '@angular/core'
//------------------------------------------------------------------------------
import * as lib from '../library'
//------------------------------------------------------------------------------
export const Imports            = lib.Imports
export const BaseModule         = lib.Base.Module

export const ItemComp           = lib.Item.Comp
export const ItemDiv            = lib.Item.Div
export const ListComp           = lib.List.Comp
export const ListDiv            = lib.List.Div
export const PstList            = lib.Pst.List
export const PstListDiv         = lib.Pst.ListDiv
export const PstModal           = lib.Pst.Modal
export const PstModalDiv        = lib.Pst.ModalDiv
export const TabbedComp         = lib.Base.Tabbed
//------------------------------------------------------------------------------
@Injectable()
export class VrgService extends lib.Base.Service {
    //--------------------------------------------------------------------------
    setHeader(values:any) {
        this.ctx.header         = (!values.id)      ? 'new...'
                                : (!values.knt_name)? values.number
                                                    : `${values.number}: ${values.knt_name}`
    }
    //--------------------------------------------------------------------------
    allTabs             :any    =        'dtl adr pst cmt ppp evn lnk'.split(/\W+/)
    typTabs             :any    = { acc: 'dtl                     lnk'.split(/\W+/)
                                  , dlv: 'dtl adr pst cmt ppp evn lnk'.split(/\W+/)
                                  , idl: 'dtl     pst         evn lnk'.split(/\W+/)
                                  , inv: 'dtl adr pst cmt ppp evn lnk'.split(/\W+/)
                                  , ior: 'dtl     pst         evn lnk'.split(/\W+/)
                                  , ord: 'dtl adr pst cmt ppp evn lnk'.split(/\W+/)
                                  , stk: 'dtl     pst         evn lnk'.split(/\W+/)
                                  , use: 'dtl     pst         evn lnk'.split(/\W+/)
                                  }
    //--------------------------------------------------------------------------
    setValidTabs(typ:string) {
// this.log('setValidTabs:', typ)
        typ                     = (typ || '').toLowerCase()
        this.validTabs          = this.typTabs[typ] || this.allTabs
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
export const VrgTemplate        = `
<div class='tabs container'>
  <div class='tabshead'>
    <h3 class='header floatl'>
      <button               class='btn back'
                            (click)='popClick()'
      >{{glob.backArrow}}</button>{{ctx.header}}</h3>
    <span     *ngIf ='btns.tr.length && ctx.vrg_id'
                            class='actions top floatr {{ctx.header && "header"}}'>
      <button *ngFor='let btn of btns.tr'
                            class='{{btn.cls}} {{btn.offClass}} {{btn.hide}}'
                            (click)='btn.CLICK("form", $event)'>{{btn.lab}}</button>
    </span>
    <div class='u-cf'></div>
  </div>
  <div class='tabstabs'>
    <div                    (click)='stabSel("dtl")' class='{{stabCls("dtl")}}'>details  </div>
    <div *ngIf='stabs.adr'  (click)='stabSel("adr")' class='{{stabCls("adr")}}'>addresses</div>
    <div *ngIf='stabs.pst'  (click)='stabSel("pst")' class='{{stabCls("pst")}}'>items    </div>
    <div *ngIf='stabs.cmt'  (click)='stabSel("cmt")' class='{{stabCls("cmt")}}'>comments </div>
    <div *ngIf='stabs.ppp'  (click)='stabSel("ppp")' class='{{stabCls("ppp")}}'>paypal   </div>
    <div *ngIf='stabs.evn'  (click)='stabSel("evn")' class='{{stabCls("evn")}}'>events   </div>
    <div *ngIf='stabs.lnk'  (click)='stabSel("lnk")' class='{{stabCls("lnk")}}'>links    </div>
  </div>
  <div class='tabsbody' [ngSwitch]='stab'>
    <VrgDtl    *ngSwitchDefault     ></VrgDtl   >
    <VrgAdr    *ngSwitchCase='"adr"'></VrgAdr   >
    <VrgPstLst *ngSwitchCase='"pst"'></VrgPstLst>
    <VrgCmtLst *ngSwitchCase='"cmt"'></VrgCmtLst>
    <VrgPpp    *ngSwitchCase='"ppp"'></VrgPpp   >
    <VrgEvnLst *ngSwitchCase='"evn"'></VrgEvnLst>
    <VrgLnkLst *ngSwitchCase='"lnk"'></VrgLnkLst>
  </div>
</div>
`
//------------------------------------------------------------------------------
