//------------------------------------------------------------------------------
import  { Injectable                                } from '@angular/core'
//------------------------------------------------------------------------------
import * as lib from '../library'
//------------------------------------------------------------------------------
export const Imports            = lib.Imports
export const BaseModule         = lib.Base.Module

export const ItemModal          = lib.Item.Modal
export const ItemDiv            = lib.Item.Div
//------------------------------------------------------------------------------
@Injectable()
export class WndService extends lib.Base.Service {
}
//------------------------------------------------------------------------------
