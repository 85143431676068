//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , WrkService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'WrkLst', template:ListDiv})
export class WrkLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:WrkService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { typ           : {lab:'type'               , wide: 3}
        , name          : {lab:'name'               , wide: 6}
        , prefix        : {lab:'prefix'             , wide: 2}
        , cct_name      : {lab:'cost centre (cct)'  , wide: 4}
        , move_out      : {lab:'move out'           , wide: 2, typ:'flag'}
, rsr_name   : {lab:'location (DEPRECATED)'     , wide:6}
        , move_in       : {lab:'move in'            , wide: 2, typ:'flag'}
, rsr_name_to: {lab:'to location (DEPRECATED)'  , wide:6}
        , carryover     : {lab:'c/o'                , wide: 1, typ:'flag'}
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Wrk', template:ItemDiv})
export class Wrk extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:WrkService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date','dtl_id','dtl_update_date']
        , typ           : {lab:'type'               , wide: 3               , copyCheck:true}
        , name          : {lab:'name'               , wide: 3               , copyCheck:true}
        , prefix        : {opt:'prefix'             , wide: 3               , copyCheck:true}

        , cct_id        : {opt:'cost centre (cct)'  , wide: 3, cod:'cct'    , newline:true}
        , move_out      : {opt:'move stock out'     , wide: 3, typ:'boolean'}
        , move_in       : {opt:'move stock in'      , wide: 3, typ:'boolean'}
        , carryover     : {lab:'carry over'         , wide: 3, typ:'boolean'}

//         , rsr_id_from   : {opt:'location'           , wide: 3, cod:'rsr'}
//         , rsr_id_to     : {opt:'to location'        , wide: 3, cod:'rsr'}
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
