//------------------------------------------------------------------------------
import  { Injectable                                } from '@angular/core'
//------------------------------------------------------------------------------
import * as lib from '../library'
//------------------------------------------------------------------------------
export const Imports            = lib.Imports
export const BaseModule         = lib.Base.Module

export const ItemComp           = lib.Item.Comp
export const ItemDiv            = lib.Item.Div
export const ItemModal          = lib.Item.Modal
export const ListComp           = lib.List.Comp
export const ListDiv            = lib.List.Div
export const SelectDiv          = lib.Select.Div
export const SelectModal        = lib.Select.Modal
export const TabbedComp         = lib.Base.Tabbed
//------------------------------------------------------------------------------
@Injectable()
export class CatService extends lib.Base.Service {
    //--------------------------------------------------------------------------
    allTabs             :any    = 'dtl grp csl sel'.split(/\W+/)
    validTabs           :any    = this.allTabs
    //--------------------------------------------------------------------------
    setHeader(values:any) {
        this.ctx.header         = (!values.id) ? 'new...' : values.name
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
export const CatTemplate        = `
<div class='tabs container'>
  <div class='tabshead'>
    <h3 class='header floatl'>
      <button               class='btn back'
                            (click)='popClick()'
      >{{glob.backArrow}}</button>{{ctx.header}}</h3>
    <div class='u-cf'></div>
  </div>
  <div class='tabstabs'>
    <div                    (click)='stabSel("dtl")' class='{{stabCls("dtl")}}'>details  </div>
    <div *ngIf='stabs.grp'  (click)='stabSel("grp")' class='{{stabCls("grp")}}'>groups   </div>
    <div *ngIf='stabs.csl'  (click)='stabSel("csl")' class='{{stabCls("csl")}}'>carousels</div>
    <div *ngIf='stabs.sel'  (click)='stabSel("sel")' class='{{stabCls("sel")}}'>product selectors
                                                                                         </div>
  </div>
  <div class='tabsbody' [ngSwitch]='stab'>
    <CatDtl    *ngSwitchDefault     ></CatDtl   >
    <CatGrpLst *ngSwitchCase='"grp"'></CatGrpLst>
    <CatCslLst *ngSwitchCase='"csl"'></CatCslLst>
    <CatSelLst *ngSwitchCase='"sel"'></CatSelLst>
  </div>
</div>
`
//------------------------------------------------------------------------------
