//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { RolService                                } from './service'
import  { RolLst                , Rol               } from './rolLst'
import  { RolDtl                                    } from './rolDtl'
import  { RolScpLst                                 } from './rolScpLst'
import  { RolEmpLst                                 } from './rolEmpLst'
//------------------------------------------------------------------------------
let c = [ RolLst                , Rol
        , RolDtl
        , RolScpLst
        , RolEmpLst
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ RolService ]
    }
)
export class RolModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
import  { SelectRol                                 } from './selectRol'
let d = [ SelectRol
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : d
    , providers                 : [ RolService ]
    }
)
export class SelectRolModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(d) }
}
//------------------------------------------------------------------------------
