//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv, ListComp, KntService             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'KntCusVrgLst', template:ListDiv})
export class KntCusVrgLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:KntService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { date          : {lab:''                   , wide: 4, typ:'dates'}
        , number        : {lab:'number'             , wide: 5}
        , ext_number    : {lab:'reference'          , wide: 6}
        , wrk_name      : {lab:'workflow'           , wide: 8}
        , stt_name      : {lab:'status'             , wide: 8}
        , qty           : {lab:'qty'                , wide: 2, typ:'quantity'}
        , netto         : {lab:'netto'              , wide: 4, typ:'decimal', tot:true}
        , brutto        : {lab:'brutto'             , wide: 4, typ:'decimal', tot:true}
        , cur_typ       : {lab:'cur'                , wide: 2}
        , pay_typ       : {lab:'pay'                , wide: 1}
        , wsale_yn      : {lab:'w/s'                , wide: 1, typ:'flag'}
        , booked        : {lab:'bkd'                , wide: 1, typ:'flag'}
        , events_flag   : {lab:'evn'                , wide: 1, typ:'flag'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'vrg_load'
    onNewCompName               = 'Vrg'
    onUpdateCompName            = 'Vrg'
    //--------------------------------------------------------------------------
    defaultCriteria() {
        return { date0:this.getDateSince('year') }
    }
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
//     onUpdateP(idx:number) {
//         let ctx                 = this.onUpdateContext(idx)
//         return this.callComponent('Vrg', ctx)
//         .then((res:any) => this.onUpdateDoneP(idx, res) )
//     }
    onUpdateContext(idx:number) {
        return { id:this.list[idx].id }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
