//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemComp, KntService             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'KntSupDtl', template:ItemDiv})
export class KntSupDtl extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:KntService ) { super() }
    comp_type                   = 'maintab'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date','typ_id','number','knt_id_rep','knt_id_agt','adr_id','adr_update_date','emp_id']
        , anr_id        : {lab:'salutation'         , wide: 3, cod:'anr'    }
        , title         : {opt:'title'              , wide: 3}
        , forename      : {opt:'forename'           , wide: 3}
        , surname       : {lab:'surname'            , wide: 3}
        //----------------------------------------------------------------------
        , email         : {opt:'account email'      , wide: 3               , section:true}
        , name          : {opt:'account name'       , wide: 3}
        , descr         : {opt:'description'        , wide: 6}

        , ext_number    : {opt:'external num'       , wide: 3               , newline:true}
        , vat_ident     : {opt:'vat ident'          , wide: 3}
        , eori_number   : {opt:'eori number'        , wide: 3}
        , mid_code      : {opt:'manufacturer id'    , wide: 3}

        , acc_number    : {opt:'fibu number'        , wide: 3               , newline:true}

        , lng_id        : {opt:'language'           , wide: 3, cod:'lng'    , newline:true}
        , lnd_id_tax    : {lab:'tax country'        , wide: 3, cod:'lnd'    }
        , shp_id        : {opt:'shipping override'  , wide: 3, cod:'shp'    }

        , monthly       : {opt:'monthly invoice'    , wide: 3, typ:'boolean', newline:true}
        , due_days      : {opt:'due days'           , wide: 3, typ:'integer'}
        , dsc_days      : {opt:'discount days'      , wide: 3, typ:'integer'}
        , dsc_pct       : {opt:'discount pct'       , wide: 3, typ:'percent'}
        //----------------------------------------------------------------------
        , ord_next_date : {opt:'next order date'    , wide: 3, typ:'date'   , section:true}
        , ord_lead_time : {opt:'lead time (weeks)'  , wide: 3, typ:'integer'}
        , ord_frequency : {opt:'order freq. (weeks)', wide: 3, typ:'integer'}
        , ord_chn_id    : {lab:'order channel'      , wide: 3, cod:'chn'    }

        , ord_new_sku_qty
                        : {opt:'new sku order qty'  , wide: 3, typ:'quantity'
                                                                            , newline:true}
        , ord_min_value : {opt:'minimum order value', wide: 3, typ:'decimal'}
        , poo_pct       : {opt:'payment due on order (%)'
                                                    , wide: 2, typ:'percent'}
        , pos_pct       : {opt:'payment due on shipment (%)'
                                                    , wide: 2, typ:'percent'}
        , por_pct       : {rdo:'payment due on receipt (%)'
                                                    , wide: 2, typ:'percent'}

        , ddp_uplift    : {opt:'cog uplift (%)'     , wide: 3, typ:'percent', newline:true}
        , dsg_uplift    : {opt:'designer commission uplift (%)'
                                                    , wide: 3, typ:'percent'}
        //----------------------------------------------------------------------
        , rep_name      : {opt:'company rep.'       , wide: 5, rtn:this.repSearchP
                                                                            , section:true}
        , rep_search    : {lab:'search'             , wide: 1, btn:this.repSearchP}
        , rep_email     : {rdo:'email'              , wide: 4}

        , agt_name      : {opt:'customer agent'     , wide: 5, rtn:this.agtSearchP
                                                                            , newline:true}
        , agt_search    : {lab:'search'             , wide: 1, btn:this.agtSearchP}
        , agt_email     : {rdo:'email'              , wide: 4}
        , agt_number    : {rdo:'number'             , wide: 2}
        //----------------------------------------------------------------------
        , adr_name      : {lab:'address name'       , wide: 6               , section:true}
        , adr_descr     : {opt:'description'        , wide: 6}

        , adr_street    : {lab:'street'             , wide: 3               , newline:true}
        , adr_postcode  : {lab:'postcode'           , wide: 3}
        , adr_city      : {lab:'city'               , wide: 3}
        , adr_lnd_id    : {lab:'country'            , wide: 3, cod:'lnd'    }

        , adr_email     : {opt:'address email'      , wide: 3               , newline:true}
        , adr_tel_num   : {opt:'telephone'          , wide: 3}

        , adr_rsr_id    : {opt:'location'           , wide: 3, cod:'rsr'    , newline:true}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        return this.readP()
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
    receivedItem(item:any) {
        Object.assign(this.item, item)
        if (this.item.id) {
            this.setCache(this.item)
            this.ctx.knt_id     =
            this.dft.knt_id     = this.item.id
            this.service.showValidTabs()
            this.service.setHeader(this.item)
        }
    }
    //--------------------------------------------------------------------------
    repSearchP(key:string, values:any) {
        return this.busyServiceEmitP('knt:read_selector',
            { typ               : 'emp'
            , subtyp            : ''
            , selector          : key == 'rep_name' ? values : values.rep_name
            })
        .then((res:any) => {
            return this.setItemValuesP(
                { knt_id_rep    : res.id
                , rep_name      : res.name
                , rep_email     : res.email
                , rep_number    : res.number
                })
            .then(() => {
                this.messageDone('company rep. selected')
                return this.setFocusP('rep_name')
            })
        })
        .catch((err) => {
            this.messageClear()
            window.alert(err)
            return this.setFocusP('rep_name')
        })
    }
    //--------------------------------------------------------------------------
    agtSearchP(key:string, values:any) {
        return this.busyServiceEmitP('knt:read_selector',
            { typ               : 'cus'
            , subtyp            : ''
            , selector          : key == 'agt_name' ? values : values.knt_name
            })
        .then((res:any) => {
            return this.setItemValuesP(
                { knt_id_agt    : res.id
                , agt_name      : res.name
                , agt_email     : res.email
                , agt_number    : res.number
                })
            .then(() => {
                this.messageDone('customer agent selected')
                return this.setFocusP('agt_name')
            })
        })
        .catch((err) => {
            this.messageClear()
            window.alert(err)
            return this.setFocusP('agt_name')
        })
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
