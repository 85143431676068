//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, is
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemModal, PckService            } from './service'
//------------------------------------------------------------------------------
@Component({selector:'FEDEXModal', template:ItemDiv})
export class FEDEXModal extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:PckService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    prt_ques            :any    = {}
    itemDefn            :any    =
    { HIDDEN        : 'vrg_id'
    , fedex_pak_id  : {lab:'package'            , wide:6    , cod:'fedex_pak'}
    , shipmentDate  : {lab:'shipmentDate'       , wide:6    , typ:'date'}

    , weight        : {lab:'weight'             , wide:6    , typ:'quantity'}
    , qty           : {rdo:'quantity'           , wide:6    , typ:'quantity'}

    , lengthInCM    : {lab:'lengthInCM'         , wide:4    , typ:'integer'    , opt:true}
    , widthInCM     : {lab:'widthInCM'          , wide:4    , typ:'integer'    , opt:true}
    , heightInCM    : {lab:'heightInCM'         , wide:4    , typ:'integer'    , opt:true}

    , rsr_id_from   : {lab:'sendFrom'           , wide:6    , cod:'rsr_loc'}
    , bulky         : {lab:'bulkyGoods'         , wide:1    , typ:'boolean'}
    }
    //--------------------------------------------------------------------------
    onInitP() {
        console.log(this.ctx)
        this.ctx.NAME           = this.ctx.name
        this.defineItem         (this.itemDefn      )
        this.setHeader          ('=menu='           )
        this.buttonCancel       (this.onCancelP     )

        return this.busyServiceEmitP('lookup:my_prt_ques', {})
        .then((res) => {
            res.forEach((prt_que) => {
                this.prt_ques[prt_que.key]
                                = prt_que.value
                this.makeButton(this.onSubmitP, prt_que.key, prt_que.value)
            })

            let qty             = 0
            let weight          = 0
            this.ctx.items.forEach((item:any) => {
                if (item.SELECT) {
                    qty        += 1000      // each line is a single unit
                    weight     += item.weight
                }
            })
            return this.setItemValuesP(
                { vrg_id        : this.ctx.vrg_id
                , shipmentDate  : new Date()
                , weight        : weight
                , qty           : qty
                , sendeFrom     : this.ctx.rsr_id || 1 //1 is rosbach (ZUCK)
                }
            )
        })
        .then((res) => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
    onSubmitP(key:string, values:any) {
        if (!values)            { return RESOLVE() }
        return this.busyServiceEmitP('ship_via_fedex', { ...values, list:this.ctx.items })
        .then((res:any) => {
this.log(res)
            this.messageDone('shipment created') // include tracking number?
            return this.modalRESOLVE(res)
        })
    }
    //--------------------------------------------------------------------------
}
