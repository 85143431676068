//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { UsrService                                } from './service'
import  { UsrLst                , Usr               } from './usrLst'
//------------------------------------------------------------------------------
let c = [ UsrLst                , Usr
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ UsrService ]
    }
)
export class UsrModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
