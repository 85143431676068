//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , KntService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'KntSupAdrLst', template:ListDiv})
export class KntSupAdrLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:KntService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { name          : {lab:'name'               , wide: 6}
        , descr         : {lab:'description'        , wide: 6}
        , street        : {lab:'street'             , wide: 6}
        , city          : {lab:'city'               , wide: 6}
        , state         : {lab:'state'              , wide: 2}
        , postcode      : {lab:'postcode'           , wide: 2}
        , lnd_name      : {lab:'country'            , wide: 4}
        , email         : {lab:'email'              , wide: 6}
        , tel_num       : {lab:'phone'              , wide: 4}
        , default       : {lab:'dflt'               , wide: 1, typ:'flag', cls:'clickable'}
        , preferred     : {lab:'pref'               , wide: 1, typ:'flag', cls:'clickable'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'adr_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.buttonNew          (this.onNewP        )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )
        this.cellClick          (this.onDefaultP    , 'default'  )
        this.cellClick          (this.onPreferP     , 'preferred')

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    onDefaultP(idx:number, key:string) {
        let item                = this.list[idx]
        return this.busyServiceEmitP('adr_set_default', item)
        .then(() => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
    onPreferP(idx:number, key:string) {
        let item                = this.list[idx]
        return this.busyServiceEmitP('adr_toggle_preferred', item)
        .then(() => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'KntSupAdr', template:ItemDiv})
export class KntSupAdr extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:KntService ) { super() }
    modal_size                  = 'large'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , name          : {lab:'name'               , wide: 6}
        , descr         : {opt:'description'        , wide: 6}

        , street        : {lab:'street'             , wide: 6}
        , city          : {lab:'city'               , wide: 4}
        , state         : {opt:'state'              , wide: 2}

        , postcode      : {opt:'postcode'           , wide: 6}
        , lnd_id        : {lab:'country'            , wide: 6, cod:'lnd'}

        , email         : {lab:'email'              , wide: 6}
        , tel_num       : {opt:'phone'              , wide: 6}

        , rsr_id        : {opt:'location'           , wide: 6, cod:'rsr'}
        , shp_id        : {opt:'shipping override'  , wide: 6, cod:'shp'}

        , overwrite     : {lab:'update this address', wide: 6, typ:'boolean', section:true}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'adr_read'
    onSave_task                 = 'adr_save'
    onDelete_task               = 'adr_dele'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        this.buttonCancel       (this.onCancelP     )
        this.buttonDelete       (this.onDeleteP     )
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        return this.readP({ id:this.ctx.id })
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
