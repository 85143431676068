//------------------------------------------------------------------------------
declare const window
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { BaseComp, LogoDiv                         } from './service'
//------------------------------------------------------------------------------
@Component({selector:'Logo', template:LogoDiv})
export class Logo extends BaseComp {
    //--------------------------------------------------------------------------
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
