//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { AlertTag                                  } from './alert'
import  { AlertElem                                 } from './service'
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , exports                   : [ AlertTag ]
    , declarations              : [ AlertTag, AlertElem ]
    }
)
export class AlertModule extends BaseModule {
    onConstructor() { this.glob.registerComponents([ AlertTag ]) }
}
//------------------------------------------------------------------------------
