//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { CommonModule                              } from '@angular/common'
import  { FormsModule                               } from '@angular/forms'
import  { Injectable, Component, NgModule           } from '@angular/core'
//------------------------------------------------------------------------------
import  { Controls                                  } from './controls'
import  { BaseService, BaseModule                   } from './base'
import  { ItemDiv                                   } from './templates/divs'
import  { ItemModal                                 } from './item'
//------------------------------------------------------------------------------
@Injectable()
export class ParamsService extends BaseService {}
//------------------------------------------------------------------------------
@Component({selector:'ParamsModal', template:ItemDiv})
export class ParamsModal extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:ParamsService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    onInitP() {
        this.setHeader          (this.ctx.header    || 'parameters...')
        this.defineItem         (this.ctx.itemDefn  )
        this.buttonCancel       (this.onCancelP     )
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        this.item               = this.ctx.values
        return this.setFocusP()
    }
    //--------------------------------------------------------------------------
    onSubmitP(key:string, values:any) {
        return this.modalRESOLVE(values)
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
const c=[ ParamsModal ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : [ CommonModule, FormsModule, Controls.Module ]
    , declarations              : c
    , providers                 : [ ParamsService ]
    }
)
export class ParamsModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
