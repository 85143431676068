//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , KntService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'KntCusCmtLst', template:ListDiv})
export class KntCusCmtLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:KntService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { update_date   : {lab:''                   , wide: 2, typ:'dates'      }
        , txt           : {lab:'comment'            , wide:20, typ:'textarea'   }
        , user          : {lab:'user'               , wide: 4}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'cmt_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.buttonNew          (this.onNewP        )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    defaultCriteria() {
        return { date0:new Date('2000-01-01') }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'KntCusCmt', template:ItemDiv})
export class KntCusCmt extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:KntService ) { super() }
    modal_size                  = 'large'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , txt           : {lab:'comment'            , wide:12, typ:'textarea'}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'cmt_read'
    onSave_task                 = 'cmt_save'
    onDelete_task               = 'cmt_dele'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        this.buttonCancel       (this.onCancelP     )
        this.buttonDelete       (this.onDeleteP     )
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        return this.readP({ id:this.ctx.id })
        .then(() => this.setFocusP('txt') )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
