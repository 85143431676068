//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, is
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv   , ItemComp  , ItemModal
        , ListDiv   , ListComp
        , FlexService                               } from './service'
//------------------------------------------------------------------------------
@Component({selector:'FlexList', template:ListDiv})
export class FlexList extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:FlexService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    onInitP() {
        let name                = this.ctx.module + 'List'
        this.service.setModule()
        return this.busyServiceEmitP('initList', { criteria:this.criteria })
        .then((res:any) => {
            this.log('flex ctx:', res.ctx)

            for (let key in res.attributes) {
                let value       = res.attributes[key]
                this.log(`setting attribute ${key}:`, value)
                this[key]       = value
            }

            for (let key in res.functions) {
                let value       = eval('(' + res.functions[key] + ')')
                this.log(`setting function ${key}`)
                this[key]       = value
            }

            for (let key in res.criteria) {
                let value       = res.criteria[key]
                this.log(`setting criteria ${key}:`, value)
                this.criteria[key]
                                = value
            }

            this.setHeader      (this.header || '=menu=')
            this.defineList     (res.listDefn       )

            if (res.ctx.canCreate) {
                this.buttonNew  (this.onNewP        )
            }

            this.buttonSelAll   (this.onSelectAllP  )
            this.buttonInvSel   (this.onInvertSelP  )

            if (res.ctx.canPrint) {
                this.buttonPrint(this.onPrintP      )
            }

            this.additionalButtons
                                (this.ctx.buttons   )
            this.additionalButtons
                                (res.buttons        )

            this.eventButtons   (this.ctx.events    )
            this.eventButtons   (res.events         )

            this.onCriteria     (this.onCriteriaP   )
            this.listClick      (this.onSelectItmP  )
            this.listDclick     (this.onUpdateP     )

            for (let key in res.cellsDclick) {
                let func        = this[key]
                this.cellsDclick(func, res.cellsDclick[key])
            }

            return this.loadAndFocusP()
        })
    }
    //--------------------------------------------------------------------------
    additionalButtons(buttons:any={}) {
        buttons                 = is.object(buttons) ? buttons : {}
        for (let key in buttons) {
            let fname           = buttons[key]
            let func            = this[fname]
            if (!is.function(func)) {
                this.log(`invalid button function: ${key} => ${fname}`)
            } else {
                this.makeButton(func, key, key, 'tr')
            }
        }
    }
    //--------------------------------------------------------------------------
    onNewCompName               = ''
//     onNewP() {
//         let ctx                 = this.onNewContext()
//          return this.callComponent('', ctx)
//         .then((res:any) => this.onNewDoneP(res) )
//     }
    onNewContext() {
        return { ...this.ctx, id:0 }
    }
    //--------------------------------------------------------------------------
    onUpdateCompName            = ''
//     onUpdateP(idx:number) {
//         let ctx                 = this.onUpdateContext(idx)
//         return this.callComponent('', ctx)
//         .then((res:any) => this.onUpdateDoneP(idx, res) )
//     }
    onUpdateContext(idx:number) {
        return { ...this.ctx, id:this.list[idx].id }
    }
    //--------------------------------------------------------------------------
    onSetFlagsP(key:string, values:any) {
        return this.getSelectedIdsP()
        .then((ids:any) =>
            this.busyServiceEmitP('setFlags',
                { key           : key
                , ids           : ids
                , TIMEOUT       : ids.length * 2
                })
        )
        .then(() => this.loadP() )
        .then(() => this.setFocusP(this.messageSaved()) )
    }
    //--------------------------------------------------------------------------
    onEventP(key:string, values:any) {
        return this.getSelectedIdsP()
        .then((ids) => this.busyServiceEmitP('event', { event:key, vrg_ids:ids }) )
        .then((   ) => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
    onPrintP(key:string, values:any) {
        return this.getSelectedIdsP()
        .then((ids:any) => {
            let ctx             =
                { name          : 'Art018'
                , art_ids       : ids
                }
            return this.callComponent('RptModal', ctx)
            .then((res:any) => this.setFocusP() )
        })
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'FlexItem', template:ItemDiv})
export class FlexItem extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:FlexService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    onInitP() {
        let name                = this.ctx.module + 'Item'
        this.service.setModule()
        return this.busyServiceEmitP('initItem', {})
        .then((res:any) => {
            this.log('flex ctx:', res.ctx)

            for (let key in res.attributes) {
                let value       = res.attributes[key]
                this.log(`setting attribute ${key}:`, value)
                this[key]       = value
            }

            for (let key in res.functions) {
                let value       = eval('(' + res.functions[key] + ')')
                this.log(`setting function ${key}`)
                this[key]       = value
            }

            this.setHeader      (this.header || '=menu=')
            this.defineItem     (res.itemDefn       )

            this.additionalButtons
                                (this.ctx.buttons   )
            this.additionalButtons
                                (res.buttons        )

            this.eventButtons   (this.ctx.events    )
            this.eventButtons   (res.events         )

            if (res.ctx.canCopy   && this.ctx.id) {
                this.buttonCopy (this.onCopyP       )
            }

            if (res.ctx.canDelete && this.ctx.id) {
                this.buttonDelete
                                (this.onDeleteP     )
            }

            if (res.ctx.noSubmit) {
                // no submit or OK button
            } else {
                this.buttonOk   (this.onSubmitP     )
                this.formSubmit (this.onSubmitP     )
            }

            return (res.ctx.noRead ? RESOLVE() : this.readP(this.ctx))
            .then(() => this.setFocusP() )
        })
    }
    //--------------------------------------------------------------------------
    additionalButtons(buttons:any={}) {
        buttons                 = is.object(buttons) ? buttons : {}
        for (let key in buttons) {
            let fname           = buttons[key]
            let func            = this[fname]
            if (!is.function(func)) {
                this.log(`invalid button function: ${key} => ${fname}`)
            } else {
                this.makeButton(func, key, key, 'tr')
            }
        }
    }
    //--------------------------------------------------------------------------
    flexAction(key:string, values:any) {
this.log('onFlexAction', [ key, values ])
        if (!values)            { return RESOLVE() }
        return this.busyServiceEmitP(key, values)
        .then((res:any) => {
            if (res) {
                this.receivedItem(res)
                this.messageDone()
            }
            return RESOLVE()
        })
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'FlexModal', template:ItemDiv})
export class FlexModal extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:FlexService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    onInitP() {
        let name                = this.ctx.module + 'Modal'
        this.service.setModule()
        return this.busyServiceEmitP('initModal', {})
        .then((res:any) => {
            this.log('flex ctx:', res.ctx)

            for (let key in res.attributes) {
                let value       = res.attributes[key]
                this.log(`setting attribute ${key}:`, value)
                this[key]       = value
            }

            for (let key in res.functions) {
                let value       = eval('(' + res.functions[key] + ')')
                this.log(`setting function ${key}`)
                this[key]       = value
            }

            this.setHeader      (this.header || '=menu=')
            this.defineItem     (res.itemDefn       )

            this.buttonCancel   (this.onCancelP     )

            this.additionalButtons
                                (this.ctx.buttons   )
            this.additionalButtons
                                (res.buttons        )

            this.eventButtons   (this.ctx.events    )
            this.eventButtons   (res.events         )

            if (res.ctx.canCopy   && this.ctx.id) {
                this.buttonCopy (this.onCopyP       )
            }

            if (res.ctx.canDelete && this.ctx.id) {
                this.buttonDelete
                                (this.onDeleteP     )
            }

            if (res.ctx.noSubmit) {
                // no submit or OK button
            } else {
                this.buttonOk   (this.onSubmitP     )
                this.formSubmit (this.onSubmitP     )
            }

            return (res.ctx.noRead ? RESOLVE() : this.readP(this.ctx))
            .then(() => this.setFocusP() )
        })
        .catch((err) => this.modalREJECT(err) )
    }
    //--------------------------------------------------------------------------
    additionalButtons(buttons:any={}) {
        buttons                 = is.object(buttons) ? buttons : {}
        for (let key in buttons) {
            let fname           = buttons[key]
            let func            = this[fname]
            if (!is.function(func)) {
                this.log(`invalid button function: ${key} => ${fname}`)
            } else {
                this.makeButton(func, key, key, 'tr')
            }
        }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
