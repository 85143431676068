//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, Format
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , TktService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'TktLst', template:ListDiv})
export class TktLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:TktService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { inv_date      : {lab:'date'               , wide: 2, typ:'dates'  }
        , inv_number    : {lab:'invoice'            , wide: 4}
        , ext_number    : {lab:'reference'          , wide: 4}
        , inv_status    : {lab:'status'             , wide: 3}

        , matchcode     : {lab:'ticket'             , wide: 8}
        , seq_no        : {lab:'seq'                , wide: 1, typ:'integer'}
        , code          : {lab:'code'               , wide: 6, typ:'code'   }
        , status        : {lab:'status'             , wide: 3}

        , brutto        : {lab:'price'              , wide: 2, typ:'decimal', tot:true}
        , customer      : {lab:'customer'           , wide: 6}
        , owner         : {lab:'owner'              , wide: 6}
        }
    dontLoadAll         :boolean= true
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        this.makeButton         (this.onConvertP    , 'convert to voucher')
        this.makeButton         (this.onExportP     , 'export details'    )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    defaultCriteria() {
        let since               = this.ctx.since    ? this.ctx.since
                                                    : 'fortnight'

if (this.glob.isDevel) {
    this.ctx.status = '!cnc'
}

        return Object.assign({ date0:this.getDateSince(since) }, this.ctx)
    }
    //--------------------------------------------------------------------------
    onConvertP(key:string, values:any) {
        return this.getSelectedIdUpdsP({ confirm:'conversion' })
        .then((items) => this.busyServiceEmitP('convert_to_voucher', { items:items }) )
        .then((     ) => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
    onExportP(key:string, values:any) {
        let data                = []
        data.push(              [ 'ticket', 'name', 'price', 'code', 'status', 'owner', 'email'
                                , 'invoice', 'date', 'status', 'customer', 'email'
                                ].join('\t'))
        let line
        let prev_num            = ''
        this.list.forEach((item) => {
            if (item.number == prev_num) {
                line            = ['', '', '']
            } else {
                prev_num        = item.number
                line            = [ item.number, item.matchcode, Format.decimal2human(item.brutto) ]
            }
            line.push           ( Format.code2human (item.code  )
                                , item.status
                                , item.own_name
                                , item.own_email
                                , item.inv_number
                                , Format.date2human (item.inv_date)
                                , item.inv_status
                                , item.cus_name
                                , item.cus_email
                                )
            data.push(line.join('\t'))
        })
        this.saveFile(data.join('\n'), 'ticket export.csv', 'text/csv')
        this.messageDone('saved as "ticket export.csv"')
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Tkt', template:ItemDiv})
export class Tkt extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:TktService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , inv_date      : {rdo:'invoice date'       , wide: 3, typ:'date'   }
        , inv_number    : {rdo:'invoice number'     , wide: 3}
        , ext_number    : {rdo:'reference'          , wide: 3}
        , inv_status    : {rdo:'status'             , wide: 3}

        , matchcode     : {rdo:'ticket'             , wide: 5               }
        , seq_no        : {rdo:'seq'                , wide: 1, typ:'integer'}
        , code          : {rdo:'code'               , wide: 3, typ:'code'   }
        , stt_id        : {opt:'status'             , wide: 3, cod:'tkt_stt'}

        , number        : {rdo:'article'            , wide: 3               }
        , brutto        : {rdo:'price'              , wide: 3, typ:'decimal'}

        , cus_name      : {rdo:'customer'           , wide: 6               , newline:true }
        , cus_email     : {rdo:'email'              , wide: 6}

        , own_name      : {rdo:'owner'              , wide: 6}
        , own_email     : {rdo:'email'              , wide: 6}
        }
    //--------------------------------------------------------------------------
    onInitP() {
//         if (this.ctx.id) {
            this.defineItem     (this.itemDefn     )
            this.setHeader      ('edit'            )
            this.buttonCancel   (this.onCancelP    )
//             this.buttonCopy     (this.onCopyP      )
//             this.buttonDelete   (this.onDeleteP    )
            this.buttonOk       (this.onSubmitP    )
            this.formSubmit     (this.onSubmitP    )
//         } else {
//             this.defineItem     (this.itemDefn     )
//             this.setHeader      ('add'             )
//             this.buttonCancel   (this.onCancelP    )
//             this.buttonOk       (this.onSubmitP    )
//             this.formSubmit     (this.onSubmitP    )
//         }
        return this.readP({ id:this.ctx.id })
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
