//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , VrgService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'VrgCmtLst', template:ListDiv})
export class VrgCmtLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:VrgService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { subtyp        : {lab:'type'               , wide: 1}
        , txt           : {lab:'text'               , wide:10}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'cmt_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn, true)

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
