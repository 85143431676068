//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { BusyTag                                   } from './busy'
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , exports                   : [ BusyTag ]
    , declarations              : [ BusyTag ]
    }
)
export class BusyModule extends BaseModule {
    onConstructor() { this.glob.registerComponents([ BusyTag ]) }
}
//------------------------------------------------------------------------------
