//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , VrgService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'VrgLnkLst', template:ListDiv})
export class VrgLnkLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:VrgService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { date          : {lab:'date'               , wide: 6, typ:'dates'}
        , number        : {lab:'number'             , wide: 6}
        , ext_number    : {lab:'reference'          , wide: 7}
        , knt_name      : {lab:'account'            , wide:20}
        , typ_name      : {lab:'type'               , wide: 8}
        , wrk_name      : {lab:'workflow'           , wide: 8}
        , stt_name      : {lab:'state'              , wide: 8}
        , netto         : {lab:'netto'              , wide: 5, typ:'decimal', tot:true}
        , brutto        : {lab:'brutto'             , wide: 5, typ:'decimal', tot:true}
        , cur_typ       : {lab:'cur'                , wide: 3}
        , wsale_yn      : {lab:'w/s'                , wide: 1, typ:'flag'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'lnk_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn, true)
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    onUpdateCompName           = ''
    onUpdateP(idx:number) {
        let item                = this.list[idx]
        let header              = (!item.knt_name)  ? item.number
                                                    : `${item.number}: ${item.knt_name}`
        this.cache              = { vrg:item }
        this.ctx                = { header          : header
                                  , vrg_id          : item.id
                                  , typ             : item.typ
                                  , canEdit         : false
                                  , stab            : 'lnk'
                                  }
        this.dft.vrg_id         = item.id
        this.service.setValidTabs(item.typ)
        this.service.initTabs(item.id, 'dtl')
        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
