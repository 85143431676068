//------------------------------------------------------------------------------
import  { Injectable                                } from '@angular/core'
//------------------------------------------------------------------------------
import * as lib from '../library'
//------------------------------------------------------------------------------
export const Imports            = lib.Imports
export const BaseModule         = lib.Base.Module

export const ItemComp           = lib.Item.Comp
export const ItemDiv            = lib.Item.Div
export const ItemModal          = lib.Item.Modal
export const ListComp           = lib.List.Comp
export const ListDiv            = lib.List.Div


export const TabbedComp         = lib.Base.Tabbed
//------------------------------------------------------------------------------
@Injectable()
export class ChnService extends lib.Base.Service {
    //--------------------------------------------------------------------------
    allTabs             :any    = ['dtl','rgn','rsr','pgw']
    validTabs           :any    = this.allTabs
    //--------------------------------------------------------------------------
    setHeader(values:any) {
        this.ctx.header         = values.id ? values.name : 'new...'
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
export const ChnDiv             = `
<div class='tabs container'>
  <div class='tabshead'>
    <h3 class='header floatl'>
      <button               class='btn back'
                            (click)='popClick()'
      >{{glob.backArrow}}</button>{{ctx.header}}</h3>
    <div class='u-cf'></div>
  </div>
  <div class='tabstabs'>
    <div                    (click)='stabSel("dtl")' class='{{stabCls("dtl")}}'>details         </div>
    <div *ngIf='stabs.rgn'  (click)='stabSel("rgn")' class='{{stabCls("rgn")}}'>regions         </div>
    <div *ngIf='stabs.rsr'  (click)='stabSel("rsr")' class='{{stabCls("rsr")}}'>warehouses      </div>
    <div *ngIf='stabs.pgw'  (click)='stabSel("pgw")' class='{{stabCls("pgw")}}'>payment gateways</div>
  </div>
  <div class='tabsbody' [ngSwitch]='stab'>
    <ChnDtl    *ngSwitchDefault     ></ChnDtl   >
    <ChnRgnLst *ngSwitchCase='"rgn"'></ChnRgnLst>
    <CrgRsrLst *ngSwitchCase='"rsr"'></CrgRsrLst>
    <CrgPgwLst *ngSwitchCase='"pgw"'></CrgPgwLst>
  </div>
</div>
`
//------------------------------------------------------------------------------
