//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , CslTemplate           , TabbedComp
        , CslService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'CslLst', template:ListDiv})
export class CslLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:CslService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { name          : {lab:'name'               , wide: 3}
        , descr         : {lab:'description'        , wide:10}
        , sort_no       : {lab:'sort'               , wide: 1, typ:'integer'}
        }
    //--------------------------------------------------------------------------
    onNewCompName               = 'Csl'
//     onNewP() {
//         let ctx                 = this.onNewContext()
//          return this.callComponent('Csl', ctx)
//         .then((res:any) => this.onNewDoneP(res) )
//     }
    onNewContext() {
        return { }
    }
    //--------------------------------------------------------------------------
    onUpdateCompName            = 'Csl'
//     onUpdateP(idx:number) {
//         let ctx                 = this.onUpdateContext(idx)
//         return this.callComponent('Csl', ctx)
//         .then((res:any) => this.onUpdateDoneP(idx, res) )
//     }
    onUpdateContext(idx:number) {
        return { csl_id:this.list[idx].id }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Csl', template:CslTemplate})
export class Csl extends TabbedComp {
    //--------------------------------------------------------------------------
    constructor( public service:CslService ) { super() }
    //--------------------------------------------------------------------------
    onInitP() {
        this.service.initTabs(this.ctx.id, 'dtl')
        this.ctx.csl_id         =
        this.dft.csl_id         = this.ctx.csl_id// || this.dft.csl_id
        this.service.setHeader(null)
        this.cache              = {}
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
