//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { OlsService                                } from './service'
import  { OlsLst                , Ols               } from './olsLst'
import  { OlsBrnLst             , OlsBrn            } from './olsBrnLst'
import  { OlsCatLst                                 } from './olsCatLst'
import  { OlsChnLst             , OlsChn            } from './olsChnLst'
import  { OlsDdcLst             , OlsDdc            } from './olsDdcLst'
import  { OlsDtl                                    } from './olsDtl'
import  { OlsFtrLst                                 } from './olsFtrLst'
import  { OlsLog                                    } from './olsLog'
import  { OlsPayLst             , OlsPay            } from './olsPayLst'
import  { OlsPmoLst             , OlsPmo, OlsPmoSel } from './olsPmoLst'
import  { OlsRfrLst             , OlsRfr, OlsRfrSel } from './olsRfrLst'
import  { OlsSalLst             , OlsSal, OlsSalSel } from './olsSalLst'
import  { OlsSkuLst                                 } from './olsSkuLst'
import  { OlsSkuAdd                                 } from './olsSkuAdd'
//------------------------------------------------------------------------------
let c = [ OlsLst                , Ols
        , OlsBrnLst             , OlsBrn
        , OlsCatLst
        , OlsChnLst             , OlsChn
        , OlsDdcLst             , OlsDdc
        , OlsDtl
        , OlsFtrLst
        , OlsLog
        , OlsPayLst             , OlsPay
        , OlsPmoLst             , OlsPmo, OlsPmoSel
        , OlsRfrLst             , OlsRfr, OlsRfrSel
        , OlsSalLst             , OlsSal, OlsSalSel
        , OlsSkuLst             , OlsSkuAdd
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ OlsService ]
    }
)
export class OlsModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
