//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , ShlService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'ShlLst', template:ListDiv})
export class ShlLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:ShlService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { sku           : {lab:'sku'                , wide:10, isKey:true}
        , matchcode     : {lab:'matchcode'          , wide:15, isKey:true}
        , name          : {lab:'shelf'              , wide:10}
        , prime         : {lab:'prime'              , wide: 1, typ:'flag', cls:'clickable'}
        }
    dontLoadAll         :boolean= true
    groupingKey                 = 'art_id'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.buttonNew          (this.onNewP        )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )
        this.cellClick          (this.onPrimeP      , 'prime')

        this.dft.rsr_id         = this.ctx.rsr_id

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    onUpdateContext(idx:number) {
        let item                = this.list[idx]
        return { id:item.id, art_id:item.art_id }
    }
    //--------------------------------------------------------------------------
    onPrimeP(idx:number, key:string) {
        let item                = this.list[idx]
        return this.busyServiceEmitP('set_prime', item)
        .then(() => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Shl', template:ItemDiv})
export class Shl extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:ShlService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date','rsr_id']
        , sku           : {lab:'sku number / ean code'
                                                    , wide:12, rtn:this.on_number}

        , name          : {lab:'shelf name'         , wide:12, rtn:this.on_name  }

        , prime         : {lab:'prime'              , wide: 1, typ:'boolean'
                                                             , rtn:this.on_prime }
        }
    //--------------------------------------------------------------------------
    on_number(key:string, value:any) {
        return this.setFocusP('name' )
    }
    on_name(key:string, value:any) {
        return this.setFocusP('prime')
    }
    on_prime(key:string, value:any) {
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    onInitP() {
        let args
        if (this.ctx.id) {
            this.defineItem     (this.itemDefn      )
            this.setHeader      ('edit'             )
            this.buttonCancel   (this.onCancelP     )
            this.buttonCopy     (this.onCopyP       )
            this.buttonDelete   (this.onDeleteP     )
            this.buttonOk       (this.onSubmitP     )
            this.formSubmit     (this.onSubmitP     )
            args                = { id: this.ctx.id }
        } else {
            this.defineItem     (this.itemDefn      )
            this.setHeader      ('add'              )
            this.buttonCancel   (this.onCancelP     )
            this.buttonOk       (this.onSubmitP     )
            this.formSubmit     (this.onSubmitP     )
            args                = { art_id: this.ctx.art_id }
        }

        return this.readP(args)
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
