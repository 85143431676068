//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , EntService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'EntAttLst', template:ListDiv})
export class EntAttLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:EntService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { typ           : {lab:'type'               , wide: 4}
        , txt           : {lab:'alpha'              , wide:10}
        , num           : {lab:'numeric'            , wide: 2, typ:'integer'}
        , sort_no       : {lab:'sort'               , wide: 1, typ:'integer'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'att_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.dft.ent_id         = this.ctx.ent_id
        this.defineList         (this.listDefn      )
        this.setHeader          (this.ctx.header || '=menu=')
        this.buttonBack         (this.onBackP       )
        this.buttonNew          (this.onNewP        )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'EntAtt', template:ItemDiv})
export class EntAtt extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:EntService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date','ent_id']
        , typ           : {lab:'type'               , wide:12, copyCheck:true}
        , txt           : {opt:'alpha'              , wide:12}
        , num           : {opt:'numeric'            , wide:12, typ:'integer'}
        , sort_no       : {opt:'sort'               , wide:12, typ:'integer'}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'att_read'
    onSave_task                 = 'att_save'
    onDelete_task               = 'att_dele'
    //--------------------------------------------------------------------------
    onSubmitCopyCheckP(key:string, values:any) {
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
