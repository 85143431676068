//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { SelectDiv, SelectModal, ScpService        } from './service'
//------------------------------------------------------------------------------
@Component({selector:'SelectScp', template:SelectDiv})
export class SelectScp extends SelectModal {
    //--------------------------------------------------------------------------
    constructor( public service:ScpService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { value         : {lab:'menu item'          , wide:10}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        if (this.ctx.multi)     { this.setHeader('select menu items') }
        else                    { this.setHeader('select menu item' ) }
        this.onInitSelect()

        return this.onSearchP('search', this.item)
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
