//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , PrdService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'PrdPriLst', template:ListDiv})
export class PrdPriLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:PrdService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { cur_name      : {lab:'currency'           , wide: 3}
        , suffix        : {lab:'variant'            , wide: 3}
        , retail        : {lab:'retail'             , wide: 1, typ:'decimal'}
        , wsale         : {lab:'wholesale'          , wide: 1, typ:'decimal'}
        , ws_calc       : {lab:'w/s calculated'     , wide: 1, typ:'decimal'}
        , ws_new        : {lab:'w/s pending'        , wide: 1, typ:'decimal'}
        , ws_new_date   : {lab:'w/s pend. date'     , wide: 1, typ:'date'   }
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'pri_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.onCriteria         (this.onCriteriaP   )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
