//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ArtService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'ArtVrgLst', template:ListDiv})
export class ArtVrgLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:ArtService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { date          : {lab:''                   , wide: 4, typ:'dates'}
//         , typ_name      : {lab:'type'               , wide: 5}
        , number        : {lab:'number'             , wide: 5}
        , ext_number    : {lab:'reference'          , wide: 6}
        , knt_name      : {lab:'account'            , wide:12}
        , wrk_name      : {lab:'workflow'           , wide: 8}
        , stt_name      : {lab:'status'             , wide: 8}
//         , line_num      : {lab:'line'               , wide: 2, typ:'integer'}
        , qty           : {lab:'qty'                , wide: 2, typ:'quantity', tot:true}
        , netto         : {lab:'netto'              , wide: 4, typ:'decimal', tot:true}
        , brutto        : {lab:'brutto'             , wide: 4, typ:'decimal', tot:true}
        , cur_typ       : {lab:'cur'                , wide: 2}
        , rsr_name_from : {lab:'from location'      , wide: 8}
        , rsr_name_to   : {lab:'to location'        , wide: 8}
        , stk_relevance : {lab:'stock relevance'    , wide: 6}
        , pay_typ       : {lab:'pay'                , wide: 1}
        , wsale_yn      : {lab:'w/s'                , wide: 1, typ:'flag'}
        , booked        : {lab:'bkd'                , wide: 1, typ:'flag'}
        , events_flag   : {lab:'evn'                , wide: 1, typ:'flag'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'vrg_load'
    //--------------------------------------------------------------------------
    defaultCriteria() {
        return { date0:this.getDateSince('month') }
    }
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onVrgP        )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    onVrgP(idx:number) {
        return this.callComponent('Vrg', { id:this.list[idx].id })
//         .then((res:any) => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
