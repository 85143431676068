//------------------------------------------------------------------------------
import  { Injectable                                } from '@angular/core'
//------------------------------------------------------------------------------
import * as lib from '../library'
//------------------------------------------------------------------------------
export const Imports            = lib.Imports
export const BaseModule         = lib.Base.Module

export const PstList            = lib.Pst.List
export const PstListDiv         = lib.Pst.ListDiv
export const PstModal           = lib.Pst.Modal
export const PstModalDiv        = lib.Pst.ModalDiv
//------------------------------------------------------------------------------
@Injectable()
export class RtnService extends lib.Base.Service {}
//------------------------------------------------------------------------------
