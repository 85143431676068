//------------------------------------------------------------------------------
import  { Injectable                                } from '@angular/core'
//------------------------------------------------------------------------------
import * as lib from '../../library'
//------------------------------------------------------------------------------
export const Imports            = lib.Imports
export const BaseModule         = lib.Base.Module

export const ItemComp           = lib.Item.Comp
export const ItemDiv            = lib.Item.Div
export const ItemModal          = lib.Item.Modal
export const ListComp           = lib.List.Comp
export const ListDiv            = lib.List.Div
//------------------------------------------------------------------------------
@Injectable()
export class FlexService extends lib.Base.Service {
    //--------------------------------------------------------------------------
    setModule() {
        this.module_name        = this.lowerFirst(this.ctx.module || 'unnamed')
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
