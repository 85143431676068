//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , PrdService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'PrdTagLst', template:ListDiv})
export class PrdTagLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:PrdService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { typ_name      : {lab:'type'               , wide: 5}
        , value         : {lab:'value'              , wide:15}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'tag_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn, true)

        this.buttonDelete       (this.onDeleteP     , 'delete selected')
        this.buttonSelAll       (this.onSelectAllP  )
        this.buttonInvSel       (this.onInvertSelP  )
        this.buttonNew          (this.onNewP        )

        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        this.dft.prd_id         = this.ctx.id
        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    async onDeleteP() {
        let idUpds              = await this.getSelectedIdUpdsP({ confirm:'deletion' })
        .then((items) => this.busyServiceEmitP('tag_dele', { items:items }) )
        .then((res  ) => this.onDeleteDoneP(res) )
        .then((     ) => this.loadAndFocusP(   ) )
    }
    onDeleteDoneP(res:any) {
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'PrdTag', template:ItemDiv})
export class PrdTag extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:PrdService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date','tab','tab_id']
        , typ_id        : {lab:'type'               , wide: 6, cod:'prd_tag', copyCheck:true}
        , value         : {lab:'value'              , wide: 6}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'tag_read'
    onSave_task                 = 'tag_save'
    onDelete_task               = 'tag_dele'
    //--------------------------------------------------------------------------
    readDoneP() {
        if (!this.item.prd_id) {
            this.item.tab_id    = this.dft.prd_id
        }
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
