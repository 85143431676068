//------------------------------------------------------------------------------
import  { Injectable                                } from '@angular/core'
//------------------------------------------------------------------------------
import * as lib from '../library'
//------------------------------------------------------------------------------
export const Imports            = lib.Imports
export const BaseModule         = lib.Base.Module

export const PstList            = lib.Pst.List
export const PstListDiv         = lib.Pst.ListDiv
export const PstModal           = lib.Pst.Modal
export const PstModalDiv        = lib.Pst.ModalDiv
//------------------------------------------------------------------------------
import { percentof              } from '../lib/calcs'
//------------------------------------------------------------------------------
@Injectable()
export class IdlService extends lib.Base.Service {
    //--------------------------------------------------------------------------
    calc_profit(buy:number, sell:number) {
        let margin              = (sell || 0) - (buy || 0)
        let markup              = (sell) ? percentof(margin, sell) : 0
        return [ margin, markup ]
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
