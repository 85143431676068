//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { ChnService                                } from './service'
import  { ChnLst                , Chn                } from './chnLst'
import  { ChnDtl                                    } from './chnDtl'
import  { ChnRgnLst             , ChnRgn            } from './chnRgnLst'
import  { CrgPgwLst             , CrgPgw            } from './crgPgwLst'
import  { CrgRsrLst             , CrgRsr            } from './crgRsrLst'
//------------------------------------------------------------------------------
let c = [ Chn
        , ChnDtl
        , ChnLst
        , ChnRgn
        , ChnRgnLst
        , CrgPgw
        , CrgPgwLst
        , CrgRsr
        , CrgRsrLst
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ ChnService ]
    }
)
export class ChnModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
