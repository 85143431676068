//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv               , ItemModal
        , ListDiv               , ListComp
        , PrdTemplate           , PrdFamTemplate
        , TabbedComp
        , PrdService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'PrdLst', template:ListDiv})
export class PrdLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:PrdService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { number        : {lab:'number'             , wide: 4}
//         , suffix_x      : {lab:'variants'           , wide: 6}
//         , name          : {lab:'name'               , wide: 4}
        , matchcode     : {lab:'matchcode'          , wide:10}
        , suffixes      : {lab:'variants'           , wide: 5}
        , typ_name      : {lab:'type'               , wide: 2}
        , wrg_name      : {lab:'product group'      , wide: 4}
        , brn_name      : {lab:'brand'              , wide: 4}
        , dsg_name      : {lab:'designer'           , wide: 4}
        , price_class_ws: {lab:'price class w/s'    , wide: 2}
        , brutto        : {lab:'base brutto'        , wide: 2, typ:'decimal'}
        , stt_name      : {lab:'status'             , wide: 3}
        , abc_typ       : {lab:'rev ABC'            , wide: 1, cls:'center'}
        , markers       : {lab:'markers'            , wide:10}
        }
    dontLoadAll         :boolean= true
    //--------------------------------------------------------------------------
    onInitP() {
        this.dft                =
            { vorgang           : this.ctx.vorgang
            , channel           : this.ctx.channel
            , vendor            : this.ctx.vendor
            , workflow          : this.ctx.workflow
            , status            : this.ctx.status
            }
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.buttonDelete       (this.onDeleteP     , 'delete selected')
        this.buttonSelAll       (this.onSelectAllP  )
        this.buttonInvSel       (this.onInvertSelP  )
//         this.makeButton         (this.onDryRunP     ,'reprice (dry-run)')
//         this.makeButton         (this.onRecalcP     ,'reprice (commit)' )
        this.makeButton         (this.sync_external , 'sync external')
        this.makeButton         (this.onCsvDownloadP, 'csv download')
        this.makeButton         (this.onCsvUploadP  , 'csv upload')
        this.makeButton         (this.onNewSkuP     , 'new product')
        this.makeButton         (this.onNewFamP     , 'new family')
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )


if (this.glob.userIsDeveloper && this.emptyCriteria) {
    this.criteria.number = '010012*'
}

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    onNewSkuP() {
         return this.callComponent('Prd', { id:0 })
        .then((res:any) => this.onNewDoneP(res) )
    }
    onNewFamP() {
         return this.callComponent('PrdFam', { id:0 })
        .then((res:any) => this.onNewDoneP(res) )
    }
    //--------------------------------------------------------------------------
    onUpdateCompName            = 'Prd'
    onUpdateP(idx:number) {
        let item                = this.list[idx]
        let compname            = /^fam_\w+$/.test(item.typ_typ) ? 'PrdFam' : 'Prd'
        return this.callComponent(compname, { id:item.id })
        .then((res:any) => this.onUpdateDoneP(idx, res) )
    }
    //--------------------------------------------------------------------------
//     onDryRunP(key:string, values:any) {
//         return this.getSelectedIdsP({ allowNone:true })
//         .then((ids:any) => {
//             this.messageText('recalc (dry-run) request submitted')
//             return this.downloadP(
//                 { prd_ids       : ids
//                 , dry_run       : true
//                 , TIMEOUT       : 600
//                 },
//                 { name          : 'recalc'
//                 , task          : 'recalc'
//                 })
//         })
//     }
//     //--------------------------------------------------------------------------
//     onRecalcP(key:string, values:any) {
//         return this.getSelectedIdsP({ allowNone:true })
//         .then((ids:any) => {
//             this.messageText('recalc (commit) request submitted')
//             return this.downloadP(
//                 { prd_ids       : ids
//                 , dry_run       : false
//                 , TIMEOUT       : 600
//                 },
//                 { name          : 'recalc'
//                 , task          : 'recalc'
//                 })
//         })
//     }
//     //--------------------------------------------------------------------------
    sync_external(key:string, values:any) {
        return this.getSelectedIdsP()
        .then((ids) => this.busyServiceEmitP('sync_external', { ids }) )
        .then((   ) => this.setFocusP(this.messageText('external synchronisation submitted')) )
    }
    //--------------------------------------------------------------------------
    onCsvDownloadP(key:string, values:any) {
        return this.getSelectedIdsP()
        .then((ids:any) => {
            this.messageText('csv download request submitted')
            return this.downloadP(
                { prd_ids       : ids
                , TIMEOUT       : 1200
                },
                { name          : 'csv download'
                , task          : 'csv_download'
                })
        })
    }
    //--------------------------------------------------------------------------
    onCsvUploadP(key:string, values:any) {
        return this.callComponent('AcceptCsvData', {})
        .then((res:any) => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
    onDeleteP() {
        return this.getSelectedIdUpdsP({ confirm:'deletion' })
        .then((items) => this.busyServiceEmitP('dele_many', { items:items }) )
        .then((res  ) => this.onDeleteDoneP(res) )
        .then((     ) => this.loadAndFocusP(   ) )
    }
    onDeleteDoneP(res:any) {
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Prd', template:PrdTemplate})
export class Prd extends TabbedComp {
    //--------------------------------------------------------------------------
    constructor( public service:PrdService ) { super() }
    //--------------------------------------------------------------------------
    onInitP() {
        this.service.initTabs(this.ctx.id, 'dtl')
        this.ctx.prd_id         =
        this.dft.prd_id         = this.ctx.id
        this.cache              = {}
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'PrdFam', template:PrdFamTemplate})
export class PrdFam extends TabbedComp {
    //--------------------------------------------------------------------------
    constructor( public service:PrdService ) { super() }
    //--------------------------------------------------------------------------
    onInitP() {
        this.service.initTabs(this.ctx.id, 'dtl')
        this.ctx.prd_id         =
        this.dft.prd_id         = this.ctx.id
        this.cache              = {}
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'AcceptCsvData', template:ItemDiv})
export class AcceptCsvData extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:PrdService ) { super() }
    modal_size                  = 'large'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { csvData       : {lab:'CSV data'           , wide:12, typ:'textarea', rows:30}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        this.buttonCancel       (this.onCancelP     )
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        this.item               = { csvData:'' }
        return this.setFocusP()
    }
    //--------------------------------------------------------------------------
    onSubmitP(key:string, values:any) {
        if (!values)            { return RESOLVE() }
        values.TIMEOUT          = 600
        return this.busyServiceEmitP('csv_upload', values)
        .then((res:any) => this.modalRESOLVE(res) )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
