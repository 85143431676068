//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { TktService                                } from './service'
import  { TktLst                , Tkt               } from './tktLst'
import  { TktSal                                    } from './tktSal'
import  { TktSum                                    } from './tktSum'
//------------------------------------------------------------------------------
let c = [ TktLst                , Tkt
        , TktSal
        , TktSum
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ TktService ]
    }
)
export class TktModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
