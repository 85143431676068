//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , OlsDiv                , TabbedComp
        , OlsService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'OlsLst', template:ListDiv})
export class OlsLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:OlsService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { name          : {lab:'shop name'          , wide:10}
        , shop          : {rdo:'shop key'           , wide: 3}
        , descr         : {opt:'description'        , wide:10}
        , chn_grouping  : {lab:'channel grouping'   , wide: 5, cod:'chn_grp'}
        , ord_prefix    : {opt:'order prefix'       , wide: 3}
        , url           : {lab:'url'                , wide: 6}
        , img_typ_name  : {lab:'image type'         , wide: 3, cod:'img_tag'}
        , is_online     : {lab:'online'             , wide: 1, typ:'flag'}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.buttonNew          (this.onNewP        )
        this.buttonSelAll       (this.onSelectAllP  )
        this.buttonInvSel       (this.onInvertSelP  )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    onNewCompName               = 'Ols'
//     onNewP() {
//         let ctx                 = this.onNewContext()
//          return this.callComponent('Ols', ctx)
//         .then((res:any) => this.onNewDoneP(res) )
//     }
    onNewContext() {
        return { id:0 }
    }
    //--------------------------------------------------------------------------
    onUpdateCompName            = 'Ols'
//     onUpdateP(idx:number) {
//         let ctx                 = this.onUpdateContext(idx)
//         return this.callComponent('Ols', ctx)
//         .then((res:any) => this.onUpdateDoneP(idx, res) )
//     }
    onUpdateContext(idx:number) {
        return { id:this.list[idx].id }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Ols', template:OlsDiv})
export class Ols extends TabbedComp {
    //--------------------------------------------------------------------------
    constructor( public service:OlsService ) { super() }
    //--------------------------------------------------------------------------
    onInitP() {
        this.service.initTabs(this.ctx.id, 'dtl')
        this.ctx.ols_id         =
        this.dft.ols_id         = this.ctx.id
        this.cache              = {}
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
