//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemComp, ArtService             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'ArtKpi', template:ItemDiv})
export class ArtKpi extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:ArtService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , profit_rank   : {rdo:'ranking'            , wide: 4, typ:'integer'}

        , date          : {rdo:'date'               , wide: 4, typ:'date'   , newline:true}
        , first_avail   : {rdo:'first available'    , wide: 4, typ:'date'   }
        , first_sale    : {rdo:'first sale'         , wide: 4, typ:'date'   }

        , onhand        : {rdo:'onhand'             , wide: 4, typ:'quantity'}
        , days_unstocked: {rdo:'days out of stock'  , wide: 4, typ:'integer'}
        , days_stocked  : {rdo:'days stocked'       , wide: 4, typ:'integer'}

        , idl_qty_year  : {rdo:'purchased (year)'   , wide: 4, typ:'quantity'}
        , rtn_qty_year  : {rdo:'returned (year)'    , wide: 4, typ:'quantity'}
        , rtn_pct       : {rdo:'returned (%)'       , wide: 4, typ:'decimal'}

        , dlv_qty_year  : {rdo:'sold (year)'        , wide: 4, typ:'quantity'}
        , dlv_avg       : {rdo:'daily average'      , wide: 4, typ:'quantity'}
        , dlv_avg_stk   : {rdo:'daily average (stocked)'
                                                    , wide: 4, typ:'quantity'}

        , dlv_val_year  : {rdo:'sales (€)'          , wide: 4, typ:'decimal'}
        , margin_year   : {rdo:'margin (%)'         , wide: 4, typ:'decimal'}
        , profit_year   : {rdo:'profit (€)'         , wide: 4, typ:'decimal'}

        , last_pur_netto: {rdo:'purchase netto'     , wide: 4, typ:'decimal'}
        , onh_qty_avg   : {rdo:'onhand average'     , wide: 2, typ:'quantity'}
        , onh_val_avg   : {rdo:'onhand average (€)' , wide: 2, typ:'decimal'}
        , turnover      : {rdo:'stock turnover'     , wide: 2, typ:'decimal'}
        , roi_pct       : {rdo:'return on capex (%)', wide: 2, typ:'decimal'}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'kpi_read'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        return this.readP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
