//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { EmpService                                } from './service'
import  { EmpLst                , Emp               } from './empLst'
import  { EmpDtl                                    } from './empDtl'
import  { EmpRolLst                                 } from './empRolLst'
//------------------------------------------------------------------------------
let c = [ EmpLst                , Emp
        , EmpDtl
        , EmpRolLst
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ EmpService ]
    }
)
export class EmpModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
import  { SelectEmp                                 } from './selectEmp'
let d = [ SelectEmp
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : d
    , providers                 : [ EmpService ]
    }
)
export class SelectEmpModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(d) }
}
//------------------------------------------------------------------------------
