//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , LndService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'LndLst', template:ListDiv})
export class LndLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:LndService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { name          : {lab:'name'               , wide: 5}
        , abbrev2       : {lab:'code'               , wide: 1}
        , lng_name      : {lab:'language'           , wide: 3}
        , rgn_name      : {lab:'region'             , wide: 3}
        , sort_no       : {lab:'sort'               , wide: 1, typ:'integer'}
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Lnd', template:ItemDiv})
export class Lnd extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:LndService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , name          : {lab:'name'               , wide: 6               , copyCheck:true}
        , abbrev2       : {lab:'code'               , wide: 4               , copyCheck:true}
        , sort_no       : {opt:'sort'               , wide: 2, typ:'integer'}

        , lng_id        : {lab:'language'           , wide: 6, cod:'lng'    }
        , rgn_id        : {lab:'region'             , wide: 6, cod:'rgn'    }
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
