//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, list_div, jQuery
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { BaseComp
        , DivDiv
        , DivService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'DivComp', template:DivDiv})
export class DivComp extends BaseComp {
    //--------------------------------------------------------------------------
    constructor( public service:DivService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    onInitP() {

        let $                   = jQuery
        new list_div(this.glob
            , this.ctx.module
            , $('maintag').prepend($('<div>'))
            , { ctx:this.ctx }
            )

        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
