//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { LndService                                } from './service'
import  { LndLst                , Lnd               } from './lndLst'
import  { LndDtyLst             , LndDty            } from './lndDtyLst'
import  { LndGrpLst             , LndGrp            } from './lndGrpLst'
import  { LndRsrLst             , LndRsr            } from './lndRsrLst'
//------------------------------------------------------------------------------
let c = [ LndLst                , Lnd
        , LndDtyLst             , LndDty
        , LndGrpLst             , LndGrp
        , LndRsrLst             , LndRsr
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ LndService ]
    }
)
export class LndModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
