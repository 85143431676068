//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { CatService                                } from './service'
import  { CatLst                , Cat               } from './catLst'
import  { CatCslLst                                 } from './catCslLst'
import  { CatDtl                                    } from './catDtl'
import  { CatGrpLst                                 } from './catGrpLst'
import  { CatSelLst             , CatSel            } from './catSelLst'
//------------------------------------------------------------------------------
let c = [ CatLst                , Cat
        , CatCslLst
        , CatDtl
        , CatGrpLst
        , CatSelLst             , CatSel
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ CatService ]
    }
)
export class CatModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
import  { SelectCat                                 } from './selectCat'
let d = [ SelectCat ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : d
    , providers                 : [ CatService ]
    }
)
export class SelectCatModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(d) }
}
//------------------------------------------------------------------------------
