//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, promiseLoop
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { PstListDiv            , PstList
        , PstModalDiv           , PstModal
        , VrgService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'VrgPstLst', template:PstListDiv})
export class VrgPstLst extends PstList {
    //--------------------------------------------------------------------------
    constructor( public service:VrgService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    selectorDefaults    :any    = { selector: '', qty: 1000 }
    parseTask           :string = 'select_psts'
    parseBase           :any    = {}
    recalcPst                   = true
    price_reqd                  = true
    allow_zero_qtys             = false

    mergeKey                    = 'art_id'
    xrefKeys                    = 'ean_number,number,code,matchcode'.split(/\W+/g)
    //--------------------------------------------------------------------------
    editDefn            :any    =
        { selector      : {opt:'article number or barcode'
                                                    , wide: 6}
        , qty           : {opt:'quantity'           , wide: 2, typ:'quantity'}
        , net_tot       : {rdo:'total netto'        , wide: 2, typ:'decimal'}
        , brt_tot       : {rdo:'total brutto'       , wide: 2, typ:'decimal'}
        }
    dispDefn            :any    =
        { stuff         : {rdo:''                   , wide: 8, typ:'skip'}
        , net_tot       : {rdo:'total netto'        , wide: 2, typ:'decimal'}
        , brt_tot       : {rdo:'total brutto'       , wide: 2, typ:'decimal'}
        }
    listDefn            :any    =
        { line_num      : {lab:'line'               , wide: 1, typ:'integer'}
        , number        : {lab:'number'             , wide: 5}
        , txt           : {lab:'description'        , wide:12}
        , qty           : {lab:'qty'                , wide: 1, typ:'quantity'}
        , discount      : {lab:'discount'           , wide: 2, typ:'decimal'}
        , price         : {lab:'price'              , wide: 2, typ:'decimal'}
        , total         : {lab:'total'              , wide: 2, typ:'decimal'}
        , cur_typ       : {lab:'cur'                , wide: 1}

        , netto_tot     : {lab:'netto'              , wide: 2, typ:'decimal'}
        , taxpct        : {lab:'tax %'              , wide: 2, typ:'percent'}
        , brutto_tot    : {lab:'brutto'             , wide: 2, typ:'decimal'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'pst_load'
    onRead_task                 = 'DEFAULTS'        // read selectorDefaults
    onSave_task                 = 'pst_save'
    //--------------------------------------------------------------------------
    onInitP() {
        if (this.ctx.canEdit) {
            this.defineItem     (this.editDefn      )
        } else {
            this.defineItem     (this.dispDefn      )
        }
        this.defineList         (this.listDefn, true)
        if (this.ctx.canEdit) {
            this.buttonSelAll   (this.onSelectAllP  )
            this.buttonInvSel   (this.onInvertSelP  )
            this.buttonRemSel   (this.onDeleteP     )
            this.makeButton     (this.reloadPricesP , 'reload prices')
            this.buttonSave     (this.onSaveP       )
            this.formSubmit     (this.onSubmitP     )
            this.listClick      (this.onSelectItmP  )
            this.listDclick     (this.onUpdateListP )
        }

        return this.readP()
        .then(() => this.loadSpecialP() )
        .then(() => this.setFocusP   () )
    }
    //--------------------------------------------------------------------------
    applyValuesToList_extra(idx:number, values:any) {
// this.log('applyValuesToList_extra (before):', values)
        values.wsale_sw         = this.cache.vrg.wsale_sw
        if (values.wsale_sw) {
            values.dsc_net      = values.discount
            values.netto        = values.price
        } else {
            values.dsc_brt      = values.discount
            values.brutto       = values.price
        }
        values.exchrate         = values.exchrate   || 100000000
        this.doRecalcPst(values)
// this.log('applyValuesToList_extra (after):', values)
    }
    //--------------------------------------------------------------------------
    reloadPricesP(key:string) {
        return this.getSelectedItemsP()
        .then((items) => {
            return this.busyServiceEmitP('reload_item_prices',
                { vrg           : this.cache.vrg
                , items         : items
                })
        })
        .then((items) =>
            promiseLoop(items, items, (item) =>
                this.applyValuesToListP(item.idx, item)
                .catch((err:any) =>
                    RESOLVE(this.log('[applyValuesToListP] error: %s\n[applyValuesToListP] item:', err, item))
                )
            )
        )
        .then((items) => {
            this.messageDone(`${items.length} prices reloaded`)
            return this.setTotalsP()
        })
    }
    //--------------------------------------------------------------------------
    setTotalsP() {
        let net_tot             = 0
        let brt_tot             = 0
        this.list.forEach((item:any) => {
            net_tot            += item.netto    * item.qty / 1000
            brt_tot            += item.brutto   * item.qty / 1000
        })
        return this.setItemValuesP({ net_tot:net_tot, brt_tot:brt_tot })
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'VrgPst', template:PstModalDiv})
export class VrgPst extends PstModal {
    //--------------------------------------------------------------------------
    constructor( public service:VrgService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['idx']
        , line_num      : {rdo:'line'               , wide:12, typ:'integer'}
        , number        : {rdo:'number'             , wide:12}
        , matchcode     : {rdo:'matchcode'          , wide:12}
        , qty           : {opt:'quantity'           , wide:12, typ:'quantity', chg:this.on_qty       }
        , base_price    : {opt:'original price'     , wide:12, typ:'decimal' , chg:this.on_base_price}
        , discount      : {opt:'discount amount'    , wide: 6, typ:'decimal' , chg:this.on_discount  }
        , dsc_pct       : {opt:'discount percent'   , wide: 6, typ:'percent' , chg:this.on_dsc_pct   }
        , price         : {rdo:'discounted price'   , wide:12, typ:'decimal'}
        , total         : {rdo:'total'              , wide:12, typ:'decimal'}
        , txt           : {lab:'description'        , wide:12}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.itemDefn.base_price.opt
                                = this.cache.vrg.wsale_sw
                                    ? 'original netto'
                                    : 'original brutto'
        this.defineItem         (this.itemDefn      )
        this.setHeader          ('edit'             )
        this.buttonPrev         (this.onPrevP       )
        this.buttonNext         (this.onNextP       )
        this.buttonCancel       (this.onCancelP     )
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        this.setSource(this.cache[this.name + 'Lst'])
        return this.setItemFromSourceP(this.ctx.idx)
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
    setExtraValues(values:any) {
// this.log('setExtraValues:', { base_price:values.base_price, price:values.price })
        values.base_price       = values.price + values.discount
        if (values.discount && values.base_price) {
            values.dsc_pct      = Math.round(10000 * values.discount / values.base_price)
        } else if (values.discount) {
            values.base_price   = 0
            values.dsc_pct      = 0
        } else {
            values.discount     = 0
            values.dsc_pct      = 0
        }
    }
    //--------------------------------------------------------------------------
    on_qty(key:string, value:any) {
// this.log('on_qty:', [ value, this.clone(this.item) ])
        let item                = this.item
        if (value == item.qty) {
            return RESOLVE()
        }
        let qty                 = value
        let total               = Math.round(item.price * qty / 1000)
        return this.setItemValuesP({ qty:qty, total:total })
    }
    //--------------------------------------------------------------------------
    on_base_price(key:string, value:any) {
// this.log('on_base_price:', [ value, this.clone(this.item) ] )
        let item                = this.item
        if (value == item.base_price) {
            return RESOLVE()
        }
        let base_price          = value
        let dsc_pct             = item.dsc_pct
        let discount            = item.discount
        if (!base_price) {
            dsc_pct             = 0
        } else if (dsc_pct) {
            discount            = Math.round(base_price * dsc_pct / 10000)
        } else if (discount) {
            dsc_pct             = Math.round(10000 * discount / base_price)
        } else {
            dsc_pct             = 0
            discount            = 0
        }
        let price               = base_price - discount
        let total               = Math.round(price * item.qty / 1000)
        return this.setItemValuesP(
                                { base_price        : base_price
                                , discount          : discount
                                , dsc_pct           : dsc_pct
                                , price             : price
                                , total             : total
                                })
    }
    //--------------------------------------------------------------------------
    on_discount(key:string, value:any) {
// this.log('on_discount:', [ value, this.clone(this.item) ] )
        let item                = this.item
        if (value == item.discount) {
            return RESOLVE()
        }
        let discount            = value
        let base_price          = item.base_price
        let dsc_pct             = 0
        if (base_price) {
            dsc_pct             = Math.round(10000 * discount / base_price)
        }
        let price               = base_price - discount
        let total               = Math.round(price * item.qty / 1000)
        return this.setItemValuesP(
                                { discount          : discount
                                , dsc_pct           : dsc_pct
                                , price             : price
                                , total             : total
                                })
    }
    //--------------------------------------------------------------------------
    on_dsc_pct(key:string, value:any) {
// this.log('on_dsc_pct:', [ value, this.clone(this.item) ])
        let item                = this.item
        if (value == item.dsc_pct) {
            return RESOLVE()
        }
        let dsc_pct             = value
        let base_price          = item.base_price
        let discount            = Math.round(base_price * dsc_pct / 10000)
        let price               = base_price - discount
        let total               = Math.round(price * item.qty / 1000)
        return this.setItemValuesP(
                                { discount          : discount
                                , dsc_pct           : dsc_pct
                                , price             : price
                                , total             : total
                                })
    }
    //--------------------------------------------------------------------------
    on_txt(key:string, value:any) {
// this.log('on_txt:', [ value, this.clone(this.item) ])
        let item                = this.item
        if (value == item.txt) {
            return RESOLVE()
        }
        return this.setItemValuesP({ txt:value })
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
