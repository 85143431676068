//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { GrpService                                } from './service'
import  { GrpLst                , Grp               } from './grpLst'
import  { GrpCatLst                                 } from './grpCatLst'
import  { GrpDtl                                    } from './grpDtl'
import  { GrpPrdLst                                 } from './grpPrdLst'
//------------------------------------------------------------------------------
let c = [ GrpLst                , Grp
        , GrpCatLst
        , GrpDtl
        , GrpPrdLst
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ GrpService ]
    }
)
export class GrpModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
import  { SelectGrp                                 } from './selectGrp'
let d = [ SelectGrp
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : d
    , providers                 : [ GrpService ]
    }
)
export class SelectGrpModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(d) }
}
//------------------------------------------------------------------------------
