//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , OlsService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'OlsDdcLst', template:ListDiv})
export class OlsDdcLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:OlsService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { subtyp        : {lab:'shop customer id'   , wide: 3}
        , descr         : {lab:'description'        , wide:10}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'ddc_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn, true)
        this.buttonNew          (this.onNewP        )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'OlsDdc', template:ItemDiv})
export class OlsDdc extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:OlsService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , subtyp        : {lab:'shop customer id'   , wide:12               , copyCheck:true}
        , descr         : {opt:'description'        , wide:12}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'ddc_read'
    onSave_task                 = 'ddc_save'
    onDelete_task               = 'ddc_dele'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
