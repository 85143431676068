//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, jQuery
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { PstListDiv, PstList, PckService           } from './service'
//------------------------------------------------------------------------------
@Component({selector:'PckPstLst', template:PstListDiv})
export class PckPstLst extends PstList {
    //--------------------------------------------------------------------------
    constructor( public service:PckService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    selectorDefaults    :any    = { selector: '' }
    parseTask           :string = ''
    parseBase           :any    = {}
    recalcPst                   = false
    price_reqd                  = false
    allow_zero_qtys             = false

    mergeKey                    = 'art_id'
    xrefKeys                    = 'ean_number,number,code,matchcode'.split(/\W+/g)
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { selector      : {opt:'article number / barcode'
                                                    , wide: 6}
        , qty           : {rdo:'selected qty'       , wide: 3, typ:'quantity'}
        , weight        : {rdo:'selected weight'    , wide: 3, typ:'quantity'}
        }
    listDefn            :any    =
        { line_num      : {lab:'line'               , wide: 1, typ:'integer'}
        , shelves       : {lab:'shelves'            , wide: 4, typ:'textarea'}
        , number        : {lab:'number'             , wide: 4}
        , matchcode     : {lab:'matchcode'          , wide:10}
        , code          : {lab:'code'               , wide: 2}
        , ean_number    : {lab:'ean number'         , wide: 2}
        , brutto        : {lab:'brutto'             , wide: 2, typ:'decimal'}
        , qty           : {lab:'qty'                , wide: 1, typ:'quantity'}
        , weight        : {lab:'weight'             , wide: 1, typ:'quantity'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'pst_load'
    onRead_task                 = 'DEFAULTS'        // read selectorDefaults
    onSave_task                 = 'pst_save'
    onDelete_task               = 'pst_dele'
    //--------------------------------------------------------------------------
    onInitP() {
        this.item               = { selector:'', qty:0, weight:0 }
        this.defineItem         (this.itemDefn      )
        this.defineList         (this.listDefn, true)
        this.buttonSelAll       (this.onSelectAllP  )
        this.buttonInvSel       (this.onInvertSelP  )
        this.buttonSave         (this.onSaveP       )
        this.makeButton         (this.onDHLP        , 'DHL')
        this.makeButton         (this.onFEDEXP      , 'FEDEX')
        this.makeButton         (this.onUPS         , 'UPS')
        this.formSubmit         (this.onSubmitP     )
        this.listClick          (this.onSelectItmP  )

        return this.readP()
        .then(() => this.loadP() )
        .then(() => this.setFocusP(this.setupXref(this.list)) )
    }
    //--------------------------------------------------------------------------
    readDoneP() {
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    onDHLP(key:string, values:any) {
        let list                = this.list
        if (!list.some((item:any) => item.SELECT)) {
            // nothing selected (without being forced) => SELECT everything...
            list.forEach((item:any) => {
                item.HIDDEN     = false
                item.SELECT     = true
            })
        }
        return this.callComponent('DHLModal',
            { name              : 'DHL delivery'
            , vrg_id            : this.dft.vrg_id
            , items             : list
            })
        .then((res:any) => {
            if (!(res && res.packed_id)) {
                return REJECT(`invalid response from server: [${res}]`)
            }
            if (res.todo_id) {
                return this.setItemValuesP({ selector:'', qty:1000 })
                .then(() => this.loadAndFocusP() )
            } else {
                return this.onBackP()
            }
        })
    }
    //--------------------------------------------------------------------------
    onFEDEXP(key:string, values:any) {
        let list                = this.list
        if (!list.some((item:any) => item.SELECT)) {
            // nothing selected (without being forced) => SELECT everything...
            list.forEach((item:any) => {
                item.HIDDEN     = false
                item.SELECT     = true
            })
        }
        return this.callComponent('FEDEXModal',
            { name              : 'FEDEX delivery'
            , vrg_id            : this.dft.vrg_id
            , items             : list
            })
        .then((res:any) => {
            if (!(res && res.packed_id)) {
                return REJECT(`invalid response from server: [${res}]`)
            }
            if (res.todo_id) {
                return this.setItemValuesP({ selector:'', qty:1000 })
                .then(() => this.loadAndFocusP() )
            } else {
                return this.onBackP()
            }
        })
    }
    //--------------------------------------------------------------------------
    onUPS(key:string, values:any) {
        let list                = this.list
        if (!list.some((item:any) => item.SELECT)) {
            // nothing selected (without being forced) => SELECT everything...
            list.forEach((item:any) => {
                item.HIDDEN     = false
                item.SELECT     = true
            })
        }
        return this.callComponent('UPSModal',
            { name              : 'UPS delivery'
            , vrg_id            : this.dft.vrg_id
            , items             : list
            })
        .then((res:any) => {
            if (!(res && res.packed_id)) {
                return REJECT(`invalid response from server: [${res}]`)
            }
            if (res.todo_id) {
                return this.setItemValuesP({ selector:'', qty:1000 })
                .then(() => this.loadAndFocusP() )
            } else {
                this.onBackP()
                return RESOLVE()
            }
        })
    }
    //--------------------------------------------------------------------------
    onSubmitP(key:string, values:any) {
        let [ selector, qty ]   = this.decodeSelector(values)
        if (!selector) {
            return REJECT('scan/enter article number or barcode')
        }
        let found               = this.xrefFind(selector)
        if (!found) {
            return REJECT(`no item found for "${selector}"`)
        }
        let idx                 = null
        found.some((x:number) => {
            if (this.list[x].SELECT) {
                return false
            } else {
                idx             = x
                return true
            }
        })
        if (idx === null) {
            return REJECT(`no unselected item found for "${selector}"`)
        }
        let item                = this.list[idx]
        item.HIDDEN             = false
        item.SELECT             = true
        this.messageDone(`item '${item.number}' selected`)
        jQuery('.list.container tr').eq(idx).get(0).scrollIntoView()
        return this.setItemValuesP({ selector:'' })
        .then(() => this.setTotalsP() )
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
    setTotalsP() {
        let qty                 = 0
        let weight              = 0
        this.list.forEach((item:any) => {
            if (item.SELECT) {
                qty            += item.qty
                weight         += item.weight
            }
        })
        return this.setItemValuesP({ qty:qty, weight:weight })
    }
    //--------------------------------------------------------------------------
    onSaveP(key:string, values:any) {
        let list                = this.list
        if (!list.some((item:any) => item.SELECT)) {
            // nothing selected (without being forced) => SELECT everything...
            list.forEach((item:any) => {
                item.HIDDEN     = false
                item.SELECT     = true
            })
        }
        let hold_result
        return this.busyServiceEmitP(this.onSave_task, { list:list })
        .then((res:any) => {
            if (!(res && res.packed_id)) {
                return REJECT(`invalid response from server: [${res}]`)
            }
            hold_result         = res
            return this.downloadP(
                { vrg_id        : res.packed_id
                },
                { name          : 'packing list'
                , task          : 'print'
                })
        })
        .then(() => {
            if (hold_result.todo_id) {
                return this.setItemValuesP({ selector:'', qty:1000 })
                .then(() => this.loadAndFocusP() )
            } else {
                return this.onBackP()
            }
        })
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
