//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, is
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemModal, ShpService            } from './service'
//------------------------------------------------------------------------------
@Component({selector:'DHLLabelModal', template:ItemDiv})
export class DHLLabelModal extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:ShpService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    prt_ques            :any    = {}
    itemDefn            :any    =
    { HIDDEN        : 'vrg_id'
    , tracking_num  : {rdo:'tracking'           , wide: 6}
    }
    //--------------------------------------------------------------------------
    onInitP() {
        this.ctx.NAME           = this.ctx.name
        this.defineItem         (this.itemDefn      )
        this.setHeader          ('=menu='           )
        this.buttonCancel       (this.onCancelP     )

        return this.busyServiceEmitP('lookup:my_prt_ques', {})
        .then((res) => {
            res.forEach((prt_que) => {
                this.prt_ques[prt_que.key]
                                = prt_que.value
                this.makeButton(this.onSubmitP, prt_que.key, prt_que.value)
            })
            return this.setItemValuesP(this.ctx)
        })
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
    onSubmitP(key:string, values:any) {
        if (!values)            { return RESOLVE() }
        if (!this.prt_ques[key]){ return REJECT('Unknown printer') }
        return this.busyServiceEmitP('printDHL', { ...values, prt_que_id:key})
        .then((res:any) => {
this.log('RES:', res)
            this.messageDone('label sent to printer')
            return this.modalRESOLVE(res)
        })
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
