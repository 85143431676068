//------------------------------------------------------------------------------
import  { Injectable                                } from '@angular/core'
//------------------------------------------------------------------------------
import * as lib from '../library'
//------------------------------------------------------------------------------
export const Imports            = lib.Imports
export const BaseModule         = lib.Base.Module

export const Item               = lib.Item
export const ItemComp           = lib.Item.Comp
export const ItemDiv            = lib.Item.Div
export const ItemModal          = lib.Item.Modal
export const ListComp           = lib.List.Comp
export const ListDiv            = lib.List.Div
export const ListModal          = lib.List.Modal
export const SelectDiv          = lib.Select.Div
export const SelectModal        = lib.Select.Modal
export const TabbedComp         = lib.Base.Tabbed
//------------------------------------------------------------------------------
@Injectable()
export class KntService extends lib.Base.Service {
    //--------------------------------------------------------------------------
    setHeader(values:any) {
        this.ctx.header         = (!values.id)      ? 'new...'
                                : (!values.number)  ? values.name
                                                    : `${values.number}: ${values.name}`
    }
    //--------------------------------------------------------------------------
    allTabs             :any    = 'dtl adr cmt rbg ent ptn vrg'.split(/\W+/)
    validTabs           :any    = this.allTabs
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
export const CusTemplate        = `
<div class='tabs container'>
  <div class='tabshead'>
    <h3 class='header floatl'>
      <button               class='btn back'
                            (click)='popClick()'
      >{{glob.backArrow}}</button>{{ctx.header}}</h3>
    <div class='u-cf'></div>
  </div>
  <div class='tabstabs'>
    <div                    (click)='stabSel("dtl")' class='{{stabCls("dtl")}}'>details         </div>
    <div *ngIf='stabs.adr'  (click)='stabSel("adr")' class='{{stabCls("adr")}}'>addresses       </div>
    <div *ngIf='stabs.cmt'  (click)='stabSel("cmt")' class='{{stabCls("cmt")}}'>comments        </div>
    <div *ngIf='stabs.rbg'  (click)='stabSel("rbg")' class='{{stabCls("rbg")}}'>discounts       </div>
    <div *ngIf='stabs.ent'  (click)='stabSel("ent")' class='{{stabCls("ent")}}'>discount groups </div>
    <div *ngIf='stabs.ptn'  (click)='stabSel("ptn")' class='{{stabCls("ptn")}}'>partner payment </div>
    <div *ngIf='stabs.vrg'  (click)='stabSel("vrg")' class='{{stabCls("vrg")}}'>documents       </div>
  </div>
  <div class='tabsbody' [ngSwitch]='stab'>
    <KntCusDtl    *ngSwitchDefault     ></KntCusDtl   >
    <KntCusAdrLst *ngSwitchCase='"adr"'></KntCusAdrLst>
    <KntCusCmtLst *ngSwitchCase='"cmt"'></KntCusCmtLst>
    <KntCusRbgLst *ngSwitchCase='"rbg"'></KntCusRbgLst>
    <KntCusEntLst *ngSwitchCase='"ent"'></KntCusEntLst>
    <KntCusPtn    *ngSwitchCase='"ptn"'></KntCusPtn   >
    <KntCusVrgLst *ngSwitchCase='"vrg"'></KntCusVrgLst>
  </div>
</div>
`
//------------------------------------------------------------------------------
export const EmpTemplate        = `
<div class='tabs container'>
  <div class='tabshead'>
    <h3 class='header floatl'>
      <button               class='btn back'
                            (click)='popClick()'
      >{{glob.backArrow}}</button>{{ctx.header}}</h3>
    <div class='u-cf'></div>
  </div>
  <div class='tabstabs'>
    <div                    (click)='stabSel("dtl")' class='{{stabCls("dtl")}}'>details         </div>
    <div *ngIf='stabs.adr'  (click)='stabSel("adr")' class='{{stabCls("adr")}}'>addresses       </div>
    <div *ngIf='stabs.cmt'  (click)='stabSel("cmt")' class='{{stabCls("cmt")}}'>comments        </div>
  </div>
  <div class='tabsbody' [ngSwitch]='stab'>
    <KntEmpDtl    *ngSwitchDefault     ></KntEmpDtl   >
    <KntEmpAdrLst *ngSwitchCase='"adr"'></KntEmpAdrLst>
    <KntEmpCmtLst *ngSwitchCase='"cmt"'></KntEmpCmtLst>
  </div>
</div>
`
//------------------------------------------------------------------------------
export const GrpTemplate        = `
<div class='tabs container'>
  <div class='tabshead'>
    <h3 class='header floatl'>
      <button               class='btn back'
                            (click)='popClick()'
      >{{glob.backArrow}}</button>{{ctx.header}}</h3>
    <div class='u-cf'></div>
  </div>
  <div class='tabstabs'>
    <div                    (click)='stabSel("dtl")' class='{{stabCls("dtl")}}'>details     </div>
    <div *ngIf='stabs.rbg'  (click)='stabSel("rbg")' class='{{stabCls("rbg")}}'>discounts   </div>
  </div>
  <div class='tabsbody' [ngSwitch]='stab'>
    <KntGrpDtl    *ngSwitchDefault     ></KntGrpDtl   >
    <KntGrpRbgLst *ngSwitchCase='"rbg"'></KntGrpRbgLst>
  </div>
</div>
`
//------------------------------------------------------------------------------
export const SupTemplate        = `
<div class='tabs container'>
  <div class='tabshead'>
    <h3 class='header floatl'>
      <button               class='btn back'
                            (click)='popClick()'
      >{{glob.backArrow}}</button>{{ctx.header}}</h3>
    <div class='u-cf'></div>
  </div>
  <div class='tabstabs'>
    <div                    (click)='stabSel("dtl")' class='{{stabCls("dtl")}}'>details         </div>
    <div *ngIf='stabs.adr'  (click)='stabSel("adr")' class='{{stabCls("adr")}}'>addresses       </div>
    <div *ngIf='stabs.cmt'  (click)='stabSel("cmt")' class='{{stabCls("cmt")}}'>comments        </div>
  </div>
  <div class='tabsbody' [ngSwitch]='stab'>
    <KntSupDtl    *ngSwitchDefault     ></KntSupDtl   >
    <KntSupAdrLst *ngSwitchCase='"adr"'></KntSupAdrLst>
    <KntSupCmtLst *ngSwitchCase='"cmt"'></KntSupCmtLst>
  </div>
</div>
`
//------------------------------------------------------------------------------
export const VndTemplate        = `
<div class='tabs container'>
  <div class='tabshead'>
    <h3 class='header floatl'>
      <button               class='btn back'
                            (click)='popClick()'
      >{{glob.backArrow}}</button>{{ctx.header}}</h3>
    <div class='u-cf'></div>
  </div>
  <div class='tabstabs'>
    <div                    (click)='stabSel("dtl")' class='{{stabCls("dtl")}}'>details         </div>
    <div *ngIf='stabs.adr'  (click)='stabSel("adr")' class='{{stabCls("adr")}}'>addresses       </div>
    <div *ngIf='stabs.cmt'  (click)='stabSel("cmt")' class='{{stabCls("cmt")}}'>comments        </div>
    <div *ngIf='stabs.rbg'  (click)='stabSel("rbg")' class='{{stabCls("rbg")}}'>discounts       </div>
  </div>
  <div class='tabsbody' [ngSwitch]='stab'>
    <KntVndDtl    *ngSwitchDefault     ></KntVndDtl   >
    <KntVndAdrLst *ngSwitchCase='"adr"'></KntVndAdrLst>
    <KntVndCmtLst *ngSwitchCase='"cmt"'></KntVndCmtLst>
    <KntVndRbgLst *ngSwitchCase='"rbg"'></KntVndRbgLst>
  </div>
</div>
`
//------------------------------------------------------------------------------
