//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, list_div, jQuery
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , CodService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'CodLst', template:ListDiv})
export class CodLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:CodService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { tab           : {lab:'table'              , wide: 2}
        , typ           : {lab:'type'               , wide: 2}
        , subtyp        : {lab:'subtype'            , wide: 2}
        , name          : {lab:'name'               , wide: 4}
        , descr         : {lab:'description'        , wide:10, typ:'textarea'}
        , data_as_text  : {lab:'data'               , wide:10}
        , txt           : {lab:'alpha'              , wide: 2}
        , num           : {lab:'numeric'            , wide: 1, typ:'integer'}
        , sort_no       : {lab:'sort'               , wide: 1, typ:'integer'}
        }
    dontLoadAll         :boolean= true
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.buttonNew          (this.onNewP        )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Cod', template:ItemDiv})
export class Cod extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:CodService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , tab           : {lab:'table'              , wide: 3               , copyCheck:true}
        , typ           : {opt:'type'               , wide: 3               , copyCheck:true}
        , subtyp        : {opt:'subtype'            , wide: 3               , copyCheck:true}
        , sort_no       : {opt:'sort'               , wide: 3, typ:'integer'}

        , name          : {opt:'name'               , wide:12}

        , descr         : {opt:'description'        , wide:12, typ:'textarea'}

        , data_as_text  : {opt:'data'               , wide:12, typ:'textarea'}

        , txt           : {opt:'alpha (deprecated)' , wide: 9}
        , num           : {opt:'numeric (deprecated)'
                                                    , wide: 3, typ:'integer'}
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
