//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { CodLngService                             } from './service'
import  { CodLngLst             , CodLng            } from './codLngLst'
//------------------------------------------------------------------------------
let c = [ CodLng                , CodLngLst
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ CodLngService ]
    }
)
export class CodLngModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
