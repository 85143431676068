//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , EvnQueService                             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'EvnQueLst', template:ListDiv})
export class EvnQueLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:EvnQueService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { valid_from    : {lab:'valid from'         , wide: 4, typ:'dates'}
        , vrg_num       : {lab:'document'           , wide: 5}
        , ext_number    : {lab:'reference'          , wide: 5}
        , wrk_name      : {lab:'workflow'           , wide: 6}
        , run_state     : {lab:'run state'          , wide: 6}
        , evn_name      : {lab:'event'              , wide: 6}
        , run_date      : {lab:'run at'             , wide: 6, typ:'datetime'}
        , npc           : {lab:'st.'                , wide: 2, cls:'center'}
        , txt           : {lab:'error'              , wide:20, cls:'lines'}
        , cur_state     : {lab:'current state'      , wide: 6}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.buttonSelAll       (this.onSelectAllP  )
        this.buttonInvSel       (this.onInvertSelP  )
        this.makeButton         (this.onEventP      , 'hold'  )
        this.makeButton         (this.onEventP      , 'cancel')
        this.makeButton         (this.onEventP      , 'retry' )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    defaultCriteria() {
        return { date0:this.getDateSince('week'), npc:'!cx' }
    }
    //--------------------------------------------------------------------------
    onEventP(key:string, values:any) {
        return this.getSelectedIdUpdsP()
        .then((items) => this.busyServiceEmitP(key, { items:items }) )
        .then((     ) => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'EvnQue', template:ItemDiv})
export class EvnQue extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:EvnQueService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , vrg_num       : {rdo:'document'           , wide:12}
        , ext_number    : {rdo:'reference'          , wide:12}
        , cur_state     : {rdo:'current state'      , wide:12}
        , run_date      : {rdo:'run date'           , wide:12, typ:'timestamp'}
        , run_state     : {rdo:'run state'          , wide:12}
        , evn_id        : {lab:'event'              , wide:12, cod:'evn'    , copyCheck:true}
        , valid_from    : {lab:'valid from'         , wide:12, typ:'timestamp'}
        , npc           : {lab:'status'             , wide:12}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        this.buttonCancel       (this.onCancelP     )
        if (this.ctx.id) {
            this.setHeader      ('edit'             )
            this.buttonCopy     (this.onCopyP       )
        } else {
            this.setHeader      ('add'              )
        }
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        return this.readP({ id:this.ctx.id })
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
