//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, promiseLoop
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { PstListDiv            , PstList
        , PstModalDiv           , PstModal
        , IdlService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'IdlQtyLst', template:PstListDiv})
export class IdlQtyLst extends PstList {
    //--------------------------------------------------------------------------
    constructor( public service:IdlService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    selectorDefaults    :any    = { selector: '', qty: 1000 }
    parseTask           :string = 'select_psts'
    parseBase           :any    = {}
    recalcPst                   = false
    price_reqd                  = false
    allow_zero_qtys             = true

    mergeKey                    = 'art_id'
    xrefKeys                    = 'ean_number,number,code,matchcode'.split(/\W+/g)
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { selector      : {opt:'article number or barcode'
                                                    , wide:10}
        , qty           : {opt:'quantity'           , wide: 2, typ:'quantity'}
        }
    listDefn            :any    =
        { shelves       : {lab:'shelves'            , wide: 4, typ:'textarea'}
        , number        : {lab:'number'             , wide: 5}
        , matchcode     : {lab:'matchcode'          , wide:10}
        , ean_number    : {lab:'ean number'         , wide: 3}
        , ext_number    : {lab:'external sku'       , wide: 3}
        , weight        : {lab:'weight'             , wide: 2, typ:'quantity'}
        , lnk_qty       : {lab:'ordered'            , wide: 1, typ:'quantity'}
        , qty           : {lab:'received'           , wide: 1, typ:'quantity'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'qty_load'
    onRead_task                 = 'qty_read'
    onSave_task                 = 'qty_save'
    //--------------------------------------------------------------------------
    onInitP() {
        this.ctx.canEdit        = true
        this.ctx.vrg_id         = this.ctx.id
        this.dft.vrg_id         = this.ctx.id
//         this.dft                = { vrg_id:this.ctx.vrg_id }
        this.defineItem         (this.itemDefn      )
        this.defineList         (this.listDefn, true)
        this.setHeader          (this.ctx.header    )
        this.buttonBack         (this.onBackP       )
        this.buttonSelAll       (this.onSelectAllP  )
        this.buttonInvSel       (this.onInvertSelP  )
        this.makeButton         (this.onLabelsP     , 'LabOrd', 'print labels (ordered)'  )
        this.makeButton         (this.onLabelsP     , 'LabRcv', 'print labels (received)' )
        this.makeButton         (this.onSetSelP     , 'set selected'    )
        this.makeButton         (this.onResetSelP   , 'reset selected'  )
        this.buttonSave         (this.onSaveP       )
        this.formSubmit         (this.onSubmitP     )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateListP )

        return this.readP()
        .then(() => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
    readResult(res:any) {
        this.cache.vrg          = res
        return this.selectorDefaults
    }
    //--------------------------------------------------------------------------
    onLabelsP(key:string, values:any) {
        return this.getSelectedItemsP()
        .then((items:any) => {
            items               = items.map((item) => (
                { number        : item.number
                , matchcode     : item.matchcode
                , code          : item.ean_number   || item.code
                , qty           : key == 'LabOrd'
                                    ? item.lnk_qty  / 1000
                                    : item.qty      / 1000
                }
            ))
            items               = items.filter((item) => item.qty > 0)
            if (items.length < 1) {
                return REJECT('nothing to print')
            }
            let ctx             =
                { name          : 'sku labels'
                , items         : items
                , qtyIsSet      : true
                }
            return this.callComponent('PrtLabModal', ctx)
            .then((res:any) => this.onLabelsDone() )
        })
    }
    onLabelsDone() {
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    onSetSelP(key:string, values:any) {
        return promiseLoop(this.list, [], (item:any, idx:number) => {
            if (!item.SELECT) {
                return RESOLVE()
            }
// this.log('onSetSelP')
            return this.applyValuesP(item, { qty:item.lnk_qty })
            .then((values:any) => {
                this.setItemColour(item, idx)
                return RESOLVE()
            })
        })
        .then(() => {
            this.messageClear()
            return RESOLVE()
        })
    }
    //--------------------------------------------------------------------------
    onResetSelP(key:string, values:any) {
        return promiseLoop(this.list, [], (item:any, idx:number) => {
            if (!item.SELECT) {
                return RESOLVE()
            }
// this.log('onResetSelP')
            return this.applyValuesP(item, { qty:0 })
            .then((values:any) => {
                this.setItemColour(item, idx)
                return RESOLVE()
            })
        })
        .then(() => {
            this.messageClear()
            return RESOLVE()
        })
    }
    //--------------------------------------------------------------------------
    setTotalsP() {
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    setItemColour(item:any, idx:number) {
// this.log('setColour:', idx)
        let colour              = (item.qty < item.lnk_qty) ? 'orange'
                                : (item.qty > item.lnk_qty) ? 'red'
                                                            : 'green'
        this.set_cell_bg(idx, 'qty', this.colours[colour])
    }
    //--------------------------------------------------------------------------
    onSaveP(key:string, values:any) {
        return this.busyServiceEmitP(this.onSave_task,
            { vrg               : this.cache.vrg
            , psts              : this.list
            , allow_zero_qtys   : this.allow_zero_qtys
            })
        .then(() => this.readP() )
        .then(() => this.loadP() )
        .then(() => RESOLVE(this.messageSaved()) )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'IdlQty', template:PstModalDiv})
export class IdlQty extends PstModal {
    //--------------------------------------------------------------------------
    constructor( public service:IdlService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['idx','art_id']
        , shelves       : {rdo:'shelves'            , wide:12}
        , number        : {rdo:'number'             , wide:12}
        , matchcode     : {rdo:'matchcode'          , wide:12}
        , lnk_qty       : {rdo:'ordered quantity'   , wide:12, typ:'quantity'}
        , qty           : {opt:'received quantity'  , wide:12, typ:'quantity', chg:this.on_qty}
        , shelf         : {opt:'shelf'              , wide: 9                , rtn:this.onKeyP}
        , shelf_btn     : {lab:'add/remove'         , wide: 3                , btn:this.onKeyP}
        , ean_number    : {opt:'ean number'         , wide: 9                , rtn:this.onKeyP}
        , ean_number_btn: {lab:'update'             , wide: 3                , btn:this.onKeyP}
//         , matchcode     : {opt:'matchcode'          , wide: 9                , rtn:this.onKeyP}
//         , matchcode_btn : {lab:'update'             , wide: 3                , btn:this.onKeyP}
        , ext_number    : {opt:'external sku'       , wide: 9                , rtn:this.onKeyP}
        , ext_number_btn: {lab:'update'             , wide: 3                , btn:this.onKeyP}
        , weight        : {opt:'weight'             , wide: 9, typ:'quantity', rtn:this.onKeyP}
        , weight_btn    : {lab:'update'             , wide: 3                , btn:this.onKeyP}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        this.setHeader          ('edit'             )
        this.buttonPrev         (this.onPrevP       )
        this.buttonNext         (this.onNextP       )
        this.buttonCancel       (this.onCancelP     )
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        this.setSource(this.cache[this.name + 'Lst'])
        return this.setItemFromSourceP(this.ctx.idx)
        .then(() => this.setFocusP('qty') )
    }
    //--------------------------------------------------------------------------
    setExtraValues(values:any) {
        values.shelf            = ''
    }
    //--------------------------------------------------------------------------
    on_qty(key:string, value:any) {
        this.item.qty           = value
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
