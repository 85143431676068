//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { PstListDiv            , PstList
        , PstModalDiv           , PstModal
        , StkService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'StkStkLst', template:PstListDiv})
export class StkStkLst extends PstList {
    //--------------------------------------------------------------------------
    constructor( public service:StkService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    selectorDefaults    :any    = { selector: '' }
    parseTask           :string = 'select_arts'
    parseBase           :any    = { price_reqd      : false
                                  , knt_id          : 0
                                  , cur_id          : 0
                                  , exchrate        : 0
                                  , wsale_sw        : true
                                  }
    recalcPst                   = false
    price_reqd                  = false
    allow_zero_qtys             = true

    mergeKey                    = 'art_id'
    xrefKeys                    = 'ean_number,number,code,matchcode'.split(/\W+/g)
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { selector      : {opt:'article number or barcode'
                                                    , wide: 12}
        }
    listDefn            :any    =
        { shelves       : {lab:'shelves'            , wide: 5, typ:'textarea'}
        , number        : {lab:'number'             , wide: 5}
        , matchcode     : {lab:'matchcode'          , wide:12}
        , code          : {lab:'code'               , wide: 3}
        , ean_number    : {lab:'ean number'         , wide: 3}
        , ext_number    : {lab:'external sku'       , wide: 3}
        , weight        : {lab:'weight'             , wide: 3, typ:'quantity'}
        , count_date    : {lab:'last counted'       , wide: 3, typ:'date'}
        , onhand        : {lab:'onhand'             , wide: 1, typ:'quantity'}
        , available     : {lab:'available'          , wide: 1, typ:'quantity'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'EMPTY'           // load an empty list
    onRead_task                 = 'DEFAULTS'        // read selectorDefaults
    onSave_task                 = 'NONE'            // no save button
    //--------------------------------------------------------------------------
    onInitP() {
        this.dft.rsr_id         = this.ctx.rsr_id

        this.ctx.canEdit        = true

        this.defineItem         (this.itemDefn      )
        this.defineList         (this.listDefn, true)
        this.setHeader          ('=menu='           )
        this.buttonSelAll       (this.onSelectAllP  )
        this.buttonInvSel       (this.onInvertSelP  )
        this.buttonRemSel       (this.onDeleteP     )
        this.makeButton         (this.onMultiShelfP , 'load multi-shelved')
        this.makeButton         (this.onNoShelfP    , 'load non-shelved')
        this.formSubmit         (this.onSubmitP     )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateListP )

        return this.readP()
        .then(() => this.loadSpecialP() )
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
    readDoneP() {
        this.parseBase          = { price_reqd      : this.price_reqd
                                  , knt_id          : 0
                                  , cur_id          : 0
                                  , exchrate        : 0
                                  , wsale_sw        : true
                                  }
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    loadAndFocusP(criteria:any=null) {
this.log('loadAndFocus:', criteria)
        if (this.list.length < 1)
                                { return RESOLVE() }
        this.cancelFormatting_  = false
        let art_ids             = this.list.map((item) => item.id)
        this.messageFetch()
        return this.serviceEmitP('load_art_ids', { art_ids:art_ids })
        .then((list:any) => this.setListP(this.loadResult(list) || []) )
        .then((        ) => this.loadDoneP() )
    }
    //--------------------------------------------------------------------------
    onMultiShelfP(key:string, values:any) {
        this.cancelFormatting_  = false
        this.messageFetch()
        return this.serviceEmitP('load_multi_shelved', { TIMEOUT:600 })
        .then((list:any) => this.setListP(this.loadResult(list) || []) )
        .then((        ) => this.loadDoneP() )
    }
    //--------------------------------------------------------------------------
    onNoShelfP(key:string, values:any) {
        this.cancelFormatting_  = false
        this.messageFetch()
        return this.serviceEmitP('load_non_shelved', { TIMEOUT:600 })
        .then((list:any) => this.setListP(this.loadResult(list) || []) )
        .then((        ) => this.loadDoneP() )
    }
    //--------------------------------------------------------------------------
    onParseDoneP(res:any) {
        this.list.forEach((item:any) => {
            item.HIDDEN         = false
            item.SELECT         = false
        })
        this.list.sort( (a:any, b:any) =>
            a.shelves > b.shelves ? +1 :
            a.shelves < b.shelves ? -1 : 0
        )
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    unsavedData() {
        return false
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'StkStk', template:PstModalDiv})
export class StkStk extends PstModal {
    //--------------------------------------------------------------------------
    constructor( public service:StkService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['idx','art_id']
        , shelves       : {rdo:'shelves'            , wide:12}
        , number        : {rdo:'number'             , wide: 9}
        , onhand        : {rdo:'current onhand'     , wide: 3, typ:'quantity'}
        , shelf         : {opt:'shelf'              , wide: 9                , rtn:this.onKeyP}
        , shelf_btn     : {lab:'add/remove'         , wide: 3                , btn:this.onKeyP}
        , stocktake     : {opt:'stock count'        , wide: 9, typ:'quantity', rtn:this.onKeyP}
        , stocktake_btn : {lab:'commit'             , wide: 3                , btn:this.onKeyP}
        , weight        : {opt:'weight'             , wide: 9, typ:'quantity', rtn:this.onKeyP}
        , weight_btn    : {lab:'update'             , wide: 3                , btn:this.onKeyP}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        this.setHeader          ('details'          )
        this.buttonPrev         (this.onPrevP       )
        this.buttonNext         (this.onNextP       )
        this.buttonCancel       (this.onCancelP     )
        this.buttonOk           (this.onCancelP     )

        this.setSource(this.cache[this.name + 'Lst'])
        return this.setItemFromSourceP(this.ctx.idx)
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
    setExtraValues(values:any) {
        values.shelf            = ''
    }
    //--------------------------------------------------------------------------
    onCancelP() {
this.log('onCancelP')
        return this.onSubmitDoneP(this.item)
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
