//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemComp, PckService             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'PckDtl', template:ItemDiv})
export class PckDtl extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:PckService ) { super() }
    comp_type                   = 'maintab'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { knt_name      : {rdo:'account name'       , wide: 9}
        , knt_num       : {rdo:'account number'     , wide: 3}

        , date          : {rdo:'date'               , wide: 3, typ:'datetime'}
        , ext_number    : {rdo:'reference'          , wide: 3}
        , descr         : {rdo:'description'        , wide: 6}

        , pay_name      : {rdo:'payment type'       , wide: 4}
        , monthly       : {rdo:'monthly'            , wide: 2, typ:'boolean'}
        , due_days      : {rdo:'due days'           , wide: 2, typ:'integer'}
        , dsc_days      : {rdo:'disc. days'         , wide: 2, typ:'integer'}
        , dsc_pct       : {rdo:'disc. %'            , wide: 2, typ:'decimal'}

        , netto         : {rdo:'netto'              , wide: 2, typ:'decimal'}
        , brutto        : {rdo:'brutto'             , wide: 2, typ:'decimal'}
        , total         : {rdo:'total'              , wide: 2, typ:'decimal'}
        , cur_typ       : {rdo:'currency'           , wide: 2}
        , acc_netto     : {rdo:'netto (€)'          , wide: 2, typ:'decimal'}
        , acc_brutto    : {rdo:'brutto (€)'         , wide: 2, typ:'decimal'}

        , wsale_sw      : {rdo:'wholesale'          , wide: 2, typ:'boolean'}
        , invoiced      : {rdo:'invoiced'           , wide: 2, typ:'boolean'}
//         , totalled      : {rdo:'totalled'           , wide: 2, typ:'boolean'}
        , booked        : {rdo:'booked'             , wide: 2, typ:'boolean'}
        , dont_email    : {rdo:'dont email'         , wide: 2, typ:'boolean'}

        , chn_name      : {rdo:'channel'            , wide: 3               , newline:true}
        , vnd_name      : {rdo:'owner'              , wide: 3}
        , wrk_name      : {rdo:'workflow'           , wide: 3}
        , stt_name      : {rdo:'status'             , wide: 3}

        , typ_name      : {rdo:'type'               , wide: 3}
        , number        : {rdo:'number'             , wide: 3}
        , code          : {rdo:'code'               , wide: 3, typ:'integer'}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )

        return this.readP()
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
    receivedItem(item:any) {
        Object.assign(this.item, item)
        if (this.item.id) {
            this.setCache(this.item)
            this.ctx.vrg_id     =
            this.dft.vrg_id     = this.item.id
            this.service.showValidTabs()
            this.service.setHeader(this.item)
        }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
