//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, is
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , OlsService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'OlsCatLst', template:ListDiv})
export class OlsCatLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:OlsService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { cat_path      : {lab:'category (path)'    , wide:20}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'cat_load'
    onRead_task                 = 'cat_read'
    onSave_task                 = 'cat_save'
    onDelete_task               = 'cat_dele'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.buttonSelAll       (this.onSelectAllP  )
        this.buttonInvSel       (this.onInvertSelP  )
        this.buttonRemSel       (this.onRemoveP     )
        this.buttonAdd          (this.onAddP        )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    onAddP() {
        let ctx                 =
            { selector          : ''
            , foundItems        : []
            , multi             : true
            }
        return this.callComponent('SelectCat', ctx)
        .then((res:any) => {
            if (!res)           { return RESOLVE() }
            if (is.object(res)) { res = [ res ] }
            return this.busyServiceEmitP('cat_add_links',
                { ols_id        : this.ctx.ols_id
                , cat_ids       : this.extractKeys(res)
                })
            .then(() => this.loadAndFocusP() )
        })
    }
    //--------------------------------------------------------------------------
    onRemoveP(key:string, values:any) {
        return this.getSelectedIdUpdsP({ confirm:'removal' })
        .then((items) => this.busyServiceEmitP('cat_remove_links', { items:items }) )
        .then((     ) => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
