//------------------------------------------------------------------------------
import  { Directive, ViewContainerRef               } from '@angular/core'
//------------------------------------------------------------------------------
import * as lib from '../../library'
//------------------------------------------------------------------------------
export const Imports            = lib.Imports
export const BaseModule         = lib.Base.Module

export const BaseComp           = lib.Base.Comp
//------------------------------------------------------------------------------
@Directive({selector:'[AlertElement]'})
export class AlertElem {
    //--------------------------------------------------------------------------
    constructor( public viewContainerRef:ViewContainerRef ) { }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
export const AlertDiv           = '<ng-template AlertElement></ng-template>'
//------------------------------------------------------------------------------
