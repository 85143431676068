//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, is
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , PrdService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'PrdChdLst', template:ListDiv})
export class PrdChdLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:PrdService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { number        : {lab:'number'             , wide: 5}
        , matchcode     : {lab:'matchcode'          , wide:10}
        , sort_no       : {lab:'sort'               , wide: 2, typ:'integer'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'chd_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn, true)

        this.buttonSelAll       (this.onSelectAllP  )
        this.buttonInvSel       (this.onInvertSelP  )
        this.buttonRemSel       (this.onRemoveP     )
        this.buttonAdd          (this.onAddP        )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    onAddP() {
        let ctx                 =
            { selector          : ''
            , typ               : 'art'
            , foundItems        : []
            , multi             : true
            }
        return this.callComponent('SelectPrd', ctx)
        .then((res:any) => {
            if (!res)           { return RESOLVE() }
            if (is.object(res)) { res = [ res ] }
            return this.busyServiceEmitP('chd_add',
                { prd_id_fam    : this.ctx.prd_id
                , prd_ids       : res.map((prd) => prd.id)
                })
            .then(() => this.loadAndFocusP() )
        })
    }
    //--------------------------------------------------------------------------
    onRemoveP(key:string, values:any) {
        return this.getSelectedIdUpdsP({ confirm:'removal' })
        .then((items) => this.busyServiceEmitP('chd_remove', { items:items }) )
        .then((     ) => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'PrdChd', template:ItemDiv})
export class PrdChd extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:PrdService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , number        : {rdo:'number'             , wide:  6}
        , matchcode     : {rdo:'matchcode'          , wide: 12, newline:true}
        , sort_no       : {opt:'sort'               , wide:  3, typ:'integer'}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'chd_read'
    onSave_task                 = 'chd_save'
    onDelete_task               = 'chd_dele'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
