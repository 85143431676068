//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { UtlService                                } from './service'
import  { Utl                                       } from './utl'
import  { UtlModal                                  } from './utlModal'
//------------------------------------------------------------------------------
let c = [ Utl
        , UtlModal
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ UtlService ]
    }
)
export class UtlModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
