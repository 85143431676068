//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { MixedDiv, MixedComp, OlsService           } from './service'
//------------------------------------------------------------------------------
@Component({selector:'OlsDtl', template:MixedDiv})
export class OlsDtl extends MixedComp {
    //--------------------------------------------------------------------------
    constructor( public service:OlsService ) { super() }
    comp_type                   = 'maintab'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , name          : {lab:'shop name'          , wide: 6}
        , shop          : {rdo:'shop key'           , wide: 6}

        , descr         : {opt:'description'        , wide:12}

        , chn_grouping  : {lab:'channel grouping'   , wide: 6, cod:'chn_grp'}
        , ord_prefix    : {opt:'order prefix'       , wide: 6}

        , url           : {lab:'url'                , wide: 6}
        , img_typ_id    : {lab:'product image type' , wide: 6, cod:'img_tag'}

        , is_online     : {lab:'is online'          , wide: 6, typ:'boolean'}
        }
    listDefn            :any    =
        { name          : {lab:'shop windows'       , wide: 5}
        , icon_src      : {lab:'icon'               , wide: 5, typ:'image'}
        , cat_name      : {lab:'top category'       , wide: 5}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load_wnd'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        this.formSubmit         (this.onSubmitP     )

        this.defineList         (this.listDefn, true)
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        this.buttonNew          (this.onNewP        , 'new window')
        this.buttonOk           (this.onSubmitP     )

        this.ctx.ols_id         =
        this.dft.ols_id         = this.ctx.ols_id || this.dft.ols_id
        this.ctx.shop           =
        this.dft.shop           = this.ctx.shop   || this.dft.shop
        return this.readP(
            { ols_id            : this.ctx.ols_id
            , shop              : this.ctx.shop
            })
        .then(() => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
    receivedItem(item:any) {
        Object.assign(this.item, item)
        if (this.item.id) {
            this.setCache(this.item)
            this.ctx.ols_id     =
            this.dft.ols_id     = this.item.id
            this.service.showValidTabs()
            this.service.setHeader(this.item)
        }
    }
    //--------------------------------------------------------------------------
    onNewCompName               = 'Wnd'
//     onNewP() {
//         let ctx                 = this.onNewContext()
//          return this.callComponent('Wnd', ctx)
//         .then((res:any) => this.onNewDoneP(res) )
//     }
    onNewContext() {
        return  { id            : 0
                , ols_id        : this.item.id
                , header        : `${this.item.name}: new...`
                }
    }
    //--------------------------------------------------------------------------
    onUpdateCompName            = 'Wnd'
//     onUpdateP(idx:number) {
//         let ctx                 = this.onUpdateContext(idx)
//         return this.callComponent('Wnd', ctx)
//         .then((res:any) => this.onUpdateDoneP(idx, res) )
//     }
    onUpdateContext(idx:number) {
        let ctx                 = this.clone(this.list[idx])
        ctx.header              = `${this.item.name}: ${ctx.name}`
        return ctx
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
