//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , PrdService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'PrdTxtLst', template:ListDiv})
export class PrdTxtLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:PrdService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { chn_name      : {lab:'channel'            , wide: 2}
        , lng_name      : {lab:'language'           , wide: 1}
        , typ_name      : {lab:'type'               , wide: 2}
        , txt           : {lab:'localised'          , wide: 15, typ:'textarea'}
        , is_ai         : {lab:'AI'                 , wide: 1, typ:'flag'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'txt_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    onUpdateContext(idx:number) {
        return this.list[idx]
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'PrdTxt', template:ItemDiv})
export class PrdTxt extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:PrdService ) { super() }
    modal_size                  = 'large'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date','chn_id','lng_id','typ_id']
        , chn_name      : {rdo:'channel'            , wide: 4}
        , lng_name      : {rdo:'language'           , wide: 4}
        , typ_name      : {rdo:'type'               , wide: 4}

        , txt_dft       : {rdo:'default'            , wide:12, typ:'textarea', rows:10, newline:true}
        , txt           : {opt:'localised'          , wide:12, typ:'textarea', rows:10, newline:true}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = ''
    onSave_task                 = 'txt_save'
    onDelete_task               = ''
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        this.buttonCancel       (this.onCancelP     )
        this.makeButton         (this.onLoadAiP     , 'fetch AI translation')
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        Object.assign(this.item, this.ctx)
        return this.setFocusP()
    }
    //--------------------------------------------------------------------------
    async onLoadAiP(key:string, value:any) {
        // get the AI txt...
        this.messageFetch()
        return this.serviceEmitP('txt_fetch_ai_trans', this.item)
        .then((res:any) => {
            this.messageClear()
            if (confirm('Click "OK" to replace localised text with the following:\n\n' + res.txt)) {
                this.item.txt   = res.txt
                return RESOLVE()
            }
        })
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
