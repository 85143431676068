//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, is
//------------------------------------------------------------------------------
import  { Base                                      } from './base'
import  { ItemDiv                                   } from './templates/divs'
import  { Type, columnClass, makeList               } from './fields'
//------------------------------------------------------------------------------
export class ItemComp extends Base.Comp {
    //--------------------------------------------------------------------------
    buttonRow                   = 'b'
    //-------------------------------------------------------------------------
    implementsItem      :boolean= true
    //--------------------------------------------------------------------------
    // HIDE :string[]           = [ <hidden field>, ... ]
    // RDO  :boolean            = all fields are readonly
    // <key>: { lab     :string = <screen label> (also see 'opt' & 'rdo')
    //        , wide    :number = width in columns (1-12)
    //        , btn     :func   = field is a button (implies typ=='btn')
    //        , cod     :string = key for dropdown values (implies typ=='cod')
    //        , typ     :string = skip -> ignore this field
    //                          || text / date / quantity / etc...
    //        , cls     :string = field class
    //        , opt     :string / :boolean
    //                          = optional (string -> label)
    //        , rdo     :string / boolean
    //                          = readonly (string -> label)
    //        , off     :boolean= isDisabled
    //        , rows    :number = number of rows in a textarea (default: 4)
    //        , chg     :func   = on change function (actually, on blur)
    //        , rtn     :func   = on return function
    //        , newline :boolean= <starts a new line>
    //        , section :boolean= <starts a new section>
    //        }
    defineItem(defn:any={}) {
        if (this.iDef.KEYS && this.iDef.KEYS.length) {
            this.log('defineItem called multiple times')
            return
        }
        defn                    = this.clone(defn)
        let iDef                = this.iDef
        if (this.ctx.canForceEdit === true) {
            iDef.forceEdit      = this.forceEdit.bind(this)
        }
        if (this.showDev) {
            iDef.onDump         = this.onDump.bind(this)
        }
        let allFields           = this.allFields = {}
        let KEYS                = Object.keys(defn).filter((key) => !/^[A-Z]+$/.test(key))
        iDef.HIDE               = makeList(defn.HIDE)
        iDef.KEYS               = [ ...iDef.HIDE, ...KEYS ]
        iDef.RDO                = (defn.RDO === true)
        iDef.ROWS               = []
//         delete defn.HIDE
//         delete defn.RDO
        if (KEYS.length < 1)    { return }
        let row_keys            = []
        let row_width           = 0
        let cck                 = this.copyCheckKeys
                                = []
        KEYS.forEach((key:string) => {
            let fld             = iDef[key]
                                = allFields[key]
                                = defn[key]
            fld.name            = key
            if (!fld.lab) {
                if      (is.string(fld.opt)) { [fld.lab, fld.opt] = [fld.opt, true] }
                else if (is.string(fld.rdo)) { [fld.lab, fld.rdo] = [fld.rdo, true] }
                else                         {  fld.lab           =  ''             }
            }
            // width is 1-12 columns (default=12) vis skeleton.css
            fld.wide            = parseInt(fld.wide) || 12
            if (fld.wide < 0 || fld.wide > 12) {
                fld.wide        = 12
            }
            row_width          += fld.wide
            if (row_width > 12 || fld.newline || fld.section) {
                iDef.ROWS.push(row_keys)
                row_keys        = []
                row_width       = fld.wide
                if (fld.section) {
                    iDef.ROWS.push(['<HR>'])
                }
            }
            row_keys.push(key)
            fld.opt             = Boolean(fld.opt)
            fld.off             = Boolean(fld.off)
            fld.rdo             = Boolean(fld.rdo  || iDef.RDO)
            fld.rows            = Number (fld.rows || 4)
            if (fld.btn && fld.rdo) {
                fld.typ         = 'skip'
            }
            if (fld.typ == 'skip') {
                fld.cod         =
                fld.btn         = undefined
            } else {
                fld.typ         = fld.cod   ? 'cod'
                                : fld.btn   ? 'btn'
                                : fld.typ   ? fld.typ
                                            : 'text'
            }
            fld.onBLUR          = this.onACTION(fld.chg, 'BLUR'  )
            fld.onBUTTON        = this.onACTION(fld.btn, 'BUTTON')
            fld.onRETURN        = this.onACTION(fld.rtn, 'RETURN')
            let typ             = Type[fld.typ] || Type['text']
            fld.format          = typ.format
            // div class...
            let cls             = [ columnClass(fld.wide) ]
            fld.divCls          = cls.join(' ')
            // field class...
            cls                 = []
            if (fld.copyCheck)  { cck.push(key    ) }
            if (fld.isKey    )  { cls.push('isKey') }
//             if (typ.cls)        { cls.push(typ.cls) }
            if (fld.cls)        { cls.push(fld.cls) }
            fld.cls             = cls.join(' ')
        })
        if (row_keys.length > 0) {
            iDef.ROWS.push(row_keys)
        }
    }
    //--------------------------------------------------------------------------
    forceEdit() {
this.log('***** forceEdit *****')
        this.ctx.canEdit        = true
        Object.keys(this.itemDefn).forEach((key) => {
            let fld             = this.itemDefn[key]
            // all "fields" that are not initally defined as read-only...
            if (is.object(fld) && !fld.rdo) {
                this.enableField(key)
            }
        })
        if (is.empty(this.allButtons['ok'])) {
            this.buttonOk(this.onSubmitP)
            this.formSubmit (this.onSubmitP)
        }
        this.showButton  ('ALL')
//         this.enableButton('ALL')
        this.messageError('WARNING: You can now seriously break stuff. Be VERY careful!')
        // stop event propogating...
        return false
    }
    //--------------------------------------------------------------------------
    onACTION(func:any, name:string) {
        if (is.string(func)) {
            func                = this[func]
        }
        if (!is.function(func)) {
//             this.log(`unable to find function for action ${name}`)
            return null
        }
        return (key:string, value:any) => {
            return func.call(this, key, value)
            .catch((err) => this.messageError(err) )
//             return false
        }
    }
    //--------------------------------------------------------------------------
    formSubmit(func:any) {
        this.iDef.onSUBMIT      = (key:string, form:any) => {
this.log('[iDef.onSUBMIT]', form)
            if (!form.valid) {
                this.messageError('invalid/incomplete data')
                return false
            }
            return func.call(this, key, form.value)
            .catch((err) => this.messageError(err) )
        }
    }
    //--------------------------------------------------------------------------
    readP(criteria:any={}) {
        criteria                = criteria || {}
        this.messageFetch()
        return this.serviceEmitP(this.onRead_task, criteria)
        .then((res:any) => {
            this.messageClear()
            res                 = this.readResult(res)
// this.log('readP res:', res)
            if (res) {
                this.receivedItem(res)
                return this.readDoneP()
            } else {
                return RESOLVE()
            }
        })
// .then ((res) => { this.log('readP result:', res); return RESOLVE(res) })
// .catch((err) => { this.log('readP error :', err); return REJECT (err) })
    }
    readResult(res:any) {
        return res
    }
    readDoneP() {
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    receivedItem(item:any) {
        Object.assign(this.item, item)
        if (this.comp_type == 'maintab' && item && item.id) {
            this.setCache(this.item)
            this.ctx.id         =
            this.dft.id         = item.id
            this.service.showValidTabs()
            this.service.setHeader(this.item)
            this.showButton('copy'  )
            this.showButton('delete')
        }
    }
    //--------------------------------------------------------------------------
    setCache(data:any) {
        this.cache.main         = data
    }
    //--------------------------------------------------------------------------
    setItemValuesP(values:any, force:boolean=false) {
        return this.applyValuesP(this.item, values, force)
    }
    //--------------------------------------------------------------------------
    onCopyP() {
        return this.setItemValuesP({ id: 0 })
        .then(() => {
            this.service.setHeader('new...')
            this.hideButton('copy'  )
            this.hideButton('delete')
            return this.setFocusP()
        })
    }
    //--------------------------------------------------------------------------
    onDeleteP() {
        return this.onActionConfirmP('deletion', 1)
        .then((       ) => this.busyServiceEmitP(this.onDelete_task, this.item) )
        .then((res:any) => this.onDeleteDoneP(res) )
        .then((       ) => this.setFocusP() )
    }
    onDeleteDoneP(res:any) {
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    onSubmitP(key:string, values:any) {
this.log('(ItemComp) onSubmitP', [ key, values ])
        if (!values)            { return RESOLVE() }
        return this.onSubmitCopyCheckP(key, values)
        .then(() => this.busyServiceEmitP(this.onSave_task, values) )
        .then((res:any) => {
            if (res) {
                this.receivedItem(res)
                return this.onSubmitDoneP(res)
            } else {
                return RESOLVE()
            }
        })
        .then(() => this.setFocusP() )
    }
    onSubmitCopyCheckP(key:string, values:any) {
        let keys                = this.copyCheckKeys
        if (!is.array(keys) || is.empty(keys))
                                { return RESOLVE() }
        let item                = this.item
        if (!item.id)           { return RESOLVE() }
        if (keys.every((key:string) => item[key] == values[key]))
                                { return RESOLVE() }
        if (confirm('You have altered key value(s) of this record.\n\nClick "ok" to update the current record or "cancel" to return to the edit screen.')) {
            return RESOLVE()
        } else {
            return REJECT('update cancelled - click "copy" and then "ok" to create a new record')
        }
    }
    onSubmitDoneP(res:any) {
        this.messageSaved()
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    onKeyP(key:string, value:any) {
// this.log('onKeyP:', [key, value])
        if (/_btn$/.test(key)) {
            key                 = key.replace(/_btn$/,'')
            value               = value[key]
        }
        if (is.string(value)) {
            value               = value.trim()
            if (!value)         { return RESOLVE() }
        }
        return this.busyServiceEmitP('onKey',
            { item              : this.item
            , key               : key
            , value             : value
            })
        .then((res:any) => this.setItemValuesP(res) )
        .then((res:any) => this.onKeyDoneP(key, res) )
    }
    onKeyDoneP(key:string, res:any) {
        return this.setFocusP(key)
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
export class ItemModal extends ItemComp {
    //--------------------------------------------------------------------------
    comp_type                   = 'modal'
    //--------------------------------------------------------------------------
    onInitP() {
        if (this.ctx.id > 0) {
            this.defineItem     (this.itemDefn     )
            this.setHeader      ('edit'            )
            this.buttonCancel   (this.onCancelP    )
            if (this.onDelete_task) {
                this.buttonCopy  (this.onCopyP      )
                this.buttonDelete(this.onDeleteP    )
            }
            this.buttonOk       (this.onSubmitP    )
            this.formSubmit     (this.onSubmitP    )
        } else {
            this.defineItem     (this.itemDefn     )
            this.setHeader      ('add'             )
            this.buttonCancel   (this.onCancelP    )
            this.buttonOk       (this.onSubmitP    )
            this.formSubmit     (this.onSubmitP    )
        }
        return this.readP({ id:this.ctx.id })
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
    onDeleteDoneP(res:any) {
// this.log('onDeleteDoneP:', res)
        return this.modalRESOLVE(res)
    }
    //--------------------------------------------------------------------------
    onSubmitDoneP(res:any) {
// this.log('onSubmitDoneP:', res)
        return this.modalRESOLVE(res)
    }
    //--------------------------------------------------------------------------
    onCancelP() {
this.log('onCancelP')
        return this.modalREJECT()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
export const Item               =
{ Comp                          : ItemComp
, Modal                         : ItemModal
, Div                           : ItemDiv
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
