//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, is
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , BrnService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'BrnLst', template:ListDiv})
export class BrnLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:BrnService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { name          : {lab:'name'               , wide: 6}
        , brn_grp_name  : {lab:'group [brn_grp]'    , wide: 4}
        , has_ean       : {lab:'has ean'            , wide: 1, typ:'boolean'}
        , price_uplift  : {lab:'royalty uplift (%)' , wide: 2, typ:'percent'}
        , watermark     : {lab:'watermark'          , wide: 6, typ:'image'  }
        , sort_no       : {lab:'sort'               , wide: 1, typ:'integer'}
        }
//     dontLoadAll         :boolean= true
    //--------------------------------------------------------------------------
    onInitP() {
        this.buttonSelAll   (this.onSelectAllP  )
        this.buttonInvSel   (this.onInvertSelP  )
        this.makeButton     (this.onSetGroup, 'set group')
        return super.onInitP()
    }
    //--------------------------------------------------------------------------
    async onSetGroup() {
        let brn_ids             = await this.getSelectedIdsP()
        let ctx                 =
            { tab               : 'brn_grp'
            , selector          : ''
            , foundItems        : []
            , multi             : false
            }
        let res                 = await this.callComponent('SelectCod', ctx)

        if (is.empty(res))      { return null }
        if (is.array(res))      { res = res[0] }

        return this.busyServiceEmitP('set_brn_grp',
            { brn_ids           : brn_ids
            , brn_grp_id        : res.id
            }
        )
        .then(() => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Brn', template:ItemDiv})
export class Brn extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:BrnService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , name          : {lab:'name'               , wide: 6               , copyCheck:true}
        , brn_grp_id    : {lab:'brand  group'       , wide: 4, cod:'brn_grp'}
        , sort_no       : {opt:'sort'               , wide: 2, typ:'integer'}

        , has_ean       : {opt:'has ean'            , wide: 3, typ:'boolean', newline:true}
        , price_uplift  : {opt:'price uplift (%)'   , wide: 3, typ:'percent'}

        , watermark     : {opt:'watermark'          , wide:12, typ:'image'  , newline:true}
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
