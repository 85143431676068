//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , TxtWwwService                             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'TxtWwwLst', template:ListDiv})
export class TxtWwwLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:TxtWwwService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { subtyp        : {lab:'key'                , wide: 5}
        , name          : {lab:'name'               , wide: 5}
        , descr         : {lab:'description'        , wide: 5}
        , txt           : {lab:'default value'      , wide:16}
        , missing       : {lab:'n/a'                , wide: 1, typ:'flag'}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.buttonNew          (this.onNewP        )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    onUpdateContext(idx:number) {
// this.log('onUpdateContext:', idx)
        let item                = this.list[idx]
        return { header         : item.name
               , id             : item.id
               , tab            : item.tab
               , tab_id         : item.tab_id
               , typ_id         : item.typ_id
               }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'TxtWww', template:ItemDiv})
export class TxtWww extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:TxtWwwService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    baseDefn            :any    =
        { HIDE          : ['id','update_date','tab','tab_id','typ_id']
        , subtyp        : {lab:'key'                , wide:12}
        , name          : {lab:'name'               , wide:12}
        , descr         : {lab:'descr'              , wide:12}
        , txt           : {opt:'text'               , wide:12, typ:'textarea'}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        let args

        let  itemDefn           =
        this.itemDefn           = this.clone(this.baseDefn)
        if (this.ctx.id) {
            itemDefn.subtyp.rdo =
            itemDefn.name.rdo   =
            itemDefn.descr.rdo  = true
            this.setHeader      (this.ctx.header    )
            this.defineItem     (this.itemDefn      )
            this.buttonCancel   (this.onCancelP     )
            this.buttonDelete   (this.onDeleteP     )
            this.buttonOk       (this.onSubmitP     )
            this.formSubmit     (this.onSubmitP     )
            args                = { id              : this.ctx.id }
        } else if (this.ctx.typ_id){
            itemDefn.subtyp.rdo =
            itemDefn.name.rdo   =
            itemDefn.descr.rdo  = true
            this.setHeader      (this.ctx.header    )
            this.defineItem     (this.itemDefn      )
            this.buttonCancel   (this.onCancelP     )
            this.buttonOk       (this.onSubmitP     )
            this.formSubmit     (this.onSubmitP     )
            args                = { typ_id          : this.ctx.typ_id }
        } else {
            this.setHeader      ('new...'           )
            this.defineItem     (this.itemDefn      )
            this.buttonCancel   (this.onCancelP     )
            this.buttonOk       (this.onSubmitP     )
            this.formSubmit     (this.onSubmitP     )
            args                = {}
        }

        return this.readP(args)
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
