//------------------------------------------------------------------------------
declare const REJECT, is
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemModal, LoginService          } from './service'
//------------------------------------------------------------------------------
@Component({selector:'Login', template:ItemDiv})
export class Login extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:LoginService ) { super() }
    modal_size                  = 'tiny'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { username      : {opt:'username'           , wide:12}
        , password      : {opt:'password'           , wide:12, typ:'password'}
//         , auth_code     : {opt:'authorisation code' , wide:12}
        }
    //--------------------------------------------------------------------------
    item                :any    = {}
    userSet             :boolean= false
    //--------------------------------------------------------------------------
    onInitP() {
        this.glob.loginDisplayed
                                = true
        this.defineItem         (this.itemDefn      )
        this.setHeader          ('login'            )
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )
        this.setItem('', '')
        return this.setFocusP()
    }

    setItem(username='', password='') {
        this.item               =
            { username          : username || ''
            , password          : password || ''
            }
    }

    onSubmitP(key:string, values) {
this.log('onSubmitP values:', values)
        values.username         = values.username.trim()
        values.password         = values.password.trim()
        if (!values.username || !values.password) {
            return REJECT()
        }
        return this.busyServiceEmitP('login', values)
        .then((res) => {
this.log('onSubmitP res:', res)
            this.glob.loginDisplayed
                                = false
            return this.modalRESOLVE(res)
        })
        .catch((err) => {
this.log('onSubmitP err:', err)
            return REJECT(err)
        })
    }
    onCancelP() {
this.log('onCancelP')
        return REJECT()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
