//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemComp, StkService             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'StkArt', template:ItemDiv})
export class StkArt extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:StkService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['art_id']
        , selector      : {lab:'selector'           , wide: 9                , rtn:this.onKeyP}
        , selector_btn  : {lab:'read'               , wide: 3                , btn:this.onKeyP}

        , matchcode     : {rdo:'matchcode'          , wide:12}

        , shelves       : {rdo:'shelves'            , wide: 9}
        , onhand        : {rdo:'current onhand'     , wide: 3, typ:'quantity'}

        , shelf         : {opt:'shelf'              , wide: 9                , rtn:this.onKeyP}
        , shelf_btn     : {lab:'add/delete'         , wide: 3                , btn:this.onKeyP}

        , stocktake     : {opt:'stock count'        , wide: 9, typ:'quantity', rtn:this.onKeyP}
        , stocktake_btn : {lab:'commit'             , wide: 3                , btn:this.onKeyP}

        , weight        : {opt:'weight'             , wide: 9, typ:'quantity', rtn:this.onKeyP}
        , weight_btn    : {lab:'update'             , wide: 3                , btn:this.onKeyP}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.dft.rsr_id         = this.ctx.rsr_id

        this.defineItem         (this.itemDefn      )
        this.setHeader          ('=menu='           )

        return this.setFocusP('selector')
    }
    //--------------------------------------------------------------------------
    onKeyDoneP(key:string, res:any) {
        if      (key == 'selector' && res.art_id ) { key = 'shelf'    }
        else if (key == 'shelf'    && res.shelves) { key = 'selector' }
        return this.setFocusP(key)
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
