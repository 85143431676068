//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemComp, RptService             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'Rpt', template:ItemDiv})
export class Rpt extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:RptService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    TIMEOUT             :any    = undefined

    onInitP() {
        return this.busyServiceEmitP('get_options',
            { ctx               : this.ctx
            , NAME              : this.ctx.name || 'NAME'
            , TITLE             : this.header   || 'TITLE'
            })
        .then((res:any) => {
            if (res.options && res.options.TIMEOUT) {
                this.TIMEOUT    = res.options.TIMEOUT
            }
            for (let key in res.fields) {
                if (!res.fields[key].wide) {
                    res.fields[key].wide
                                = 12
                }
            }
            this.defineItem     (res.fields         )
            this.setHeader      ('=menu='           )

            this.buttonOk       (this.onSubmitP     )
            this.formSubmit     (this.onSubmitP     )

            this.item           = res.item
            return this.setFocusP()
        })
    }
    //--------------------------------------------------------------------------
    onSubmitP(key:string, values:any) {
        if (!values)            { return RESOLVE() }
        values.ctx              = this.ctx
        values.NAME             = this.ctx.name
        values.TITLE            = this.header
        values.TIMEOUT          = this.TIMEOUT
        return this.downloadP(values,
            { name              : `report [${this.header}]`
            , task              : 'get_report'
            })
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
