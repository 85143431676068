//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { PrdService                                } from './service'
import  { PrdLst                , Prd, PrdFam
        , AcceptCsvData                             } from './prdLst'
import  { PrdAttLst             , PrdAtt            } from './prdAttLst'
import  { PrdChdLst             , PrdChd            } from './prdChdLst'
import  { PrdCslLst                                 } from './prdCslLst'
import  { PrdDtl                                    } from './prdDtl'
import  { PrdFamDtl                                 } from './prdFamDtl'
import  { PrdGrpLst                                 } from './prdGrpLst'
import  { PrdLic                                    } from './prdLic'
import  { PrdImgLst             , PrdImgNew
                                , PrdImgUpd         } from './prdImgLst'
import  { PrdParLst             , PrdPar            } from './prdParLst'
import  { PrdPriLst                                 } from './prdPriLst'
import  { PrdTagLst             , PrdTag            } from './prdTagLst'
import  { PrdTxtLst             , PrdTxt            } from './prdTxtLst'
import  { PrdVrnLst             , PrdVrn            } from './prdVrnLst'
//------------------------------------------------------------------------------
let c = [ PrdLst                , Prd, PrdFam
        , PrdAttLst             , PrdAtt
        , PrdChdLst             , PrdChd
        , PrdCslLst
        , PrdDtl                , PrdFamDtl
        , PrdGrpLst
        , PrdImgLst             , PrdImgNew
                                , PrdImgUpd
        , PrdLic
        , PrdParLst             , PrdPar
        , PrdPriLst
        , PrdTxtLst             , PrdTxt
        , PrdTagLst             , PrdTag
        , PrdVrnLst             , PrdVrn
        , AcceptCsvData
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ PrdService ]
    }
)
export class PrdModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
import  { SelectPrd                                 } from './selectPrd'
let d = [ SelectPrd ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : d
    , providers                 : [ PrdService ]
    }
)
export class SelectPrdModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(d) }
}
//------------------------------------------------------------------------------
