//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , TaxService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'TaxLst', template:ListDiv})
export class TaxLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:TaxService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { name          : {lab:'name'               , wide: 8}
        , lnd_name      : {lab:'country'            , wide: 4}
        , percent       : {lab:'percent'            , wide: 4, typ:'percent'}
        , acc_code      : {lab:'account code'       , wide: 4}
        , sales_acct    : {lab:'revenue acct'       , wide: 4}
        , skonto_acct   : {lab:'discount acct'      , wide: 4}
        , comment       : {lab:'invoice comment'    , wide:12}
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Tax', template:ItemDiv})
export class Tax extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:TaxService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , name          : {lab:'name'               , wide: 9               , copyCheck:true}
        , lnd_id        : {opt:'country'            , wide: 3, cod:'lnd'    , copyCheck:true}

        , percent       : {opt:'percent'            , wide: 3, typ:'percent'}
        , acc_code      : {opt:'account code'       , wide: 3}
        , sales_acct    : {lab:'revenue acct'       , wide: 3}
        , skonto_acct   : {opt:'discount acct'      , wide: 3}

        , comment       : {opt:'invoice comment'    , wide:12}
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
