//------------------------------------------------------------------------------
import  { Injectable                                } from '@angular/core'
//------------------------------------------------------------------------------
import * as lib from '../library'
//------------------------------------------------------------------------------
export const Imports            = lib.Imports
export const BaseModule         = lib.Base.Module

export const ItemComp           = lib.Item.Comp
export const ItemDiv            = lib.Item.Div
export const ItemModal          = lib.Item.Modal
export const ListComp           = lib.List.Comp
export const ListDiv            = lib.List.Div
export const SelectDiv          = lib.Select.Div
export const SelectModal        = lib.Select.Modal
export const TabbedComp         = lib.Base.Tabbed
//------------------------------------------------------------------------------
@Injectable()
export class ArtService extends lib.Base.Service {
    //--------------------------------------------------------------------------
    allTabs             :any    = ['dtl','pri','rsr','vrg','kpi']
    validTabs           :any    = this.allTabs
    //--------------------------------------------------------------------------
    setHeader(values:any) {
        this.ctx.header         = (!values.id)      ? 'new...'
                                : (values.number)   ? `${values.number}: ${values.matchcode}`
                                                    : values.matchcode
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
export const ArtDiv             = `
<div class='tabs container'>
  <div class='tabshead'>
    <h3 class='header floatl'>
      <button               class='btn back'
                            (click)='popClick()'
      >{{glob.backArrow}}</button>{{ctx.header}}</h3>
    <div class='u-cf'></div>
  </div>
  <div class='tabstabs'>
    <div                    (click)='stabSel("dtl")' class='{{stabCls("dtl")}}'>details  </div>
    <div *ngIf='stabs.pri'  (click)='stabSel("pri")' class='{{stabCls("pri")}}'>prices   </div>
    <div *ngIf='stabs.rsr'  (click)='stabSel("rsr")' class='{{stabCls("rsr")}}'>on hand  </div>
    <div *ngIf='stabs.vrg'  (click)='stabSel("vrg")' class='{{stabCls("vrg")}}'>documents</div>
    <div *ngIf='stabs.kpi'  (click)='stabSel("kpi")' class='{{stabCls("kpi")}}'>kpi      </div>
  </div>
  <div class='tabsbody' [ngSwitch]='stab'>
    <ArtDtl    *ngSwitchDefault     ></ArtDtl   >
    <ArtPriLst *ngSwitchCase='"pri"'></ArtPriLst>
    <ArtRsrLst *ngSwitchCase='"rsr"'></ArtRsrLst>
    <ArtVrgLst *ngSwitchCase='"vrg"'></ArtVrgLst>
    <ArtKpi    *ngSwitchCase='"kpi"'></ArtKpi   >
  </div>
</div>
`
//------------------------------------------------------------------------------
