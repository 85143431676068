//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , OlsService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'OlsSkuLst', template:ListDiv})
export class OlsSkuLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:OlsService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { number        : {lab:'art number'         , wide: 6, isKey:true }
        , sup_name      : {lab:'supplier'           , wide: 6, isKey:true }
        , matchcode     : {lab:'matchcode'          , wide:20, isKey:true }
        , sku           : {lab:'shop sku'           , wide: 8}
        }
    dontLoadAll         :boolean= true
    groupingKey                 = 'art_id'
    //--------------------------------------------------------------------------
    onLoad_task                 = 'sku_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.onCriteria         (this.onCriteriaP   )
        this.listButton_cell    ('matchcode', 'add'   , this.whenAdd   , this.onAddP   , 'floatr')
        this.listButton_cell    ('sku'      , 'remove', this.whenRemove, this.onRemoveP, 'floatr')

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    whenAdd(item:any) {
        return (item.GROUP == 'single' || item.GROUP == 'group1st')
    }
    onAddP(idx:number) {
        let item                = this.list[idx]
        let ctx                 =
            { shop              : this.ctx.shop
            , art_id            : item.art_id
            , number            : item.number
            , matchcode         : item.matchcode
            }
        return this.callComponent('OlsSkuAdd', ctx)
        .then((res:any) => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
    whenRemove(item:any) {
        return (item.id)
    }
    onRemoveP(idx:number) {
        return this.service.sku_remove(this.list[idx])
        .then(() => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
