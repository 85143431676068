//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , NmbService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'NmbLst', template:ListDiv})
export class NmbLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:NmbService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { typ           : {lab:'type'               , wide:10}
        , prefix        : {lab:'prefix'             , wide:10}
        , last_number   : {lab:'last number'        , wide: 5, typ:'integer'}
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Nmb', template:ItemDiv})
export class Nmb extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:NmbService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , typ           : {lab:'type'               , wide:12               , copyCheck:true}
        , prefix        : {opt:'prefix'             , wide:12               , copyCheck:true}
        , last_number   : {opt:'last number'        , wide:12, typ:'integer'}
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
