//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , ArtService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'ArtPriLst', template:ListDiv})
export class ArtPriLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:ArtService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { cur_typ       : {lab:'currency'           , wide: 1}
        , cur_name      : {lab:'name'               , wide: 3}
        , retail        : {lab:'retail'             , wide: 1, typ:'decimal'}
        , rt_calc       : {lab:'calculated'         , wide: 1, typ:'decnone'}
        , rt_new        : {lab:'new'                , wide: 1, typ:'decnone'}
        , rt_new_date   : {lab:'apply date'         , wide: 1, typ:'date'   }
        , wsale         : {lab:'wholesale'          , wide: 1, typ:'decimal'}
        , ws_calc       : {lab:'calculated'         , wide: 1, typ:'decnone'}
        , ws_new        : {lab:'new'                , wide: 1, typ:'decnone'}
        , ws_new_date   : {lab:'apply date'         , wide: 1, typ:'date'   }
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'pri_load'
    //--------------------------------------------------------------------------
    onNewCompName               = 'ArtPri'
    onUpdateCompName            = 'ArtPri'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn, true)
        this.buttonNew          (this.onNewP        )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'ArtPri', template:ItemDiv})
export class ArtPri extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:ArtService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , cur_id        : {lab:'currency'           , wide:12, cod:'cur'    , copyCheck:true}
        , retail        : {opt:'retail'             , wide: 3, typ:'decimal'}
        , rt_calc       : {opt:'calculated'         , wide: 3, typ:'decimal'}
        , rt_new        : {opt:'new'                , wide: 3, typ:'decimal'}
        , rt_new_date   : {opt:'apply date'         , wide: 3, typ:'date'   }
        , wsale         : {opt:'wholesale'          , wide: 3, typ:'decimal'}
        , ws_calc       : {opt:'calculated'         , wide: 3, typ:'decimal'}
        , ws_new        : {opt:'new'                , wide: 3, typ:'decimal'}
        , ws_new_date   : {opt:'apply date'         , wide: 3, typ:'date'   }
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'pri_read'
    onSave_task                 = 'pri_save'
    onDelete_task               = 'pri_dele'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
