//------------------------------------------------------------------------------
import  { enableProdMode                            } from '@angular/core'
import  { platformBrowserDynamic                    } from '@angular/platform-browser-dynamic'

import  { AppModule                                 } from './app/module'
import  { environment                               } from './environments/environment'
//------------------------------------------------------------------------------
if (window.location.port == '443' || environment.production) {
    console.log('----- running in production mode...')
    enableProdMode()
}
//------------------------------------------------------------------------------
platformBrowserDynamic().bootstrapModule(AppModule).catch(console.error)
//------------------------------------------------------------------------------
