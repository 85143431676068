//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { PrtLabService                             } from './service'
import  { PrtLab                                    } from './prtLab'
import  { PrtLabModal                               } from './prtLabModal'
//------------------------------------------------------------------------------
let c = [ PrtLab
        , PrtLabModal
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ PrtLabService ]
    }
)
export class PrtLabModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
