//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemComp, CpaService             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'CpaDtl', template:ItemDiv})
export class CpaDtl extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:CpaService ) { super() }
    comp_type                   = 'maintab'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , vnd_id        : {lab:'vendor (payer)'     , wide: 6, cod:'vnd'}
        , knt_id        : {lab:'supplier (payee)'   , wide: 6, cod:'sup'}

        , descr         : {lab:'description'        , wide:12}

        , period        : {opt:'period'             , wide: 6, cod:'period'}
        , next_run_date : {opt:'next run date'      , wide: 6, typ:'date'}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.setHeader(this.ctx.header)
        this.defineItem         (this.itemDefn      )
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        if (this.cache.main && !this.ctx.cpa_id) {
            this.receivedItem(this.readResult(this.cache.main))
            return this.setFocusP()
        } else {
            return this.readP(this.ctx)
            .then(() => this.setFocusP() )
        }
    }
    //--------------------------------------------------------------------------
    readResult(res:any) {
        this.service.initTabs(res.id, 'dtl')
        this.setCache(res)
        return res
    }
    //--------------------------------------------------------------------------
    receivedItem(item:any) {
        Object.assign(this.item, item)
        if (this.item.id) {
            this.setCache(this.item)
            this.ctx.cpa_id     =
            this.dft.cpa_id     = this.item.id
            this.service.showValidTabs()
            this.service.setHeader(this.item)
        }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
