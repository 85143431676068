//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , CpaService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'CpaItmLst', template:ListDiv})
export class CpaItmLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:CpaService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { descr         : {lab:'description'        , wide:10}
        , chn_name      : {lab:'channel'            , wide: 6}
        , sup_name      : {lab:'supplier'           , wide: 6}
        , dsg_name      : {lab:'designer'           , wide: 6}
        , lnd_or_grp    : {lab:'country'            , wide: 6}
        , brn_name      : {lab:'brand'              , wide: 6}
        , wrg_name      : {lab:'product group'      , wide: 6}
        , art_selector  : {lab:'articles to match'  , wide: 6}
        , percent       : {lab:'percent'            , wide: 2, typ:'percent'}
        , use_purch_price
                        : {lab:'purch'              , wide: 2, typ:'flag'}
        , tax_name      : {lab:'tax'                , wide: 5}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'itm_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn, true)
        this.buttonNew          (this.onNewP        )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'CpaItm', template:ItemDiv})
export class CpaItm extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:CpaService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['idx','id','update_date']
        , descr         : {lab:'description'        , wide:12               , copyCheck:true}

        , chn_id        : {opt:'channel'            , wide: 6, cod:'chn'    , copyCheck:true}
        , sup_id        : {opt:'supplier'           , wide: 6, cod:'sup'    , copyCheck:true}

        , dsg_id        : {opt:'designer'           , wide: 6, cod:'sup'    , copyCheck:true}
        , lnd_or_grp_id : {opt:'country / group'    , wide: 6, cod:'lnd_or_grp'
                                                                            , copyCheck:true}

        , brn_id        : {opt:'brand'              , wide: 6, cod:'brn'    , copyCheck:true}
        , wrg_id        : {opt:'product group'      , wide: 6, cod:'wrg'    , copyCheck:true}

        , art_selector  : {opt:'articles to match'  , wide: 6               , copyCheck:true}

        , percent       : {lab:'percent'            , wide: 3, typ:'percent', newline:true}
        , use_purch_price
                        : {opt:'use purchase price' , wide: 3, typ:'boolean'}


        , tax_id        : {lab:'tax'                , wide: 6, cod:'tax'    , newline:true}
        , dr_acct       : {lab:'debit account'      , wide: 3}
        , cr_acct       : {lab:'credit account'     , wide: 3}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'itm_read'
    onSave_task                 = 'itm_save'
    onDelete_task               = 'itm_dele'
    //--------------------------------------------------------------------------
    onInitP() {
        this.setHeader          ('edit'             )
        this.defineItem         (this.itemDefn      )
        this.buttonCancel       (this.onCancelP     )
        this.buttonCopy         (this.onCopyP       )
        this.buttonDelete       (this.onDeleteP     )
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        return this.readP({ id:this.ctx.id })
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
