//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , PrdService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'PrdImgLst', template:ListDiv})
export class PrdImgLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:PrdService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { typ_name      : {lab:'type'               , wide: 3}
        , filename      : {lab:'filename'           , wide: 8}
        , src           : {lab:'image'              , wide:12, typ:'image'}
//         , tags          : {lab:'tags'               , wide:12}
        , sort_no       : {lab:'sort'               , wide: 1, typ:'integer'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'img_load'
    onNewCompName               = 'PrdImgNew'
    onUpdateCompName            = 'PrdImgUpd'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn, true)
        this.buttonDelete       (this.onDeleteP     , 'delete selected')
        this.buttonSelAll       (this.onSelectAllP  )
        this.buttonInvSel       (this.onInvertSelP  )
        this.buttonNew          (this.onNewP        )
//         this.makeButton         (this.onSetDefaultP , 'set default(s)')
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
//     onSetDefaultP() {
//         return this.getSelectedItemsP()
//         .then((items) => this.busyServiceEmitP('img_setDefault', { items:items }) )
//         .then((     ) => this.loadAndFocusP(   ) )
//     }
    //--------------------------------------------------------------------------
    onDeleteP() {
        return this.getSelectedIdUpdsP({ confirm:'deletion' })
        .then((items) => this.busyServiceEmitP('img_dele', { items:items }) )
        .then((res  ) => this.onDeleteDoneP(res) )
        .then((     ) => this.loadAndFocusP(   ) )
    }
    onDeleteDoneP(res:any) {
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'PrdImgUpd', template:ItemDiv})
export class PrdImgUpd extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:PrdService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , typ_id        : {lab:'type'               , wide: 6, cod:'img_tag_type', copyCheck:true}
        , sort_no       : {opt:'sort'               , wide: 6, typ:'integer'}
        , image         : {lab:'image'              , wide:12, typ:'image'  }
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'img_read'
    onSave_task                 = 'img_save'
    onDelete_task               = 'img_dele'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'PrdImgNew', template:ItemDiv})
export class PrdImgNew extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:PrdService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , typ_id        : {lab:'type'               , wide: 6, cod:'img_tag_type', copyCheck:true}
        , sort_no       : {opt:'sort'               , wide: 6, typ:'integer'}
        , image         : {lab:'image'              , wide:12, typ:'image'  }
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'img_read'
    onSave_task                 = 'img_save'
    onDelete_task               = 'img_dele'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
