//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { DtyCodService                             } from './service'
import  { DtyCodLst             , DtyCod            } from './dtyCodLst'
//------------------------------------------------------------------------------
let c = [ DtyCodLst             , DtyCod
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ DtyCodService ]
    }
)
export class DtyCodModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
