//------------------------------------------------------------------------------
import  { Injectable                                } from '@angular/core'
//------------------------------------------------------------------------------
import * as lib from '../library'
//------------------------------------------------------------------------------
export const Imports            = lib.Imports
export const BaseModule         = lib.Base.Module

export const ItemComp           = lib.Item.Comp
export const ItemModal          = lib.Item.Modal
export const ItemDiv            = lib.Item.Div
export const ListComp           = lib.List.Comp
export const ListDiv            = lib.List.Div
export const PstList            = lib.Pst.List
export const PstListDiv         = lib.Pst.ListDiv
export const TabbedComp         = lib.Base.Tabbed
//------------------------------------------------------------------------------
@Injectable()
export class PckService extends lib.Base.Service {}
//------------------------------------------------------------------------------
export const PckDiv             = `
<div class='tabs container'>
  <div class='tabshead'>
    <h3 class='header floatl'>
      <button               class='btn back'
                            (click)='popClick()'
      >{{glob.backArrow}}</button>{{ctx.header}}</h3>
    <div class='u-cf'></div>
  </div>
  <div class='tabstabs'>
    <div                    (click)='stabSel("dtl")' class='{{stabCls("dtl")}}'>details  </div>
    <div                    (click)='stabSel("pst")' class='{{stabCls("pst")}}'>items    </div>
  </div>
  <div class='tabsbody' [ngSwitch]='stab'>
    <PckDtl     *ngSwitchDefault     ></PckDtl   >
    <PckPstLst  *ngSwitchCase='"pst"'></PckPstLst>
  </div>
</div>
`
//------------------------------------------------------------------------------
