//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , LndService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'LndGrpLst', template:ListDiv})
export class LndGrpLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:LndService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { grp_name      : {lab:'group'              , wide:10}
        , lnd_name      : {lab:'country'            , wide:10}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'grp_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.buttonNew          (this.onNewP        )
        this.makeButton         (this.onNonGermanP  , 'update non-german')
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    onNonGermanP(key:string, values:any) {
        return this.busyServiceEmitP('update_non_german', {})
        .then(() => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'LndGrp', template:ItemDiv})
export class LndGrp extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:LndService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , grp_id        : {lab:'group'              , wide:12, cod:'l_g'    , copyCheck:true}
        , lnd_id        : {lab:'country'            , wide:12, cod:'lnd'    , copyCheck:true}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'grp_read'
    onSave_task                 = 'grp_save'
    onDelete_task               = 'grp_dele'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
