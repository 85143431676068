//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
//------------------------------------------------------------------------------
import  { BaseComp, BusyDiv                         } from './service'
//------------------------------------------------------------------------------
@Component({selector:'BusyTag', template:BusyDiv})
export class BusyTag extends BaseComp {
    //--------------------------------------------------------------------------
    constructor() {
        super()
        this.glob.registerRoot(this)
    }
    comp_type                   = 'modal'
    //--------------------------------------------------------------------------
    clearTag() {
        return
    }
    //--------------------------------------------------------------------------
    loadTagP(opts:any={}) {
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
