//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , ShpService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'ShpLst', template:ListDiv})
export class ShpLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:ShpService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { date          : {lab:''                   , wide: 4, typ:'dates'}
        , number        : {lab:'number'             , wide: 5}
        , ext_number    : {lab:'reference'          , wide: 6}
        , knt_name      : {lab:'account'            , wide:12}
        , lnd_name      : {lab:'country'            , wide: 6}
        , crr_name      : {lab:'carrier'            , wide: 6}
        , tracking_num  : {lab:'tracking'           , wide: 6}
        , stt_name      : {lab:'status'             , wide: 6}
        , events_flag   : {lab:'evn'                , wide: 1, typ:'flag'}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.setHeader          ('=menu='           )
        this.defineList         (this.listDefn      )

//         this.makeButton         (this.onPrintDHLP   , 'print DHL label'     )
//         this.makeButton         (this.onCancelDHLP  , 'cancel DHL shipment' )

        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    defaultCriteria() {
        return { date0:this.getDateSince('day') }
    }
    //--------------------------------------------------------------------------
//     onPrintDHLP(key:string, values:any) {
//         return this.getSelectedItemsP({ onlyOne:true })
//         .then((items) => {
//             return this.callComponent('DHLLabelModal',
//                 { name          : 'DHL label'
//                 , vrg_id        : items[0].id
//                 , tracking_num  : items[0].tracking_num
//                 })
//         })
//     }
    //--------------------------------------------------------------------------
//     onCancelDHLP(key:string, values:any) {
//         return this.getSelectedItemsP({ onlyOne:true, confirm:'cancel shipment' })
//         .then((items) => this.busyServiceEmitP('cancelDHL', { vrg_id:items[0].id }) )
//         .then(() => this.loadAndFocusP() )
//     }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Shp', template:ItemDiv})
export class Shp extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:ShpService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date','vrg_id']
        , number        : {rdo:'number'             , wide: 6}
        , ext_number    : {rdo:'reference'          , wide: 6}

        , knt_name      : {rdo:'account'            , wide: 6}
        , lnd_name      : {rdo:'country'            , wide: 6}

        , crr_id        : {opt:'carrier'            , wide: 6, cod:'crr'}
        , tracking_num  : {opt:'tracking'           , wide: 6}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'read'
    onSave_task                 = 'save'
    onDelete_task               = ''
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
