//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, promiseLoop
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv               , ItemComp
        , PrdService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'PrdLic', template:ItemDiv})
export class PrdLic extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:PrdService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , lic_id        : {opt:'license name'       , wide:12, cod:'lic'}
        , txt           : {opt:'conditions'         , wide:12, typ:'textarea'}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'lic_read'
    onSave_task                 = 'lic_save'
    onDelete_task               = 'lic_dele'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn     )
        this.buttonDelete       (this.onDeleteP    )
        this.buttonOk           (this.onSubmitP    )
        this.formSubmit         (this.onSubmitP    )

        return this.readP({ prd_id:this.ctx.prd_id })
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
    onSubmitDoneP(res:any) {
        return this.readP()
    }
    //--------------------------------------------------------------------------
    onDeleteDoneP(res:any) {
        return this.readP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
