//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , LndService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'LndDtyLst', template:ListDiv})
export class LndDtyLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:LndService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { lnd_name      : {lab:'country'            , wide: 4}
        , dty_name      : {lab:'hs code'            , wide:10}
        , tariff_num    : {lab:'taric'              , wide: 4}
        , percent       : {lab:'percent'            , wide: 2, typ:'percent'}
        , per_kg        : {lab:'cost/kg'            , wide: 2, typ:'decimal'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'dty_load'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'LndDty', template:ItemDiv})
export class LndDty extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:LndService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , lnd_id        : {lab:'country'            , wide:12, cod:'lnd'    , copyCheck:true}

        , dty_id        : {lab:'hs code'            , wide:12, cod:'dty'    , copyCheck:true}

        , tariff_num    : {opt:'taric'              , wide: 6}
        , percent       : {opt:'percent'            , wide: 2, typ:'percent'}
        , per_kg        : {opt:'cost/kg'            , wide: 2, typ:'decimal'}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'dty_read'
    onSave_task                 = 'dty_save'
    onDelete_task               = 'dty_dele'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
