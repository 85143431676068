//------------------------------------------------------------------------------
import  { Injectable                                } from '@angular/core'
//------------------------------------------------------------------------------
import * as lib from '../../library'
//------------------------------------------------------------------------------
export const Imports            = lib.Imports
export const BaseModule         = lib.Base.Module

export const BaseComp           = lib.Base.Comp
//------------------------------------------------------------------------------
@Injectable()
export class LoginService extends lib.Base.Service {}
//------------------------------------------------------------------------------
export const LogoDiv            = `
<div>
  <picture>
    <source srcset='/assets/logo-dark.png'  media='(prefers-color-scheme: dark)'/>
    <source srcset='/assets/logo-light.png' media='(prefers-color-scheme: light),(prefers-color-scheme: no-preference)'/>
    <img class='logo' src='/assets/logo-light.png' />
  </picture>
</div>`
//------------------------------------------------------------------------------
