//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemComp, StkService             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'StkShlScn', template:ItemDiv})
export class StkShlScn extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:StkService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { shelf         : {lab:'shelf'              , wide:12}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.dft.rsr_id         = this.ctx.rsr_id

        this.defineItem         (this.itemDefn      )
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        this.item               = { shelf           : '' }
        return this.setFocusP()
    }
    //--------------------------------------------------------------------------
    onSubmitP(key:string, values:any) {
        if (!(values && values.shelf)) {
            return REJECT('scan/enter a shelf identifier')
        }
        let shelf               = values.shelf.toUpperCase().replace(/ö/ig,';')
        let ctx                 = { rsr_id:this.ctx.rsr_id, shelf:shelf }
        return this.callComponent('StkShlLst', ctx)
        .then((res:any) => this.onSubmitDoneP(res) )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
