//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemComp, KntService             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'KntGrpDtl', template:ItemDiv})
export class KntGrpDtl extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:KntService ) { super() }
    comp_type                   = 'maintab'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date','typ_id','number','adr_id','adr_update_date']
        , name          : {lab:'name'               , wide:12}

        , descr         : {opt:'description'        , wide:12}

        , acc_number    : {opt:'fibu number'        , wide:12}

        , wsale_sw      : {lab:'wholesale'          , wide:12, typ:'boolean'}

        , monthly       : {opt:'monthly'            , wide: 3, typ:'boolean'}
        , due_days      : {opt:'due days'           , wide: 3, typ:'integer'}
        , dsc_days      : {opt:'discount days'      , wide: 3, typ:'integer'}
        , dsc_pct       : {opt:'discount percent'   , wide: 3, typ:'percent'}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        return this.readP()
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
    receivedItem(item:any) {
        Object.assign(this.item, item)
        if (this.item.id) {
            this.setCache(this.item)
            this.ctx.knt_id     =
            this.dft.knt_id     = this.item.id
            this.service.showValidTabs()
            this.service.setHeader(this.item)
        }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
