//------------------------------------------------------------------------------
import * as lib from '../../library'
//------------------------------------------------------------------------------
export const Imports            = lib.Imports
export const BaseModule         = lib.Base.Module

export const BaseComp           = lib.Base.Comp
//------------------------------------------------------------------------------
export const BusyDiv            = '<div class="modal-position"><div>'
//------------------------------------------------------------------------------
