//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { Logo                                      } from './logo'
//------------------------------------------------------------------------------
let c = [ Logo ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    }
)
export class LogoModule extends BaseModule {
    onConstructor() { this.glob.registerComponents([ Logo ]) }
}
//------------------------------------------------------------------------------
