//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { IdlService                                } from './service'
import  { IdlQtyLst             , IdlQty            } from './idlQtyLst'
import  { IdlValLst             , IdlVal            } from './idlValLst'
//------------------------------------------------------------------------------
let c = [ IdlQtyLst             , IdlQty
        , IdlValLst             , IdlVal
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ IdlService ]
    }
)
export class IdlModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
