//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { TxtService                                } from './service'
import  { TxtLst                , Txt               } from './txtLst'

import  { TxtChnService                             } from './service'
import  { TxtChnLst             , TxtChn            } from './txtChnLst'

import  { TxtFrmService                             } from './service'
import  { TxtFrmLst             , TxtFrm            } from './txtFrmLst'

import  { TxtSupService                             } from './service'
import  { TxtSupLst             , TxtSup            } from './txtSupLst'

import  { TxtVndService                             } from './service'
import  { TxtVndLst             , TxtVnd            } from './txtVndLst'

import  { TxtWwwService                             } from './service'
import  { TxtWwwLst             , TxtWww            } from './txtWwwLst'
//------------------------------------------------------------------------------
let c = [ TxtLst                , Txt
        , TxtChnLst             , TxtChn
        , TxtFrmLst             , TxtFrm
        , TxtSupLst             , TxtSup
        , TxtVndLst             , TxtVnd
        , TxtWwwLst             , TxtWww
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ TxtService, TxtChnService, TxtFrmService, TxtSupService, TxtVndService, TxtWwwService ]
    }
)
export class TxtModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
