//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, is, promiseLoop
//------------------------------------------------------------------------------
import  { Item                                      } from './item'
import  { Mixed                                     } from './mixed'
import  { recalcPst                                 } from './calcs'
//------------------------------------------------------------------------------
export class PstList extends Mixed.Base {
    //--------------------------------------------------------------------------
    selectorDefaults    :any    = { selector: '', qty: 1000 }
    parseTask           :string = 'select_psts'     // '' -> no parse
    parseBase           :any    = {}
    recalcPst           :boolean= true
    price_reqd          :boolean= true
    allow_zero_qtys     :boolean= false

    mergeKey            :string = 'art_id'
    xrefKeys            :any    = 'ean_number,number,code,matchcode'.split(/\W+/g)

//  onLoad_task                 = 'EMPTY'           // load empty list
//  onRead_task                 = 'DEFAULTS'        // read selectorDefaults
//  onSave_task                 = 'NONE'            // no save button
    //--------------------------------------------------------------------------
    onInitP() {
        let defn                = this.clone(this.itemDefn)
        if (!this.ctx.canEdit) {
            // hide selector and qty
            defn.selector.typ   = 'skip'
            defn.qty.typ        = 'skip'
        }
        this.defineItem         (defn               )
        this.defineList         (this.listDefn, true)

        return this.readP()
        .then(() => this.loadSpecialP() )
        .then(() => this.setFocusP   () )
    }
    //--------------------------------------------------------------------------
    readP(criteria:any={}) {
        if (this.onRead_task == 'DEFAULTS') {
            Object.assign(this.item, this.selectorDefaults)
            return this.readDoneP()
        } else {
            return super.readP(criteria)
        }
    }
    readDoneP() {
        let vrg                 = this.cache.vrg
        this.parseBase          =
            { price_reqd        : this.price_reqd
            , ols_id            : vrg.ols_id
            , chn_id            : vrg.chn_id
            , lnd_id_tax        : vrg.lnd_id
            , vnd_id            : vrg.vnd_id
            , knt_id            : vrg.cus_id
            , cur_id            : vrg.cur_id
            , exchrate          : vrg.exchrate
            , wsale_yn          : vrg.wsale_yn
            , wsale_sw          : vrg.wsale_yn == 'y'
            , dsc_ids           : vrg.dsc_ids
            , ent_ids           : vrg.ent_ids
            }
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    loadSpecialP():any {
// this.log('loadSpecialP')
        let initted             = this.name + '_initialised_'
        if (!this.ctx[initted]) {
            this.ctx[initted]   = true
            delete this.cache[this.name]
        }
        let list                = this.cache[this.name]
        return is.array(list)
            ? this.loadWithP(list)
            : this.loadP()
    }
    loadDoneP():any {
        this.cache[this.name]   = this.list
        if (this.colours) {
            this.list.forEach((item, idx) => this.setItemColour(item, idx))
        }
        return this.setTotalsP()
    }
    //--------------------------------------------------------------------------
    selectionChangedP():any {
        return this.setTotalsP()
    }
    //--------------------------------------------------------------------------
    setTotalsP() {
        return RESOLVE({})
    }
    //--------------------------------------------------------------------------
    decodeSelector(values:any) {
        if (!values || !values.selector) {
            return [ null, null ]
        }
        let selector            = values.selector.trim()
        let qty                 = values.qty || 1000
        let test        :any    = /^((-?\d+)\*)?(.+)$/.exec(selector)
//  eg: /^((-?\d+)\*)?(.+)$/.exec('-1*1234 7262 22')
//  -> [ '-1*1234 7262 22', '-1*', '-1', '1234 7262 22', index: 0, input: '-1*1234 7262 22' ]
        if (!test) {
            return [ null, null ]
        }
        if (test[2] !== undefined) {
            qty                 = +test[2] * 1000
        }
        selector                = test[3].trim()
        return [ selector, qty ]
    }
    //--------------------------------------------------------------------------
    onSubmitP(key:string, values:any) {
        return this.parseSelectorP(values)
        .then(() => this.setTotalsP() )
        .then(() => this.setDefaultsP() )
    }
    //--------------------------------------------------------------------------
    parseSelectorP(values:any) {
        let [ selector, qty ]   = this.decodeSelector(values)
        if (!selector) {
            return REJECT('scan/enter article number or barcode')
        }
        // create xref and unselect all items
        this.setupXref(this.list)
        let found               = this.xrefFind(selector)
        if (found) {
            // we will use the first of possibly multiple items
            let idx     :number = found[0]
            let item    :any    = this.list[idx]
            this.messageDone(`item found in list`)
            this.scrollIntoView(idx)
            return this.applyValuesP(item,
                { qty           : qty + (item.qty || 0)
                , DIRTY         : true
                , HIDDEN        : false
                , SELECT        : true
                })
            .then((values:any) => {
                this.doRecalcPst(item)
                this.setItemColour(item, idx)
                return this.onParseDoneP(values)
            })
        } else if (this.parseTask) {
            return this.onParseP({ selector:selector, qty:qty })
        } else {
            return REJECT('no items found')
        }
    }
    //--------------------------------------------------------------------------
    onParseP(values:any) {
        let selectArtP          = (res:any) => {
            // { selector:'...', foundItems:[ <art>, ... ] }
            return this.callComponent('SelectArt', res)
            .then((res:any) => {
                if (!res)   { return RESOLVE([]) }
                values.art_id
                            = res.id
                return this.busyServiceEmitP('select_psts', values)
            })
        }
        //----------------------------------------------------------------------
        Object.assign(values, this.parseBase)
        return this.busyServiceEmitP(this.parseTask, values)
        .then((res:any) => (res && res.foundItems)
            ? selectArtP(
                    { selector  : res.selector
                    , foundItems: res.foundItems
                    })
            : RESOLVE(res)
        )
        .then((res) => this.onParseResultP(res) )
        .then((res) => this.mergeToListP  (res) )
        .then((res) => this.onParseDoneP  (res) )
    }
    //--------------------------------------------------------------------------
    onParseResultP(res:any) {
// this.log('onParseResultP:', res)
        if (is.object(res)) {
            return RESOLVE([ res ])
        }
        if (!res || res.length < 1) {
            return REJECT('no items found')
        }
        return RESOLVE(res)
    }
    //--------------------------------------------------------------------------
    mergeToListP(items:any) {
// try {
//     this.log('mergeToListP:', items)
// } catch(err) {
//     console.log(err)
// }
        let list                = this.list
        let xref                = this.xref
        // reset SELECT flag and create [key]] -> idx map
        let key         :string = this.mergeKey
        let added       :number = 0
        let updated     :number = 0
        let idx         :number
        let item        :any
        return promiseLoop(items, [], (values:any) => {
            idx                 = xref[key] && xref[key][values[key]]
            if (idx !== undefined) {
                item            = list[idx]
// preserve qty...
                values.qty     += item.qty || 0
                this.doRecalcPst(values)
                updated++
            } else {
                this.mergeNew(values)
                idx             = list.length
                item            = values
                list.push(item)
                this.xrefItem(xref, idx, item)
                added++
            }
            values.DIRTY        = true
            values.HIDDEN       = false
            values.SELECT       = true
            this.setExtraValues(values)
            this.scrollIntoView(idx)
            return this.applyValuesP(item, values)
            .then((values:any) => {
                this.setItemColour(item, idx)
                return RESOLVE()
            })
        })
        .then(() => {
            this.messageDone(`${added} items added, ${updated} items updated`)
            return RESOLVE()
        })
    }
    mergeFound(values:any, idx:number) {
    }
    mergeNew(values:any) {
        values.lnk_qty          = 0
    }
    //--------------------------------------------------------------------------
    onParseDoneP(res:any) {
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    setDefaultsP() {
        return this.setItemValuesP(this.selectorDefaults)
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
    applyValuesToList_extra(idx:number, values:any) {
        this.doRecalcPst(values)
    }
    applyValuesToListDoneP(idx:number, item:any) {
        this.setItemColour(item, idx)
        return this.setTotalsP()
    }
    //--------------------------------------------------------------------------
    doRecalcPst(item:any) {
        if (this.recalcPst) {
            recalcPst(item)
        }
        return item
    }
    //--------------------------------------------------------------------------
    onDeleteP() {
        let list                = this.list.filter((item:any) => {
            item.HIDDEN         = false
            return !item.SELECT
        })
        return this.loadWithP(list)
    }
    //--------------------------------------------------------------------------
    onSaveP(key:string, values:any) {
        return (this.ctx.vrg_id
            ? this.save_pstsP()
            : this.save_allP ()
            )
    }
    //--------------------------------------------------------------------------
    save_pstsP() {
        return this.busyServiceEmitP(this.onSave_task,
            { vrg_id            : this.ctx.vrg_id
            , psts              : this.list
            , allow_zero_qtys   : this.allow_zero_qtys
            , TIMEOUT           : this.list.length * 2
            })
        .then(() => this.loadP() )
        .then(() => {
            this.messageSaved()
            return this.setFocusP()
        })
    }
    //--------------------------------------------------------------------------
    save_allP() {
        return this.busyServiceEmitP('save_all',
            { vrg               : this.cache.vrg
            , psts              : this.list
            , allow_zero_qtys   : this.allow_zero_qtys
            , TIMEOUT           : this.list.length * 2
            })
        .then((res:any) => {
            this.cache.vrg      = res
            this.ctx.vrg_id     = res.id
            this.dft.vrg_id     = res.id
            this.service.showValidTabs()
            return this.loadP()
        })
        .then(() => {
            this.messageSaved()
            return this.setFocusP()
        })
    }
    //--------------------------------------------------------------------------
    unsavedData() {
        let list                = this.cache[this.name]
        if (list && list.some((item:any) => item.DIRTY)) {
            let okToLeave       = confirm('leaving this page may discard unsaved data.')
            if (!okToLeave) {
                return true
            }
        }
        return false
    }
    //--------------------------------------------------------------------------
    xref                :any    = {}
    setupXref(list:any) {
        // create [key] -> idx map and, optionally, reset SELECT flag
        let xref = this.xref    = {}
        if (list.length < 1)    { return xref }
        if (this.xrefKeys.length < 1)
                                { return xref }
        this.onSelectNoneP('setupXref')
        this.xrefKeys.forEach((key:string) => {
            xref[key]           = {}
        })
        xref[this.mergeKey]     = {}
        list.forEach((item:any, idx:number) => {
            this.xrefItem(xref, idx, item)
        })
// this.log('XREF:', xref)
        return xref
    }
    xrefItem(xref:any, idx:number, item:any) {
        let val
        let addKey              = (key:string) => {
            val                 = item[key]
            try { val           = val.toLowerCase() }
            catch(err:any) {}
            if (!xref[key]) {
                xref[key]       = { [val]:[ idx ] }
            } else if (!xref[key][val]) {
                xref[key][val]  = [ idx ]
            } else {
                xref[key][val].push(idx)
            }
        }
        this.xrefKeys.forEach(addKey)
        addKey(this.mergeKey)
    }
    xrefFind(value:any, findall:boolean=false) {
// this.log('xrefFind value:', [ value ])
        let xref                = this.xref
        if (is.empty(xref) || is.empty(this.xrefKeys))
                                { return null }
        try { value             = value.toLowerCase() }
        catch(err:any) {}
        let found               = null
        this.xrefKeys.some((key:string) => {
            try { found         = xref[key][value] }
            catch(err:any) {}
            return found !== null
        })
//         if (!found)             { return null  }
//         else                    { return found }
        return found
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
export class PstModal extends Item.Modal {
    //--------------------------------------------------------------------------
//     constructor(modal_size:string) { super(modal_size) }
    //--------------------------------------------------------------------------
    onInitP() {
        if (this.ctx.id) {
            this.defineItem     (this.itemDefn      )
            this.setHeader      ('edit'             )
            this.buttonCancel   (this.onCancelP     )
            this.buttonCopy     (this.onCopyP       )
            this.buttonDelete   (this.onDeleteP     )
            this.buttonOk       (this.onSubmitP     )
            this.formSubmit     (this.onSubmitP     )
        } else {
            this.defineItem     (this.itemDefn      )
            this.setHeader      ('add'              )
            this.buttonCancel   (this.onCancelP     )
            this.buttonOk       (this.onSubmitP     )
            this.formSubmit     (this.onSubmitP     )
        }
        return this.readP({ id:this.ctx.id })
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
    source              :any    =
        { list                  : []
        , max                   : 0
        , item                  : {}
        }
    setSource(list:any) {
// this.log('setSource')
        this.source             =
            { list              : list
            , max               : list.length - 1
            , item              : {}
            }
    }
    setItemFromSourceP(idx:number) {
        let max                 = this.source.max
        if      (idx < 0  )     { idx = 0   }
        else if (idx > max)     { idx = max }
        return this.publishP('selectListItem', { idx:idx, prev:this.source.item.idx })
        .then(() => {
            let item            = this.source.list[idx]
//             let values  :any    = {}
//             this.iDef.KEYS.forEach((key:string) => {
//                 values[key]     = item[key]
//             })
//             values.idx          = idx
            let values          = { ...item, idx:idx }
            this.source.item    = { ...values }
            this.setExtraValues(values)
            return this.setItemValuesP(values)
        })
        .then(() => {
            this.enablePrevNext(idx, max)
            return this.setFocusP()
        })
    }
    //--------------------------------------------------------------------------
    setExtraValues(values:any) {}
    //--------------------------------------------------------------------------
    enablePrevNext(idx:number, max:number) {
        if      (idx > 0       ){ this.enableButton ('prev') }
        else                    { this.disableButton('prev') }
        if      (idx < max)     { this.enableButton ('next') }
        else                    { this.disableButton('next') }
    }
    //--------------------------------------------------------------------------
    onPrevP(key:string, values:any) {
// this.log('onPrevP:', values)
        this.messageClear()
        return this.getChangedValuesP(values)
        .then((changes) => this.publishChangesP(changes) )
        .then((       ) => this.setItemFromSourceP(values.idx - 1) )
    }
    //--------------------------------------------------------------------------
    onNextP(key:string, values:any) {
// this.log('onNextP:', values)
        this.messageClear()
        return this.getChangedValuesP(values)
        .then((changes) => this.publishChangesP(changes) )
        .then((       ) => this.setItemFromSourceP(values.idx + 1) )
    }
    //--------------------------------------------------------------------------
    onSubmitP(key:string, values:any) {
        this.messageClear()
        return this.getChangedValuesP(values)
        .then((changes:any) => {
            return this.modalRESOLVE(changes)
        })
    }
    //--------------------------------------------------------------------------
    getChangedValuesP(values:any) { return new Promise((resolve:any, reject:any) => {
// this.log('getChangedValuesP:', values)
        let resolveSourceChanges= () => {
// this.log('resolving changes...')
            if (this.item.idx != this.source.item.idx) {
                return reject('non-matching item index')
            }
            // return any changes from original item (or null)...
            let changes         = this.getChanges(this.item, this.source.item)
            if (changes) {
// this.log('changes found:', changes)
                changes.idx     = values.idx
                return resolve(changes)
            } else {
// this.log('no changes found...')
                return resolve(null)
            }
        }

        // changed values within this item...
        let changes     :any    = this.getChanges(values, this.item)
        if (!changes) {
// this.log('no changes within this item...')
            return resolveSourceChanges()
        }

        // find 'on_*' function for any on-screen change and execute
        // the first one we find...
        let on_change_function, key
        Object.keys(changes).some((name:string) => {
            on_change_function  = this['on_' + name]
            if (on_change_function) {
                key             = name
                return true
            } else {
                return false
            }
        })
        if (on_change_function) {
// this.log('on_change_function found...')
            on_change_function.call(this, key, values[key])
            .then(() => resolveSourceChanges() )
        } else {
// this.log('no on_change_function found...')
            return resolveSourceChanges()
        }
    })}
    getChanges(values:any, previous:any) {
        let changes     :any    = {}
        for (let key in values) {
            if (values[key] != previous[key]) {
                changes[key]    = values[key]
            }
        }
// this.log('getChanges:', changes)
        return (Object.keys(changes).length ? changes : null)
    }
    //--------------------------------------------------------------------------
    onKeyDoneP(key:string, res:any) {
        return this.publishChangesP(res)
        .then(() => this.setFocusP(key) )
    }
    //--------------------------------------------------------------------------
    publishChangesP(changes:any=null) {
// this.log('[pst.publishChangesP]', changes)
        if (changes) {
            return this.publishP('updateListItem', changes)
        } else {
            return RESOLVE()
        }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
export const Pst                =
{ List                          : PstList
, ListDiv                       : Mixed.Div
, Modal                         : PstModal
, ModalDiv                      : Item.Div
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
