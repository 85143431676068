//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, list_div, jQuery
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , EmlService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'EmlTmpLst', template:ListDiv})
export class EmlTmpLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:EmlService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { name          : {lab:'name'               , wide: 2}
        , lng_typ       : {lab:'language'           , wide: 1}
        , subject       : {lab:'subject'            , wide: 3}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'eml_tmp_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.buttonNew          (this.onNewP        )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'EmlTmp', template:ItemDiv})
export class EmlTmp extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:EmlService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , name          : {lab:'name'               , wide: 6               , copyCheck:true}
        , lng_typ       : {opt:'language'           , wide: 2               , copyCheck:true}

        , subject       : {lab:'subject'            , wide:12,newline:true  }

        , text          : {opt:'text'               , wide:12, typ:'textarea'}

        , html          : {opt:'html'               , wide:12, typ:'textarea'}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'eml_tmp_read'
    onSave_task                 = 'eml_tmp_save'
    onDelete_task               = 'eml_tmp_dele'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
