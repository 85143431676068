//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, is, jQuery, window, document, regexTest
//------------------------------------------------------------------------------
import  { ChangeDetectorRef                         } from '@angular/core'
import  { Component                                 } from '@angular/core'
import  { Injectable                                } from '@angular/core'
import  { OnInit                                    } from '@angular/core'
import  { OnDestroy                                 } from '@angular/core'
import  { EventEmitter                              } from '@angular/core'
import  { saveAs                                    } from 'file-saver'
//------------------------------------------------------------------------------
import  { clone
        , glob
        , getDate
        , stringify                                 } from './globals'
//------------------------------------------------------------------------------
export class BaseClass {
    //--------------------------------------------------------------------------
    public name         :string = this.constructor.name
    $                           = jQuery
    clone                       = clone
    glob                        = glob
    stringify                   = stringify
    getDateSince                = getDate.since
    lowerFirst                  = (s) => s.replace(/^\w/, (c) => c.toLowerCase())
    //--------------------------------------------------------------------------
    get showDev()               { return glob.showDev         }
    set showDev(value)          {        glob.showDev = value }
    //--------------------------------------------------------------------------
    constructor() {
//         console.log(`[${this.name}.constructor]`)
        this.onConstructor()
    }
    //--------------------------------------------------------------------------
    onConstructor() {
//         console.log(`[${this.name}.onConstructor]`)
    }
    //--------------------------------------------------------------------------
    log(...args:any) {
        if (is.string(args[0])) {
            args[0]             = `[${this.name}] ` + args[0]
        } else {
            args.unshift(`[${this.name}]`)
        }
        console.log(...args)
    }
    //--------------------------------------------------------------------------
    header              :any    = ''
    setHeader(value:any) {
        this.header             = (value == '=menu=' ? glob.menu.title : value) || ''
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'BaseComp', template:''})
export class BaseComp extends BaseClass implements OnInit, OnDestroy {
    //--------------------------------------------------------------------------
    comp_type                   = 'main'
    service             :any    = {}
    debug               :boolean=false
    //--------------------------------------------------------------------------
    modal_size          :string = 'medium'
    opts                :any    = {}
    //--------------------------------------------------------------------------
    implementsItem      :boolean= false
    itemDefn            :any    = {}
    iDef                :any    = { KEYS            : []
                                  , ROWS            : []
                                  , HIDE            : []
                                  , RDO             : false
                                  }
    item                :any    = {}
    copyCheckKeys       :any    = []
    //--------------------------------------------------------------------------
    implementsList      :boolean= false
    listDefn            :any    = {}
    lDef                :any    = { KEYS            : []
                                  , TOTKEYS         : []
                                  , TOTS            : {}
                                  , HIDE            : []
                                  , RDO             : false
                                  }
    list                :any[]  = []
    criteria            :any    = {}
    groupingKey         :string = ''
    labelsOnly          :boolean= false
    dontLoadAll         :boolean= false
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    onRead_task                 = 'read'
    onSave_task                 = 'save'
    onDelete_task               = 'dele'
    //--------------------------------------------------------------------------
    onNewCompName       :string = ''
    onUpdateCompName    :string = ''
    //--------------------------------------------------------------------------
    get ctx      ()             { return this.service.ctx           }
    set ctx      (value:any)    {        this.service.ctx   = value }
    //--------------------------------------------------------------------------
    get dft      ()             { return this.service.dft           }
    set dft      (value:any)    {        this.service.dft   = value }
    //--------------------------------------------------------------------------
    get cache    ()             { return this.service.cache         }
    set cache    (value:any)    {        this.service.cache = value }
    //--------------------------------------------------------------------------
    get stabs    ()             { return this.service.stabs         }
    set stabs    (value:any)    {        this.service.stabs = value }
    //--------------------------------------------------------------------------
    get stab     ()             { return this.service.stab          }
    set stab     (value:any)    {        this.service.stab  = value }
    //--------------------------------------------------------------------------
    stabCls(stab:string) {
        return 'stab ' + (this.stab == stab ? 'selected' : '')
    }
    stabSel(stab:string) {
        this.stab               = stab
    }
    //--------------------------------------------------------------------------
    get div1Class   () { return this.comp_type == 'modal' ? 'modal-position'                   : '' }
    get div2Class   () { return this.comp_type == 'modal' ? `modal-content ${this.modal_size}` : '' }
    //--------------------------------------------------------------------------
    onLoadInstanceP(opts:any, return_promise:boolean=true) {
this.log('onLoadInstanceP:', opts)
        this.opts               = opts
        this.ctx                = opts.ctx || {}
        if (regexTest(this.ctx.modal_size, 'tiny', 'small', 'medium', 'large')) {
            this.modal_size     = this.ctx.modal_size
        }
// this.log('creating promise...')
        if (return_promise) {
            return new Promise((resolve, reject) => {
                let _resolve    = (res) => {
                    this.log('[resolve]', res)
                    resolve(res)
                }
                let _reject     = (err) => {
                    this.log('[reject]', err)
                    reject(err)
                }
                opts._promise   =
                    { resolve   : _resolve
                    , reject    : _reject
                    }
            })
        } else {
            return RESOLVE()
        }
    }
    //--------------------------------------------------------------------------
    modalRESOLVE                = (res:any=undefined) => {
        if (this.opts._promise) {
this.log('resolve modal promise:', res)
            this.opts._promise.resolve(res)
        } else {
            this.log('\n***** UNDEFINED resolve called - this is probably not good *****\n')
        }
        this.close()
        return RESOLVE()
    }
    modalREJECT                 = (err:any=undefined) => {
        if (this.opts._promise) {
this.log('reject modal promise:', err)
            this.opts._promise.reject(err)
        } else {
            this.log('\n***** UNDEFINED reject called - this is probably not good *****\n')
        }
        this.close()
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    restoreCriteria() {
        return this.glob.restoreCriteria() || this.defaultCriteria()
    }
    storeCriteria(criteria) {
        this.glob.storeCriteria(criteria)
    }
    defaultCriteria() {
        return {}
    }
    get emptyCriteria() {
        let defaults            = this.defaultCriteria()
        let value
        return Object.keys(this.criteria)
        .every((key:string) => {
            if (this.lDef.HIDE.includes(key))
                                { return true }
            value               = this.criteria[key]
            return (is.empty(value) || value == defaults[key])
        })
    }
    //--------------------------------------------------------------------------
    previousTop         :any    = null
    ngOnInit() {
// this.log('ngOnInit:', this.ctx)
        this.previousTop        = this.glob.setTop(this)
        this.criteria           = this.restoreCriteria()
        this.messageBusy()
        return this.onInitP()
        .then ((       ) => RESOLVE(this.messageClear(   )) )
        .catch((err:any) => RESOLVE(this.messageError(err)) )
    }
    onInitP() {
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    ngOnDestroy                 () {
// this.log('ngOnDestroy')
        this.glob.setTop(this.previousTop)
        this.onDestroy()
    }
    onDestroy                   () { }
    //--------------------------------------------------------------------------
    onBackP(check:boolean=true) {
// TESTING
//         if (check && this.unsavedData())
//                                 { return REJECT('cancelled') }
//         this.opts._promise.resolve(null)
//         return RESOLVE()
        return this.glob.popCompP(check)
    }
    //--------------------------------------------------------------------------
    // these are currently only for items definitions...
    allFields           :any    = {}
    getFields(key:string) {
// this.log('getFields:', [ key ])
        let list
        if (key == 'ALL') {
            list                = Object.keys(this.allFields)
                                    .map((key:string) => this.allFields[key])
        } else {
            list                = [ this.allFields[key] ]
        }
        if (list.length < 1 || list[0] === undefined) {
// this.log(`unable to find field key '${key}'`)
            return []
        } else {
            return list
        }
    }
    disableField(key:string) {
// this.log('disableField:', key)
        this.getFields(key).forEach((field:any) => {
            field.rdo           = true
            field.off           = true
        })
    }
    enableField(key:string) {
// this.log('enableField:', key)
        this.getFields(key).forEach((field:any) => {
            field.rdo           = false
            field.off           = false
        })
    }
    //--------------------------------------------------------------------------
    onEventP(key:string, values:any) {
        return REJECT('undefined event')
    }
    //--------------------------------------------------------------------------
    allButtons          :any    = {}
    btns                :any    = { bk:[], tl:[], tr:[], bl:[], br:[], ok:[] }
    noBtns              :any    = { bk:[], tl:[], tr:[], bl:[], br:[], ok:[] }
    buttonRow                   = 'b'

    buttonBack  (func:any, lab:string='') {
        if (this.glob.isStacked)          { this.makeButton(func,'back'              ,lab ,'bk') }
    }

    buttonPrev  (func:any, lab:string='') { this.makeButton(func,'prev'              ,'<<','tl') }
    buttonNext  (func:any, lab:string='') { this.makeButton(func,'next'              ,'>>','tr') }

    buttonCancel(func:any, lab:string='') { this.makeButton(func,'cancel'            ,lab , 'l') }

    buttonAdd   (func:any, lab:string='') { this.makeButton(func,'add'               ,lab , 'r') }
    buttonCopy  (func:any, lab:string='') { this.makeButton(func,'copy'              ,lab , 'r') }
    buttonDelete(func:any, lab:string='') { this.makeButton(func,'delete'            ,lab , 'r') }
    buttonEdit  (func:any, lab:string='') { this.makeButton(func,'edit'              ,lab , 'r') }
    buttonNew   (func:any, lab:string='') { this.makeButton(func,'new'               ,lab , 'r') }
    buttonOk    (func:any, lab:string='') { this.makeButton(func,'ok'                ,lab , 'r') }
    buttonPrint (func:any, lab:string='') { this.makeButton(func,'print'             ,lab , 'r') }
    buttonSave  (func:any, lab:string='') { this.makeButton(func,'save'              ,lab , 'r') }

    buttonSelAll(func:any, lab:string='') { this.makeButton(func,'select all'        ,lab ,'tr') }
    buttonInvSel(func:any, lab:string='') { this.makeButton(func,'invert selection'  ,lab ,'tr') }

    buttonAddSel(func:any, lab:string='') { this.makeButton(func,'add selected'      ,lab ,'tr') }
    buttonDelSel(func:any, lab:string='') { this.makeButton(func,'delete selected'   ,lab ,'tr') }
    buttonRemSel(func:any, lab:string='') { this.makeButton(func,'remove selected'   ,lab ,'tr') }
    buttonRtnSel(func:any, lab:string='') { this.makeButton(func,'use selected'      ,lab ,'tr') }
    //--------------------------------------------------------------------------
    makeButton(func:any, key:string, lab:string='', pos:string='r') {
        if (!is.function(func)) { return }
        if (regexTest(pos, 'l', 'r')) {
            pos                 = this.buttonRow + pos
        } else if (!regexTest(pos, 'bk', 'tl', 'tr', 'bl', 'br')) {
            pos                 = this.buttonRow + 'r'
        }
        let btn         :any    =
            { key               : key
            , lab               : lab || key
            , cls               : 'btn'
            , off               : false
            , hide              : ''
            }

        Object.defineProperty(btn, 'isOff'   , { get:() => btn.off              })
        Object.defineProperty(btn, 'offClass', { get:() => btn.off ? 'off' : '' })
        btn.CLICK               = (form:any={}, event:any=null) => {
// this.log('btn.CLICK:', [func, key, lab, form, event])
            if (btn.off || btn.hide) {
                this.log(`button [${key}] is disabled or hidden`)
            } else {
                func.call(this, key, form && form.value)
                .then ((res) => null)
                .catch((err) => this.messageError(err) )
            }
            // stop bubbling (otherwise 'ok' & 'submit' will both fire)...
            return false
        }
        if (regexTest(key, 'ok')) {
            pos                 = 'ok'
            btn.ok              = true
            btn.cls             = 'btn submit'
        } else if (regexTest(key, 'back', 'delete')) {
            btn.cls             = 'btn ' + key
        }
        this.allButtons[key]    = btn
        this.btns[pos].push(btn)
    }
    //--------------------------------------------------------------------------
    eventButtons(events:any={}) {
        events                  = is.object(events) ? events : {}
        for (let key in events) {
            this.makeButton(this.onEventP, key, events[key], 'tr')
        }
    }
    //--------------------------------------------------------------------------
    getButtons(key:string) {
// this.log('getButtons:', [ key ])
        let list
        if (key == 'ALL') {
            list                    = Object.keys(this.allButtons)
                                        .map((key:string) => this.allButtons[key])
        } else {
            list                    = [ this.allButtons[key] ]
        }
        if (list.length < 1 || list[0] === undefined) {
            return []
        } else {
            return list
        }
    }
    disableButton(key:string) {
        this.getButtons(key).forEach((btn:any) => {
            btn.off             = true
        })
    }
    enableButton(key:string) {
        this.getButtons(key).forEach((btn:any) => {
            btn.off             = false
        })
    }
    hideButton(key:string) {
        this.getButtons(key).forEach((btn:any) => btn.hide = 'hide' )
    }
    showButton(key:string) {
        this.getButtons(key).forEach((btn:any) => btn.hide = ''     )
    }
    //--------------------------------------------------------------------------
    openAlertP(name:string, ctx:any={}, onAlertClose:any=null) {
        let hold_context        = this.clone(this.ctx)
        if (this.stab) {
            ctx.stab            = this.stab
            if (this.ctx.header) {
                ctx.header      = this.ctx.header
            }
        }
        return this.glob.openAlertP(name, ctx)
        .then((res:any) => {
// this.log('openAlert.then hold_context:', hold_context)
            this.ctx            = hold_context
            return onAlertClose
                ? onAlertClose.call(this, res)
                : RESOLVE(res)
        })
        .catch((err:any) => {
// this.log('openAlert.catch hold_context:', hold_context)
            // modal load error OR modal cancelled...
            this.messageError(err)
            this.ctx            = hold_context
            return REJECT(err)
        })
    }
    //--------------------------------------------------------------------------
//     callCompName        :string = ''
    callComponent(name:string='', ctx:any={}) {
//         name                    = name || this.callCompName
        if (name) {
            // trust the passed value...
        } else if (/Lst$/.test(this.name)) {
            name                = this.name.replace(/Lst$/,'')
        } else if (this.name == 'FlexList') {
            name                = 'FlexItem'
        } else {
            this.messageError('Item component is only available in "*Lst" components')
            return RESOLVE()
        }
        let comp                = this.glob.components[name]
        if (!comp) {
            this.messageError(`Component is not available: '${comp}'`)
            return RESOLVE()
        }
        if (this.glob.compIsModal(comp)) {
// this.log('[callComponent.callModal] ...')
            return this.callModalP(name, ctx)
        }
// this.log('[callComponent.pushCompP] ...')
        return this.glob.pushCompP(name, ctx)
        .then((res:any) => {
// this.log('[callComponent.pushCompP].res:', res)
            this.messageClear()
            return RESOLVE()
        })
        .catch((err:any) => {
// this.log('[callComponent.pushCompP].err:', err)
            this.messageError('call error: ' + err.toString())
            return RESOLVE()
        })
    }
    //--------------------------------------------------------------------------
    callModalP(name:string, ctx:any={}) {
        let hold_context        = this.clone(this.ctx)
        return this.glob.callModalP(name, { ...ctx, stab:this.ctx.stab })
        .then((res:any) => {
            this.ctx            = hold_context
            return RESOLVE(res)
        })
        .catch((err:any) => {
            // modal load error OR modal cancelled...
            this.messageError(err)
            this.ctx            = hold_context
            return REJECT(err)
        })
    }
    //--------------------------------------------------------------------------
    popClick() {
        if (!this.glob.isBusy)  { this.onBackP() }
        return false
    }
    //--------------------------------------------------------------------------
    unsavedData() {
        return false
    }
    //--------------------------------------------------------------------------
    applyValuesP(item:any, values:any, force:boolean=false) {
// !!NOTE: may not be required...
        for (let key in values) {
            if (!values[key] || force) {
                item[key]       = null
            }
        }
// !!NOTE: this bit is required...
        return new Promise((resolve:any, reject:any) => {
            setTimeout(() => {
                Object.assign(item, values)
                return resolve(values)
            })
        })
// !!NOTE: this doesn't work...
//         setTimeout(() => { Object.assign(item, values) })
//         return RESOLVE(values)
    }
    //--------------------------------------------------------------------------
    async setFocusP(id:any=null) {
        if (this.glob.isBusy && this.name != 'BusyTag') {
// this.log('[base.setFocusP] clear message')
            this.messageClear()
        }
        let selector            = id ? '#' + id : 'input,select,textarea'
// this.log('[base.setFocusP]:', id)
        let promise
        let attempt             = (attempts:number) => {
// this.log('[base.setFocusP] attempt:', attempts)
            let $test           = jQuery(this.name).find(selector)
                                    .not('[disabled],[type=hidden],[type=submit]')
                                    .first()
            if ($test.length) {
// this.log('[base.setFocusP] $test:', $test)
                if ($test.not('.key_catcher').length > 0 || attempts > 3) {
                    $test.eq(0).focus().select()
                    return promise.resolve(null)
                }
            }
            if (attempts > 4) {
                this.log('setFocusP(%s) did not find anything on which to focus', id)
                return promise.resolve(null)
            }
            setTimeout(attempt, 100, attempts + 1)
        }
        setTimeout(attempt, 200, 0)
        return new Promise((resolve, reject) => {
            promise             = { resolve, reject }
        })
    }
    //--------------------------------------------------------------------------
    refocusP() {
        jQuery(document.activeElement).blur().focus().select()
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    message             :any    = { text:'', cls:'' }
    useMessage          :any    = null
    //--------------------------------------------------------------------------
    messageBusy  ()               { this.msg_('processing...'    ,''     ,'busy') }
    messageClear ()               { this.msg_(''                 ,''     ,'idle') }
    messageDone  (text:string='') { this.msg_(text               ,'done' ,'idle') }
    messageError (text:string='') { this.msg_(text               ,'error','idle') }
    messageFetch ()               { this.msg_('loading...'       ,''     ,'busy') }
    messageLock  (text:string='') { this.msg_(text               ,''     ,'busy') }
    messageNoLoad()               { this.msg_('data not loaded'  ,''     ,'idle') }
    messageSaved ()               { this.msg_('update successful','done' ,'idle') }
    messageText  (text:string='') { this.msg_(text               ,''     , null ) }
    messageUnlock(text:string='') { this.msg_(text               ,''     ,'idle') }
    messageValid ()               { this.msg_('data is valid'    ,'done' ,'idle') }
    //--------------------------------------------------------------------------
    msg_timer_          :any    = null
    msg_(text:string, cls:string, busy:any) {
        if (!text) {
            [ text, cls, busy ] = [ '', '', 'idle' ]
        }
        let message             = this.message
        message.text            = text.toString()
        message.cls             = regexTest(cls, '', 'done', 'error') ? cls : ''

        switch(busy) {
            case 'busy': this.glob.openBusy (`msg_(text='${text}', cls='${cls}')`); break
            case 'idle': this.glob.closeBusy(`msg_(text='${text}', cls='${cls}')`); break
        }

        this.msg_timer_         = null
        let showprogress        = () => {
            this.msg_timer_     = clearTimeout(this.msg_timer_)
            try {
                if (!/\.{3}$/.test(message.text)) {
                    return
                }
                if (message.text.length < 100) {
                    message.text
                               += '.'
                } else {
                    message.text
                                = message.text.replace(/\.+$/, '...')
                }
                this.msg_timer_ = setTimeout(showprogress, 1000)
            } catch(err) {
                this.log('showprogress:', err)
            }
        }
        if (/\.{3}$/.test(message.text) && !this.msg_timer_) {
            this.msg_timer_     = setTimeout(showprogress, 1000)
        }
    }
    //--------------------------------------------------------------------------
    onActionConfirmP(action:string, count:number) {
        let txt                 = count > 1 ? `of ${count} items` : ''
        if (confirm(`Click "ok" to confirm ${action}${txt} or "cancel" to return.`)) {
            return RESOLVE()
        } else {
            return REJECT(`${action} cancelled`)
        }
    }
    //--------------------------------------------------------------------------
    onCancelP() {
// this.log('onCancelP')
        return RESOLVE()
    }
    cancelFormatting_           = false
    //--------------------------------------------------------------------------
//     private saveToFileSystem(response) {
//         let cdHeader            = response.headers.get('Content-Disposition')
//         let parts               = cdHeader.split(';')
//         let filename            = parts[1].split('=')[1]
//         let blob                = new Blob([response._body], { type: 'text/plain' })
//         saveAs(blob, filename)
//     }
    //--------------------------------------------------------------------------
    downloadP(data:any, options:any={}) {
        // data = { <document options> }
        let name                = options.name  || 'document'
        let opts                =
            { task              : 'get_document'
            , submittedText     : `${name} request submitted`
            , errorText         : `${name} creation error`
            , emptyText         : `${name} result is empty`
            , savedText         : `${name} saved as`
            , printErrorText    : `${name} print error`
            , printedText       : `${name} printed to`
            }
        for (let key in opts) {
            if (options[key]) {
                opts[key]       = options[key]
            }
        }
        setTimeout(() => this.messageClear(), 5000)
        this.messageDone(opts.submittedText)
        return this.serviceEmitP(opts.task, data)
        .then((res:any) => {
this.log('service RES:', res)
            if (is.empty(res)) {
                window.alert(opts.emptyText)
            } else if (this.ctx.to_printer) {
                if (res.printer) {
                    window.alert(`${opts.printedText} ${res.printer.name}`)
                } else {
                    window.alert(`${opts.printErrorText} ${res.err || res}`)
                }
            } else {
                this.saveFile(res.data, res.name, res.mimetype)
                window.alert(`${opts.savedText} ${res.name}`)
            }
            return RESOLVE()
        })
        .catch((err:any) => {
            this.log('downloadP:', err)
            let msg             = err.message || err.toString()
            window.alert(`${opts.errorText}: ${msg}`)
            return REJECT(err)
        })
    }
    //--------------------------------------------------------------------------
    close() {
// this.log('close...')
        setTimeout(() => this.glob.closeModal(), 50)
    }
    //--------------------------------------------------------------------------
    busyServiceEmitP(task:string, args:any) {
        this.messageBusy()
        return this.serviceEmitP(task, args)
        .then((res:any) => {
            if (this.glob.isBusy) {
                this.messageClear()
            }
            return RESOLVE(res)
        })
    }
    //--------------------------------------------------------------------------
    serviceEmitP(task:string, args:any) {
        return this.service.emitP(task, args)
    }
    //--------------------------------------------------------------------------
    subscribe(name:string, func:any) {
        return this.service.subscribe(name, func)
    }
    publishP(name:string, values:any) {
        return this.service.publishP(name, values)
    }
    unsubscribe(name:string, sub:any) {
        return this.service.unsubscribe(name, sub)
    }
    //--------------------------------------------------------------------------
    scrollView($container, $row) {
        let cTop                = $container.scrollTop()
        let cHeight             = $container.height()
        let rTop                = $row.offset().top
        let rBot                = rTop + $row.height()
// this.log(`scrollView: cTop:${cTop}, cHeight:${cHeight}, rTop:${rTop}, rBot:${rBot}`)
        let topMin              = cHeight * 0.5
        let botMax              = cHeight * 1.3
        if (rBot > botMax)      { $container.scrollTop(cTop + (cHeight * 0.6)) }
        if (rTop < topMin)      { $container.scrollTop(cTop - (cHeight * 0.6)) }
    }
    //--------------------------------------------------------------------------
    copyToCB(key:string) {
        try {
            let value           = document.getElementById(key).value
            let tmp             = document.createElement('textarea')
            tmp.value           = value
            tmp.style.position  = 'fixed'
            tmp.style.top       = '-1000px'
            tmp.style.left      = '-1000px'
            document.body.appendChild(tmp)
            tmp.select()
            tmp.setSelectionRange(0, value.length)
            if (document.execCommand('copy')) {
                this.log('[copy-to-clipboard] successful')
            }
        } catch(err) {
            this.log('[copy-to-clipboard]', err)
        }
    }
    //--------------------------------------------------------------------------
    onDump() {
        let data                = []
        let format              = (label:string, value:any) => {
            if (value === undefined) {
                return
            }
            if (!is.array(value)) {
                return format(label, [value])
            }
            let list            = value
            if (data.length) {
                data.push('')
            }
            if (list.length < 1) {
                data.push(label + ': <empty>' )
                return
            }
            data.push(label + ':')
            let keys, item, val
            try {
                keys            = Object.keys(list[0])
            } catch(err) {
                this.log(err)
                keys            = ['non-object']
                list            = [ list ]
            }
            data.push(keys.join('\t'))
            for (let idx in list) {
                let obj         = list[idx]
                data.push(
                    keys.map((key:string) => {
                        let val = obj[key]
                        if (is.string(val)) {
                            return val.replace(/[\t\n\r]+ */g, ', ')
                        } else {
                            return stringify(val)
                        }
                    }).join('\t')
                )
            }
        }
        format('Context'    , this.ctx              )
        format('Document'   , this['cache']['vrg']  )
        format('Item'       , this['item']          )
        format('List'       , this['list']          )
        this.saveFile(data.join('\n'), 'data dump.tsv', 'text/csv')
        // stop event propogating...
        return false
    }
    //--------------------------------------------------------------------------
    saveFile(data:any, name:string, mimetype='') {
        mimetype                = mimetype || 'text/plain;charset=utf-8'
        let file                = new File([data], name, {type: mimetype})
        saveAs(file)
    }
    //------------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Injectable()
export class BaseService extends BaseClass implements OnInit, OnDestroy {
    //--------------------------------------------------------------------------
    constructor() {
        super()
        if (!this.module_name) {
            this.module_name    = this.name.replace(/Service$/,'')
        }
        this.module_name        = this.lowerFirst(this.module_name)
    }
    //--------------------------------------------------------------------------
    module_name         :string = ''
    //--------------------------------------------------------------------------
    ngOnInit() {
        this.onInitP()
        .catch((err:any) => {
            this.log('onInit error', err)
        })
    }
    onInitP() {
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    ngOnDestroy                 () {
// this.log('ngOnDestroy')
        this.deleteEventObjs()
        this.onDestroy()
    }
    onDestroy                   () { }
    //--------------------------------------------------------------------------
    emitP(task:string, args:any) {
this.log('service.emitP defaults (dft):', this.dft)
        if (!/:/.test(task)) {
            task                = this.module_name + ':' + task
        }
        // passed values will override default (dft) values...
//         args                    = this.clone(this.dft, args)
        args                    = { ctx:this.ctx, ...this.dft, ...args }
this.log('service.emitP:', [task, args])
        return this.glob.serverAction(task, args)
    }
    //--------------------------------------------------------------------------
    private eventObjs:any    = {}
    getEventObj(name:string, create:boolean) {
        if (!this.eventObjs[name]) {
            if (!create) {
                return undefined
            }
            this.eventObjs[name]= { emitter         : new EventEmitter<any>(true)
                                  , subs            : []
                                  }
        }
        return this.eventObjs[name]
    }
    deleteEventObj(name:string) {
        let eventObj            = this.getEventObj(name, false)
        if (eventObj === undefined)
                                { return this.log(`eventObj [${name}] not found`) }
        eventObj.subs.forEach((sub:any) => sub.unsubscribe())
        eventObj.subs           = []
        delete eventObj.emitter
        delete this.eventObjs[name]
    }
    deleteEventObjs() {
        Object.keys(this.eventObjs).forEach(this.deleteEventObj)
    }
    subscribe(name:string, func:any) {
// this.log('subscribe:', name, func)
        let eventObj            = this.getEventObj(name, true)
        let sub                 = eventObj.emitter.asObservable().subscribe(func)
        eventObj.subs.push(sub)
        return sub
    }
    unsubscribe(name:string, sub:any) {
// this.log('unsubscribe:', name, sub)
        let eventObj            = this.eventObjs[name]
        if (eventObj === undefined)
                                { return this.log(`eventObj [${name}] not found`) }
        sub.unsubscribe()
        eventObj.subs           = eventObj.subs.filter((sub:any) => !sub.closed )
        if (eventObj.subs.length < 1) {
            this.deleteEventObj(name)
        }
    }
    publishP(name:string, values:any) {
        this.getEventObj(name, true).emitter.emit(values)
        return RESOLVE(values)
    }
    //--------------------------------------------------------------------------
    CTX                 :any    = {}
    get ctx()                   { return this.CTX                     }
    set ctx(value:any)          {        this.CTX = this.clone(value) }
    //--------------------------------------------------------------------------
    // default values sent in every server call
    DFT                 :any    = {}
    get dft()                   { return this.DFT                     }
    set dft(value:any)          {        this.DFT = this.clone(value) }
    //--------------------------------------------------------------------------
    cache               :any    = {}
    //--------------------------------------------------------------------------
    allTabs             :any    = []
    validTabs           :any    = []
    stabs               :any    = {}
    //--------------------------------------------------------------------------
    get stab()                  { return this.ctx.stab || '' }
    set stab(value:any) {
        let stab                = this.ctx.stab
        if (stab && (stab == value || this.glob.unsavedData()))
                                { return }
        this.ctx.stab           = value || ''
        this.glob.onResize()
    }
    //--------------------------------------------------------------------------
    initTabs(showAll:any, dflt='dtl') {
// this.log('initTabs:', showAll, dflt)
        if (showAll) {
            this.showValidTabs()
        } else {
            // show only <dflt> tab
            this.allTabs.forEach((tab:string) => {
                this.stabs[tab] = (tab == dflt)
            })
        }
        if (!this.stab)         { this.stab = dflt }
    }
    //--------------------------------------------------------------------------
    showValidTabs() {
// this.log('showValidTabs')
        this.allTabs.forEach((tab:string) => {
            this.stabs[tab]     = (this.validTabs.indexOf(tab) > -1)
        })
    }
    //--------------------------------------------------------------------------
    revealTab(tab:string) {
// this.log(`revealTab('${tab}')`)
        if (this.validTabs.indexOf(tab) > -1) {
            this.stabs[tab]     = true
            this.stab           = tab
            return true
        } else {
            return false
        }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
export class BaseModule extends BaseClass {}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
export class TabbedComp extends BaseComp {
    //--------------------------------------------------------------------------
    comp_type                   = 'tabbed'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
export const Base               =
{ Class                         : BaseClass
, Comp                          : BaseComp
, Module                        : BaseModule
, Service                       : BaseService
, Tabbed                        : TabbedComp
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------

