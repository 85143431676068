//------------------------------------------------------------------------------
import  { Div       as d} from './lib/div'        ; export const Div          = d

import  { Base      as b} from './lib/base'       ; export const Base         = b
import  { Controls  as c} from './lib/controls'   ; export const Controls     = c
import  { Item      as i} from './lib/item'       ; export const Item         = i
import  { List      as l} from './lib/list'       ; export const List         = l
import  { Mixed     as m} from './lib/mixed'      ; export const Mixed        = m
import  { Pst       as p} from './lib/pst'        ; export const Pst          = p
import  { Select    as s} from './lib/select'     ; export const Select       = s
//------------------------------------------------------------------------------
import  { CommonModule  } from '@angular/common'
import  { FormsModule   } from '@angular/forms'

export const Imports            = [ CommonModule, FormsModule, Controls.Module ]
export const BaseModule         = Base.Module
//------------------------------------------------------------------------------
