//------------------------------------------------------------------------------
import  { Injectable                                } from '@angular/core'
//------------------------------------------------------------------------------
import * as lib from '../library'
//------------------------------------------------------------------------------
export const Imports            = lib.Imports
export const BaseModule         = lib.Base.Module

export const ItemComp           = lib.Item.Comp
export const ItemDiv            = lib.Item.Div
export const ListComp           = lib.List.Comp
export const ListDiv            = lib.List.Div
export const SelectDiv          = lib.Select.Div
export const SelectModal        = lib.Select.Modal
export const TabbedComp         = lib.Base.Tabbed
//------------------------------------------------------------------------------
@Injectable()
export class GrpService extends lib.Base.Service {
    //--------------------------------------------------------------------------
    setHeader(values:any) {
        this.ctx.header         = this.glob.menu.title + ': '
                                + ((values && values.name) || 'new...')
    }
    //--------------------------------------------------------------------------
    allTabs             :any    = 'dtl prd cat'.split(/\W+/)
    validTabs           :any    = this.allTabs
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
export const GrpTemplate        = `
<div class='tabs container'>
  <div class='tabshead'>
    <h3 class='header floatl'>
      <button               class='btn back'
                            (click)='popClick()'
      >{{glob.backArrow}}</button>{{ctx.header}}</h3>
    <div class='u-cf'></div>
  </div>
  <div class='tabstabs'>
    <div                    (click)='stabSel("dtl")' class='{{stabCls("dtl")}}'>details      </div>
    <div *ngIf='stabs.prd'  (click)='stabSel("prd")' class='{{stabCls("prd")}}'>products     </div>
    <div *ngIf='stabs.cat'  (click)='stabSel("cat")' class='{{stabCls("cat")}}'>categories   </div>
  </div>
  <div class='tabsbody' [ngSwitch]='stab'>
    <GrpDtl    *ngSwitchDefault     ></GrpDtl   >
    <GrpPrdLst *ngSwitchCase='"prd"'></GrpPrdLst>
    <GrpCatLst *ngSwitchCase='"cat"'></GrpCatLst>
  </div>
</div>
`
//------------------------------------------------------------------------------
