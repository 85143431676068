//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Mixed                                     } from './mixed'
//------------------------------------------------------------------------------
export class SelectModal extends Mixed.Base {
    //--------------------------------------------------------------------------
    comp_type                   = 'modal'
    //--------------------------------------------------------------------------
    selectorDefaults    :any    = { selector: '' }
    itemDefn            :any    =
        { selector      : {opt:'selector'           , wide:12}
        }
    listDefn            :any    = { }
    //--------------------------------------------------------------------------
    onInitSelect() {
        this.defineItem         (this.itemDefn      )
        this.item               = { selector:this.ctx.selector }

        this.defineList         (this.listDefn, true)
        this.formSubmit         (this.onSearchP     )
        this.buttonCancel       (this.onCancelP     )

        if (this.ctx.multi) {
            this.buttonSelAll   (this.onSelectAllP  )
            this.buttonInvSel   (this.onInvertSelP  )
            this.buttonRtnSel   (this.onReturnSelP  )
            this.listClick      (this.onSelectItmP  )
            this.listDclick     (this.onReturnItmP  )
        } else {
            this.listClick      (this.onReturnItmP  )
            this.listDclick     (this.onReturnItmP  )
        }
    }
    //--------------------------------------------------------------------------
    readP(criteria:any={}) {
        if (this.onRead_task == 'DEFAULTS') {
            Object.assign(this.item, this.selectorDefaults)
            return this.readDoneP()
        } else {
            return super.readP(criteria)
        }
    }
    //--------------------------------------------------------------------------
    onReturnSelP(key:string, values:any) {
        return this.getSelectedItemsP()
        .then((res:any) => this.modalRESOLVE(res) )
    }
    //--------------------------------------------------------------------------
    onReturnItmP(idx:number) {
        return this.modalRESOLVE(this.list[idx])
    }
    //--------------------------------------------------------------------------
    onReturnResP(res:any) {
        return this.modalRESOLVE(res)
    }
    //--------------------------------------------------------------------------
    onSearchP(key:string, values:any) {
        return this.busyServiceEmitP('search', values)
        .then((res:any) => this.loadWithP(res) )
    }
    //--------------------------------------------------------------------------
    onCancelP() {
this.log('SelectModal.onCancelP')
        return this.modalREJECT()
    }
    //--------------------------------------------------------------------------
    loadDoneP():any {
        this.cache[this.name]   = this.list
        if (this.colours) {
            this.list.forEach((item, idx) => this.setItemColour(item, idx))
        }
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
export const Select             =
{ Modal                         : SelectModal
, Div                           : Mixed.Div
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
