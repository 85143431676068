//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , LndService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'LndRsrLst', template:ListDiv})
export class LndRsrLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:LndService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { lnd_name      : {lab:'country'            , wide:10}
        , rsr_name      : {lab:'non-export from location'
                                                    , wide:10}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'rsr_load'
}
//------------------------------------------------------------------------------
@Component({selector:'LndRsr', template:ItemDiv})
export class LndRsr extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:LndService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , lnd_id        : {lab:'country'            , wide:12, cod:'lnd'    , copyCheck:true}
        , rsr_id        : {lab:'location'           , wide:12, cod:'rsr'}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'rsr_read'
    onSave_task                 = 'rsr_save'
    onDelete_task               = 'rsr_dele'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
