//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, is, Format, regexTest
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { CommonModule                              } from '@angular/common'
import  { ControlValueAccessor                      } from '@angular/forms'
// import  { EditorModule                              } from '@tinymce/tinymce-angular'
import  { UntypedFormControl                        } from '@angular/forms'
import  { FormsModule                               } from '@angular/forms'
import  { forwardRef                                } from '@angular/core'
import  { Input                                     } from '@angular/core'
import  { NgModule                                  } from '@angular/core'
import  { NG_VALIDATORS                             } from '@angular/forms'
import  { NG_VALUE_ACCESSOR                         } from '@angular/forms'
import  { OnChanges                                 } from '@angular/core'
import  { OnInit                                    } from '@angular/core'
import  { Validator                                 } from '@angular/forms'
//------------------------------------------------------------------------------
import  { Base                                      } from './base'
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
let index_                      = 0
function nextid() {
    return `id-$index_++}`
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
// BaseControl
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
@Component({selector:'BaseControl', template:''})
export class BaseControl extends Base.Class implements ControlValueAccessor, OnChanges, OnInit {
    //--------------------------------------------------------------------------
    @Input() def        :any    = {}
    @Input() name       :string = ''
    @Input() datum      :any    = ''
    @Input() rdo        :boolean= false
    @Input() off        :boolean= false
    @Input() rows       :number = 12
    //--------------------------------------------------------------------------
    header              :string = ''
    lab                 :string = ''
    cls                 :string = ''
    opt                 :boolean= false
    debug               :boolean= false
    //--------------------------------------------------------------------------
    ngOnInit() {
        this.lab                = this.def.lab      || ''
        this.cls                = this.def.cls      || ''
        this.opt                = !!this.def.opt
        this.debug              = !!this.def.debug
        if (this.cls)           { this.class_base = this.class_base.concat(this.cls) }
        return this.onInitP()
        .catch((err:any) => {
            this.log('onInitP error:', err)
        })
    }
    //--------------------------------------------------------------------------
    onInitP()                   { return RESOLVE() }
    //--------------------------------------------------------------------------
    onChangesKeys       :any    = []
    ngOnChanges(inputs:any) {
        if (inputs.datum    !== undefined) { this.value     = this.toHuman(inputs.datum.currentValue) }
        if (inputs.off      !== undefined) { this.off       = inputs.off.currentValue }
        if (inputs.opt      !== undefined) { this.opt       = inputs.opt.currentValue }
        if (inputs.rdo      !== undefined) { this.rdo       = inputs.rdo.currentValue }
    }
    //--------------------------------------------------------------------------
    class_base          :any[]  = []
    setClass(val='') {
        setTimeout(() => this.cls = this.class_base.concat(val).join(' '))
    }
    //--------------------------------------------------------------------------
    toHuman                     = (val:any) => val === undefined ? '' : val
    toMachine                   = (val:any) => val
    human               :any    = undefined
    //==========================================================================
    // ControlValueAccessor requirements...
    //--------------------------------------------------------------------------
    registerOnChange (fn:any)   { this.onChangeFunction  = fn }
    onChangeFunction    :any    = (val:any) => {}
    onChange            :any    = (val:any) => {
        try             { this.onChangeFunction(val)   }
        catch(err)      { this.log('[onChange]' , err) }
    }
    //--------------------------------------------------------------------------
    registerOnTouched(fn:any)   { this.onTouchedFunction = fn }
    onTouchedFunction   :any    = (val:any) => {}
    onTouched           :any    = (val:any) => {
        try             { this.onTouchedFunction(val)  }
        catch(err)      { this.log('[onTouched]', err) }
    }
    //--------------------------------------------------------------------------
    writeValue(val:any)         { this.value = this.human }
    //==========================================================================
    get value()                 { return this.human }
    set value(val:any) {
        // expects 'val' to be the human formatted value
        this.human              = val
        this.datum              = this.toMachine(val)
        this.onChange (this.datum)
        this.onTouched(this.datum)
    }
    //--------------------------------------------------------------------------
    get isOff       () { return this.off || this.def.off            }
    get offClass    () { return this.isOff ? 'off' : ''             }
    get isDisabled  () { return Boolean( this.isOff ||  this.rdo)   }
    get isRequired  () { return Boolean(!this.rdo   && !this.opt)   }
    get isReadonly  () { return Boolean( this.rdo                )  }
//     get numOrText   () { return this.isReadonly ? 'text' : 'number' }
    get numOrText   () { return 'number' }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
// ControlsGroup
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
@Component({selector:'ControlsGroup', template:''})
export class ControlsGroup extends Base.Class implements ControlValueAccessor, OnChanges, OnInit {
    //--------------------------------------------------------------------------
    @Input() header     :string = ''
    name                :string = ''
    lab                 :string = ''
    datum               :any    = ''
    cls                 :string = ''
    opt                 :boolean= false
    rdo                 :boolean= false
    @Input() off        :boolean= false
    @Input() debug      :boolean= false
    //--------------------------------------------------------------------------
    ngOnInit() {
        if (this.cls)           { this.class_base = this.class_base.concat(this.cls) }
        return this.onInitP()
        .catch((err:any) => {
            this.log('onInitP error:', err)
        })
    }
    //--------------------------------------------------------------------------
    onInitP()                   { return RESOLVE() }
    //--------------------------------------------------------------------------
    onChangesKeys       :any    = []
    ngOnChanges(inputs:any) {
        let test
        this.onChangesKeys.forEach((key:string) => {
            test                = inputs[key]
            if (test && test.currentValue != test.previousValue) {
                this[key]       = test.currentValue
            }
        })
    }
    //--------------------------------------------------------------------------
    class_base          :any[]  = []
    setClass(val='') {
        setTimeout(() => this.cls = this.class_base.concat(val).join(' '))
    }
    //--------------------------------------------------------------------------
    toHuman                     = (val:any) => val === undefined ? {} : val
    toMachine                   = (val:any) => val
    human               :any    = {}
    //--------------------------------------------------------------------------
    // ControlValueAccessor requirements...
    //--------------------------------------------------------------------------
    registerOnChange (fn:any)   { this.onChangeFunction  = fn }
    onChangeFunction    :any    = (val:any) => {}
    onChange            :any    = (val:any) => {
        try             { this.onChangeFunction(val)   }
        catch(err)      { this.log('[onChange]' , err) }
    }
    //--------------------------------------------------------------------------
    registerOnTouched(fn:any)   { this.onTouchedFunction = fn }
    onTouchedFunction   :any    = (val:any) => {}
    onTouched           :any    = (val:any) => {
        try             { this.onTouchedFunction(val)  }
        catch(err)      { this.log('[onTouched]', err) }
    }
    //--------------------------------------------------------------------------
    writeValue(val:any)         { this.value = this.human }
    //--------------------------------------------------------------------------
    get value()                 { return this.human }
    set value(val:any) {
        // expects 'val' to be the human formatted value
        this.human              = val
        this.datum              = this.toMachine(val)
        this.onChange (this.datum)
        this.onTouched(this.datum)
    }
    //--------------------------------------------------------------------------
    get isOff       () { return this.off                            }
    get offClass    () { return this.isOff ? 'off' : ''             }
//     get isDisabled  () { return Boolean( this.isOff ||  this.rdo)   }
//     get isRequired  () { return Boolean(!this.rdo   && !this.opt)   }
//     get isReadonly  () { return Boolean( this.rdo                )  }
//     get numOrText   () { return this.isReadonly ? 'text' : 'number' }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
// item controls group
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
@Component({selector:'ItemControls', templateUrl:'./templates/itemForm.html'
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:ItemControls, multi:true }
      ]
    }
)
export class ItemControls extends ControlsGroup {
    //--------------------------------------------------------------------------
    @Input() message    :any    = {}
    @Input() iDef       :any    = {}
    @Input() btns       :any    = {}
    @Input() item       :any    = {}
    @Input() prev       :any    = {}
    @Input() next       :any    = {}

    debug                       = true
    //--------------------------------------------------------------------------
    // for the benefit of the templates...
    get iD()                    { return this.iDef }
    //--------------------------------------------------------------------------
    onChangesKeys       :any    = ['message','item','off']
    //--------------------------------------------------------------------------
    onSubmit(form:any) {
this.log('ItemControls.onSubmit:', form)
        if (this.iDef.onSUBMIT) {
            this.iDef.onSUBMIT('submit', form)
        } else {
            this.log('no onSUBMIT function defined')
        }
        return false
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
// list controls group
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
@Component({selector:'ListControls', templateUrl:'./templates/listForm.html'
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:ListControls, multi:true }
      ]
    }
)
export class ListControls extends ControlsGroup {
    //--------------------------------------------------------------------------
    @Input() message    :any    = {}
    @Input() lDef       :any    = {}
    @Input() btns       :any    = {}
    @Input() list       :any    = []
    @Input() labelsOnly :boolean= false
    @Input() criteria   :any    = null
    @Input() isMixed    :boolean= false
    //--------------------------------------------------------------------------
    // for the benefit of the templates...
    get lD() { return this.lDef }
    //--------------------------------------------------------------------------
    onChangesKeys       :any    = ['message','list','criteria','off']
    //--------------------------------------------------------------------------
    onCriteria(criteria:any) {
// this.log(`onCriteria(${criteria})`)
        if (this.lDef.onCRITERIA) {
            this.lDef.onCRITERIA(criteria)
        }
        return false
    }
    //--------------------------------------------------------------------------
    dclickIdx           :any    = null
    dclickKey           :any    = null
    dclickTimer         :any    = null
    dclickTimeout       :any    = this.glob.config.doubleclick_timer
    lCLICK(idx:number, key:string, evt) {
// this.log(`lCLICK(${idx}, '${key}')`, evt)
        if (this.dclickTimer) {
            this.dclickTimer    = clearTimeout(this.dclickTimer)
            if (this.dclickKey == key && this.dclickIdx == idx) {
                this.dclickIdx  = null
                this.dclickKey  = null
                this.lDCLICK(idx, key, evt)
                return false
            }
        }
        this.dclickKey          = key
        this.dclickIdx          = idx
        let execute             = () => {
            this.dclickTimer    = null
            if (this.lDef[key].cellCLICK ) {
                this.lDef[key].cellCLICK(idx, key, evt)
            }
            if (this.lDef.rowCLICK) {
                this.lDef.rowCLICK(idx, key, evt)
            }
        }
        this.dclickTimer        = setTimeout(execute, this.dclickTimeout)
        return false
    }
    //--------------------------------------------------------------------------
    lDCLICK(idx:number, key:string, evt) {
// this.log(`lDCLICK(${idx}, '${key}')`, evt)
        if      (this.lDef[key].cellDCLICK) { this.lDef[key].cellDCLICK(idx, key, evt) }
        else if (this.lDef.rowDCLICK      ) { this.lDef.rowDCLICK      (idx, key, evt) }
        return false
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
@Component({selector:'InputControl', template:''})
export class InputControl extends BaseControl implements Validator {
    //--------------------------------------------------------------------------
    @Input() id         :any    = null
    //--------------------------------------------------------------------------
    onInitP() {
        if (!this.id)           {
            this.id             = this.name
        }
        if (!this.def.onRETURN) { return RESOLVE() }
        let listener            = (event:any) => {
// this.log('listener event:', event)
            if (event.keyCode == 13 || (event.originalEvent?.keyCode == 13)) {
                this.def.onRETURN(this.name, this.datum)
                return false
            } else {
                return null
            }
        }
        let initialise          = () => {
            this.$(`#${this.id}`).on('keypress', listener)
        }
        setTimeout(initialise, 300)
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    validate(control:UntypedFormControl) {
//         if (this.debug) {
//             this.log(`[${this.name}] validate: ${control.value}:${is.type(control.value)}`)
//             this.log(`[${this.name}] ... human:${this.human}, datum:${this.datum}, required:${this.isRequired}`)
//         }
        if ((this.human      &&  this.datum === undefined)
         || (this.isRequired && !this.datum)
           ) {
            this.setClass('ng-invalid')
            return { validationError: { given:this.human } }
        } else {
            this.setClass('')
            return null
        }
    }
    //--------------------------------------------------------------------------
    onBlur() {
        if (this.def.onBLUR) {
            this.def.onBLUR(this.name, this.datum)
        }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
@Component({selector:'ListControl', template:''})
export class ListControl extends BaseControl implements Validator {
    //--------------------------------------------------------------------------
    class_base          :any[]  = ['crit']
    //--------------------------------------------------------------------------
    validate(control:UntypedFormControl) {
        let val                 = control.value
        if (this.human && this.datum === undefined) {
            this.setClass('ng-invalid')
            return { validationError: { given:this.human } }
        } else {
            this.setClass('')
            return null
        }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
// boolean (checkbox)
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
@Component({selector:'BooleanIC', template:`
<span *ngIf='lab'><label for='{{id}}'>{{lab}}</label><br/></span>
<input    [(ngModel)]           = 'value' name='{{name}}' id='{{id}}'
          type                  = 'checkbox'
          [class]               = 'cls'
          [disabled]            = 'isDisabled'
          [readonly]            = 'isReadonly'
          (blur)                = 'onBlur()'
>
<div *ngIf='debug'>name:{{name}}, human:{{human}}, datum:{{datum}}, cls:{{cls}}, off:{{isOff}}</div>
`
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:BooleanIC, multi:true }
      , { provide:NG_VALIDATORS    , useExisting:BooleanIC, multi:true }
      ]
    }
)
export class BooleanIC extends InputControl {
    //--------------------------------------------------------------------------
    ngOnInit() {
        super.ngOnInit()
        this.opt                = true
    }
    //--------------------------------------------------------------------------
    toHuman                     = (val:any) => (val && val != 'false')
    toMachine                   = (val:any) => Boolean(val)
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'BooleanLC', template:`
<input    [(ngModel)]           = 'value' name='{{name}}'
          type                  = 'checkbox'
          [class]               = 'cls'
          [disabled]            = 'isDisabled'
          [readonly]            = 'isReadonly'
>
<div *ngIf='debug'>name:{{name}}, human:{{human}}, datum:{{datum}}, cls:{{cls}}, off:{{isOff}}</div>
`
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:BooleanLC, multi:true }
      , { provide:NG_VALIDATORS    , useExisting:BooleanLC, multi:true }
      ]
    }
)
export class BooleanLC extends ListControl {
    //--------------------------------------------------------------------------
    ngOnInit() {
        super.ngOnInit()
        this.opt                = true
    }
    //--------------------------------------------------------------------------
    toHuman                     = (val:any) => (val && val != 'false')
    toMachine                   = (val:any) => val == '' ? null : Boolean(val)
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
// cod (select)
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
@Component({selector:'CodIC', template:`
<label *ngIf='lab' for='{{id}}'>{{lab}}</label>
<select   [(ngModel)]           = 'value' name='{{name}}' id='{{id}}'
          type                  = 'text'
          [class]               = 'cls'
          [disabled]            = 'isDisabled'
          (blur)                = 'onBlur()'
>
<option   *ngFor='let opt of opts'
          value                 = '{{opt.key}}'
          [disabled]            = 'opt.key === null'
          [selected]            = 'opt.key == value'
          >{{opt.value}}</option>
<optgroup *ngFor='let grp of grps' label='{{grp.group}}'>
  <option *ngFor='let opt of grp.opts'
          value                 = '{{opt.key}}'
          [disabled]            = 'opt.key === null'
          [selected]            = 'opt.key == value'
          >{{opt.value}}</option>
</optgroup>
</select>
<div *ngIf='debug'>name:{{name}}, human:{{human}}, datum:{{datum}}, cls:{{cls}}, off:{{isOff}}, cod:{{cod}}, opts:{{opts|json}}</div>
`
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:CodIC, multi:true }
      , { provide:NG_VALIDATORS    , useExisting:CodIC, multi:true }
      ]
    }
)
export class CodIC extends InputControl {
    //--------------------------------------------------------------------------
    toHuman                     = Format.cod2human
    toMachine                   = Format.human2cod
    //--------------------------------------------------------------------------
    opts                :any    = []
    grps                :any    = []
    @Input() cod        :string = ''
//     @Input() opt        :boolean= false

    cod_loaded          :string = ''
    //--------------------------------------------------------------------------
    onChangesKeys       :any    = []
    ngOnChanges(inputs:any) {
        super.ngOnChanges(inputs)
        if (inputs.cod !== undefined) {
            this.initOptsP(inputs.cod.currentValue)
        }
    }
    //--------------------------------------------------------------------------
    onInitP() {
        return super.onInitP()
        .then(() => this.initOptsP(this.cod) )
    }
    //--------------------------------------------------------------------------
    initOptsP(cod:string) {
        if (cod == this.cod_loaded) {
            return RESOLVE()
        }
        this.cod_loaded         = this.cod = cod
        let bits                = /^(\w+)(:(.+))?$/.exec(cod)
        return this.glob.serverAction('lookup:' + bits[1], { filter:bits[3] })
        .then((res:any=[]) => {
            if (is.object(res[0]) && res[0].group) {
                // result is a list of groups...
                this.opts       = this.set_opts([ ])
                this.grps       = res
            } else {
                // result is a list of options...
                this.opts       = this.set_opts(res)
                this.grps       = []
            }
            this.datum          = this.datum || 0
            this.onChange (this.datum)
            this.onTouched(this.datum)
            return RESOLVE()
        })
        .catch((err:any) => {
            this.log('lookup dropdown options error:', [err])
            return RESOLVE()
        })
    }
    //--------------------------------------------------------------------------
    set_opts(opts:any) {
        if (is.empty(opts)) {
            opts                = []
        }
        if (this.opt) {
            opts.unshift({ key:0 , value:'none'})
        } else if (opts.length == 1) {
            this.datum          = opts[0].key
        } else {
            opts.unshift({ key:'', value:'select...'})
        }
        return opts
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
// code (digital assets code)
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
@Component({selector:'CodeIC', template:`
<label *ngIf='lab' for='{{id}}'>{{lab}}</label>
<input    [(ngModel)]           = 'value' name='{{name}}' id='{{id}}'
          type                  = 'text'
          [class]               = 'cls'
          [disabled]            = 'isDisabled'
          [readonly]            = 'isReadonly'
          [required]            = 'isRequired'
          (blur)                = 'onBlur()'
>
<div *ngIf='debug'>name:{{name}}, human:{{human}}, datum:{{datum}}, cls:{{cls}}, off:{{isOff}}, required:{{isRequired}}</div>
`
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:CodeIC, multi:true }
      , { provide:NG_VALIDATORS    , useExisting:CodeIC, multi:true }
      ]
    }
)
export class CodeIC extends InputControl {
    //--------------------------------------------------------------------------
    toHuman                     = Format.code2human
    toMachine                   = Format.human2code
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
// date
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
@Component({selector:'DateIC', template:`
<label *ngIf='lab' for='{{id}}'>{{lab}}</label>
<input    [(ngModel)]           = 'value' name='{{name}}' id='{{id}}'
          type                  = 'text'
          [class]               = 'cls'
          [disabled]            = 'isDisabled'
          [readonly]            = 'isReadonly'
          [required]            = 'isRequired'
          (blur)                = 'onBlur()'
>
<div *ngIf='debug'>name:{{name}}, human:{{human}}, datum:{{datum}}, cls:{{cls}}, off:{{isOff}}, required:{{isRequired}}</div>
`
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:DateIC, multi:true }
      , { provide:NG_VALIDATORS    , useExisting:DateIC, multi:true }
      ]
    }
)
export class DateIC extends InputControl {
    //--------------------------------------------------------------------------
    toHuman                     = Format.date2human
    toMachine                   = Format.human2date
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'DateLC', template:`
<input    [(ngModel)]           = 'value' name='{{name}}'
          type                  = 'text'
          [class]               = 'cls'
          [disabled]            = 'isDisabled'
          [readonly]            = 'isReadonly'
>
<div *ngIf='debug'>name:{{name}}, human:{{human}}, datum:{{datum}}, cls:{{cls}}, off:{{isOff}}</div>
`
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:DateLC, multi:true }
      , { provide:NG_VALIDATORS    , useExisting:DateLC, multi:true }
      ]
    }
)
export class DateLC extends ListControl {
    //--------------------------------------------------------------------------
    toHuman                     = Format.date2human
    toMachine                   = Format.human2date
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
// datetime
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
@Component({selector:'DatetimeIC', template:`
<label *ngIf='lab' for='{{id}}'>{{lab}}</label>
<input    [(ngModel)]           = 'value' name='{{name}}' id='{{id}}'
          type                  = 'text'
          [class]               = 'cls'
          [disabled]            = 'isDisabled'
          [readonly]            = 'isReadonly'
          [required]            = 'isRequired'
          (blur)                = 'onBlur()'
>
<div *ngIf='debug'>name:{{name}}, human:{{human}}, datum:{{datum}}, cls:{{cls}}, off:{{isOff}}, required:{{isRequired}}</div>
`
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:DatetimeIC, multi:true }
      , { provide:NG_VALIDATORS    , useExisting:DatetimeIC, multi:true }
      ]
    }
)
export class DatetimeIC extends InputControl {
    //--------------------------------------------------------------------------
    toHuman                     = Format.datetime2human
    toMachine                   = Format.human2datetime
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'DatetimeLC', template:`
<input    [(ngModel)]           = 'value' name='{{name}}'
          type                  = 'text'
          [class]               = 'cls'
          [disabled]            = 'isDisabled'
          [readonly]            = 'isReadonly'
>
<div *ngIf='debug'>name:{{name}}, human:{{human}}, datum:{{datum}}, cls:{{cls}}, off:{{isOff}}</div>
`
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:DatetimeLC, multi:true }
      , { provide:NG_VALIDATORS    , useExisting:DatetimeLC, multi:true }
      ]
    }
)
export class DatetimeLC extends ListControl {
    //--------------------------------------------------------------------------
    toHuman                     = Format.datetime2human
    toMachine                   = Format.human2datetime
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
// decimal
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
@Component({selector:'DecimalIC', template:`
<label *ngIf='lab' for='{{id}}'>{{lab}}</label>
<input    [(ngModel)]           = 'value' name='{{name}}' id='{{id}}'
          type                  = '{{numOrText}}' step = '0.01'
          [class]               = 'cls'
          [disabled]            = 'isDisabled'
          [readonly]            = 'isReadonly'
          [required]            = 'isRequired'
          (blur)                = 'onBlur()'
>
<div *ngIf='debug'>name:{{name}}, human:{{human}}, datum:{{datum}}, cls:{{cls}}, off:{{isOff}}, required:{{isRequired}}</div>
`
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:DecimalIC, multi:true }
      , { provide:NG_VALIDATORS    , useExisting:DecimalIC, multi:true }
      ]
    }
)
export class DecimalIC extends InputControl {
    //--------------------------------------------------------------------------
    toHuman                     = Format.decimal2human
    toMachine                   = Format.human2decimal
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'DecimalLC', template:`
<input    [(ngModel)]           = 'value' name='{{name}}'
          type                  = '{{numOrText}}' step = '1'
          [class]               = 'cls'
          [disabled]            = 'isDisabled'
          [readonly]            = 'isReadonly'
>
<div *ngIf='debug'>name:{{name}}, human:{{human}}, datum:{{datum}}, cls:{{cls}}, off:{{isOff}}</div>
`
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:DecimalLC, multi:true }
      , { provide:NG_VALIDATORS    , useExisting:DecimalLC, multi:true }
      ]
    }
)
export class DecimalLC extends ListControl {
    //--------------------------------------------------------------------------
    get numOrText   () { return 'text' }
//     toHuman                     = (val:any) => val ? Format.decimal2human(val) : ''
    toHuman                     = Format.decimal2criteria
    toMachine                   = Format.criteria2decimal
    //--------------------------------------------------------------------------
}
// //------------------------------------------------------------------------------
// //------------------------------------------------------------------------------
// // editor
// //------------------------------------------------------------------------------
// //------------------------------------------------------------------------------
// @Component({selector:'EditorIC', template:`
// <label *ngIf='lab' for='{{id}}'>{{lab}}</label>
// <editor   [(ngModel)]           = 'value' name='{{name}}' id='{{id}}'
//           [class]               = 'cls'
//           [disabled]            = 'isDisabled'
//
//           [required]            = 'isRequired'
//           (blur)                = 'onBlur()'
//           apiKey                = 'hciiol290qtjzwe4h4y0eeo2xosh2v2fcsf63gaay5e5qtmf'
//           [init]                = '{ height         : 500
//                                    , plugins        : [ "advlist autolink lists link image charmap print preview anchor"
//                                                       , "searchreplace visualblocks code fullscreen"
//                                                       , "insertdatetime media table paste code help wordcount"
//                                                       ]
//                                     , menubar       : false
//                                     , toolbar       : "undo redo | formatselect | bold italic color backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat code | help"
//                                     }'
// ></editor>
// <div *ngIf='debug'>name:{{name}}, human:{{human}}, datum:{{datum}}, cls:{{cls}}, off:{{isOff}}, required:{{isRequired}}</div>
// `
//     , providers:
//       [ { provide:NG_VALUE_ACCESSOR, useExisting:EditorIC, multi:true }
//       , { provide:NG_VALIDATORS    , useExisting:EditorIC, multi:true }
//       ]
//     }
// )
// export class EditorIC extends InputControl { }
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
// exchange rate
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
@Component({selector:'ExchrateIC', template:`
<label *ngIf='lab' for='{{id}}'>{{lab}}</label>
<input    [(ngModel)]           = 'value' name='{{name}}' id='{{id}}'
          type                  = '{{numOrText}}' step = '0.01'
          [class]               = 'cls'
          [disabled]            = 'isDisabled'
          [readonly]            = 'isReadonly'
          [required]            = 'isRequired'
          (blur)                = 'onBlur()'
>
<div *ngIf='debug'>name:{{name}}, human:{{human}}, datum:{{datum}}, cls:{{cls}}, off:{{isOff}}, required:{{isRequired}}</div>
`
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:ExchrateIC, multi:true }
      , { provide:NG_VALIDATORS    , useExisting:ExchrateIC, multi:true }
      ]
    }
)
export class ExchrateIC extends InputControl {
    //--------------------------------------------------------------------------
    toHuman                     = Format.exchrate2human
    toMachine                   = Format.human2exchrate
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'ExchrateLC', template:`
<input    [(ngModel)]           = 'value' name='{{name}}'
          type                  = '{{numOrText}}' step = '1'
          [class]               = 'cls'
          [disabled]            = 'isDisabled'
          [readonly]            = 'isReadonly'
>
<div *ngIf='debug'>name:{{name}}, human:{{human}}, datum:{{datum}}, cls:{{cls}}, off:{{isOff}}</div>
`
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:ExchrateLC, multi:true }
      , { provide:NG_VALIDATORS    , useExisting:ExchrateLC, multi:true }
      ]
    }
)
export class ExchrateLC extends ListControl {
    //--------------------------------------------------------------------------
    toHuman                     = (val:any) => val ? Format.exchrate2human(val) : ''
    toMachine                   = (val:any) => val ? Format.human2exchrate(val) : undefined
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
// image
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
const image_cleared             = { src:'/assets/image-cleared.png', cleared:true }

@Component({selector:'ImageIC', template:`
<label *ngIf='lab' for='{{id}}'>{{lab}}</label> &nbsp; {{filename}}
<div      [(ngModel)]           = 'value' name='{{name}}' id='{{id}}'
          [class]               = 'divClass'
><img     [src]                 = 'src' alt = 'no image'
          [class]               = 'cls'
><button class = 'clear'>X</button></div>
<div *ngIf='debug'>name:{{name}}, datum:{{datum|json}}, cls:{{cls}}</div>
`
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:ImageIC, multi:true }
      , { provide:NG_VALIDATORS    , useExisting:ImageIC, multi:true }
      ]
    }
)
export class ImageIC extends InputControl {
    //--------------------------------------------------------------------------
    error               :any    = null
    max_size                    = this.glob.config.max_image_size
    get isDropTarget() { return !this.isReadonly }
    get divClass    () { return this.isDropTarget ? 'dropTarget' : '' }
    //--------------------------------------------------------------------------
    onInitP() {
        return super.onInitP()
        .then(() => {
            if (this.isDropTarget) {
                setTimeout(() => this.init_drop_target(), 300)
            }
            return RESOLVE()
        })
    }
    //--------------------------------------------------------------------------
    init_drop_target() {
        let onDrop              = (file:any, data:any) => {
// this.log('image.onDrop()', file)
            this.error          = null
            if (!regexTest(file.type, 'image/jpeg', 'image/png')) {
                this.error      = 'Invalid file type: JPEG or PNG only (for now)'
            } else if (file.size > this.max_size) {
                this.error      = `File size is greater than ${Format.thousands(this.max_size)} bytes`
            } else {
                this.value      = { data            : data
                                  , filename        : file.name
                                  , type            : file.type
                                  , size            : file.size
                                  , lastModified    : file.lastModified
                                  }
            }
        }
        let onClear             = (evn:any) => {
// this.log('image.onClear()', evn)
            this.value          = image_cleared
            return false
        }
// this.log('image.initialise()')
        this.$(`div#${this.id}.dropTarget`)
            .dropTarget(        { callback          : onDrop
                                , max_files         : 1
                                , max_size          : this.max_size
                                })
            .find('button.clear').click(onClear.bind(this))
    }
    //--------------------------------------------------------------------------
    get value()                 { return this.human }
    set value(val:any) {
        this.human              = val || {}
        this.datum              = val || {}
        this.onChange (this.datum)
        this.onTouched(this.datum)
    }
    //--------------------------------------------------------------------------
    get src() {
        if (is.string(this.human)) {
            return this.human
        } else {
            return this.human.data || this.human.src || ''
        }
    }
    get alt_text() {
        return this.human.alt || 'no image'
    }
    get filename() {
        return this.error          ? `*** ${this.error} ***`
             : this.human.filename ? `(${this.value.filename})`
                                   : ''
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'ImageLC', template:`
<input    [(ngModel)]           = 'value' name='{{name}}'
          type                  = 'text'
          disabled readonly
          [class]               = 'cls'
>
<div *ngIf='debug'>name:{{name}}, human:{{human}}, datum:{{datum}}, cls:{{cls}}, off:{{isOff}}</div>
`
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:ImageLC, multi:true }
//       , { provide:NG_VALIDATORS    , useExisting:ImageLC, multi:true }
      ]
    }
)
export class ImageLC extends ListControl { }
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
// integer
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
@Component({selector:'IntegerIC', template:`
<label *ngIf='lab' for='{{id}}'>{{lab}}</label>
<input    [(ngModel)]           = 'value' name='{{name}}' id='{{id}}'
          type                  = '{{numOrText}}' step = '1'
          [class]               = 'cls'
          [disabled]            = 'isDisabled'
          [readonly]            = 'isReadonly'
          [required]            = 'isRequired'
          (blur)                = 'onBlur()'
>
<div *ngIf='debug'>name:{{name}}, human:{{human}}, datum:{{datum}}, cls:{{cls}}, off:{{isOff}}, required:{{isRequired}}</div>
`
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:IntegerIC, multi:true }
      , { provide:NG_VALIDATORS    , useExisting:IntegerIC, multi:true }
      ]
    }
)
export class IntegerIC extends InputControl {
    //--------------------------------------------------------------------------
    get numOrText   () { return 'text' }
//     toHuman                     = (val:any) => val ? Format.decimal2human(val) : ''
//     toHuman                     = Format.integer2criteria
//     toMachine                   = Format.criteria2integer
    toHuman                     = Format.integer2human
    toMachine                   = Format.human2integer
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'IntegerLC', template:`
<input    [(ngModel)]           = 'value' name='{{name}}'
          type                  = '{{numOrText}}' step = '1'
          [class]               = 'cls'
          [disabled]            = 'isDisabled'
          [readonly]            = 'isReadonly'
>
<div *ngIf='debug'>name:{{name}}, human:{{human}}, datum:{{datum}}, cls:{{cls}}, off:{{isOff}}</div>
`
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:IntegerLC, multi:true }
      , { provide:NG_VALIDATORS    , useExisting:IntegerLC, multi:true }
      ]
    }
)
export class IntegerLC extends ListControl {
    //--------------------------------------------------------------------------
    get numOrText   () { return 'text' }
    toHuman                     = (val:any) => val ? Format.integer2human(val) : ''
    toMachine                   = Format.criteria2integer
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
// none
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
@Component({selector:'NoneLC', template:`
<input    [(ngModel)]           = 'value' name='{{name}}'
          type                  = 'text'
          [class]               = 'cls'
          disabled
>
<div *ngIf='debug'>name:{{name}}, human:{{human}}, datum:{{datum}}, cls:{{cls}}, off:{{isOff}}</div>
`
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:NoneLC, multi:true }
      , { provide:NG_VALIDATORS    , useExisting:NoneLC, multi:true }
      ]
    }
)
export class NoneLC extends ListControl { }
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
// password
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
@Component({selector:'PasswordIC', template:`
<label *ngIf='lab' for='{{id}}'>{{lab}}</label>
<input    [(ngModel)]           = 'value' name='{{name}}' id='{{id}}'
          type                  = 'password'
          [class]               = 'cls'
          [disabled]            = 'isDisabled'
          [readonly]            = 'isReadonly'
          [required]            = 'isRequired'
          (blur)                = 'onBlur()'
>
<div *ngIf='debug'>name:{{name}}, human:{{human}}, datum:{{datum}}, cls:{{cls}}, off:{{isOff}}, required:{{isRequired}}</div>
`
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:PasswordIC, multi:true }
      , { provide:NG_VALIDATORS    , useExisting:PasswordIC, multi:true }
      ]
    }
)
export class PasswordIC extends InputControl {}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
// quantity
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
@Component({selector:'QuantityIC', template:`
<label *ngIf='lab' for='{{id}}'>{{lab}}</label>
<input    [(ngModel)]           = 'value' name='{{name}}' id='{{id}}'
          type                  = '{{numOrText}}' step = '1'
          [class]               = 'cls'
          [disabled]            = 'isDisabled'
          [readonly]            = 'isReadonly'
          [required]            = 'isRequired'
          (blur)                = 'onBlur()'
>
<div *ngIf='debug'>name:{{name}}, human:{{human}}, datum:{{datum}}, cls:{{cls}}, off:{{isOff}}, required:{{isRequired}}</div>
`
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:QuantityIC, multi:true }
      , { provide:NG_VALIDATORS    , useExisting:QuantityIC, multi:true }
      ]
    }
)
export class QuantityIC extends InputControl {
    //--------------------------------------------------------------------------
    toHuman                     = Format.quantity2human
    toMachine                   = Format.human2quantity
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'QuantityLC', template:`
<input    [(ngModel)]           = 'value' name='{{name}}'
          type                  = '{{numOrText}}' step = '1'
          [class]               = 'cls'
          [disabled]            = 'isDisabled'
          [readonly]            = 'isReadonly'
>
<div *ngIf='debug'>name:{{name}}, human:{{human}}, datum:{{datum}}, cls:{{cls}}, off:{{isOff}}</div>
`
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:QuantityLC, multi:true }
      , { provide:NG_VALIDATORS    , useExisting:QuantityLC, multi:true }
      ]
    }
)
export class QuantityLC extends ListControl {
    //--------------------------------------------------------------------------
    get numOrText   () { return 'text' }
    toHuman                     = Format.quantity2human
    toMachine                   = Format.criteria2quantity
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
// text
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
@Component({selector:'TextIC', template:`
<label *ngIf='lab' for='{{id}}'>{{lab}}</label>
<input    [(ngModel)]           = 'value' name='{{name}}' id='{{id}}'
          type                  = 'text'
          [class]               = 'cls'
          [disabled]            = 'isDisabled'
          [readonly]            = 'isReadonly'
          [required]            = 'isRequired'
          (blur)                = 'onBlur()'
>
<div *ngIf='debug'>name:{{name}}, human:{{human}}, datum:{{datum}}, cls:{{cls}}, off:{{isOff}}, required:{{isRequired}}</div>
`
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:TextIC, multi:true }
      , { provide:NG_VALIDATORS    , useExisting:TextIC, multi:true }
      ]
    }
)
export class TextIC extends InputControl {
    //--------------------------------------------------------------------------
    toHuman                     = Format.text2human
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'TextLC', template:`
<input    [(ngModel)]           = 'value' name='{{name}}'
          type                  = 'text'
          [class]               = 'cls'
          [disabled]            = 'isDisabled'
          [readonly]            = 'isReadonly'
>
<div *ngIf='debug'>name:{{name}}, human:{{human}}, datum:{{datum}}, cls:{{cls}}, off:{{isOff}}</div>
`
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:TextLC, multi:true }
      , { provide:NG_VALIDATORS    , useExisting:TextLC, multi:true }
      ]
    }
)
export class TextLC extends ListControl { }
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
// textarea
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
@Component({selector:'TextareaIC', template:`
<label *ngIf='lab' for='{{id}}'>{{lab}}</label>
<textarea [(ngModel)]           = 'value' name='{{name}}' id='{{id}}'
          rows                  = '{{rows}}'
          [class]               = 'cls'
          [disabled]            = 'isDisabled'
          [readonly]            = 'isReadonly'
          [required]            = 'isRequired'
          (blur)                = 'onBlur()'
></textarea>
<div *ngIf='debug'>name:{{name}}, human:{{human}}, datum:{{datum}}, cls:{{cls}}, off:{{isOff}}, required:{{isRequired}}</div>
`
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:TextareaIC, multi:true }
      , { provide:NG_VALIDATORS    , useExisting:TextareaIC, multi:true }
      ]
    }
)
export class TextareaIC extends InputControl {
    //--------------------------------------------------------------------------
    toHuman                     = Format.textarea2human
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
// time
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
@Component({selector:'TimeIC', template:`
<label *ngIf='lab' for='{{id}}'>{{lab}}</label>
<input    [(ngModel)]           = 'value' name='{{name}}' id='{{id}}'
          type                  = 'text'
          [class]               = 'cls'
          [disabled]            = 'isDisabled'
          [readonly]            = 'isReadonly'
          [required]            = 'isRequired'
          (blur)                = 'onBlur()'
>
<div *ngIf='debug'>name:{{name}}, human:{{human}}, datum:{{datum}}, cls:{{cls}}, off:{{isOff}}, required:{{isRequired}}</div>
`
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:TimeIC, multi:true }
      , { provide:NG_VALIDATORS    , useExisting:TimeIC, multi:true }
      ]
    }
)
export class TimeIC extends InputControl {
    //--------------------------------------------------------------------------
    toHuman                     = Format.time2human
    toMachine                   = Format.human2time
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'TimeLC', template:`
<input    [(ngModel)]           = 'value' name='{{name}}'
          type                  = 'text'
          [class]               = 'cls'
          [disabled]            = 'isDisabled'
          [readonly]            = 'isReadonly'
>
<div *ngIf='debug'>name:{{name}}, human:{{human}}, datum:{{datum}}, cls:{{cls}}, off:{{isOff}}</div>
`
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:TimeLC, multi:true }
      , { provide:NG_VALIDATORS    , useExisting:TimeLC, multi:true }
      ]
    }
)
export class TimeLC extends ListControl {
    //--------------------------------------------------------------------------
    toHuman                     = Format.time2human
    toMachine                   = Format.human2time
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
// timestamp (to milliseconds)
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
@Component({selector:'TimestampIC', template:`
<label *ngIf='lab' for='{{id}}'>{{lab}}</label>
<input    [(ngModel)]           = 'value' name='{{name}}' id='{{id}}'
          type                  = 'text'
          [class]               = 'cls'
          [disabled]            = 'isDisabled'
          [readonly]            = 'isReadonly'
          [required]            = 'isRequired'
          (blur)                = 'onBlur()'
>
<div *ngIf='debug'>name:{{name}}, human:{{human}}, datum:{{datum}}, cls:{{cls}}, off:{{isOff}}, required:{{isRequired}}</div>
`
    , providers:
      [ { provide:NG_VALUE_ACCESSOR, useExisting:TimestampIC, multi:true }
      , { provide:NG_VALIDATORS    , useExisting:TimestampIC, multi:true }
      ]
    }
)
export class TimestampIC extends InputControl {
    //--------------------------------------------------------------------------
    toHuman                     = Format.timestamp2human
    toMachine                   = Format.human2timestamp
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
let c = [ ItemControls          , ListControls
        , BooleanIC             , BooleanLC
        , CodIC
        , CodeIC
        , DateIC                , DateLC
        , DatetimeIC            , DatetimeLC
        , DecimalIC             , DecimalLC
//         , EditorIC
        , ExchrateIC            , ExchrateLC
        , ImageIC               , ImageLC
        , IntegerIC             , IntegerLC
                                , NoneLC
        , PasswordIC
        , QuantityIC            , QuantityLC
        , TextIC                , TextLC
        , TextareaIC
        , TimeIC                , TimeLC
        , TimestampIC
        ]
//------------------------------------------------------------------------------
@NgModule(
//     { imports                   : [ CommonModule, EditorModule, FormsModule ]
    { imports                   : [ CommonModule, FormsModule ]
    , exports                   : c
    , declarations              : c
    }
)
export class ControlsModule {}
//------------------------------------------------------------------------------
export const Controls           =
{ Module                        : ControlsModule
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
