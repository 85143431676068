//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { ShlService                                } from './service'
import  { ShlLst                , Shl               } from './shlLst'
import  { ShlRnm                                    } from './shlRnm'
//------------------------------------------------------------------------------
let c = [ ShlLst                , Shl
        , ShlRnm
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ ShlService ]
    }
)
export class ShlModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
