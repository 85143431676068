//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemComp, CslService             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'CslDtl', template:ItemDiv})
export class CslDtl extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:CslService ) { super() }
    comp_type                   = 'maintab'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , name          : {lab:'name'               , wide:12}
        , descr         : {opt:'description'        , wide:12}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        this.ctx.csl_id         =
        this.dft.csl_id         = this.ctx.csl_id || this.dft.csl_id
        return this.readP({ id:this.ctx.csl_id })
    }
    //--------------------------------------------------------------------------
    receivedItem(item:any) {
        Object.assign(this.item, item)
        this.setCache(this.item)
        this.service.setHeader(this.item)
        if (this.item.id) {
            this.ctx.csl_id     =
            this.dft.csl_id     = this.item.id
            this.service.showValidTabs()
        }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
