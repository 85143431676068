//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, jQuery
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ChangeDetectorRef                         } from '@angular/core'
import  { ItemDiv               , ItemModal
        , ListDiv               , ListComp
        , PriSkuService                             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'PriSkuLst', template:ListDiv})
export class PriSkuLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:PriSkuService, private cdr:ChangeDetectorRef ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { number        : {lab:'product'            , wide: 4               , isKey:true}
        , matchcode     : {lab:'matchcode'          , wide:10               , isKey:true}
        , brn_name      : {lab:'brand'              , wide: 5               , isKey:true}
        , sup_name      : {lab:'supplier'           , wide: 8               , isKey:true}
        , pri_cls_ws    : {lab:'w/s class'          , wide: 2               , isKey:true}
        , abc_typ       : {lab:'rev ABC'            , wide: 1, cls:'center' }
        , retail_base   : {lab:'base retail'        , wide: 2, typ:'decimal', isKey:true}
        , retail_new    : {lab:'new retail'         , wide: 2, typ:'decnone', isKey:true}
        , retail_due    : {lab:'apply date'         , wide: 2, typ:'date'   , isKey:true}

        , suffix        : {lab:'var'                , wide: 2, cls:'left-border'}
        , price_adj     : {lab:'sku adj.'           , wide: 2, typ:'decnone'}

        , cur_typ       : {lab:'curr'               , wide: 1, cls:'left-border'}
        , target_retail : {lab:'target retail'      , wide: 2, typ:'decnone'}

        , retail        : {opt:'retail'             , wide: 2, typ:'decimal', cls:'left-border'}
        , rt_calc       : {opt:'calc.'              , wide: 2, typ:'decnone'}
        , rt_new        : {opt:'new'                , wide: 2, typ:'decnone'}
        , rt_new_date   : {opt:'apply date'         , wide: 2, typ:'date'   }

        , wsale         : {opt:'w/sale'             , wide: 2, typ:'decimal', cls:'left-border'}
        , ws_calc       : {opt:'calc.'              , wide: 2, typ:'decnone'}
        , ws_new        : {opt:'new'                , wide: 2, typ:'decnone'}
        , ws_new_date   : {opt:'apply date'         , wide: 2, typ:'date'   }
        }
    //--------------------------------------------------------------------------
    dontLoadAll         :boolean= true
    groupingKey                 = 'id'
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
//         this.buttonSelAll       (this.onSelectAllP  )
//         this.buttonInvSel       (this.onInvertSelP  )

        this.makeButton(this.recalcPrds             , 're-calc listed products' )
        this.makeButton(this.recalcAllPrds          , 're-calc **ALL** products')

        this.makeButton(this.scheduleRetail         , 'schedule retail update'  )
        this.makeButton(this.unscheduleRetail       , 'un-schedule retail update')

        this.makeButton(this.scheduleWsale          , 'schedule w/sale update'  )
        this.makeButton(this.unscheduleWsale        , 'un-schedule w/sale update')

        this.onCriteria         (this.onCriteriaP   )
//         this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdatePrdP  )
        this.cellsDclick        (this.onUpdateVrnP  ,
            [ 'suffix','price_adj' ]
        )
        this.cellsDclick        (this.onUpdateCurP  ,
            [ 'cur_typ','target_retail' ]
        )
        this.cellsDclick        (this.onUpdatePriP  ,
            [ 'retail','rt_calc','rt_new','rt_new_date'
            , 'wsale' ,'ws_calc','ws_new','ws_new_date'
            ]
        )

        this.dft.LIMIT          = this.ctx.LIMIT
        this.dft.LOW_PRICING    = this.ctx.LOW_PRICING


if (this.glob.userIsDeveloper && this.emptyCriteria) {
    this.criteria.number    = '010012*'
    this.criteria.cur_typ   = '*'
}

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    loadResult(list:any) {
        return this.preformat_list(list)
    }
    //--------------------------------------------------------------------------
    testAndSet(idx, item, col, orange, yellow, green) {
        let value               = item[col]
        if (!value)             { return }
        if (value < item[orange]) {
            return this.set_cell_bg(idx, col, 'orange')
        }
        if (value < item[yellow]) {
            return this.set_cell_bg(idx, col, 'yellow')
        }
        if (value > item[green]) {
            return this.set_cell_bg(idx, col, 'green')
        }
    }

    setItemColour(item:any, idx:number) {
        this.testAndSet(idx, item, 'retail' , 'target_retail', ''      , ''      )
        this.testAndSet(idx, item, 'rt_calc', 'target_retail', 'retail', 'retail')
        this.testAndSet(idx, item, 'rt_new' , 'target_retail', 'retail', 'retail')

        this.testAndSet(idx, item, 'wsale'  , 'target_wsale' , ''      , ''      )
        this.testAndSet(idx, item, 'ws_calc', 'target_wsale' , 'wsale' , 'wsale' )
        this.testAndSet(idx, item, 'ws_new' , 'target_wsale' , 'wsale' , 'wsale' )
    }
    //--------------------------------------------------------------------------
    recalcPrds(key:string, values:any) {
        if (this.list.length < 1)
                                { return REJECT('list is empty') }
        let prd_set             = new Set(this.list.map((item) => item.id))
        let prd_ids             = [ ...prd_set ]
        return this.busyServiceEmitP('recalc',
            { prd_ids
            , TIMEOUT           : prd_ids.length * 1
            }
        )
        .then(() => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
    recalcAllPrds(key:string, values:any) {
        return this.busyServiceEmitP('recalc',
            { prd_ids           : null
            , TIMEOUT           : 600
            }
        )
        .then(() => {
            this.messageDone('recalculation in progress')
            return this.setFocusP()
        })
//         .then(() => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
    async get_pri_rows() {
        if (this.list.length < 1)
                                { return REJECT('list is empty') }
        return this.list.map((item) => (
                { id:item.pri_id, update_date:item.pri_upd }
              ))
    }
    //--------------------------------------------------------------------------
    async scheduleRetail(key:string, values:any) {
        let pri_rows            = await this.get_pri_rows()
        let res                 = await this.callComponent('ParamsModal',
            { header            : 'schedule retail update...'
            , itemDefn          : { apply_date:{lab:'apply_date', typ:'date'}   }
            , values            : { apply_date: new Date()                      }
            }
        )
        if (res && res.apply_date) {
            return this.busyServiceEmitP('scheduleRetail',
                { pri_rows, apply_date:res.apply_date }
            )
            .then(() => this.loadAndFocusP() )
        }
    }
    //--------------------------------------------------------------------------
    async unscheduleRetail(key:string, values:any) {
        let pri_rows            = await this.get_pri_rows()
        return this.busyServiceEmitP('unscheduleRetail', { pri_rows })
        .then(() => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
    async scheduleWsale(key:string, values:any) {
        let pri_rows            = await this.get_pri_rows()
        let res                 = await this.callComponent('ParamsModal',
            { header            : 'schedule wholesale update...'
            , itemDefn          : { apply_date:{lab:'apply_date', typ:'date'}   }
            , values            : { apply_date: new Date()                      }
            }
        )
        if (res && res.apply_date) {
            return this.busyServiceEmitP('scheduleWsale',
                { pri_rows, apply_date:res.apply_date }
            )
            .then(() => this.loadAndFocusP() )
        }
    }
    //--------------------------------------------------------------------------
    async unscheduleWsale(key:string, values:any) {
        let pri_rows            = await this.get_pri_rows()
        return this.busyServiceEmitP('unscheduleWsale', { pri_rows })
        .then(() => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
    onUpdatePrdP                = (idx:number) => this.onUpdateX(idx, 'PriSkuPrd', 'prd_id')
    onUpdateVrnP                = (idx:number) => this.onUpdateX(idx, 'PriSkuVrn', 'vrn_id')
    onUpdateCurP                = (idx:number) => this.onUpdateX(idx, 'PriSkuCur', 'cur_id')
    onUpdatePriP                = (idx:number) => this.onUpdateX(idx, 'PriSkuPri', 'pri_id')

    onUpdateX(idx:number, compname, id_name) {
        let item                = this.list[idx]
        return this.callComponent(compname,
            { prd_id            : item.prd_id
            , vrn_id            : item.vrn_id
            , cur_id            : item.cur_id
            , pri_id            : item.pri_id
            }
        )
        .then((res:any) => this.onUpdateDoneP(idx, res) )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'PriSkuCur', template:ItemDiv})
export class PriSkuCur extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:PriSkuService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['c_p_id','c_p_upd','pur_id','pur_upd']
        , sku           : {rdo:'sku'                , wide: 3, newline:true}
        , matchcode     : {rdo:'matchcode'          , wide: 9}

        , exchrate      : {rdo:'exchange rate'      , wide: 3, typ:'exchrate', newline:true}
        , ddp_uplift    : {rdo:'ddp uplift (%)'     , wide: 3, typ:'percent'}
        , cur_uplift    : {rdo:'currency uplift (%)', wide: 3, typ:'percent'}
        , prd_uplift    : {rdo:'product uplift (%)' , wide: 3, typ:'percent'}

        , dsg_uplift    : {rdo:'designer uplift (%)', wide: 3, typ:'percent', newline:true}
        , brn_uplift    : {rdo:'brand uplift (%)'   , wide: 3, typ:'percent'}
        , ws_factor     : {rdo:'w/s factor'         , wide: 3, typ:'quantity'}
        , cur_tax_pct   : {rdo:'tax (%)'            , wide: 3, typ:'percent'}

        , eur_name      : {rdo:'currency'           , wide: 6               , newline:true}
        , pur_wsale     : {rdo:'purchase wsale'     , wide: 3, typ:'decimal'}
        , target_retail : {rdo:'target retail'      , wide: 3, typ:'decimal'}

        , cur_name      : {rdo:''                   , wide: 6               , newline:true}
        , pur_local     : {rdo:''                   , wide: 3, typ:'decimal'}
        , target_local  : {rdo:''                   , wide: 3, typ:'decimal'}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.ctx.id             = this.ctx.cur_id
        return super.onInitP()
        .then(() => {
            this.setHeader('currency details')
            return RESOLVE()
        })
    }
    //--------------------------------------------------------------------------
    onRead_task                 = 'cur_read'
    onSave_task                 = 'cur_save'
    onDelete_task               = null
    //--------------------------------------------------------------------------
    readP(criteria:any={}) {
        return super.readP(this.ctx)
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'PriSkuPrd', template:ItemDiv})
export class PriSkuPrd extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:PriSkuService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['prd_id','prd_upd','pur_id','pur_upd']
        , number        : {rdo:'number'             , wide: 3, newline:true}
        , vrn_suffix    : {rdo:'base variant'       , wide: 3}
        , last_pur_date : {rdo:'last purchase'      , wide: 3, typ:'date'}
        , pur_wsale     : {rdo:'purchase price'     , wide: 3, typ:'decimal'}

        , sup_name      : {rdo:'supplier'           , wide: 6, newline:true}
        , ddp_uplift    : {rdo:'cog uplift (%)'     , wide: 3, typ:'decimal'}
        , ddp_uplift_val: {rdo:'value'              , wide: 3, typ:'decimal'}

        , matchcode     : {rdo:'matchcode'          , wide: 6, newline:true}
        , prd_uplift    : {rdo:'cog adjustment (%)' , wide: 3, typ:'decimal'}
        , prd_uplift_val: {rdo:'value'              , wide: 3, typ:'decimal'}

        , dsg_name      : {rdo:'designer'           , wide: 6, newline:true}
        , dsg_uplift    : {rdo:'designer uplift (%)', wide: 3, typ:'decimal'}
        , dsg_uplift_val: {rdo:'value'              , wide: 3, typ:'decimal'}

        , brn_name      : {rdo:'brand'              , wide: 6, newline:true}
        , brn_uplift    : {rdo:'brand uplift (%)'         , wide: 3, typ:'decimal'}
        , brn_uplift_val: {rdo:'value'              , wide: 3, typ:'decimal'}

        , pri_cls_id_ws : {lab:'w/s class'          , wide: 6, cod:'pri_cls_ws', newline:true}
        , ws_factor     : {rdo:'w/s factor'         , wide: 3, typ:'quantity'}
        , ws_factor_val : {rdo:'value'              , wide: 3, typ:'decimal'}

        , SKIP_2        : {rdo:''                   , wide: 6, typ:'skip'   , newline:true}
        , cur_tax_pct   : {rdo:'tax (%)'            , wide: 3, typ:'decimal'}
        , cur_tax_val   : {rdo:'value'              , wide: 3, typ:'decimal'}

        , SKIP_3        : {rdo:''                   , wide: 6, typ:'skip'   , newline:true}
        , margin        : {rdo:'current margin'     , wide: 3, typ:'percent'}
        , target_retail : {rdo:'target retail'      , wide: 3, typ:'decimal'}

        , retail_base   : {lab:'base retail'        , wide: 4, typ:'decimal', newline:true}
        , retail_new    : {opt:'new base retail'    , wide: 4, typ:'decimal'}
        , retail_due    : {opt:'apply date'         , wide: 4, typ:'date'   }
        }
    //--------------------------------------------------------------------------
    onInitP() {
        return super.onInitP()
        .then(() => {
            this.setHeader('product details')
            return RESOLVE()
        })
    }
    //--------------------------------------------------------------------------
    onRead_task                 = 'prd_read'
    onSave_task                 = 'prd_save'
    onDelete_task               = null
    //--------------------------------------------------------------------------
    readP(criteria:any={}) {
        return super.readP(this.ctx)
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'PriSkuPri', template:ItemDiv})
export class PriSkuPri extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:PriSkuService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['pri_id','pri_upd']
        , sku           : {rdo:'sku'                , wide: 3}
        , matchcode     : {rdo:'matchcode'          , wide: 9}

        , cur_name      : {rdo:'currency'           , wide: 3, newline:true}
        , exchrate      : {rdo:'exchange rate'      , wide: 3, typ:'exchrate'}
        , pri_cls_ws    : {rdo:'w/s class'          , wide: 3}
        , ws_factor     : {rdo:'w/s factor'         , wide: 3, typ:'quantity'}

        , retail        : {opt:'retail'             , wide: 3, typ:'decimal', newline:true}
        , rt_calc       : {opt:'calculated'         , wide: 3, typ:'decimal'}
        , rt_new        : {opt:'new'                , wide: 3, typ:'decimal'}
        , rt_new_date   : {opt:'apply date'         , wide: 3, typ:'date'   }

        , wsale         : {opt:'wholesale'          , wide: 3, typ:'decimal', newline:true}
        , ws_calc       : {opt:'calculated'         , wide: 3, typ:'decimal'}
        , ws_new        : {opt:'new'                , wide: 3, typ:'decimal'}
        , ws_new_date   : {opt:'apply date'         , wide: 3, typ:'date'   }
        }
    //--------------------------------------------------------------------------
    onInitP() {
        return super.onInitP()
        .then(() => {
            this.setHeader('price details')
            return RESOLVE()
        })
    }
    //--------------------------------------------------------------------------
    onRead_task                 = 'pri_read'
    onSave_task                 = 'pri_save'
    onDelete_task               = null
    //--------------------------------------------------------------------------
    readP(criteria:any={}) {
        return super.readP(this.ctx)
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'PriSkuVrn', template:ItemDiv})
export class PriSkuVrn extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:PriSkuService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['vrn_id','vrn_upd']
        , sku           : {rdo:'sku'                , wide: 3, newline:true}
        , matchcode     : {rdo:'matchcode'          , wide: 9}

        , retail        : {rdo:'base retail'        , wide: 3, typ:'decimal', newline:true}
        , price_adj     : {opt:'sku price adjustment'
                                                    , wide: 3, typ:'decimal'}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        return super.onInitP()
        .then(() => {
            this.setHeader('variant details')
            return RESOLVE()
        })
    }
    //--------------------------------------------------------------------------
    onRead_task                 = 'vrn_read'
    onSave_task                 = 'vrn_save'
    onDelete_task               = null
    //--------------------------------------------------------------------------
    readP(criteria:any={}) {
        return super.readP(this.ctx)
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
