//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { SelectDiv, SelectModal, CslService        } from './service'
//------------------------------------------------------------------------------
@Component({selector:'SelectCsl', template:SelectDiv})
export class SelectCsl extends SelectModal {
    //--------------------------------------------------------------------------
    constructor( public service:CslService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { value         : {opt:'carousel'           , wide:12}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        if (this.ctx.multi)     { this.setHeader('select carousels') }
        else                    { this.setHeader('select carousel' ) }
        this.onInitSelect()

        return this.onSearchP('search', this.item)
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
