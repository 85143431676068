//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemModal, OlsService            } from './service'
//------------------------------------------------------------------------------
@Component({selector:'OlsSkuAdd', template:ItemDiv})
export class OlsSkuAdd extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:OlsService ) { super() }
    modal_size                  = 'large'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { number        : {rdo:'number'             , wide:12}
        , matchcode     : {rdo:'matchcode'          , wide:12}
        , sku           : {lab:'shop sku'           , wide:12}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        this.buttonCancel       (this.onCancelP     )
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        this.item               = this.ctx
        return this.setFocusP()
    }
    //--------------------------------------------------------------------------
    onSubmitP(key:string, values:any) {
        if (!values) {
            return RESOLVE()
         }
        let data                = { art_id          : this.item.art_id
                                  , sku             : values.sku
                                  }
        return this.service.sku_add(data)
        .then((res:any) => this.modalRESOLVE(res) )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
