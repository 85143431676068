//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, list_div, jQuery
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , CurService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'CurPriLst', template:ListDiv})
export class CurPriLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:CurService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { cur_name      : {lab:'currency'           , wide: 3}
        , uplift_pct    : {lab:'uplift percent'     , wide: 2, typ:'percent'}
        , tax_pct       : {lab:'vat percent'        , wide: 2, typ:'percent'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'cur_pri_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.buttonNew          (this.onNewP        )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'CurPri', template:ItemDiv})
export class CurPri extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:CurService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , cur_id        : {lab:'currency'           , wide: 4, cod:'cur'    , copyCheck:true}
        , uplift_pct    : {opt:'uplift percent'     , wide: 4, typ:'percent'}
        , tax_pct       : {opt:'vat percent'        , wide: 4, typ:'percent'}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'cur_pri_read'
    onSave_task                 = 'cur_pri_save'
    onDelete_task               = 'cur_pri_dele'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
