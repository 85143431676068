//------------------------------------------------------------------------------
import  { Injectable                                } from '@angular/core'
//------------------------------------------------------------------------------
import * as lib from '../library'
//------------------------------------------------------------------------------
export const Imports            = lib.Imports
export const BaseModule         = lib.Base.Module
export const BaseComp           = lib.Base.Comp

export const DivDiv             = lib.Div.DivDiv
//------------------------------------------------------------------------------
@Injectable()
export class DivService extends lib.Base.Service {}
//------------------------------------------------------------------------------
