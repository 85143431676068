//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListModal
        , KntService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'SelectKnt', template:ListDiv})
export class SelectKnt extends ListModal {
    //--------------------------------------------------------------------------
    constructor( public service:KntService ) { super() }
    modal_size                  = 'large'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { name          : {lab:'name'               , wide: 6}
        , email         : {lab:'email'              , wide: 6}
        , adr_street    : {lab:'street'             , wide: 6}
        , adr_city      : {lab:'city'               , wide: 6}
        , adr_postcode  : {lab:'postcode'           , wide: 2}
        , adr_lnd_name  : {lab:'country'            , wide: 4}
        , adr_email     : {lab:'addr. email'        , wide: 6}
        , adr_tel_num   : {lab:'phone'              , wide: 4}
        , grp_name      : {lab:'group'              , wide: 6}
        , cur_typ       : {lab:'cur'                , wide: 1}
        , wsale_yn      : {lab:'w/s'                , wide: 1, typ:'flag'}
        , number        : {lab:'number'             , wide: 1}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.setHeader          ('=menu='           )
        this.defineList         (this.listDefn      )
        this.buttonNew          (this.onNewP        )
        this.onCriteria         (this.onCriteriaP   )
//         this.listClick          (this.onSelectItmP  )
        this.listClick          (this.onReturnItmP  )
        this.listDclick         (this.onReturnItmP  )

        this.dft.typ            = this.ctx.typ      || 'cus'
        this.dft.subtyp         = this.ctx.subtyp   || ''
        this.dft.vendor         = this.ctx.vendor

        this.criteria           = { name:this.ctx.name || '' }

        this.dontLoadAll        = this.dft.typ == 'cus'
        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    loadResult(list:any) {
        if (list && list.length == 1) {
            return this.modalRESOLVE(list[0])
        } else {
            return list
        }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
