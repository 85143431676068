//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { RulService                                } from './service'
import  { RulLst                , Rul, RulOpt
        , SelectRulOpt                              } from './rulLst'
//------------------------------------------------------------------------------
let c = [ RulLst                , Rul, RulOpt
        , SelectRulOpt
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ RulService ]
    }
)
export class RulModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
