<div          class         = 'div-menu container {{glob.windowClass}}'>
  <!-- first line -->
  <div        class         = 'line1 u-full-width'>
    <div      class         = 'left'>
      <!-- logo -> return to main menu  -->
      <button class         = 'btn logo'
              (click)       = 'glob.onLogoClick($event)'
      >
        <picture>
          <source srcset='/assets/logo-dark.png'  media='(prefers-color-scheme: dark)'/>
          <source srcset='/assets/logo-light.png' media='(prefers-color-scheme: light),(prefers-color-scheme: no-preference)'/>
          <img class='logo' src='/assets/logo-light.png' />
        </picture>
      </button>
    </div>

    <!-- username and logout button -->
    <div      *ngIf         = 'glob.user.username'
              class         = 'right'
    >
      {{glob.user.name}}
      <button class         = 'btn logout'
              (click)       = 'glob.logout()'
      >logout</button>
    </div>

    <!-- clear float -->
    <div      class         = 'u-cf'></div>
  </div>

  <!-- second line -->
  <div        class         = 'line2 u-full-width'>
    <!-- 'breadcrumbs' -->
    <div      class         = 'left'>
      <button *ngFor        = 'let id of glob.breadcrumbs'
              class         = 'btn'
              (click)       = 'glob.onMenuClick(id)'
      >{{glob.menu_detail[id].title}}</button>
    </div>

    <!-- menu items -->
    <div      class         = 'right'>
      <button *ngFor        = 'let id of glob.menu.children'
              class         = 'btn'
              (click)       = 'glob.onMenuClick(id)'
      >{{glob.menu_detail[id].title}}</button>
    </div>

    <!-- clear float -->
    <div      class       = 'u-cf'></div>
  </div>

  <!-- underline -->
  <hr class='menu-hr'>
</div>
<div class='debug' (dblclick)='showDev=!showDev'>
  <div *ngIf='showDev'>[{{glob.menu.id}}] {{glob.top?.name}} context: {{glob.top?.ctx|json}} / default: {{glob.top?.dft|json}}</div>
  <div *ngIf='!showDev'>&nbsp;</div>
</div>
