//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , KntService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'KntVndRbgLst', template:ListDiv})
export class KntVndRbgLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:KntService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { name          : {lab:'name'               , wide: 6}
        , percent       : {lab:'percent'            , wide: 2, typ:'percent'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'rbg_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn, true)
        this.buttonNew          (this.onNewP        )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'KntVndRbg', template:ItemDiv})
export class KntVndRbg extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:KntService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , rbg_id        : {lab:'name'               , wide:12, cod:'rbg'    , copyCheck:true}
        , percent       : {lab:'percent'            , wide:12, typ:'percent'}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'rbg_read'
    onSave_task                 = 'rbg_save'
    onDelete_task               = 'rbg_dele'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
