//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { RbgService                                } from './service'
import  { RbgLst                , Rbg               } from './rbgLst'
//------------------------------------------------------------------------------
let c = [ RbgLst                , Rbg
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ RbgService ]
    }
)
export class RbgModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
