//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv               , ItemModal
        , ListDiv               , ListComp
        , OlsService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'OlsBrnLst', template:ListDiv})
export class OlsBrnLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:OlsService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { brn_name      : {lab:'brand'              , wide:20}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'brn_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn, true)
        this.buttonNew          (this.onNewP        )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'OlsBrn', template:ItemDiv})
export class OlsBrn extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:OlsService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , tab_id        : {lab:'brand'           , wide:12, cod:'brn'       , copyCheck:true}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'brn_read'
    onSave_task                 = 'brn_save'
    onDelete_task               = 'brn_dele'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
