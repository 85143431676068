//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, is
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemComp, PrtLabService          } from './service'
//------------------------------------------------------------------------------
@Component({selector:'PrtLab', template:ItemDiv})
export class PrtLab extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:PrtLabService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    TIMEOUT             :any    = undefined

    onInitP() {
        this.ctx.NAME           = this.ctx.name
        return this.busyServiceEmitP('get_options', this.ctx)
        .then((res:any) => {
            if (res.options && res.options.TIMEOUT) {
                this.TIMEOUT    = res.options.TIMEOUT
            }
            this.defineItem     (res.fields         )
            this.setHeader      ('=menu='           )
            this.buttonCancel   (this.onCancelP     )
            if (res.fields['prt_que_id']) {
                this.buttonOk   (this.onSubmitP     )
                this.formSubmit (this.onSubmitP     )
            }
            this.item           = res.item
            this.messageClear()
            return this.setFocusP()
        })
    }
    //--------------------------------------------------------------------------
    onPrintP(key:string, values:any) {
this.log('onPrintP:', [ key, values ])
        let test                = /^prt_que_(\d+)$/.exec(key)
        if (!test)              { return REJECT('unable to find printer name') }
        let prt_que_id          = parseInt(test[1]) || 0
        values.prt_que_id       = prt_que_id
        values.printer          = this.item.printers[prt_que_id]
        return this.printLabelP(values)
    }
    //--------------------------------------------------------------------------
    onSubmitP(key:string, values:any) {
        if (!values)            { return RESOLVE() }
        return this.printLabelP(values)
    }
    //--------------------------------------------------------------------------
    printLabelP(data:any) {
        data.ctx                = this.ctx
        data.NAME               = this.ctx.name
        data.TIMEOUT            = this.TIMEOUT
        setTimeout(() => this.messageClear(), 5000)
        this.messageDone(`print request submitted to ${data.printer}`)
        return this.serviceEmitP('print_labels', data)
        .then((res:any) => {
this.log('service RES:', res)
            if (is.empty(res)) {
                res             = 'print process returned no result'
            } else if (data.printer) {
                res             = `labels printed to ${data.printer}`
            } else {
                res             = `printing error ${res.err || res}`
            }
            return RESOLVE(res)
        })
        .catch((err:any) => {
            this.log('printLabelP:', err)
            let msg             = err.message || err.toString()
            msg                 = `label creation error: ${msg}`
            return REJECT(msg)
        })
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
