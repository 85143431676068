//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, is, jQuery
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemComp, ArtService             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'ArtDim', template:ItemDiv})
export class ArtDim extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:ArtService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , selector      : {opt:'selector'           , wide: 3, rtn:this.onSelector}

        , number        : {rdo:'number'             , wide: 3, newline:true}
        , matchcode     : {rdo:'matchcode'          , wide: 9}

        , height        : {opt:'height (cm)'        , wide: 3, typ:'integer'
                                                             , rtn:this.onHeight}
        , width         : {opt:'width (cm)'         , wide: 3, typ:'integer'
                                                             , rtn:this.onWidth}
        , depth         : {opt:'depth (cm)'         , wide: 3, typ:'integer'
                                                             , rtn:this.onDepth}

        , weight        : {opt:'weight (kg)'        , wide: 3, typ:'quantity'
                                                             , newline:true
                                                             , rtn:this.onWeight}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'dim_read'
    onSave_task                 = 'dim_save'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        this.buttonOk           (this.onSubmitP     )
//         this.formSubmit         (this.onSubmitP     )
        return this.setFocusP('selector')
    }
    //--------------------------------------------------------------------------
    onSelector(key:string, value:any) {
this.log('onSelector:', { key, value })
        if (is.empty(value)) {
            this.messageError(`no ${key} entered`)
            return this.setFocusP(key)
        }
        return this.readP({ [key]:value })
        .then ((res) => this.setFocusP('height') )
        .catch((err) => {
            this.messageError(err)
            return this.setFocusP(key)
        })
    }
    //--------------------------------------------------------------------------
    onHeight(key:string, value:any) {
this.log('onHeight:', { key, value })
        if (is.empty(value)) {
            this.messageError(`no ${key} entered`)
            return this.setFocusP(key)
        }
        this.item[key]              = value
        return this.setFocusP('width')
    }
    //--------------------------------------------------------------------------
    onWidth(key:string, value:any) {
this.log('onWidth:', { key, value })
        if (is.empty(value)) {
            this.messageError(`no ${key} entered`)
            return this.setFocusP(key)
        }
        this.item[key]              = value
        return this.setFocusP('depth')
    }
    //--------------------------------------------------------------------------
    onDepth(key:string, value:any) {
this.log('onDepth:', { key, value })
        if (is.empty(value)) {
            this.messageError(`no ${key} entered`)
            return this.setFocusP(key)
        }
        this.item[key]              = value
        return this.setFocusP('weight')
    }
    //--------------------------------------------------------------------------
    onWeight(key:string, value:any) {
this.log('onWeight:', { key, value })
        if (is.empty(value)) {
            this.messageError(`no ${key} entered`)
            return this.setFocusP(key)
        }
        this.item[key]              = value
        jQuery('input.submit').click()
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    onSubmitP(key:string, values:any) {
this.log('onSubmitP', { key, values })
        if (!values)            { return RESOLVE() }
        return this.busyServiceEmitP(this.onSave_task, values)
        .then((res:any) => {
            res                 =
                { id            : 0
                , selector      : null
                , number        : ''
                , matchcode     : ''
                , height        : null
                , width         : null
                , depth         : null
                , weight        : null
                }
            this.receivedItem(res)
            return this.onSubmitDoneP(res)
            .then(() => this.setFocusP('selector') )
        })
        .catch((err) => {
            this.messageError(err)
            return RESOLVE()
        })
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
