//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv               , ItemComp
        , ShlService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'ShlRnm', template:ItemDiv})
export class ShlRnm extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:ShlService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { old_name      : {lab:'old shelf name'     , wide:12, rtn:this.on_old }

        , new_name      : {lab:'new shelf name'     , wide:12, rtn:this.on_new }
        }
    //--------------------------------------------------------------------------
    readP(criteria:any={}) {
        Object.assign(this.item, { old_name:'', new_name:'' })
        return this.readDoneP()
    }
    //--------------------------------------------------------------------------
    on_old(key:string, value:any) {
        if (!value)             { return this.setFocusP('old_name') }
        this.item.old_name      = value
        return this.setFocusP('new_name')
    }
    on_new(key:string, value:any) {
        if (!value)             { return this.setFocusP('new_name') }
        this.item.new_name      = value
        return this.busyServiceEmitP('rename',
            { rsr_id            : this.ctx.rsr_id
            , old_name          : this.item.old_name
            , new_name          : this.item.new_name
            })
        .then(() => this.readP() )
        .then(() => {
            this.messageSaved()
            return this.setFocusP('old_name')
        })
    }
    //--------------------------------------------------------------------------
    onInitP() {
        this.setHeader          ('=menu='           )
        this.defineItem         (this.itemDefn      )

        return this.readP()
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
