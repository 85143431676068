//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { ItemComp                                  } from './item'
import  { ListComp                                  } from './list'
import  { MixedDiv                                  } from './templates/divs'
import  { recalcPst                                 } from './calcs'
//------------------------------------------------------------------------------
export class MixedBase extends ListComp implements ItemComp {
    //--------------------------------------------------------------------------
    // ListComp is extended, so we're good...
    //--------------------------------------------------------------------------
    // ItemComp is a mix-in and will overwrite duplicate properties
    // from the ListComp (i.e. useMessage & useButtons)
    // We're ok with most of them, but need to exclude the ones listed below
    //
    // UPDATE
    // typescript class inheritance seems to have changed somewhat and the
    // applyMixins exclusion list now only needs 'constructor' (apparently)
    // I'll leave the debug display in for now so I can keep an eye on it
    //
    //--------------------------------------------------------------------------
    buttonRow                   = 't'       // doesn't get mixed-in ??
    //--------------------------------------------------------------------------
    implementsItem      :boolean= true      // doesn't get mixed-in ??
    implementsList      :boolean= true      // doesn't get mixed-in ??
    //--------------------------------------------------------------------------
    defineItem          (defn:any={}, opts:any={}   ):any {}
    //--------------------------------------------------------------------------
    forceEdit           (                           ):any {}
    onACTION            (func, name                 ):any {}
    formSubmit          (func                       ):any {}
    //--------------------------------------------------------------------------
    readP               (criteria:any={}            ):any {}
    readResult          (res:any                    ):any {}
    readDoneP           (                           ):any {}
    //--------------------------------------------------------------------------
    receivedItem        (values                     ):any {}
    setCache            (values                     ):any {}
    setItemValuesP      (values                     ):any {}
    //--------------------------------------------------------------------------
    onCopyP             (                           ):any {}
    //--------------------------------------------------------------------------
    onDeleteP           (                           ):any {}
    onDeleteDoneP       (res:any                    ):any {}
    //--------------------------------------------------------------------------
    onSubmitP           (key:string, values:any     ):any {}
    onSubmitCopyCheckP  (key:string, values:any     ):any {}
    onSubmitDoneP       (res:any                    ):any {}
    //--------------------------------------------------------------------------
    onKeyP              (key:string, value:any      ):any {}
    onKeyDoneP          (key:string, res:any        ):any {}
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
function applyMixins(base:any, mixins:any[], exclude:any=[]) {
    exclude                     = new Set(exclude)
    base                        = base.prototype
    mixins.forEach((mixin:any) => {
        mixin                   = mixin.prototype
        Object.getOwnPropertyNames(mixin).forEach((name:string) => {
            if (exclude.has(name)) {
//                 console.log('applyMixins EXCLUDING:', name)
            } else {
//                 console.log('applyMixins including:', name)
                base[name]    = mixin[name]
            }
        })
    })
}
//------------------------------------------------------------------------------
applyMixins(MixedBase, [ ItemComp ], ['constructor','ngOnInit','implementsList'])
//------------------------------------------------------------------------------
export class MixedComp extends MixedBase {
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn)
        this.defineList         (this.listDefn, true)

        return this.readP()
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
export const Mixed              =
{ Base                          : MixedBase
, Comp                          : MixedComp
, Div                           : MixedDiv
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
