//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { VrgService                                } from './service'
import  { VrgLst                , Vrg               } from './vrgLst'
import  { VrgAdr                                    } from './vrgAdr'
import  { VrgCmtLst                                 } from './vrgCmtLst'
import  { VrgDtl                                    } from './vrgDtl'
import  { VrgEvnLst                                 } from './vrgEvnLst'
import  { VrgLnkLst                                 } from './vrgLnkLst'
import  { VrgPpp                                    } from './vrgPpp'
import  { VrgPstLst             , VrgPst            } from './vrgPstLst'
//------------------------------------------------------------------------------
let c = [ VrgLst                , Vrg
        , VrgAdr
        , VrgCmtLst
        , VrgDtl
        , VrgEvnLst
        , VrgLnkLst
        , VrgPpp
        , VrgPstLst             , VrgPst
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ VrgService ]
    }
)
export class VrgModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
