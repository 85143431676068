//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , ChnService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'CrgRsrLst', template:ListDiv})
export class CrgRsrLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:ChnService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { rgn_name      : {lab:'region'             , wide: 5}
        , priority      : {lab:'priority'           , wide: 2, typ:'integer'}
        , rsr_name      : {lab:'warehouse'          , wide: 5}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'crg_rsr_load'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'CrgRsr', template:ItemDiv})
export class CrgRsr extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:ChnService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , chn_rgn_id    : {lab:'region'             , wide:12, cod:`chn_rgn:${this.ctx.chn_id}` }
        , priority      : {opt:'priority'           , wide:12, typ:'integer'}
        , rsr_id        : {lab:'warehouse'          , wide:12, cod:'rsr_loc'}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'crg_rsr_read'
    onSave_task                 = 'crg_rsr_save'
    onDelete_task               = 'crg_rsr_dele'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
