//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { RsrService                                } from './service'
import  { RsrLst                , Rsr               } from './rsrLst'
//------------------------------------------------------------------------------
let c = [ RsrLst                , Rsr
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ RsrService ]
    }
)
export class RsrModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
